export class Financier {
  id: number;
  value: string;

  constructor({ id, value }: FinancierDto) {
    this.id = id;
    this.value = value;
  }
}

interface FinancierDto {
  id: number;
  value: string;
}
