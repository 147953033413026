import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Drivers(props) {
  return (
    <SvgIcon viewBox="0 0 96 96" width="96px" height="96px" {...props}>

      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
        fill="#FFFFFF" stroke="none">
        <path d="M52 788 c-9 -9 -12 -83 -12 -269 0 -250 1 -258 21 -272 11 -8 28 -30
    37 -48 26 -50 65 -74 122 -74 58 0 97 24 123 78 l19 37 99 0 99 0 14 -33 c27
    -66 102 -100 170 -77 41 13 82 57 90 97 4 19 19 35 44 48 l37 20 3 89 c4 141
    -19 173 -142 198 l-70 13 -40 67 c-86 139 -82 138 -374 138 -164 0 -231 -3
    -240 -12z m228 -128 l0 -60 -80 0 -80 0 0 60 0 60 80 0 80 0 0 -60z m250 50
    c10 -5 32 -32 49 -60 l31 -50 -125 0 -125 0 0 60 0 60 76 0 c41 0 84 -4 94
    -10z m217 -204 c86 -19 93 -26 93 -102 0 -67 -13 -82 -41 -48 -51 61 -154 56
    -207 -11 -19 -24 -24 -25 -132 -25 -108 0 -113 1 -132 25 -43 56 -118 70 -175
    35 l-33 -20 0 80 0 80 283 0 c197 -1 301 -5 344 -14z m-487 -206 c11 -11 20
    -29 20 -40 0 -26 -34 -60 -60 -60 -26 0 -60 34 -60 60 0 11 9 29 20 40 11 11
    29 20 40 20 11 0 29 -9 40 -20z m480 0 c11 -11 20 -29 20 -40 0 -26 -34 -60
    -60 -60 -11 0 -29 9 -40 20 -11 11 -20 29 -20 40 0 11 9 29 20 40 11 11 29 20
    40 20 11 0 29 -9 40 -20z"/>
      </g>
    </SvgIcon >

  );
}