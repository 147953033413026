import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRange from '@material-ui/icons/DateRange';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import ValidationInput from '../ValidationInput';
import HeartWithPulse from '../../icons/HeartWithPulse';
import { setScheduleDetail } from '../../actions/medicalRecordAction';
import ScheduleApi from '../../api/ScheduleApi';
import './ScheduleDetail.css';
import './MedicalRecordList.css'

import config from '../../config/config';
const dateTimeSmallFormat = config.getDateTimeSmallFormat();
const dateTimeFormat = config.getDateTimeFormat();

class ScheduleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentWillMount() {
        this.setState({ loading: true });

        ScheduleApi.getScheduleModel(this.props.routeParams.scheduleId, data => {
            this.props.onSetScheduleDetail(data);
            this.setState({ loading: false });
        }, () => {
            this.setState({ loading: false });
        });
    }

    onBack = () => {
        browserHistory.goBack();
    }

    getPressureValue(vital_signs) {
        const pressureMaxValue = vital_signs.pressure.max ?? '-';
        const pressureMinValue = vital_signs.pressure.min ?? '-';
        return `${pressureMaxValue}/${pressureMinValue} mmHg`;
    }

    renderScheduleMetadata(metadata) {
        const { t } = this.props;
        if (metadata) {
            return (
                <Card md={6}>
                    <CardHeader icon>
                        <CardIcon color="primary">
                            <HeartWithPulse />
                        </CardIcon>
                        <h3 className="card-icon-title">{t('scheduleDetail.vital_signs')}</h3>

                    </CardHeader>
                    <CardBody className="schedule-detail-form">
                        <form>
                            {/* All vital Signs in map */}
                            {metadata?.vitalSignsList && metadata.vitalSignsList.map(vital_signs => {
                                return (
                                    <Card key={vital_signs.key}>
                                        <CardHeader className="vital-sign-various-head" color="default" icon>
                                            <h4 className="card-title card-int">{`${t('scheduleDetail.vital_signs.itemTittle')} ${vital_signs.timeRegistry.split(' ')[0]} ${t('scheduleDetail.vital_signs.itemTittle2')} ${moment(vital_signs.timeRegistry, dateTimeFormat).format('hh:mm a')}`}</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6}>
                                                    <ValidationInput text={t('scheduleDetail.vital_signs.temperature')} value={vital_signs.temperature ? `${vital_signs.temperature}° C` : '-'} disabled />
                                                </GridItem>
                                                <GridItem xs={12} sm={6}>
                                                    <ValidationInput text={t('scheduleDetail.vital_signs.pulse')} value={vital_signs.pulse ? `${vital_signs.pulse} ${t('scheduleDetail.vital_signs.pulse.unit')}` : '-'} disabled />
                                                </GridItem>
                                                <GridItem xs={12} sm={6}>
                                                    <ValidationInput text={t('scheduleDetail.vital_signs.breathing')} value={vital_signs.breathing ? `${vital_signs.breathing} %` : '-'} disabled />
                                                </GridItem>
                                                <GridItem xs={12} sm={6}>
                                                    <ValidationInput text={t('scheduleDetail.vital_signs.pressure')} value={this.getPressureValue(vital_signs)} disabled />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                )}
                            )}
                            <GridContainer>
                                <GridItem xs={12}>
                                    <ValidationInput
                                        text={t('scheduleDetail.note')}
                                        value={metadata.note}
                                        multiline
                                        disabled />
                                </GridItem>
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            )
        }
    }

    render() {
        const { t } = this.props;
        const { scheduleDetail } = this.props || {};

        return (
            <div className="schedule-detail">
                <GridContainer>
                    <GridItem xs={12} >
                        <Card md={6}>
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <DateRange />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('scheduleDetail')}</h3>
                            </CardHeader>

                            {this.state.loading ?
                                <div style={{
                                    display: 'flex',
                                    height: '200px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <CircularProgress />
                                </div>
                                :
                                <CardBody className="schedule-detail-form">
                                    <form>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.order')} value={scheduleDetail.order} disabled />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.state')} value={t(`scheduleDetail.state.${scheduleDetail.state}`)} disabled />
                                            </GridItem>

                                            {scheduleDetail.reasonRejectedDetail &&
                                                <GridItem xs={12}>
                                                    <ValidationInput text={t('scheduleDetail.reasonRejected')} value={scheduleDetail.reasonRejectedDetail} disabled />
                                                </GridItem>
                                            }

                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.dateVisit')} value={scheduleDetail.scheduleDateTime ? moment(scheduleDetail.scheduleDateTime).format(dateTimeSmallFormat) : ''} disabled />
                                                <ValidationInput text={t('scheduleDetail.dateVisitEnd')} value={scheduleDetail.scheduleEndDateTime ? moment(scheduleDetail.scheduleEndDateTime).format(dateTimeSmallFormat) : ''} disabled />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.realDateVisit')} value={scheduleDetail.startDateTime ? moment(scheduleDetail.startDateTime).format(dateTimeSmallFormat) : ''} disabled />
                                                <ValidationInput text={t('scheduleDetail.realDateVisitEnd')} value={scheduleDetail.endDateTime ? moment(scheduleDetail.endDateTime).format(dateTimeSmallFormat) : ''} disabled />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.type')} value={scheduleDetail.type === 'P' ? t('scheduleDetail.type.practice') : t('scheduleDetail.type.package')} disabled />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.speciality')} value={scheduleDetail.speciality} disabled />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <ValidationInput text={t('scheduleDetail.professional')} value={scheduleDetail.professional} disabled />
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardBody>
                            }
                        </Card>

                        {this.renderScheduleMetadata(scheduleDetail?.metadata)}
                    </GridItem>
                    <GridItem className="actions-buttons text-center">
                        <Button id="button-back" onClick={() => this.onBack()}>
                            {t('appointment.new.back')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        scheduleDetail: state.medicalRecord.scheduleDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetScheduleDetail: (data) => {
            dispatch(setScheduleDetail(data))
        }
    }
}

ScheduleDetail.propTypes = {
    t: PropTypes.func,
    routeParams: PropTypes.object,
    onSetScheduleDetail: PropTypes.func,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ScheduleDetail));
