import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { Financier } from "../../domain";
import { FinanciersRepository } from "../repositories";

@injectable()
export class GetFinanciersUsecase {
  @inject(FinanciersRepository) protected repository!: FinanciersRepository;

  async execute(): Promise<Either<GetFinanciersError, Financier[]>> {
    const result = await this.repository.getFinanciers();
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetFinanciersError {
    if (exception instanceof NotFoundException) {
      return new GetFinanciersNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetFinanciersConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetFinanciersOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetFinanciersAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetFinanciersBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetFinanciersUsecase");
  }
}

export abstract class GetFinanciersError {
  error?: string;
}

export class GetFinanciersNotFoundError extends GetFinanciersError { }
export class GetFinanciersConnectionError extends GetFinanciersError { }
export class GetFinanciersOperationalError extends GetFinanciersError { }
export class GetFinanciersAuthorizationError extends GetFinanciersError { }
export class GetFinanciersBadRequestError extends GetFinanciersError {
  constructor(public readonly error: string) {
    super();
  }
}
