import '../AppointmentTools.css';

import { Checkbox, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Button from '../../CustomButtons/Button';
import { Check } from '@material-ui/icons';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import styles from '../../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

class AppointmentToolsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            open: false,
            type: '',
            checked: [],
            setChecked: [],
            component: null,
            current: null,
            action:'',
            tools_hold:[],
            removedItems:[],
        }
    }

    componentDidMount(){
        const { componentAction, dates, selectedAppointment, removedItems, onGetAppointmentsTools, holdIds } = this.props;
        this.setState({ loading: true });
        if (componentAction === 'create'){
            this.getTools(dates.dateFrom, dates.dateTo, onGetAppointmentsTools);
        }
        if (componentAction === 'edit'){
            const from = selectedAppointment?.startDate ?? '';
            const to = selectedAppointment?.endDate ?? '';
            this.getTools(from, to, onGetAppointmentsTools);
        }
        const removed = removedItems.map(i => {
            return {
                ...i
            }
        });
        this.setState({ removedItems: removed, tools_hold: holdIds });
    }

    getTools(from, to, cb) {
        this.setState({ loading: true });
        AppointmentApiInvoker.getAppointmentTool(from, to, data => {
            this.setState({ loading: false });
            // Create Mode
            if(!data.length){
                return cb([]);
            }
            else {
                return cb(data);
            }
        }, () => {
            this.setState({ loading: false });
        })
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open });
    }

    cancel = () => {
        const { appointmentsTools } = this.props;
        const { tools_hold } = this.state;
        const list = [];
        appointmentsTools.map((i, _) => {
            if (tools_hold.indexOf(i.toolId) > -1) {
                list.push(i);
            }
        });
        this.props.onUndoSelectedTools(this.state.tools_hold, list);
        this.props.closeModal();
    }

    getOnChexkTools(row) {
        const { componentAction } = this.props;
        if (componentAction === 'create') {
            return this.props.onCheckAppointmentTools(row);
        }
        if (componentAction === 'edit') {
            return this.props.onCheckAppointmentToolsEdit(row);
        }
    }

    formatTableDataRow(row) {
        const { checkedToolsList, classes } = this.props;
        return {
            ...row,
            input:
                <Checkbox
                    id={`tool-${row.toolId}`}
                    tabIndex={-1}
                    onChange={() => this.getOnChexkTools(row)}
                    checked={checkedToolsList.indexOf(row.toolId) !== -1}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                />,
        }
    }

    tableData = () => {
        const { appointmentsTools, componentAction } = this.props;
        let availableTools = appointmentsTools.concat(this.state.removedItems);
        let tableData;

        if (componentAction === 'edit') {
            availableTools = availableTools.filter(p => this.state.tools_hold.indexOf(p.toolId) < 0);
        }
        tableData = availableTools.map(p => this.formatTableDataRow(p));
        return tableData;
    }
    
    render() {
        const { t } = this.props;
        return (
            <GridContainer id="modal-tools" className="appointment-modal-tools check-input">
                <GridItem xs={12}>
                    <Table
                        loading={this.state.loading}
                        tableHeaderColor="primary"
                        defaultPageSize={this.props.appointmentsTools.length > 5 ? 20 : 5}
                        tableHead={[
                            { Header: "#", accessor: 'toolId', sortable: false},
                            { Header: " ", accessor: 'input', sortable: false},
                            { Header: t('tools.type'), accessor: 'toolType.name', filterable: true, sortable: false},
                            { Header: t('tools.code'), accessor: 'toolType.toolTypeCode', filterable: true, sortable: false },
                            { Header: t('tools.serie.number'), accessor: 'serialNumber', sortable: false },
                            { Header: t('tools.detail'), accessor: 'detail', sortable: false }
                        ]}
                        sortable={false}
                        tableData={this.tableData()}
                        colorsColls={["primary"]}
                        showPaginationBottom={true}
                    />
                </GridItem>
                <GridItem xs={12} className="buttons-bottom-modal text-center">
                    <Button id="button-cancel-tools" onClick={() => this.cancel()} className="buttonPlusFooter" color="danger">
                        {t('common.cancel')}
                    </Button>
                    <Button id="button-accept-tools" onClick={() => this.props.closeModal()} className="buttonPlusFooter" color="primary">
                        {t('appointment.new.accept')}
                    </Button>
                </GridItem> 
            </GridContainer>    
        )
    }
}

AppointmentToolsModal.defaultProps = {
    appointmentsTools: [],
    checkedToolsList: [],
    selectedAppointment: {
        startDate: '',
        endDate: '',
    },
    componentAction: 'create',
    onCheckAppointmentToolsEdit: () => {}
}

AppointmentToolsModal.propTypes = {
    open: PropTypes.bool,
    onGetAppointmentsTools: PropTypes.func,
    onCheckAppointmentTools: PropTypes.func,
    onCheckAppointmentToolsEdit: PropTypes.func,
    appointmentsTools: PropTypes.array,
    checkedToolsList: PropTypes.array,
    componentAction: PropTypes.oneOf(['create', 'view', 'edit']),
    removedItems: PropTypes.array,
    dates: PropTypes.shape({
        dateFrom: PropTypes.string,
        dateTo: PropTypes.string,
    }),
    selectedAppointment: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    holdIds: PropTypes.array,
    onUndoSelectedTools: PropTypes.array,
    closeModal: PropTypes.func,
    classes: PropTypes.object,
    t: PropTypes.func,
}

export default withStyles(styles)(withTranslation()(AppointmentToolsModal));
