import './ModalSuggestedProfessionals.css';

import React, { Component } from 'react';

import Button from '../../../../CustomButtons/Button';
import CustomDialog from '../../../../CustomDialog';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import SuggestedProfessional from '../../../../SuggestedProfessional';
import { withTranslation } from 'react-i18next';

class ModalSuggestedProfessionals extends Component {
    constructor(props) {
        super(props);
        
        const { t } = this.props;
        const typesState = [{
            id: 1,
            value: t('Practice'),
        }, {
            id: 2,
            value: t('Package'),
        }];

        this.state = {
            open: false,
            types: typesState,
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            suggested: [],
            shifts: [1, 2, 3],
        }
    }

    componentDidMount() {
        this.fillSuggested();
    }
    componentWillReceiveProps(next) {
        this.fillSuggested();
        this.setState({ open: next.open })
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        })
        this.props.onCloseModal();
    }

    handleValue(value, shift, packageDetailId) {
        const { suggested } = this.state;
        const idxselected = suggested.findIndex(m => m.packageDetailId === packageDetailId && m.order === shift);

        if (idxselected !== -1) {
          const element = suggested[idxselected];
          element.filerByZone = value.filerByZone;
          element.practiceTypeId = value.practiceTypeId;
          element.suggestedEmployeeId = value.suggestedEmployeeId;
          this.setState({ suggested });
        }
    }

    fillSuggested = () => {
        if (this.state.suggested.length === 0) {
            const { module } = this.props;
            let sa = [];
            module.packageDetail.map(m => {
                let shifts = this.fillArray(m);
                shifts.forEach(t => {
                    let row = {}
                    row['packageDetailId'] = m.packageDetailId;
                    row['practiceTypeId'] = m.practiceType.practiceTypeId;
                    let detail = this.findSuggestedFrontItems(m.practiceType.practiceTypeId, t, shifts.length !== 1);

                    if (detail) {
                        row['suggestedEmployeeId'] = detail.suggestedEmployeeId;
                        row['filerByZone'] = detail.filerByZone;
                    } else {
                        row['suggestedEmployeeId'] = null;
                        row['filerByZone'] = true;
                    }
                    row['order'] = t;
                    sa.push(row);
                });
            });
            this.setState({ suggested: sa });
        }
    }

    findSuggestedFrontItems = (practice, shift, hasShift) => {
        const { itemRequestDetails } = this.props;
        if (!itemRequestDetails) {
            return null;
        }

        let foundItem;
        itemRequestDetails.forEach(requestDetail => {
            const isMatchingPractice = requestDetail.practiceTypeId === practice;
            const isMatchingShift = hasShift ? (isMatchingPractice && requestDetail.order === shift) : isMatchingPractice;
    
            if (isMatchingShift) {
                foundItem = requestDetail;
            }
        });
        return  foundItem;
    }

    fillArray = (item) => {
        const { estimatedDuration } = item.practiceType;
        const { processingScheduleType } = item.schedulePlan;
        const timeUnitsRequired = 1440 / estimatedDuration;
        const generateArray = (length, startValue) => Array.from({ length }, (_, idx) => idx + startValue);

        if (processingScheduleType === 'INTRADAY') {
            return generateArray(timeUnitsRequired, 1);
        }
        return [1];
    }

    render() {
        const { t, module, lender } = this.props;
        const self = this;
        return (
            <CustomDialog
                maxWidth="sm"
                title={t('suggested.professionals.title')}
                open={this.state.open}
                onClose={() => this.closeModal()}
                className="suggested-professionals-dialog"
            >
                <GridContainer className='ps-child modules-form assign-professionals-form'>
                    <GridItem xs={12}>
                        <InputLabel className="title-item title-module">
                            <small>{t('suggested.module.module')}</small>
                            {module.name}
                        </InputLabel>
                        {module.packageDetail.map((item, i) => {
                            const packageEmployees = this.state.suggested.filter(f => f.packageDetailId === item.packageDetailId);
                            const shifts = this.fillArray(item).map(item => {
                                const empl = packageEmployees.find(pe => pe.order === item);
                                return empl ?? item;
                            });
                            const key = i+1;
                            return (
                                <div className="speciality-group" key={key}>
                                    <h4 className="item-speciality-title">
                                        <small>{`${t('suggested.module.speciality')} ${key}`}</small>
                                        {`${item.practiceType.name}`}
                                    </h4>
                                    {shifts.map((shiftItem, idx) => {
                                        const indexKey = idx+1;
                                        const hasShift = shifts.length > 1;
                                        return (
                                        <div className="speciality-item speciality-item-padding" key={indexKey}>
                                            {hasShift &&
                                                <InputLabel className="title-item title-practice">
                                                    <small>{`${t('suggested.shift')} ${(indexKey)}:`}</small>
                                                </InputLabel>
                                            }
                                            <SuggestedProfessional
                                                customer={self.props.customer}
                                                hasShift={hasShift}
                                                order={indexKey}
                                                inline={true}
                                                lender={lender}
                                                itemRequestDetails={shiftItem}
                                                packageDetailId={item.packageDetailId}
                                                practiceType={item.practiceType.practiceTypeId}
                                                onChangeValue={(value) => this.handleValue(value, indexKey, item.packageDetailId)}
                                            />
                                        </div>)
                                    })}
                                </div> 
                            )
                        }
                    )}
                    </GridItem>
                    <GridItem className="schedule-form-submit" xs={12}>
                        <Button id="button-suggested-employees" color="primary" onClick={() => this.props.onConfirmModal(this.state.suggested)}>
                            {t('suggestedprofessionals.save')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CustomDialog>
        )
    }
}

ModalSuggestedProfessionals.propTypes = {
    t: PropTypes.func,
    onCloseModal: PropTypes.func,
    lender: PropTypes.number,
    module: PropTypes.object,
    itemRequestDetails: PropTypes.array,
    onConfirmModal: PropTypes.func,
}

export default withTranslation()(ModalSuggestedProfessionals);
