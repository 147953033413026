import React, { useEffect } from 'react';

import AppBar from "@material-ui/core/AppBar";
import Button from "../CustomButtons/Button.jsx";
import Hidden from "@material-ui/core/Hidden";
// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
// core components
import UserService from "../../containers/UserService";
import ViewList from "@material-ui/icons/ViewList";
import adminNavbarStyle from "../../assets/components/adminNavbarStyle.jsx";
import { browserHistory } from 'react-router';
import cx from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from 'react-i18next';

function AdminNavbar({ ...props }) {
  const { classes, color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });


  useEffect(() => {
    // code to run on component mount
    let cookiename = "sessionActive";
    let browserSessionId = window.sessionStorage.getItem("sessionId")
    // no se logueo en esta pestaña
    if (browserSessionId == null) {
      let cookiestring = RegExp(cookiename+"=[^;]+").exec(document.cookie);
      let key = decodeURIComponent(cookiestring.toString().replace(/^[^=]+./,""));
      if (key != null) {
        window.sessionStorage.setItem("sessionId",key);
      }
    }
    let log = setInterval(function () {

      let cookiestring = RegExp(cookiename+"=[^;]+").exec(document.cookie);
      if (window.location.href.includes("/login") || window.location.href.includes("/forgot-password")) {
        clearImmediate(log);
        return;
      }
      if (cookiestring == undefined || cookiestring == null) {
        browserHistory.push('/login');
        clearImmediate(log);
        return;
      }
      // browser cookies
      let browserSessionId = window.sessionStorage.getItem("sessionId")
      // no se logueo en esta pestaña
      if (browserSessionId == null) {
        return;
      }
      let key = null;
      if (cookiestring != null) {
        key = decodeURIComponent(cookiestring.toString().replace(/^[^=]+./,""));
      }
      if (key == null || browserSessionId != key) {
        browserHistory.push('/login');
        clearImmediate(log);
      }
    }, 5000);
  }, []);


  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

    document.addEventListener("cookieChanged", function (e) {
    });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {props.t(brandText)}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <UserService.NavbarService rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  t: PropTypes.func,
  brandText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  miniActive: PropTypes.bool,
  rtlActive: PropTypes.bool,
  sidebarMinimize: PropTypes.func,
};

export default withStyles(adminNavbarStyle)(withTranslation()(AdminNavbar));
