import './Navigator.css';

import React, { Component } from 'react';

import AdminNavbar from '../Navbars/AdminNavbar';
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Navbar from '../Navbar';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import routes from '../../routes';
import Button from '../CustomButtons/Button';
import ChatBubble from '@material-ui/icons/ChatBubble';
import Chat from '../Chat/index.js';

class Navigator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            miniActive: false,
            open: false,
            brandText: '',
            isChatVisible: false
        }
    }

    UNSAFE_componentWillReceiveProps() {
        this.setBrandText();
    }

    componentDidMount() {
        this.setBrandText();
    }

    isTheCorrectPath(route, browserPath) {
        if (!route.path.includes(':')) {
            return browserPath.lastIndexOf("/") === browserPath.indexOf(route.path);
        } else {
            let routeWithoutId = route.path.substring(0, route.path.lastIndexOf("/"));
            let broswerPathWithoutId = browserPath.substring(0, browserPath.lastIndexOf("/"));

            return broswerPathWithoutId === routeWithoutId;
        }
    }

    includeViewPath(path) {
        return path && browserHistory.getCurrentLocation().pathname.includes(path);
    }

    setBrandTextForView(pathname, routeViews) {
        routeViews.forEach(v => {
            if (!v.views) {
                if (pathname == v.path) {
                    this.setState({ brandText: v.name });
                }
                return;
            }
            v.views.forEach(view => {
                if (view.views) {
                    view.views.forEach(view2 => {
                        if (this.includeViewPath(view2.path)) {
                            this.setState({ brandText: view2.titlewindow ?? view2.name });
                        }
                    });
                    return;
                }
                if (this.includeViewPath(view.path)) {
                    this.setState({ brandText: (view.titlewindow ?? view.name) });
                }
            });
        });
    }

    setBrandText() {
        const pathname = browserHistory.getCurrentLocation().pathname;
        routes.forEach(route => {
            if (route.path && this.isTheCorrectPath(route, pathname)) {
                this.setState({ brandText: route.name });
                return;
            }
            if (route.views) {
                this.setBrandTextForView(pathname, route.views);
            }
        });
    }

    sidebarMinimize() {
        this.setState((prev) => ({ miniActive: !prev.miniActive }));
    }

    handleDrawerToggle() {
        this.setState((prev) => ({ open: !prev.open }));
    }
  
    startChat = () => {
        this.setState((prev) => ({ ...prev, isChatVisible: !prev.isChatVisible }));
    }

    render() {
        return (
            <div>
                <Navbar
                    miniActive={this.state.miniActive}
                    open={this.state.open}
                    handleDrawerToggle={() => this.handleDrawerToggle()}
                />
                <div>
                    <GridContainer>
                        <GridItem xs={12} className={this.state.miniActive ? "customers-content-header-mini" : "customers-content-header"}>
                            <AdminNavbar brandText={this.state.brandText}
                                sidebarMinimize={() => this.sidebarMinimize()}
                                miniActive={this.state.miniActive}
                                handleDrawerToggle={() => this.handleDrawerToggle()} />
                        </GridItem>
                    </GridContainer>
                    <div className={this.state.miniActive ? 'content-mini' : 'content'}>
                        {this.props.children}
                        <Button
                            id="button-signin"
                            onClick={this.startChat}
                            class="chat-floating">
                            <ChatBubble />
                        </Button>
                        <Chat login={false} isVisible={this.state.isChatVisible} />

                    </div>
                </div>
            </div>
        )
    }
}

Navigator.propTypes = {
    children: PropTypes.any,
}

export default Navigator;
