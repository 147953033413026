import { connect } from 'react-redux';
import IntegralTechnicalFile from '../components/IntegralTechnicalFile/IntegralTechnicalFile';
import { getTechnicalFile } from '../actions/technicalFile';

const mapStateToProps = (state) => {
    return {
        IntegralTechnicalFile: state.tools.tools,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetTechnicalFile: (tools) => {
            dispatch(getTechnicalFile (tools));
        },
    }
}

const IntegralTechnicalFileService = connect(
    mapStateToProps,
    mapDispatchToProps
)(IntegralTechnicalFile);

export default IntegralTechnicalFileService;
