export class ScheduleFee {
  id: number;
  scheduleId: number;
  scheduleDateTime: string;
  scheduleStatus: ScheduleStatus;
  practiceFeesId: number;
  isPaid: boolean;
  amount: number;

  constructor({ id, scheduleId, scheduleStatus, practiceFeesId, isPaid, amount, scheduleDateTime }: ScheduleFeeDto) {
    this.id = id;
    this.scheduleId = scheduleId;
    this.scheduleDateTime = scheduleDateTime;
    this.scheduleStatus = scheduleStatus;
    this.practiceFeesId = practiceFeesId;
    this.isPaid = isPaid;
    this.amount = amount;
  }
}

export interface ScheduleFeeDto {
  id: number;
  scheduleId: number;
  scheduleDateTime: string;
  scheduleStatus: ScheduleStatus;
  practiceFeesId: number;
  isPaid: boolean;
  amount: number;
}

export enum ScheduleStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  WITHOUT_ATTENTION = 'WITHOUT_ATTENTION',
  NOT_ATTENDED = 'NOT_ATTENDED',
}

export interface ScheduleFeeRequest {
  scheduleFeesId?: number;
  scheduleId: number;
  practiceFeesId?: number;
  scheduleStatus?: string;
  isPaid?: boolean;
  totalAmount?: number;
}
