import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from '../Table/Table';
import Button from '../CustomButtons/Button';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Assignment from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import './Modules.css';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';

class Modules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modules: [],
            loading: false,
        }
    }

    componentWillMount() {
        if (this.props.modules) {
            this.formatModules(this.props.modules);
            return;
        }
        this.getModules();
    }

    componentDidMount() {
        if (this.props.modules) {
            this.formatModules(this.props.modules);
        }
    }

    componentWillReceiveProps(next) {
        if (next.modules) {
            this.formatModules(next.modules);
        }
    }

    getModules() {
        this.setState({ loading: true })
        
        AppointmentApiInvoker.getPackagesActive(data => {
            if (data?.length) {
                this.props.onGetModules(data);
            }
            this.setState({ loading: false });
        }, () => {
            this.setState({ loading: false });
        });
    }

    formatModules(modules) {
        const { t } = this.props;
        let data = modules.map(m => {
            return {
                id: m.packageId,
                name: m.name,
                actions: <div className="modules-actions">
                    <Tooltip title={t('common.view')}><div><Button id={`button-view-${m.packageId}`} simple justIcon color="primary" authority="package_view" onClick={console.log('view')}><Assignment /></Button></div></Tooltip>
                    <Tooltip title={t('common.edit')}><div><Button id={`button-edit-${m.packageId}`} simple justIcon color="success" authority="package_edit" onClick={console.log('edit')}><Edit /></Button></div></Tooltip>
                    <Tooltip title={t('remove')}><div><Button id={`button-delete-${m.packageId}`} simple justIcon color="danger" authority="package_del" onClick={console.log('delete')}><Delete /></Button></div></Tooltip>
                </div>
            }
        });

        this.setState({ modules: data });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="modules">
                <GridContainer >
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <Assignment />
                                </CardIcon>
                                <Button id="button-add" className="modules-button" round justIcon color="success" authority="package_new" onClick={console.log('Add')}>
                                    <Add className="modules-button-icon" />
                                </Button>
                            </CardHeader>
                            <CardBody className="modules-content-body filtrable-table">
                                <Table
                                    filterable
                                    striped
                                    loading={this.state.loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        { Header: t('modules.table.id'), accessor: 'id' },
                                        { Header: t('modules.table.module'), accessor: 'name' },
                                        { Header: t('modules.table.actions'), accessor: 'actions', sortable: false, filterable: false }
                                    ]}
                                    tableData={this.state.modules}
                                    colorsColls={['primary']}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

Modules.propTypes = {
    t: PropTypes.func,
    onGetModules: PropTypes.func.isRequired,
    modules: PropTypes.array,
}

export default withTranslation()(Modules);
