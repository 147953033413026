import { useEffect, useState } from 'react';

import config from '../config/config';
import lodash from 'lodash';
import moment from 'moment';

const dateTimeFormat = config.getDateTimeFormat();
const dateTimeSmallFormat = config.getDateTimeSmallFormat();
const dateTimeFormatServer = config.getDateTimeFormatServer();

const filterIntegralSchedules = (schedules, filters) => {
  return schedules
    .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)))
    .filter((schedule) => {
      let accepted = true;
      if (filters.profesionals?.length) {
        accepted &= filters.profesionals.indexOf(schedule.employeeId) > -1;
      }
      if (filters.specialties?.length) {
        accepted &= filters.specialties.indexOf(schedule.practiceTypeId) > -1;
      }
      if (filters.dateFrom) {
        accepted &= moment(schedule.scheduleDateTime).isSameOrAfter(filters.dateFrom, 'day');
      }
      if (filters.dateTo) {
        accepted &= moment(schedule.scheduleEndDateTime).isSameOrBefore(filters.dateTo, 'day');
      }
      return accepted;
    })
}

// Change this if backend sends a different string for visited state
const visitedState = 'DONE';

const filterSchedules = (schedules, filters) => {
  return schedules.filter(a => {
    return a.state === visitedState;
  })
  .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)))
  .filter((schedule) => {
    let accepted = true;
    if (filters.profesional) {
      accepted &= schedule.professional === filters.profesional;
    }
    if (filters.speciality) {
      accepted &= schedule.speciality === filters.speciality;
    }
    if (filters.dateFrom) {
      accepted &= moment(schedule.startDateTime).isSameOrAfter(filters.dateFrom, 'day');
    }
    if (filters.dateTo) {
      accepted &= moment(schedule.startDateTime).isSameOrBefore(filters.dateTo, 'day');
    }
    return accepted;
  })
}

const computeSchedulesList = (schedules) => {


  // Hay 2 tipos posibles de "vital signs" uno viene en "vitalSignsList" 
  // para la nueva version de la app
  // y otro en "vital_signs" para viejas versiones de la app.
  const allVitalSignsNested = schedules.map(sc => {
    let vitalSignsList = lodash.get(sc, 'metadata.vitalSignsList');
    if (vitalSignsList){
      return vitalSignsList.map(vs => {
        if(!vs.timeRegistry) {
          return {
            ...vs,
            timeRegistry: moment(sc.scheduleDateTime).format(dateTimeFormat)
          }
        } else {
          return vs
        }
      })
    }
    let vitalSignsObject = lodash.get(sc, 'metadata.vital_signs');
    if (vitalSignsObject) {
      return [{
        ...vitalSignsObject,
        timeRegistry: moment(sc.scheduleDateTime).format(dateTimeFormat)
      }];
    }
    return [];
  })
  const allVitalSigns = lodash.flatten(allVitalSignsNested)
  const pulse = allVitalSigns.reduce((prev, curr) => {
    return {
      labels: prev.labels.concat([moment(curr.timeRegistry, dateTimeFormatServer).format(dateTimeSmallFormat)]),
      series: [prev.series[0].concat([curr.pulse])],
    }
  }, { labels: [], series: [[]] });

  const oxigen = allVitalSigns.reduce((prev, curr) => {
    return {
      labels: prev.labels.concat([moment(curr.timeRegistry, dateTimeFormatServer).format(dateTimeSmallFormat)]),
      series: [prev.series[0].concat([curr.breathing])],
    }
  }, { labels: [], series: [[]] });

  const arterialTension = allVitalSigns.reduce((prev, curr) => {
    return {
      labels: prev.labels.concat([moment(curr.timeRegistry, dateTimeFormatServer).format(dateTimeSmallFormat)]),
      series: [
        prev.series[0].concat([curr.pressure ? curr.pressure.max : '']),
        prev.series[1].concat([curr.pressure ? curr.pressure.min : '']),
      ],
    }
  }, { labels: [], series: [[], []] });
  const temperature = allVitalSigns.reduce((prev, curr) => {
    return {
      labels: prev.labels.concat([moment(curr.timeRegistry, dateTimeFormatServer).format(dateTimeSmallFormat)]),
      series: [prev.series[0].concat([curr.temperature])],
    }
  }, { labels: [], series: [[]] });
  return [pulse, oxigen, arterialTension, temperature];
}

/**
 * Use this hook in order to filter and _compute_ schedules into the shape of { labels: Array, series: Array } commonly used to display information in charts.
 * @param {Array<any>} schedules - Array of scheuldes
 * @param {{ profesional: any, speciality: any, dateFrom: any, dateTo: any }} filters - A hashmap of filters
 */
export function useVitalSigns(schedules, filters) {

  const [pulse, setPulse] = useState({ labels: [], series: [] })
  const [oxigen, setOxigen] = useState({ labels: [], series: [] })
  const [arterialTension, setArterialTension] = useState({ labels: [], series: [] })
  const [temperature, setTemperature] = useState({ labels: [], series: [] })

  useEffect(() => {
    const fitleredSchedules = filterSchedules(schedules, filters)
    const [computedPulse, computedOxigen, computedArterialTension, computedTemperature] = computeSchedulesList(fitleredSchedules)
    setPulse(computedPulse)
    setOxigen(computedOxigen)
    setArterialTension(computedArterialTension)
    setTemperature(computedTemperature)
  }, [schedules, filters.profesional, filters.speciality, filters.dateFrom, filters.dateTo])

  return [pulse, oxigen, arterialTension, temperature]
}

/**
 * Use this hook in order to filter and _compute_ schedules into the shape of { labels: Array, series: Array } commonly used to display information in charts.
 * @param {Array<any>} schedules - Array of scheuldes
 * @param {{ profesional: any, speciality: any, dateFrom: any, dateTo: any }} filters - A hashmap of filters
 */
export function useIntegralVitalSigns(schedules, filters) {

  const [pulse, setPulse] = useState({ labels: [], series: [] })
  const [oxigen, setOxigen] = useState({ labels: [], series: [] })
  const [arterialTension, setArterialTension] = useState({ labels: [], series: [] })
  const [temperature, setTemperature] = useState({ labels: [], series: [] })

  useEffect(() => {
    const fitleredSchedules = filterIntegralSchedules(schedules, filters)
    const [computedPulse, computedOxigen, computedArterialTension, computedTemperature] = computeSchedulesList(fitleredSchedules)
    setPulse(computedPulse)
    setOxigen(computedOxigen)
    setArterialTension(computedArterialTension)
    setTemperature(computedTemperature)
  }, [schedules, filters.profesionals, filters.specialties, filters.dateFrom, filters.dateTo])

  return [pulse, oxigen, arterialTension, temperature]
}