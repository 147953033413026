import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
} from 'react-google-maps';
import PinPrimary from '../../assets/img/pin-primary.png';
import PinSecondary from '../../assets/img/pin-secondary.png';

const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={8}
            center={{ lat: props.location.lat, lng: props.location.lng }}
            defaultOptions={{ scrollwheel: false }}
        >
            {props.directions && (
                <DirectionsRenderer
                    directions={props.directions}
                    options={{ suppressMarkers: true }}
                />
            )}
            {props.waypoints ?
                props.waypoints.map((d, i) => {
                    const key = i+1;
                    return (
                        <Marker
                            key={key}
                            className="routes-marker"
                            defaultLabel={{ text: (i + 1).toString(), fontSize: '15px', color: '#3C4858' }}
                            defaultIcon={
                                d.status.scheduleStatusId === 2
                                    ? {
                                        url: PinSecondary,
                                        scaledSize: { width: 40, height: 40 },
                                        size: { width: 40, height: 40 },
                                        labelOrigin: new window.google.maps.Point(20, 15),
                                    }
                                    : {
                                        url: PinPrimary,
                                        scaledSize: { width: 40, height: 40 },
                                        size: { width: 40, height: 40 },
                                        labelOrigin: new window.google.maps.Point(20, 15),
                                    }
                            }
                            position={d.location}
                            onClick={() => props.showWaypointDetail(d)}
                        />
                    )
                })
                : <div />
            }
        </GoogleMap>
    ))
)

export default RegularMap;
