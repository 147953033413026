import { connect } from 'react-redux';
import { getEmployee, getCustomers, getProvincies } from '../actions/reasignappointments';
import Filters from '../components/ReassignTransferAppointmentsStepper/components/AppointmentsStep/Filters';
import ReassignTransferAppointmentsStepper from '../components/ReassignTransferAppointmentsStepper';

const mapStateToProps = (state) => {
    return {
        profesionals: state.dashboardprofesional.employee,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCustomers: (customers) => {
            dispatch(getCustomers(customers));
        },
        onGetEmployeeTypes: (employees) => {
            dispatch(getEmployee(employees));
        },
        onGetProvincies: (provincies) => {
            dispatch(getProvincies(provincies));
        },
    }
}

const ReassignTransferAppointmentsStepperService = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReassignTransferAppointmentsStepper);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Filters);

export { ReassignTransferAppointmentsStepperService, FiltersService };
