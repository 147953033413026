import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import FolderShared from '@material-ui/icons/FolderShared';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import HistoriaClinicaApi from '../../api/HistoriaClinicaApi';
import './MedicalRecordList.css';

import config from '../../config/config';

const dateFormat = config.getDateFormat();

class MedicalRecordList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentWillMount() {        
        this.getHistoriasClinicas();
    }

    getHistoriasClinicas() {
        this.setState({ loading: true });

        HistoriaClinicaApi.getHistoriaClinicaList(this.props.routeParams.patientId,
            data => {
                this.setState({ loading: false });
                this.props.onSetList(data);
            }, error => {
                this.setState({ loading: false });              
            }, null);
    }

    sortDate = (a, b) => {
        const a1 = moment(a, dateFormat).valueOf();
        const b1 = moment(b, dateFormat).valueOf();
        if (a1 < b1)
         return -1;
        else if (a1 > b1)
            return 1;
        else
            return 0;                  
    }

    onBack = () => browserHistory.goBack();

    handleOnButtonAction = (data, action) => {
        browserHistory.push({
            pathname: `/patient/${this.props.routeParams.patientId}/medical-record/${data.id}/${action}`,
            state: { mode: action } 
        });
    }

    renderButtonsActions = (data) => {
        const { t } = this.props;
        return (
            <>
                <Tooltip title={t('medical_record.table.actions.consult')}>
                    <div className="d-inline">
                        <Button id={`button-view-${data.id}`} simple justIcon color="primary" authority="customers_view_mr_view_schedule" onClick={() => this.handleOnButtonAction(data, 'view')}>
                            <Assignment />
                        </Button>
                    </div>
                </Tooltip>
                
                {data.appointmentStatus !== 'CANCELLED' && data.appointmentStatus !== 'ENDED' && data.appointmentStatus !== 'OVERDUE' &&
                    <Tooltip title={t('medical_record.table.actions.edit')}>
                        <div className="d-inline">
                            <Button id={`button-edit-${data.id}`} simple justIcon color="primary" authority="customers_view_mr_edit_schedule" onClick={() => this.handleOnButtonAction(data, 'edit')}>
                                <Edit />
                            </Button>
                        </div>
                    </Tooltip>
                }
            </>
        )
    }

    render() {
        const { list } = this.props.medicalRecord || [];
        const { t } = this.props;
        const { loading } = this.state;
        return (
            <div className="schedules medical-records">
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <FolderShared fontSize="large"/>
                                </CardIcon>
                            </CardHeader>
                            <CardBody className="filtrable-table">
                                <Table
                                    filterable
                                    loading={loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        { Header: t('medical_record.table.request_number'), accessor: 'id' },
                                        { Header: t('medical_record.table.financier'), accessor: 'financier' },
                                        {
                                            id: 1,
                                            Header: t('medical_record.table.dateCreate'),
                                            accessor: row => row.dateCreated ? moment(row.dateCreated).format(dateFormat) : '',
                                            sortMethod: (a, b) => this.sortDate(a, b)
                                        },
                                        { Header: t('medical_record.table.requestor'), accessor: 'requestor' },
                                        { Header: t('medical_record.table.auditor'), accessor: 'auditor' },
                                        { 
                                            id: 2, 
                                            Header: t('medical_record.table.status'), 
                                            accessor: row => t(`appointment.table.status.${row.appointmentStatus}`) 
                                        },
                                        {
                                            id: 3,
                                            Header: t('medical_record.table.actions'),
                                            sortable: false,
                                            accessor: row => this.renderButtonsActions(row),
                                            filterable: false,
                                        }
                                    ]}
                                    colorsColls={["primary"]}
                                    tableData={list}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem className="actions-buttons text-center">
                        <Button id="button-back" onClick={() => this.onBack()}>
                            {t("appointment.new.back")}
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

MedicalRecordList.propTypes = {
    t: PropTypes.func.isRequired,
    routeParams: PropTypes.object,
    onSetList: PropTypes.func,
    medicalRecord: PropTypes.object,
}

export default withTranslation()(MedicalRecordList);
