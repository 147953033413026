import { connect } from 'react-redux';
import SupplyCatalog from '../components/SupplyCatalog';
import { unmountCatalogSupply,getSupplyCatalog} from '../actions/supplycatalog';

const mapStateToProps = (state) => {
    return {
        supplycatalog: state.supplycatalog.supplycatalog,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetSupplyCatalog: (supplycatalog) => {
            dispatch(getSupplyCatalog(supplycatalog));
        },
        onUnmountSupplyCatalog: () => {
            dispatch(unmountCatalogSupply());
        }
    }
}

const SupplyCatalogService = connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplyCatalog);

export default SupplyCatalogService;
