/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PersonApiInvoker from '../api/PersonApiInvoker';

export const usePersonDataTypes = (t) => {

  const [documentTypes, setDocumentTypes] = useState([]);
  const [genderTypes, setGenderTypes] = useState([]);
  const [nationalities, setNationalities] = useState([]);

  const formatDocumentTypes = (data) => data.map(d => ({
    id: d.idTypeId,
    value: d.name,
    name: `${d.name} - ${d.type}`,
  }));

  const getDocumentTypes = () => {
    PersonApiInvoker.getIdTypes(data => {
      const d = formatDocumentTypes(data);
      setDocumentTypes(d);
    }, null);
  }

  const formatGenderTypes = (genderTypes) => {
    const genderTypesValue = {
      UNDEFINED: t('label.undefined'),
      MALE: t('label.gender.male'),
      FEMALE: t('label.gender.female'),
    }
    return genderTypes.map(genderType => ({
      id: genderType,
      value: genderTypesValue[genderType],
    }));
  };

  const getGenderTypes = () => {
    PersonApiInvoker.getGenderTypes(data => {
      const d = formatGenderTypes(data);
      setGenderTypes(d);
    }, null);
  };

  const formatNationalities = (data) => data.map(nat => ({
    id: nat.nationalityId,
    name: nat.name,
  }))

  const getNationalities = () => {
    PersonApiInvoker.getNationalities(data => {
      const nat = formatNationalities(data)
      setNationalities(nat);
    }, null);
  }

  useEffect(() => {
    getDocumentTypes();
    getGenderTypes();
    getNationalities();
    return () => {
      setDocumentTypes([]);
      setGenderTypes([]);
      setNationalities([]);
    }
  }, []);

  return {
    documentTypes,
    genderTypes,
    nationalities,
  }
}