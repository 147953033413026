import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Table from '../../../../Table/Table';
import config from  '../../../../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateTimeFormat = config.getDateTimeFormat();

class VitalSignsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schedulesVitalSigns: this.props.scheduleVitalSigns || {},
            vitalSignsOne: [],
            scheduleSelected: this.props.scheduleSelected || null,
        }
    }

    componentDidMount(){
        this.specificVitalSigns(this.state.schedulesVitalSigns);
    }

    buildDataOnVitalSignsList(schedVS, result) {
        const { vitalSignsList } = schedVS.metadata;
        return vitalSignsList.map(vs => {
            const isPressure = !!(vs.pressure.min || vs.pressure.max);
            const pressure = (isPressure) ? `${vs.pressure.max || '-'}/${vs.pressure.min || '-'}` : result.pressure;
            const isTimeRegistry = !!vs.timeRegistry;
            return {
                date: isTimeRegistry ? moment(vs.timeRegistry, dateTimeFormat).format(dateFormat): moment(schedVS.scheduleDateTime).format(dateFormat),
                hour: isTimeRegistry ? moment(vs.timeRegistry, dateTimeFormat).format('hh:mm:ss a'): moment(schedVS.scheduleDateTime).format('hh:mm:ss a'),
                temperature: vs.temperature || result.temperature,
                breathing: vs.breathing || result.breathing,
                pulse: vs.pulse || result.pulse,
                pressure,
            }
        });
    }

    buildDataOnVitalSigns(schedVS, result) {
        const isPressure = schedVS?.metadata?.vital_signs?.pressure?.min || schedVS?.metadata?.vital_signs?.pressure?.max;
        const pressure = isPressure ? `${schedVS?.metadata?.vital_signs?.pressure?.max || '-'}/${schedVS?.metadata?.vital_signs?.pressure?.min || '-'}` : result.pressure;
        result = [{
            date: moment(schedVS.scheduleDateTime).format(dateFormat),
            hour: moment(schedVS.scheduleDateTime).format('hh:mm:ss a'),
            temperature: schedVS?.metadata?.vital_signs?.temperature || result.temperature,
            breathing: schedVS?.metadata?.vital_signs?.breathing || result.breathing,
            pulse: schedVS?.metadata?.vital_signs?.pulse || result.pulse,
            pressure,
        }];
        return result;
    }
    
    specificVitalSigns = async (schedules) => {
        const { t } = this.props;
        if (schedules?.length) {
            schedules.filter(schedFt => schedFt.scheduleId === this.state.scheduleSelected).forEach(schedVS => {
                const noDataText = t('integralDashboardClinical.modal.undefined');
                let result = [{
                    date: moment(schedVS.scheduleDateTime).format(dateFormat),
                    hour: moment(schedVS.scheduleDateTime).format('hh:mm:ss a'),
                    temperature: noDataText,
                    breathing: noDataText,
                    pulse: noDataText,
                    pressure: noDataText,
                }];
                if(schedVS?.metadata?.vitalSignsList) {
                    result = this.buildDataOnVitalSignsList(schedVS, result);
                }
                if (schedVS?.metadata && schedVS?.vital_signs) {
                    result = this.buildDataOnVitalSigns(schedVS, result);
                }
                this.setState({ vitalSignsOne: result });
            });
        }
    }
    
    render() {
        const { t }  = this.props;
        const { loading, vitalSignsOne } = this.state;
        return (
            <Table
                loading={loading}
                tableHeaderColor="primary"
                filtrable
                sortable
                tableHead={[
                    { Header: t('common.date'), accessor: 'date'},
                    { Header: t('integralDashboardClinical.modal.table.hour'), accessor: 'hour' },
                    { Header: `${t('integralDashboardClinical.modal.table.temperature')} (°C)`, accessor: 'temperature' },
                    { Header: `${t('integralDashboardClinical.modal.table.breathing')} (%)`, accessor: 'breathing' },
                    { Header: `${t('integralDashboardClinical.modal.table.pulse')} (ppm)`, accessor: 'pulse' },
                    { Header: `${t('integralDashboardClinical.modal.table.pressure')} (mmHg)`, accessor: 'pressure'}
                ]}
                defaultPageSize={vitalSignsOne?.length > 5 ? 20 : 5}
                tableData={vitalSignsOne}
                colorsColls={["primary"]}
                showPaginationBottom={true}
            />
        );
    }
}

VitalSignsModal.propTypes = {
    t: PropTypes.func,
    scheduleVitalSigns: PropTypes.object,
    scheduleSelected: PropTypes.object,
}

export default withTranslation()(VitalSignsModal)