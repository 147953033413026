import React, { Component } from 'react';

import ButtonSpinner from '../../ButtonSpinner';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import TimePickerGCalendar from '../../TimePickerGCalendar';
import ValidationInput from '../../ValidationInput';
import config from '../../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateToServer = config.getDateToServer();

class ScheduleSystemFinished extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: null,
      professionals: [],
      errors: [],
      disabled: false,
      status: '',
      hasChanged: false,
      validation: false,
      validationResult: null,
      validating: false,
      busy: false,
      alertColor: 'info',
      startDate: '',
      endDate: '',
      endTime: '',
      startTime: '',
      alertMessage: '',
      alertOpen: false,
      showOpenCancel: false,
      cancel_motive: '',
    }
  }

  componentDidMount() {
    const { current } = this.props;
    current.startTime = current.startTime ? moment(current.startTime, 'HH:mm:ss').format('hh:mm a') : '';
    current.endTime = current.endTime ? moment(current.endTime, 'HH:mm:ss').format('hh:mm a') : '';
    this.setState({ current });
  }

  confirmCancel = () => {
    const { arterialTensionMax, arterialTensionMin, breathing, endDate, startTime, endTime, note, pulse, temperature } = this.state;
    const ddStart = moment(this.props.current.scheduleDateTime).format(dateToServer);
    
    const startDateTime  = moment(`${ddStart} ${startTime}`);
    const endTime24 = moment(endTime, 'hh:mm a').format('HH:mm')

    const ddEnd = moment(endDate).format(dateToServer);
    const endDateTime  = moment(`${ddEnd}T${endTime24}`);

    this.props.cancelBySystem({
      "startDate": startDateTime,
      "endDate": endDateTime,
      "startTime": startTime,
      "endTime": endTime,
      "temperature": temperature,
      "pulse": pulse,
      "arterialTensionMax": arterialTensionMax,
      "arterialTensionMin": arterialTensionMin,
      "breathing": breathing,
      "note": note,
    });
  }

  handleValue = (value, state) => {
    let val = value;
    if (val) {
      if ((val === '0' || val === 0) && (state === 'startTime' || state === 'endTime')) {
        val = '12:00 am';
      }
      this.setState({ [state]: val }, () => { 
        this.evaluateFieldSig();
      });
    }
  }

  evaluateFieldSig = () => {
    const { arterialTensionMax, arterialTensionMin, breathing, temperature, pulse } = this.state;
    this.setState({
      errorTemperature: false,
      errorPulse: false,
      errorOxigen: false,
      errorArterialTensionMin: false,
      errorArterialTensionMax: false
    })
    if (temperature != '' && (temperature  < 34 || temperature > 45)) {
      this.setState({ errorTemperature: true });
    }
    if (pulse != '' && (pulse  < 45 || pulse > 200)) {
      this.setState({ errorPulse: true });
    }
    if (breathing != '' && (breathing  < 50 || breathing > 100)) {
      this.setState({ errorOxigen: true });
    }
    if (arterialTensionMin != '' && (arterialTensionMin  < 45 || arterialTensionMin > 170)) {
      this.setState({ errorArterialTensionMin: true });
    }
    if (arterialTensionMax != '' && (arterialTensionMax  < 80 || arterialTensionMax > 200)) {
      this.setState({ errorArterialTensionMax: true });
    }
  }

  evaluateField = () => {
    const { endDate, startTime, endTime, errorTemperature, errorPulse, errorOxigen, errorArterialTensionMin, errorArterialTensionMax } = this.state;
    const isDisabled = endDate == '' || startTime == '' || endTime == '' || errorTemperature || errorPulse || errorOxigen || errorArterialTensionMin || errorArterialTensionMax;
    return isDisabled;
  }

  render() {
    const { t, current, scheduleId } = this.props; // readOnly
    const {
      arterialTensionMax, breathing, endDate, endTime, errorArterialTensionMax, errorOxigen,
      errorPulse, errorTemperature, pulse, startTime, temperature, errorArterialTensionMin,
      arterialTensionMin, note,
    } = this.state;
    const disabled = this.evaluateField();

    const startDateTimeReal = moment(current.scheduleDateTime).format(dateToServer);
    const minDate = startDateTimeReal;
    const maxDate = moment(current.scheduleDateTime).add(2, 'days').format(dateToServer);

    return (
      <div className="booking-detail-form system-finished">
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <h4>{t('schedule.finish.system.dialog.subtitle1')}</h4>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.employee')}
              value={`${current.employeeLastName} ${current.employeeFirstName}`}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.scheduleId')}
              value={scheduleId}
              disabled
            />
          </GridItem>
          
          <GridItem xs={12} sm={6}>
            <DateInput
              minDate={true}
              min={minDate}
              max={maxDate}
              maxDate={true}
              disabled={true}
              text={t('schedule.finish.system.dialog.startDate.real')}
              onChangeValue={(value) => this.handleValue(value, 'startDate')}
              value={startDateTimeReal}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <DateInput
              minDate={true}
              min={minDate}
              max={maxDate}
              maxDate={true}
              text={t('schedule.finish.system.dialog.endDate.real')}
              value={endDate}
              onChangeValue={(value) => this.handleValue(value, 'endDate')}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className="start-time system-fin">
            <TimePickerGCalendar
              name="booking_finished_start_time"
              text={t('schedule.finish.system.dialog.startTime.real')}
              value={startTime}
              onSelectedValue={(value) => this.handleValue(value, 'startTime')}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className="end-time system-fin">
            <TimePickerGCalendar
              name="booking_finished_end_time"
              text={t('schedule.finish.system.dialog.endTime.real')}
              value={endTime}
              onSelectedValue={(value) => this.handleValue(value, 'endTime')}
            />
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12}>
          <h4>{t('schedule.finish.system.dialog.subtitle2')}</h4>
        </GridItem>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              type="number"
              invalid={temperature < 1 || !temperature}
              value={temperature}
              text={t('schedule.finish.system.dialog.temperature')}
              errorText={errorTemperature ? t('schedule.finish.system.dialog.placeHolder.temperature') : null}
              placeHolder={t('schedule.finish.system.dialog.placeHolder.temperature')}
              onChangeValue={(value) => this.handleValue(value, 'temperature')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.pulse')}
              type="number"
              placeHolder={t('schedule.finish.system.dialog.placeHolder.pulse')}
              errorText={errorPulse ? t('schedule.finish.system.dialog.placeHolder.pulse') : null}
              value={pulse}
              invalid={errorPulse}
              onChangeValue={(value) => this.handleValue(value, 'pulse')}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className="no-padding">
            <GridItem xs={12}>
              <ValidationInput
                text={t('schedule.finish.system.dialog.arterialTensionMax')}
                type="number"
                placeHolder={t('schedule.finish.system.dialog.placeHolder.arterialTensionMax')}
                errorText={errorArterialTensionMax ? t('schedule.finish.system.dialog.placeHolder.arterialTensionMax') : null}
                invalid={errorArterialTensionMax}
                value={arterialTensionMax}
                onChangeValue={(value) => this.handleValue(value, 'arterialTensionMax')}
              />
            </GridItem>
            <GridItem xs={12}>
              <ValidationInput
                text={t('schedule.finish.system.dialog.arterialTensionMin')}
                placeHolder={t('schedule.finish.system.dialog.placeHolder.arterialTensionMin')}
                errorText={errorArterialTensionMin ? t('schedule.finish.system.dialog.placeHolder.arterialTensionMin') : null}
                type="number"
                invalid={errorArterialTensionMin}
                error={true}
                value={arterialTensionMin}
                onChangeValue={(value) => this.handleValue(value, 'arterialTensionMin')}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              type="number"
              value={breathing}
              placeHolder={t('schedule.finish.system.dialog.placeHolder.oxigen')}
              errorText={errorOxigen ? t('schedule.finish.system.dialog.placeHolder.oxigen') : null}
              text={t('schedule.finish.system.dialog.oxigen')}
              invalid={errorOxigen}
              onChangeValue={(value) => this.handleValue(value, 'breathing')}
            />
          </GridItem>
          <GridItem xs={12}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.add.note.clinic')}
              value={note}
              onChangeValue={(value) => this.handleValue(value, 'note')}
            />
          </GridItem>
        </GridContainer>
        <GridItem xs={12} style={{ textAlign:'right' }}>
          <ButtonSpinner
            onClick={() => this.confirmCancel()}
            label={t('schedule.finish.system.dialog.save')}
            typeButton="submit"
            disabled={disabled}
            color="primary"
            id="button-confirm"
          />
        </GridItem>
      </div>

    )
  }
}

ScheduleSystemFinished.propTypes = {
  t: PropTypes.func,
  scheduleId: PropTypes.number,
  current: PropTypes.object,
  cancelBySystem: PropTypes.func,
}

export default withTranslation()(ScheduleSystemFinished);
