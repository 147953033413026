import { MEDICAL_RECORD, SCHEDULE_DETAIL } from "../actions/medicalRecordAction";

const initialState = {
    list: [],
    scheduleDetail:{}
  };

const medicalRecord = (state = initialState, action) => {
    switch(action.type) {
        case MEDICAL_RECORD: {
            let list = action.list
            return {...state, list}
        }
        case SCHEDULE_DETAIL: {
            let scheduleDetail = action.data
            return {...state, scheduleDetail}
        }
        default:
            return state;
    }
}

export default medicalRecord;
