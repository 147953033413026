import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SnackbarColor, TermsPolicyType } from '../../util/interfaces';
import { TermsPolicyProfile, useTermsAndPolicy } from '../../hooks';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../CustomButtons/Button';
import CheckInput from '../CheckInput';
import CustomSweetAlert from '../CustomSweetAlert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import TermsAndPolicyModal from '../TermsAndPolicyModal';

export interface DataCloseTerms {
  isAccept: boolean;
  error?: {
    isError?: boolean;
    message?: string;
  }
}
type ComponentProps = {
  data: TermsPolicyProfile;
  onCloseTermsPolicy: (data: DataCloseTerms) => void;
  isLoggedUser: boolean;
}

type TermsPolicy = {
  show: boolean;
  policyText?: string;
  termsText?: string;
  type: TermsPolicyType;
}

const useStyles = makeStyles(() => ({
  spaceAround: {
    justifyContent: "space-around",
  },
  loadProfile: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .check-label .MuiFormControlLabel-label': {
      fontSize: '.95rem',
      display: 'block',
      '& .MuiButtonBase-root': {
        color: '#022E5E !important',
        padding: 0,
        fontSize: '.95rem',
        textDecoration: 'underline',
        height: 'auto',
        textTransform: 'none',
        margin: 0,
        display: 'inline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  submitTerms: {
    padding: '45px 15px 0 !important',
    textAlign: 'center',
  },
  progressButton: {
    color: 'white',
    marginLeft: 0,
    '& svg': {
      width: 18,
      height: 18,
    }
  },
  alertWarning: { marginTop: '-200px !important' },
}))

const initTermsPolicy = {
  show: false,
  type: TermsPolicyType.TERMS_POLICY,
}

const TermsAndConditionsForm = ({ data, onCloseTermsPolicy, isLoggedUser }: ComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [acceptCheck, setAcceptCheck] = useState<boolean>(false);
  const [termsPolicy, setTermsPolicy] = useState<TermsPolicy>(initTermsPolicy);
  const [loading, setLoading] = useState<boolean>(true);

  const handleClose = (): void => setTermsPolicy((prev) => ({ ...prev, show: false }));

  const hookParams = {
    onCloseTerms: handleClose,
    onCloseModal: handleClose,
    isLoggedUser,
  };
  
  const {
    snackbar,
    busy,
    updateTermsPolicy,
    alertWarning,
    openSnackbar,
    setAlertWarning,
  } = useTermsAndPolicy(hookParams);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
    
    return () => {
      setAcceptCheck(false);
      setTermsPolicy(initTermsPolicy);
      setLoading(true);
    }
  }, [])

  const handleTermsPolicy = async (isAccept = true): Promise<void> => {
    if (!acceptCheck) {
      const message = t('termsPolicy.checkWarning');
      openSnackbar(SnackbarColor.warning, message);
      return;
    }
    if (isAccept) {
      await updateTermsPolicy(true, termsPolicy.type);
      onCloseTermsPolicy({ isAccept: true });
      return;
    }
    onRejectTermsPolicyPolicy();
  }

  const onRejectTermsPolicyPolicy = (): void => {
    const message = t('termsPolicy.rejectedWarning.message');
    const title = t('termsPolicy.rejectedWarning.title');
    setAlertWarning({ show: true, message, title });
  }

  const onConfirmRejectedWarning = async (): Promise<void> => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
    await updateTermsPolicy(false, termsPolicy.type);
    setTimeout(() => {
      onCloseTermsPolicy({ isAccept: false });
    }, 1000);
  }

  const onCancelRejectedWarning = (): void => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
  }

  const buildButtonAccept = (): JSX.Element => {
    const acceptText = t('common.saving');
    return <Button
      color="primary"
      onClick={() => handleTermsPolicy()}
      id="button-accept"
      disabled={busy}
    >
      {busy
        ? <><CircularProgress className={classes.progressButton} size={18} /> <span className="labelCont">{acceptText}</span></>
        : t('common.accept')
      }
    </Button>
  }
  
  const renderTermsPolicyToAccept = (): JSX.Element => {
    const label = <Trans
      i18nKey="termsPolicy.checkTextAccept"
      values={{ termsText: t('termsUse.mainTitle'), policyText: t('policy.mainTitle') }}
      components={[
        <Button key="1" link onClick={() => setTermsPolicy({ show: true, type: TermsPolicyType.TERMS })} />,
        <Button key="2" link onClick={() => setTermsPolicy({ show: true, type: TermsPolicyType.POLICY })} />,
      ]}
    />

    return (
      <>
        <Snackbar
          place="tr"
          color={snackbar.color}
          message={snackbar.message}
          open={snackbar.open}
        />
        <GridContainer className={classes.spaceAround}>
          <GridItem xs={12} sm={8}>
            <CheckInput
              onChangeValue={() => setAcceptCheck(!acceptCheck)}
              checked={acceptCheck}
              label={label}
              labelPlacement="end"
            />
          </GridItem>
          <GridItem xs={12} sm={8} className={classes.submitTerms}>
            <Button
              color="danger"
              onClick={() => handleTermsPolicy(false)}
              id="button-reject"
            >{t('common.reject')}</Button>
            {buildButtonAccept()}
          </GridItem>
        </GridContainer>
      </>
    )
  }

  return (
    <>
      <div className={classes.loadProfile}>
        {(!data.requiereTermsAccept || loading)
          ? <CircularProgress className="circular-progress" />
          : renderTermsPolicyToAccept()
        }
      </div>
      {termsPolicy.show && <TermsAndPolicyModal
        open={termsPolicy.show}
        type={termsPolicy.type}
        onCloseTerms={() => handleClose()}
        onCloseModal={() => handleClose()}
        showButtons={false}
        isLoggedUser={false}
      />}
      {alertWarning.show &&
        <CustomSweetAlert
          className={classes.alertWarning}
          type="warning"
          confirmBtnCssClass="danger"
          confirmBtnText={t('common.reject')}
          onConfirm={() => onConfirmRejectedWarning()}
          showCancel={true}
          cancelBtnText={t('common.cancel')}
          onCancel={() => onCancelRejectedWarning()}
          message={
            <>
              {alertWarning?.title && <h5>{alertWarning?.title}</h5>}
              <p>{alertWarning.message}</p>
            </>
          }
        />
      }
    </>
  )
}

TermsAndConditionsForm.propTypes = {
  data: PropTypes.object,
  onCloseTermsPolicy: PropTypes.func,
  isLoggedUser: PropTypes.bool,
}

export default TermsAndConditionsForm;
