import { AlertWarning, SnackbarColor, SnackbarProps, TermsPolicyType } from "../util/interfaces";
import { GetTermsAndPolicyBadRequestError, GetTermsAndPolicyConnectionError, GetTermsAndPolicyError, GetTermsAndPolicyNotFoundError, GetTermsAndPolicyUsecase, TermPolicy, UpdateUserTermsBadRequestError, UpdateUserTermsConnectionError, UpdateUserTermsError, UpdateUserTermsNotFoundError, UpdateUserTermsOperationalError, UpdateUserTermsUsecase } from "../core";
import { TermsPolicyProfile, useTermsPolicyProfile } from "./useTermsPolicyProfile";
import { initialTerms, initialValueAlert, initialValueSnackbar } from "../util/constants";

import { DIContainer } from "../Root";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type onCloseTermsParams = {
  isAccept?: boolean;
  error?: {
    message: string;
    isError: boolean;
    isErrorUpdated?: boolean;
  };
}

type ComponentsProps = {
  onCloseTerms: ({ isAccept, error }: onCloseTermsParams) => void;
  onCloseModal?: () => void;
  isLoggedUser: boolean;
  data?: TermsPolicyProfile;
}

export const useTermsAndPolicy = ({ onCloseTerms, onCloseModal, isLoggedUser = false }: ComponentsProps) => {
  const { saveTermsPolicyOnProfile, getTermsAndPolicyFromProfile } = useTermsPolicyProfile();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);
  const [alertWarning, setAlertWarning] = useState<AlertWarning>(initialValueAlert);
  const [busy, setBusy] = useState<boolean>(false);
  const [termsPolicy, setTermsPolicy] = useState<TermPolicy>(initialTerms);

  const getUsecase = DIContainer.get<GetTermsAndPolicyUsecase>(GetTermsAndPolicyUsecase);
  const updateUsecase = DIContainer.get<UpdateUserTermsUsecase>(UpdateUserTermsUsecase);

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 5000);
  }

  const getTexts = (requestTermsVersion: string) => {
    return {
      TERMS: {
        titleModal: t('termsUse.title', { number: requestTermsVersion }),
        rejectedSuccess: t('termsUse.rejectedSuccess'),
        rejectedWarningMessage: t('termsUse.rejectedWarning.message'),
        rejectedWarningTitle: t('termsUse.rejectedWarning.title'),
        badRequestMessage: t('termsUse.badRequestMessage'),
      },
      POLICY: {
        titleModal: t('policy.title', { number: requestTermsVersion }),
        rejectedSuccess: t('policy.rejectedSuccess'),
        rejectedWarningMessage: t('policy.rejectedWarning.message'),
        rejectedWarningTitle: t('policy.rejectedWarning.title'),
        badRequestMessage: t('policy.badRequestMessage'),
      },
      TERMS_POLICY: {
        titleModal: t('termsUse.title', { number: requestTermsVersion }),
        rejectedSuccess: t('termsPolicy.rejectedSuccess'),
        rejectedWarningMessage: t('termsPolicy.rejectedWarning.message'),
        rejectedWarningTitle: t('termsPolicy.rejectedWarning.title'),
        badRequestMessage: t('termsPolicy.badRequestMessage'),
      }
    }
  }

  const getDataErrors = (dataError: GetTermsAndPolicyError): void => {
    let message = t('termsPolicy.errorServer');
    if (dataError instanceof GetTermsAndPolicyNotFoundError) {
      message = t('termsPolicy.notFoundMessage');
    }
    if (dataError instanceof GetTermsAndPolicyConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof GetTermsAndPolicyBadRequestError) {
      message = dataError.error;
    }
    onCloseTerms({ error: { isError: true, message } });
  }

  const getVersionOnError = (message: string): string => {
    const match = message.match(/"([^"]*)"/);
    if (!match) {
      return '';
    }
    const textBetweenQuotes = match[1];
    return textBetweenQuotes;
  }

  const buildOnGetBadResponse = (message: string): void => {
    const requestVersion = getVersionOnError(message);
    if (!requestVersion) {
      setLoading(false);
      return;
    }
    const dataTerms = getTermsAndPolicyFromProfile();
    saveTermsPolicyOnProfile({ ...dataTerms, requiereTermsAccept: true, requestTermsVersion: requestVersion });
    setLoading(false);
    onCloseTerms({ error: { isErrorUpdated: true, isError: true, message } });
  }

  const getTermsAndPolicy = async (termsProfile: TermsPolicyProfile): Promise<void> => {
    setLoading(true);
    const result = await getUsecase.execute(termsProfile.requestTermsVersion);
    if (result.isLeft()) {
      const dataError = result.getLeft();
      if (isLoggedUser && dataError instanceof GetTermsAndPolicyBadRequestError) {
        buildOnGetBadResponse(dataError.error);
        return;
      }
      getDataErrors(dataError);
      setLoading(false);
      return;
    }
    const dataResult = result.getRight();
    setTermsPolicy(dataResult);
    setLoading(false);
  }

  const getUpdateDataErrors = (dataError: UpdateUserTermsError, type: TermsPolicyType, requestTermsVersion: string): void => {
    let message = t('termsPolicy.errorServer');
    if (dataError instanceof UpdateUserTermsNotFoundError) {
      message = t('termsPolicy.notFoundMessage');
    }
    if (dataError instanceof UpdateUserTermsConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof UpdateUserTermsBadRequestError || dataError instanceof UpdateUserTermsOperationalError) {
      message = getTexts(requestTermsVersion)[type].badRequestMessage;
    }
    openSnackbar(SnackbarColor.danger, message);
  }

  const updateTermsPolicy = async (isAccept: boolean, type: TermsPolicyType): Promise<void> => {
    const dataTerms = getTermsAndPolicyFromProfile();
    setBusy(true);
    const result = await updateUsecase.execute(dataTerms.userId, dataTerms.requestTermsVersion, isAccept);
    if (result.isLeft()) {
      const dataError = result.getLeft();
      getUpdateDataErrors(dataError, type, dataTerms.requestTermsVersion);
      setBusy(false);
      return;
    }
    result.getRight();
    setBusy(false);
    saveTermsPolicyOnProfile({ ...dataTerms, requiereTermsAccept: !isAccept });
    onCloseTerms({ isAccept });
  }

  const onRejectTermsPolicy = (type: TermsPolicyType): void => {
    const dataTerms = getTermsAndPolicyFromProfile();
    const message = getTexts(dataTerms.requestTermsVersion)[type].rejectedWarningMessage;
    const title = getTexts(dataTerms.requestTermsVersion)[type].rejectedWarningTitle;
    setAlertWarning({ show: true, message, title });
  }

  const onClose = () => {
    if (onCloseModal) {
      onCloseModal();
    }
  }

  return {
    loading,
    snackbar,
    alertWarning,
    termsPolicy,
    busy,
    setAlertWarning,
    onRejectTermsPolicy,
    updateTermsPolicy,
    onClose,
    openSnackbar,
    getTexts,
    getTermsAndPolicy,
  }
}
