export class TermPolicy {
  id: number;
  companyId: number;
  contentTerms: string;
  contentPolicy: string;
  version: string;

  constructor({ id, companyId, version, contentTerms, contentPolicy }: TermPolicyDto) {
    this.id = id;
    this.companyId = companyId;
    this.contentTerms = contentTerms;
    this.contentPolicy = contentPolicy;
    this.version = version;
  }
}

export interface TermPolicyDto {
  id: number;
  companyId: number;
  contentTerms: string;
  contentPolicy: string;
  version: string;
}
