import { PreconditionData } from './precondition-data';

export abstract class CoreException {
  error?: string;
}

export class ConnectionException extends CoreException { }
export class NotFoundException extends CoreException { }
export class AuthorizationException extends CoreException { }
export class PermissionException extends CoreException { }
export class OperationalException extends CoreException { }
export class BadRequestException extends CoreException {
  constructor(public error: string) {
    super();
  }
}
export class PreconditionException extends CoreException {
  constructor(public preCondition: PreconditionData) {
    super();
  }
}