import React, { Component } from 'react';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomDialog from '../../CustomDialog/index';
import CustomSweetAlert from '../../CustomSweetAlert';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScheduleApi from '../../../api/ScheduleApi';
import ScheduleSystemFinished from '../../Bookings/ScheduleSystemFinished';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import SelectInput from '../../SelectInput';
import StatusIcon from '../StatusIcon';
import TextareaInput from '../../TextareaInput';
import TimePickerGCalendar from '../../TimePickerGCalendar';
import ValidationInput from '../../ValidationInput';
import config from '../../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ApiInvoker from '../../../api/ApiInvoker';

const dateTimeFormat = config.getDateTimeFormat();

class BookingDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      professionals: [],
      current: null,
      hasChanged: false,
      validation: false,
      errors: [],
      disabled: false,
      status: '',
      validationResult: null,
      validating: false,
      busy: false,
      alertColor: 'info',
      showOpenCancel: false,
      openSystemFinished: false,
      cancel_motive: '',
      alertMessage: '',
      alertOpen: false,
    }
  }

  getDetails() {
    const { event } = this.props;
    if (event) {
      if (event.type === 'schedule') {
          event.startTime = moment(event.startDateTime, 'HH:mm:ss').format('hh:mm a');
          event.endTime = moment(event.endDateTime, 'HH:mm:ss').format('hh:mm a');
          this.setState({ current: event }, () => this.getDrivers())
        
        return;
      }
      
        event.startTime = moment(event.startDateTime, 'HH:mm:ss').format('hh:mm a');
        event.endTime = moment(event.endDateTime, 'HH:mm:ss').format('hh:mm a');
        this.setState({ current: event }, () => this.getDrivers())
     
    }
  }

  componentDidMount() {
    this.getDetails();
  }

  componentWillReceiveProps(next) {
    if (next.errors) {
      let error = '';
      next.errors.map(e => {
        return error + '\n' + e.detail
      });
      const message = error.message ?? error;
      this.openAlert('danger', message);
      this.setState({ disabled: false, status: next.status, validation: false, busy: false });
    }
  }

  openAlert(color, message) {
    this.setState({
      alertOpen: true,
      alertColor: color,
      alertMessage: message,
    });
    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 5000);
  }

  getDrivers() {
    const { current } = this.state;
    let lender = 0;
    if (current.companyIdPartnership) {
      lender = current.companyIdPartnership.companyId;
    }

    if (current) {
      ApiInvoker.getDrivers(lender, data => { this.setState({ professionals: this.formatEmployees(data) }) }, null);
    }
  }

  formatEmployees(data) {
    if (!data.length) {
      return [];
    }
    const result = data.map(e => ({
      value: `${e.lastName} ${e.firstName}`,
      id: e.personId,
    })).sort((a, b) => a.value.localeCompare(b.value));
    return result;
  }

  cancel = () => this.setState({ showOpenCancel: true });

  updateBooking(checking) {
    const { event } = this.props;
    if (event) {
      const fetchUrl = event.type === 'schedule' ? 'schedules': 'bookings';

      if (checking) {
        this.setState({ validationResult: null, validation: false, validating: true, busy: false });

        setTimeout(() => {
          const mockValidationResult = { status: 'OK', errors: [] };
          this.setState({
            validationResult: mockValidationResult,
            busy: false,
            validation: mockValidationResult.status !== 'ERROR',
            validating: false,
          });
        }, 1000);
      } else {
        const body={
          drivers:[
            this.state.current.employeeId,
          ],
          schedules:[{
            startDateTime: this.props.event.scheduleDateTime,
            endDateTime: moment(this.props.event.end).format('YYYY-MM-DDTHH:mm:ss'),
            transferScheduleId: this.props.event.id
          }]
        }

        this.setState({ busy: true });
        AppointmentApiInvoker.scheduleReasignDriver(body, (data) => {
          if (this.props.updateAppointment) {
            this.props.updateAppointment(data);
          }
          if (this.props.onUpdate) {
            this.props.onUpdate(data);
          }
          
        }, (error) => {
          this.setState({ busy: false })
          
        }
      );

        SchedulerInvoker.postDynamicContent(fetchUrl, event.id, body, data => {
          if (this.props.updateAppointment) { this.props.updateAppointment(data) }
          if (this.props.onUpdate) { this.props.onUpdate(data) }
        }, () => this.setState({ busy: false }));
      }
    }
  }

  systemFinished = () => this.setState({ openSystemFinished: true })

  handleChange = (value, state) => {
    const { current } = this.state;
    let hasChanged;
    if (state === 'startTime') {
      hasChanged = moment(this.props.event.start).format('hh:mm a') !== value;
      let endTime = current?.endTime
      endTime = moment(value, 'hh:mm a').add(current?.estimatedDuration, 'minutes').format('hh:mm a');

      this.setState((prevState) => ({ current: { ...prevState.current, endTime } }))
    } else {
      hasChanged = current && current[state] !== value;
    }

    this.setState((prevState) => ({
      current: {
        ...prevState.current,
        [state]: value,
      },
      validation: !hasChanged,
      hasChanged,
      disabled: false,
      errors: [],
    }));
  }

  cancelScheduleConfirm = () => {
    this.setState({ showConfirmCancel: true, showOpenCancel: false })
  }

  cancelSchedule = () => {
    this.setState({ busy: true });
    const { event } = this.props;

    const body = {
      "scheduleIds": [event.id],
      "reasonCancel": this.state.cancel_motive
    }
    AppointmentApiInvoker.cancelSchedule(this.state.current.appointmentId, body, data => {
      this.getDetails()
      this.setState({
        showConfirm: false, showConfirmCancel: false
      })
    }, (error) => {
      const message = error.message ?? error;
      this.openAlert('danger', message)
    });
  }

  onChangeMotive = (value) => {
    if (value.length <= 255) {
      this.setState({ cancel_motive: value })
    }
  }

  cancelBySystem = (value) => {
    this.setState({ openSystemFinished: false })
    const { event } = this.props
    const startDateTime = moment(value['startDate']).utcOffset(0, true).format()
    const endDateTime = moment(value['endDate']).utcOffset(0, true).format()
    let presure;
    if (value['arterialTensionMax'] || value['arterialTensionMin']) { presure = {"max":value['arterialTensionMax'],"min":value['arterialTensionMin']} }
    const row = {
      "timeRegistry": moment().format(dateTimeFormat),
      "temperature":value['temperature'],
      "pulse":value['pulse'],
      "breathing":value['breathing'],
      "pressure": presure,
    }
    let rowData = [];

    if (
        !row["temperature"] &&
        !row["pulse"] &&
        !row["breathing"] &&
        !row["pressure"]
      ) {
        rowData = []
      } else {
        rowData = [ row ];
      }  

    const metadata = {
      "vitalSignsList": rowData,
      "note": (value['note'] ? value['note'] : ""),
      "start_geo_position":null,
      "end_geo_position":null,
    }
    const body = {
      "startDateTime": startDateTime,
      "endDateTime": endDateTime,
      "metadata": metadata,
    }
    ScheduleApi.finishBySystem(event.id, body, data => {
      this.getDetails();
      this.setState({ showConfirm: false, showConfirmCancel: false })
    }, (error) => {
      const message = error.message ?? error;
      this.openAlert('danger', message);
    });
  }

  getValidations = (current, disabled) => {
    let isDisabled = disabled
    if (current?.scheduleStatusName === 'CANCELLED') {
      isDisabled = true;
    }
    let validateDateTime = true;
    if (current != null) {
      const now = moment();
      const startDate = moment(current.scheduleDateTime);
      if (startDate.isAfter(now)) {
        validateDateTime = false
      }

      if (validateDateTime && (current?.scheduleStatusName === 'WITHOUT_ATTENTION' || this.state.current?.scheduleStatusName === 'NOT_ATTENDED')) {
        if (moment().isAfter(current.limitFinishedDateTime)) {
          validateDateTime = false
        }
      }
    }

    let company = localStorage.getItem("company_partnership_id")
    if (company == null) {
      company = localStorage.getItem("itlg_default_company_id");
    }
    let edit = true;
    if (current?.companyIdPartnership?.companyId != company) {
        edit = false;
        isDisabled = true;
    }
    return { edit, isDisabled, validateDateTime }
  }

  getErrors(errors) {
    if (errors?.length < 1 ) {
      return <div />
    }
    const errorsList = errors.map((e, index) => ({ ...e, key: index+1 }));
      return errorsList.map((e) => {
      const errorType = e.statusError === 'ERROR' ? 'error' : 'warning';
      return <GridItem key={`key-${e.key}`} xs={12} className={errorType}>
        <Icon color={errorType}>
          { errorType }
        </Icon>{' '}
        <span>{e.detail}</span>
      </GridItem>
    });
  }

  getCurrentEdit() {
    const { t, mode, action  } = this.props;
    const { busy, current, disabled, event, hasChanged, status, validating, validation } = this.state;
    const { isDisabled } = this.getValidations(current, disabled);
    const disabledButton = busy || !hasChanged || !validation || (disabled && status === 'ERROR');
    const validStatusNames = ['PENDING_APPROVAL', 'APPROVED', 'REJECTED', 'NOT_ATTENDED'];
    const showButton = mode != 'view' && !isDisabled && validStatusNames.includes(current.scheduleStatusName);
    const buttonText = (event && event?.type === 'booking') || !event?.action ? t('common.schedule.cancel') : t('common.close');

    return (
      <GridItem xs={action ? 9 : 12} className="booking-detail-actions">
        {showButton &&
          <Button id="button-cancel-sched" authority="cancel_schedule" color="danger" onClick={() => this.cancel()}>
            {buttonText}
          </Button>
        }
        {current.scheduleStatusName !== 'CANCELLED' && (
          <>
            <Button
              color="primary"
              disabled={!hasChanged || validating}
              onClick={() => this.updateBooking(true)}
              id="button-update-book"
            >{t('common.check')}</Button>

            <ButtonSpinner
              onClick={() => this.updateBooking()}
              labelLoading={t('common.saving')}
              loading={busy}
              disabled={disabledButton}
              label={t('common.accept')}
              typeButton="submit"
              id="button-update"
              color="primary"
            />
          </>
        )}
      </GridItem>
    );
  }

  getValidationResult() {
    const { t } = this.props;
    const { validationResult } = this.state;
    if (!validationResult) {
      return <div />
    }
    if (validationResult?.status !== 'OK') {
      const validErrorsList = validationResult.errors.map((e, index) => ({ ...e, key: index+1 }));
      return validErrorsList.map((e) => {
        const errorType = e.statusError === 'ERROR' ? 'error' : 'warning';
        return <GridItem key={`key-${e.key}`} xs={12}>
            <GridContainer>
              <GridItem 
              xs={12} 
              sm={7} 
              className={`text-center ${errorType}`}
              >
              {' '}
              <span>{t(e.detail)}</span>
            </GridItem>
            </GridContainer>
          </GridItem>
      });
    }
  }

  isMeModulePractice = (partnership) => {
    const isLocalStorageEmpty = localStorage.getItem('company_partnership_id') === null;
    return !partnership || isLocalStorageEmpty || partnership.companyId === parseInt(localStorage.getItem('company_partnership_id'))
  }

  getLenderName = (partnership) => {
    console.log(partnership)
    const isMe = this.isMeModulePractice(partnership);
    const lenderName = isMe ? (partnership?.name ?? '') : this.props.t('common.other');
    return lenderName;
  }

  renderCurrent() {
    const { t, disabled, event } = this.props;
    const { current, errors, professionals, validating, validationResult } = this.state;
    const { isDisabled, validateDateTime } = this.getValidations(current, disabled);
    const lenderName = this.getLenderName(event?.companyPartnerShip?.companyIdPartnership);
    const estimatedTime = `${moment.utc(moment.duration(parseInt(event.estimatedDuration), 'minutes').asMilliseconds()).format('HH:mm')} ${t('estimated_minutes')}`;
    const statusName = 'Ok'
    const validStatusNames = ['PENDING_APPROVAL', 'APPROVED', 'IN_PROGRESS', 'NOT_ATTENDED', 'WITHOUT_ATTENTION'];
    const showFinishedButton = validateDateTime && validStatusNames.includes(current.scheduleStatusName);
    const buttonText = event?.type === 'booking' || !event?.action ? t('schedule.detail.system.finished') : t('common.close');
    const nameProfessional = `${t('common.driver')} *`
    console.log("evento",event)
    return (
      <GridContainer>
        <GridItem xs={12} sm={6}>
            <ValidationInput
              id="input-name"
              value={event.customerName}
              text={t('common.patient')}
              disabled={true}
            />
          </GridItem>
        {event?.id &&
          <GridItem xs={12} sm={6}>
            <ValidationInput
              id="input-id"
              value={event.id}
              text={t('common.transferNumber')}
              disabled={true}
            />
          </GridItem>
        }
        <GridItem xs={12}>
          <SelectInput
            id="select-employee"
            label={nameProfessional}
            elements={professionals}
            onSelectedValue={value => this.handleChange(value, 'employeeId')}
            invalid={!current.employeeId}
            value={current.employeeId}
            disabled={false}
          />
        </GridItem>
        <GridItem xs={12}>
          <ValidationInput
            id="input-lender"
            disabled={true}
            text={`${t('common.lender')}`}
            value={lenderName}
          />
        </GridItem>

        <GridItem xs={12}>
          
            <ValidationInput
              id="estimated-label"
              value={estimatedTime}
              text={t('estimated_label')}
              disabled={true}
            />
          
        </GridItem>
        <GridItem xs={12}>
          <ValidationInput
            id="input-satatus"
            value={statusName}
            text={t('schedule.booking.detail.statusname')}
            disabled={true}
          />
        </GridItem>
        {current.scheduleStatus === 'REJECTED' &&
          <GridItem xs={12}>
            <ValidationInput
              id="input-rejected-reason"
              value={current.reasonRejected}
              text={t('schedule.booking.detail.reasonRejected')}
              disabled={true}
            />
          </GridItem>
        }
        <GridItem xs={12}>
          <DateInput
            id="datetime-start"
            text={t('common.date')}
            value={event.start?.toISOString().split('T')[0]}
            disabled={isDisabled}
            onChangeValue={value => this.handleChange(value, 'scheduleDate')}
          />
        </GridItem>
        <GridItem xs={6} className="start-time booking-det">
          <TimePickerGCalendar
            id="time-start"
            text={`${t('common.startTime')} *`}
            onSelectedValue={value => this.handleChange(value, 'startTime')}
            disabled={isDisabled}
            value={current.startTime}
            name="booking_detail_start_time"
            errorText={!current.startTime ? t('error.required') : ''}
            invalid={!current.startTime}
          />
        </GridItem>
        <GridItem xs={6} className="end-time booking-det">
          <TimePickerGCalendar
            id="time-end"
            text={t('common.endTime')}
            name="booking_detail_end_time"
            value={current.endTime}
            invalid={!current.endTime}
            errorText={!current.endTime ? t("error.required") : ''}
            disabled={true}
          />
        </GridItem>
        <GridItem xs={12}>
          <ValidationInput
            id="input-address"
            disabled={true}
            value={`${event?.address?.street} ${event?.address?.number}`}
            text={t('address.title')}
          />
        </GridItem>
        {current.observations &&
          <GridItem xs={12}>
            <TextareaInput
              id="textarea-observation"
              value={current.observations}
              text={t('label.observation')}
              disabled={true}
            />
          </GridItem>
        }

        {this.getErrors(errors)}

        <GridItem xs={12}>
          <div>
            {validating &&
              <div style={{ 
                display: 'flex', 
                height: '200px', 
                justifyContent: 'center', 
                alignItems: 'center' 
              }}>
                <CircularProgress />
              </div>}
          </div>
          {validationResult && (<StatusIcon value={validationResult.status} />)}
        </GridItem>
        {current?.scheduleStatusName === 'CANCELLED' &&
          <GridItem xs={12}>
            <div>
              <ValidationInput 
              id="input-reason" 
              text={t('common.reasonCancel')} 
              value={this.state.current.reasonCancel} 
              disabled={isDisabled} 
              />
            </div>
            <div>
              <ValidationInput id="input-employee" value={t('common.cancelEmployeeName') + ": " + this.state.current.cancelEmployeeName} disabled={isDisabled} />
            </div>
          </GridItem>
        }

        {this.getValidationResult()}

        <GridItem xs={3} className="finished-button">
        {this.props.action === 'finished_system' ?
            showFinishedButton &&
              <Button 
              id="button-system-finished" 
              authority="end_schedule_by_system" 
              color="primary" 
              onClick={() => this.systemFinished()}>
                {buttonText}
              </Button>
            : <div />}
        </GridItem>

        {this.getCurrentEdit()}
      </GridContainer>
    )
  }

  render() {
    const user = localStorage.getItem('username');
    const { t, event } = this.props;
    const { cancel_motive, current, openSystemFinished, showConfirmCancel, showOpenCancel, isTransfer } = this.state;

    return (
      <div className="booking-detail-form booking-detail">
        {current && this.renderCurrent()}
        <CustomDialog
          maxWidth="sm"
          title={t('schedule.cancel.title')}
          open={showOpenCancel}
          onClose={() => this.setState({ showOpenCancel: false })}
          isTransfer={isTransfer}
        >
          <div>
            <div>
              <ValidationInput
                id="input-motive"
                text={t('schedule.cancel.motive')}
                onChangeValue={(value) => this.onChangeMotive(value)}
                value={cancel_motive}
              />
            </div>
            <p className="style-user">
              {t('login.user')}: {user}
            </p>

          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.cancelScheduleConfirm()}
              disabled={cancel_motive == ''}
              color="primary"
              label={t('schedule.cancel.acept')}
              typeButton="submit"
              id="button-cancel"
            />
          </div>
        </CustomDialog>

        {showConfirmCancel &&
          <CustomSweetAlert
            type="warning"
            title={t('schedule.cancel.title')}
            onConfirm={() => this.cancelSchedule()}
            onCancel={() => this.setState({ showOpenCancel: true, showConfirmCancel: false })}
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            cancelBtnText={t('common.cancel')}
            showCancel={true}
            showConfirm={true}
            confirmBtnText={t('common.confirm')}
            message={
              <div className="contentMessage" dangerouslySetInnerHTML={
                { __html: t('schedule.cancel.confirm.body', { practiceType: current.practiceTypeName }) }} />
            }
          />
        }
        <CustomDialog
          className="booking-detail-component dialog-schedule-system-finished"
          maxWidth="md"
          open={openSystemFinished}
          title={t('appointment.scheduleDetail')}
          onClose={() => this.setState({ openSystemFinished: false })}
        >
          <ScheduleSystemFinished
            scheduleId={event.id}
            cancelBySystem={this.cancelBySystem}
            current={current}
            onCancel={() => this.setState({ openSystemFinished: false })}
          />
        </CustomDialog>
      </div>
    )
  }
}

BookingDetail.propTypes = {
  t: PropTypes.func,
  event: PropTypes.object,
  updateAppointment: PropTypes.func,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  action: PropTypes.string,
  mode: PropTypes.string,
}

export default withTranslation()(BookingDetail);
