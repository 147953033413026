import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../CustomButtons/Button';

const useStyles = makeStyles({
  moreLessBtn: {
    margin: 0,
    width: '30px !important',
    '& span.MuiButton-label': {
      padding: 0,
      '& svg': {
        color: 'rgb(153, 153, 153)',
        width: 25,
        height: 25,
      }
    }
  },
});

const ShowMoreLessButton = (props) => {
  const { t, isDataLength, isExpandedRow, onExpanded, id } = props;
  const classes = useStyles();

  const textTooltip = isExpandedRow ? t('common.showLess') : t('common.showMore');
  const iconButton = isExpandedRow ? <ExpandLessIcon /> : <ExpandMoreIcon />

  return (
    isDataLength &&
      <Tooltip title={textTooltip}>
        <div>
          <Button
            id={`expanded-${id}`}
            className={classes.moreLessBtn}
            justIcon simple
            onClick={() => onExpanded()}
          >{iconButton}</Button>
        </div>
      </Tooltip>
  );
};

ShowMoreLessButton.propTypes = {
  t: PropTypes.func,
  isDataLength: PropTypes.bool.isRequired,
  isExpandedRow: PropTypes.bool.isRequired,
  onExpanded: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default withTranslation()(ShowMoreLessButton);
