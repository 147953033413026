import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import styles from '../../assets/components/customCheckboxRadioSwitch';

const useStyles = makeStyles(styles);

const CustomCheckbox = ({ checked, onChangeValue }) => {
  const classes = useStyles();

  return (
    <Checkbox
      tabIndex={-1}
      checked={checked}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{
        checked: classes.checked,
        root: classes.checkRoot,
      }}
      onChange={(e) => onChangeValue(e.target.value)}
    />
  )
}

CustomCheckbox.defaultProps = {
  checked: false,
  onChangeValue: () => {},
}

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChangeValue: PropTypes.func,
}

export default CustomCheckbox