import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../CustomButtons/Button';

const defaultProps = {
  observedValues: [],
  onSaveChanges: () => {},
  onUndoChanges: () => {},
  onChangeDetected: () => {},
  undoText: 'Undo Changes',
  applyText: 'Save Changes',
}

const styles = {
  'buttonsContainer': {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'center',
    'alignItems': 'center',
  }
}

const SaveChanges = (props = defaultProps) => {
  const [ initialState, setInitialState ] = useState([]);
  const [ changed, setChanged ] = useState(false);

  const onSaveChanges = () => {
    props.onSaveChanges();
  }

  const onUndoChanges = () => {
    setChanged(false);
    props.onUndoChanges(initialState);
  }

  useEffect(() => {
    if(!initialState.length) {
      return setInitialState(props.observedValues)
    } else {
      const isChanged = props.observedValues.reduce((prev, curr, i) => {
        return !lodash.isEqual(initialState[i], curr) || prev;
      }, false);
      if (isChanged) {
        props.onChangeDetected();
        return setChanged(true);
      } else {
        return setChanged(false);
      }
    }
  }, props.observedValues)

  useEffect(() => {
    if(props.setNewInitialState){
      setInitialState(props.observedValues);
      setChanged(false);
    }
  }, [props.setNewInitialState])

  return (
    <div className={props.classes.buttonsContainer}>
      <Button id="button-undo-save" color="danger" disabled={!changed || props.disabled} onClick={onUndoChanges}> {props.undoText} </Button>
      <Button id="button-apply-save" color="primary" disabled={!changed || props.disabled} onClick={onSaveChanges}> {props.applyText} </Button>
    </div>
  )
}

SaveChanges.propTypes = {
  classes: PropTypes.object,
  setNewInitialState: PropTypes.bool,
  disabled: PropTypes.bool,
  undoText: PropTypes.string,
  applyText: PropTypes.string,
  observedValues: PropTypes.array,
  onChangeDetected: PropTypes.func,
  onSaveChanges: PropTypes.func,
  onUndoChanges: PropTypes.func,
}

export default withStyles(styles)(SaveChanges);
