import { PROFESSIONALS_ALERTS } from "../actions/alerts";

const alerts = (state = {}, action) => {
    if (action.type === PROFESSIONALS_ALERTS) {
        return { ...state, professionals: action.professionals };
    }
    return state;
}

export default alerts;
