import {
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Button from '../CustomButtons/Button';
import Cookies from 'universal-cookie';
import CustomSweetAlert from '../CustomSweetAlert';
import { Key } from '../../icons';
import { PasswordValidator } from '../../handlers/password-validator';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import { isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';

const langMap = { "es": "es-ES", "en": "en-US", "pt": "pt-BR", "it": "it-IT", "fr": "fr-FR" }

const useStyles = makeStyles(() => ({
    validatePassList: {
        color: '#3C4858',
        fontSize: '0.875rem',
        lineHeight: 1.2,
        opacity: .7,
        paddingLeft: 12,
    },
    changePass: {
        maxWidth: 350,
        margin: 'auto',
        padding: '5px 20px 30px',
        '& .MuiGrid-item': {
            marginTop: 12,
            display: 'flex',
            flexDirection: 'column',
            '& .helper-text': {
                lineHeight: 1.2,
                fontSize: '0.8rem',
                '&:not(.Mui-error)': {
                    opacity: '0.7',
                },
            }
        },
    },
    submitButton: {
        marginTop: '20px !important',
    }
}));

const initialValueSnackbar = {
    color: 'success',
    message: '',
    open: false,
}

const initialValuePass = {
    isMatchPasswords: true,
    isSubmit: false,
    openNewPass: false,
    showCurrentValue: false,
    showRepeatValue: false,
    showValue: false,
    currentValue: '',
    repeatValue: '',
    value: '',
}

const initialValidPass = {
    isNewValid: true,
    isRepeatValid: true,
}

const ChangePassword = ({ t, company, email, onSubmitChangePass }) => {
    const classes = useStyles();

    const [password, setPassword] = useState(initialValuePass);
    const [validPassword, setValidPassword] = useState(initialValidPass);
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
    const [snackbar, setSnackbar] = useState(initialValueSnackbar);
    const [alertSave, setAlertSave] = useState(false);

    const openSnackbar = (color, message) => {
        setSnackbar((prev) => ({ ...prev, color, message, open: true }));
        setTimeout(() => {
            setSnackbar((prev) => ({ ...prev, open: false }));
        }, 5000);
    }
    const handlePassValue = (value, state) => {
        setPassword((prev) => ({ ...prev, [state]: value }));
    }

    const onSubmitPass = () => {
        if (!password.currentValue || !password.value || !password.repeatValue) {
            openSnackbar('warning', t('common.messageWarning.fieldsComplete'));
            return;
        }
        if (password.currentValue && password.value && password.repeatValue) {
            if (!password.isMatchPasswords) {
                openSnackbar('warning', t('restorePassword.notMatchPass'));
                return;
            }
            setAlertSave(true);
        }
    }

	const getLangByCookies = () =>  {
		const cookies = new Cookies();
        let lang = cookies.get("lang");
		if (lang == null) {
			return langMap['es']
		} else {
			return langMap[lang] == null ? langMap['es']: langMap[lang];
		}
	}

    const getFetchParams = (method, data, companyId) => ({
        headers: new Headers({
            'Content-Type': 'application/json',
            'x-itlg-companyId': companyId,
            'Accept-Language': getLangByCookies() 
        }),
        method,
        body: JSON.stringify({
            email,
            password: data.currentValue,
            newPassword: data.value,
        }),
    });

    const getTypeMessageByData = (data) => {
        let type = 'danger';
        let message =  t('common.errorService'); // code 1/7
        if (data.code === 0) {
            type = 'success';
            message =  t('changePassword.success');
        }
        if (data.code === 6) {
            message =  t('changePassword.errorCurrentPass');
        }
        if (data.code === 11) {
            message =  t('validatePass.error.newPassUsername');
        }
        return { type, message };
    }

    const handleSubmitPass = async () => {
        setPassword((prev) => ({ ...prev, isSubmit: true }));
        setAlertSave(false);
        
        const { currentValue, value } = password;
        const params = getFetchParams('POST', { currentValue, value });
        try {
            const response = await fetch('/keychange/reset', params);
            const data = await response.json();
            const { type, message } = getTypeMessageByData(data);

            openSnackbar(type, message);
            if (data.code === 0) { // 200
                setTimeout(() => {
                    onSubmitChangePass();
                }, 1500);
            }
        } catch (error) {
            console.error('** error keychange/reset', error);
            openSnackbar('danger', t('common.errorService'));
        }
    }

    const handleMatchPass = () => {
        const isValues = !!(password.value) && !!(password.repeatValue);
        if (isValues) {
            const isMatchPasswords = isEqual(password.value, password.repeatValue);
            setPassword((prev) => ({ ...prev, isMatchPasswords }));
            return;
        }
        setPassword((prev) => ({ ...prev, isMatchPasswords: true }));
    }

    const checkInputsValidity = () => {
        const { currentValue, repeatValue, value } = password;
        if (currentValue && (!value || !repeatValue)) {
            return setIsDisabledSubmit(true);
        }
        const isNewPasswordValid = PasswordValidator(value);
        const isValidConfirmPass = PasswordValidator(repeatValue);
        const isDisabledSubmit = !(currentValue && isNewPasswordValid && isValidConfirmPass);

        setValidPassword((prev) => ({ ...prev, isNewValid: isNewPasswordValid, isRepeatValid: isValidConfirmPass }));
        setIsDisabledSubmit(isDisabledSubmit);
        handleMatchPass();
    }

    useEffect(() => {
      return () => {
        setPassword(initialValuePass);
        setSnackbar(initialValueSnackbar);
        setAlertSave(false);
      }
    }, [])
    
    return (
        <>
            <Snackbar
                place="tr"
                color={snackbar.color}
                message={snackbar.message}
                open={snackbar.open}
            />
            <form
                className="pass-user-form"
                id="pass-form"
                onSubmit={handleSubmitPass}
                onKeyDown={({key}) => key === 'Enter' && onSubmitPass}
            >
                <Grid container justify="center" className={classes.changePass}>
                    <Grid item xs={12}>
                        <FormControl variant="filled">
                            <FilledInput
                                id="password"
                                inputProps={{
                                    placeholder: t('label.currentPass')
                                }}
                                type={!password.showCurrentValue ? 'password' : 'text'}
                                startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPassword((prev) => ({ ...prev, showCurrentValue : !password.showCurrentValue }))}
                                            edge="end"
                                        >
                                            {password.showCurrentValue ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event) => handlePassValue(event.target.value, 'currentValue')}
                                value={password.currentValue}
                                error={!password.currentValue && password.isSubmit}
                                onKeyUp={checkInputsValidity}
                            />
                        </FormControl>
                        <FormHelperText className="helper-text">
                            {t('changePassword.currentPassDescription')}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        
                        <FormControl variant="filled">
                            <FilledInput
                                id="newPassword"
                                inputProps={{
                                    placeholder: t('restorePassword.newPass')
                                }}
                                type={!password.showValue ? 'password' : 'text'}
                                startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPassword((prev) => ({ ...prev, showValue : !password.showValue }))}
                                            edge="end"
                                        >
                                            {password.showValue ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event) => handlePassValue(event.target.value, 'value')}
                                value={password.value}
                                onKeyUp={checkInputsValidity}
                            />
                        </FormControl>
                        {(!validPassword.isNewValid && !!(password.value)) &&
                            <FormHelperText error className="helper-text">
                                {t('validatePass.error')}
                            </FormHelperText>
                        }
                        <div className="helper-text">
                            <ul className={classes.validatePassList}>
                                <li>{t('validatePass.isCaseSensitive')}</li>
                                <li>{t('validatePass.text1')}</li>
                                <li>{t('validatePass.text2')}</li>
                                <li>{t('validatePass.text3')}</li>
                                <li>{t('validatePass.text4')}</li>
                            </ul>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <FormControl variant="filled">
                            <FilledInput
                                id="repeatPassword"
                                inputProps={{
                                    placeholder: t('restorePassword.repeatPass')
                                }}
                                type={!password.showRepeatValue ? 'password' : 'text'}
                                startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPassword((prev) => ({ ...prev, showRepeatValue: !password.showRepeatValue }))}
                                            edge="end"
                                        >
                                            {password.showRepeatValue ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event) => handlePassValue(event.target.value, 'repeatValue')}
                                value={password.repeatValue}
                                error={!password.repeatValue && password.isSubmit}
                                onKeyUp={checkInputsValidity}
                            />
                        </FormControl>
                        {!password.isMatchPasswords &&
                            <FormHelperText error className="helper-text">
                                {t('restorePassword.notMatchPass')}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.submitButton}>
                        <Button
                            onClick={onSubmitPass}
                            className="submit-button"
                            variant="contained"
                            fullWidth={true}
                            disabled={isDisabledSubmit}
                            color="primary"
                            id="button-accept"
                        >
                            {t('common.accept')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {alertSave &&
                <CustomSweetAlert
                    type="warning"
                    title={t('label.changePass')}
                    confirmBtnCssClass="primary"
                    confirmBtnText={t('common.accept')}
                    onConfirm={handleSubmitPass} 
                    showCancel={true}
                    cancelBtnCssClass="danger"
                    cancelBtnText={t('common.cancel')}
                    onCancel={() => setAlertSave(false)}
                    message={<p>{t('changePassword.confirmText')}</p>}
                />
            }
        </>
    )
}

ChangePassword.propTypes = {
    t: PropTypes.func,
    email: PropTypes.string,
    company: PropTypes.object,
    onSubmitChangePass: PropTypes.func.isRequired,
}

export default withTranslation()(ChangePassword);
