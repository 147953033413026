import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ButtonSpinner from '../ButtonSpinner';
import CheckInput from '../CheckInput';
import CustomSweetAlert from '../CustomSweetAlert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import RadioInput from '../RadioInput';
import Snackbar from '../Snackbar/Snackbar';
import SelectInput from '../SelectInput';
import ValidationInput from '../ValidationInput';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import './CreatePhone.css';

class CreatePhone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            detail: '',
            number: '',
            phoneTypes: [],
            types: [],
            alertColor: 'info',
            alertOpen: false,
            alertMessage: '',
            phoneType: '',
            phoneDefault: false,
            save: false,
            loading: false,
            errorPhone: false,
            isFamily: false,
            familyName: '',
        }
    }

    componentWillMount() {
        const { phoneTypes } = this.props;
        this.getPhoneTypesOnMount(phoneTypes);
        this.getPhoneOnEdit();
        if (this.props.firstPhone) {
            this.setState({ phoneDefault: this.props.firstPhone ?? false });
        }
    }

    componentWillReceiveProps(next) {
        if (next.phoneTypes) {
            this.getPhoneTypesOnMount(next.phoneTypes);
        }
    }

    componentWillUnmount() {
        this.setState({
            name: '',
            detail: '',
            number: '',
            phoneType: '',
            phoneTypes: [],
            types: [],
            alertColor: 'info',
            alertOpen: false,
            alertMessage: '',
            save: false,
            loading: false,
            isFamily: false,
            familyName: '',
        });
    }

    getPhoneTypesOnMount(phoneTypes) {
        if (!phoneTypes?.length) {
            this.getPhoneTypes();
            return;
        }
        const types = {};
        const newPhoneTypes = this.formatPhoneTypes(phoneTypes);
        types['phoneTypes'] = newPhoneTypes;
        types['types'] = phoneTypes;
        this.setState((prevState) => ({ ...prevState, ...types }));
    }

    getPhoneOnEdit() {
        const { edit, personType } = this.props;
        if (edit) {
            const phone = {
                id: edit.phoneId,
                detail: edit.detail,
                number: edit.number,
                phoneDefault: edit.phoneDefault,
                phoneType: edit.phoneType.phoneTypeId,
            };
            if (personType == 'customer') {
                phone.isFamily = edit.isFamiliar ?? false;
                phone.familyName = edit.fullName ?? '';
            }
            this.setState((prevState) => ({ ...prevState, ...phone }));
        }
    }

    formatPhoneTypes(phoneTypes) {
        return phoneTypes.map(p => ({
            id: p.phoneTypeId,
            value: p.name,
        }));
    }

    getPhoneTypes() {
        PersonApiInvoker.getPhoneTypes(phoneTypes => {
            this.props.onGetPhoneTypes(phoneTypes);
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
    }

    handleValueRadio(value) {
        const isFamily = value == '1';
        if (value == '2' && this.state.familyName) {
            this.setState({ familyName: '' });
        }
        this.setState({ isFamily });
    }

    savePhone() {
        if (!this.state.number) {
            return;
        }

        if (!isValidPhoneNumber(this.state.number)) {
            this.setState({errorPhone: true});
            return;
        }
        this.submitPhone();
    }

    submitPhone() {
        const { detail, phoneType, number } = this.state;
        this.setState({ save: true, loading: true });
        const isRequiredFields = phoneType && number && number.trim().length > 0 && detail && detail.trim().length > 0
        if (!isRequiredFields) {
            this.openAlert('warning', this.props.t('common.messageWarning.fieldsComplete'));
            this.setState({ loading: false });
            return;
        }
        const data = {
            detail: this.state.detail.trim(),
            number: this.state.number.trim(),
            phoneType: this.findPhoneType(this.state.phoneType),
            phoneDefault: this.state.phoneDefault,
            personId: this.props.personId,
        }
        if (this.props.personType == 'customer') {
            data.isFamiliar = this.state.isFamily;
            data.fullName = this.state.familyName;
        }
        if (this.props.edit) {
            this.patchPhone(data);
            return;
        }
        this.postPhone(data);
    }

    postPhone(phoneData) {
        PersonApiInvoker.postPersonsNewPhone(this.props.personId, phoneData, (data) => {
            this.setState({ loading: false });
            if (data.phoneId) {
                this.openAlert('success', this.props.t('phoneForm.successAddedMessage'));
                this.props.onPhoneSubmited(data);
                return;
            }
            this.openAlert('danger', data.message);
        }, (error) => {
            this.setState({ loading: false });
            this.openAlert('danger', (error ? error.toString() : 'Error'));
        });
    }

    patchPhone(phoneData) {
       PersonApiInvoker.postPersonsPhone(this.props.personId, this.props.edit.phoneId, phoneData, (data) => {
            this.setState({ loading: false });
            if (data.phoneId) {
                this.openAlert('success', this.props.t('phoneForm.successUpdatedMessage'));
                this.props.onPhoneSubmited(data);
                return;
            }
            this.openAlert('danger', data.message);
        }, (error) => {
            this.setState({ loading: false });
            this.openAlert('danger', (error ? error.toString() : 'Error'));
        });
    }

    findPhoneType(id) {
        let phoneType = this.state.types.find(t => t.phoneTypeId === id) || {};
        return phoneType;
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    renderPersonTypeCustomer() {
        const { t, personType } = this.props;
        const { isFamily, familyName } = this.state;
        if (personType == 'employee') {
            return <div />
        }
        return (
            <>
                <GridItem xs={12} className="margin-top radio-input-inline">
                    <p className="padding-horizontal radio-legend">{t('phoneForm.isFamily')}</p>
                    <RadioInput
                        id="is-family"
                        name="isFamily"
                        value={isFamily === true ? '1' : '2'}
                        elements={[
                            { id: '1', value: t('form.option.yes') },
                            { id: '2', value: t('form.option.no') }
                        ]}
                        onChangeValue={(value) => this.handleValueRadio(value)}
                    />
                </GridItem>
                {isFamily &&
                    <GridItem xs={12} sm={6}>
                        <ValidationInput 
                            type="text" 
                            text={t('phoneForm.isFamily.name')} 
                            value={familyName} 
                            onChangeValue={(value) => this.handleValue(value, 'familyName')} 
                        />
                    </GridItem>
                }
            </>
        )
    }

    render() {
        const { t } = this.props;
        const { alertColor, alertMessage, alertOpen, detail, errorPhone, loading, number, phoneDefault, phoneType, phoneTypes, save } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={6}>
                    <ValidationInput 
                        type="text" 
                        text={t('common.required.description')} 
                        value={detail} 
                        onChangeValue={(value) => this.handleValue(value, 'detail')} 
                        invalid={(detail === '' || detail.trim().length === 0) && save} 
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <SelectInput
                        label={t('common.required.type')}
                        elements={phoneTypes}
                        value={phoneType}
                        onSelectedValue={(value) => this.handleValue(value, 'phoneType')}
                        invalid={phoneType === '' && save}
                        errorText={t('error.required')}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <div className='phone'>
                        <PhoneInput
                            value={number}
                            className='inputPhone'
                            onChange={(value) => this.handleValue(value, 'number')}
                        />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CheckInput
                        onChangeValue={() => this.handleValue(!phoneDefault, 'phoneDefault')}
                        checked={phoneDefault}
                        disabled={phoneDefault}
                        label={t('employee.new.modal.mainPhone')}
                        labelPlacement="start"
                    />
                </GridItem>
                {this.renderPersonTypeCustomer()}
                <GridItem className="base-font" xs={12}>
                    <p className="required-text">
                        <small>*</small> {t('common.fields.required')}
                    </p>
                </GridItem>

                <GridItem xs={12}>
                    <ButtonSpinner
                        className="create-address-submit"
                        onClick={() => this.savePhone()}
                        disabled={loading}
                        label={t('employee.phone.new.save')}
                        labelLoading={t('employee.phone.new.saving')}
                        loading={loading}
                        typeButton="submit"
                        color="primary"
                        id="button-save"
                    />
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>
                {errorPhone &&
                    <CustomSweetAlert
                        type="warning"
                        title={t('phone.change.validate.error.title')}
                        onConfirm={() => this.setState({errorPhone: false})}
                        confirmBtnCssClass="primary"
                        cancelBtnCssClass="danger"
                        confirmBtnText={t('common.accept')}
                        message={t('phone.change.validate.error.message')}
                        showCancel={false}
                    />
                }
            </GridContainer>
        )
    }
}

CreatePhone.propTypes = {
    t: PropTypes.func,
    firstPhone: PropTypes.bool,
    personId: PropTypes.number,
    phoneTypes: PropTypes.array,
    onGetPhoneTypes: PropTypes.func,
    onPhoneSubmited: PropTypes.func,
    edit: PropTypes.object,
    personType: PropTypes.oneOf(['customer', 'employee']),
}

export default withTranslation()(CreatePhone);
