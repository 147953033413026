import { connect } from 'react-redux';
import NewTool from '../components/NewTool';
import EditTool from '../components/EditTool';
import {getToolTypes, addTool, editTool } from '../actions/tools';

const mapStateToProps = (state) => {
    return {
        tools: state.tools.tools,
        tooltypes: state.tooltypes.tooltypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetToolTypes: (tooltypes) => {
            dispatch(getToolTypes(tooltypes));
        },
         onAddTool: (tool) => {
            dispatch(addTool(tool));
        },
        onEditTool: (tool) => {
            dispatch(editTool(tool));
        }
    }
}

const NewToolService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewTool);

const EditToolService = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTool);

export default { NewToolService, EditToolService };
