import {
  APPOINTMENTS,
  APPOINTMENT_TOOLS,
  CHECK_APPOINTMENT_TOOLS,
  CHECK_APPOINTMENT_TOOLSEDIT,
  CLEAN_APPOINTMENTS,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENTTOOLS,
  DESELECT_ALL_TOOLS,
  SAVE_APPOINTMENT_SCHEDULE,
  SAVE_TOOLS,
  SELECT_APPOINTMENT,
  SELECT_APPOINTMENT_LIST,
  SELECT_APPOINTMENT_OBJECT,
  SELECT_APPOINTMENT_SCHEDULES,
  SUBMIT_APPOINTMENT,
  UNDO_APPOINTMENTTOOLS
} from '../actions/appointments';

import _ from 'lodash';

const initialState = {};

const appointments = (state = initialState, action = APPOINTMENTS) => {
  switch (action.type) {
    case APPOINTMENTS:
      return Object.assign([], state, action.appointments)
    case CLEAN_APPOINTMENTS:
      return initialState;
    case SELECT_APPOINTMENT: {
      const selectedAppointment = selectAppointment(action.appointmentId, state);
      return { ...state, selectedAppointment: selectedAppointment[0] };
    }
    case SELECT_APPOINTMENT_LIST:
        return { ...state, selectedAppointment: null };
    case SELECT_APPOINTMENT_OBJECT:
        return { ...state, selectedAppointment: action.appointment };
    case SELECT_APPOINTMENT_SCHEDULES:
        return { ...state,  selectedSchedules: action.schedules };
    case SAVE_APPOINTMENT_SCHEDULE: {
      const _bookingId = action.schedule?.id ?? null;
      const schedulesInState = state?.selectedSchedules;
      const nextSchedules = schedulesInState.map(schedule => {
        const scheduleStatusId = _bookingId === schedule.scheduleId ? 3 : schedule.scheduleStatus.scheduleStatusId;
        return { ...schedule, scheduleStatus: { ...schedule.scheduleStatus, scheduleStatusId } }
      });
      return { ...state, selectedSchedules: nextSchedules };
    }
    case DELETE_APPOINTMENT: {
      const appointmentsList = deleteAppointment(action.appointmentId, state);
      return Object.assign([], state, appointmentsList);
    }
    case DESELECT_ALL_TOOLS: {
      const slAppointment = state.selectedAppointment || {};
      return Object.assign([], state, { selectedAppointment: { ...slAppointment, toolsList: [] }, checkedToolsList: [], appointmentsTools: [], checkAppointmentsTools: [], checkAppointmentsToolsEdit: [] });
    }
    case SUBMIT_APPOINTMENT:
      return { ...state, appointment: action.appointment };
    case APPOINTMENT_TOOLS:
      return { ...state, appointmentsTools: action.appointmentsTools };
    case SAVE_TOOLS:
      return { ...state, savedTools: action.savedTools };
    case DELETE_APPOINTMENTTOOLS: {
      const tool = action.tool;
      const selAppointment = state.selectedAppointment || {};
      const toolsList = selAppointment.toolsList;
      const toolsNewList = toolsList.filter(id => id.toolId !== tool.toolId);
      return { ...state, selectedAppointment: { ...selAppointment, toolsList: toolsNewList } };
    }
    case CHECK_APPOINTMENT_TOOLS: {
      const checkAppointmentsTools = action.checkAppointmentsTools;
      const currArray = state.checkedToolsList || [];
      let nextArray = [];
      if (currArray.indexOf(checkAppointmentsTools.toolId) < 0) {
        nextArray = [ ...currArray, checkAppointmentsTools.toolId ];
      } else {
        nextArray = [ ...currArray ].filter(id => id !== checkAppointmentsTools.toolId);
      }
      return { ...state, checkedToolsList: nextArray };
    }
    case CHECK_APPOINTMENT_TOOLSEDIT: {
      const checkAppointmentsToolsEdit = action.checkAppointmentsToolsEdit;
      const currArrayEdit = state.checkedToolsList || [];
      let nextArrayEdit = [];
      if (currArrayEdit.indexOf(checkAppointmentsToolsEdit.toolId) < 0) {
        nextArrayEdit = [ ...currArrayEdit, checkAppointmentsToolsEdit.toolId ];
      } else {
        nextArrayEdit = [ ...currArrayEdit ];
        nextArrayEdit = nextArrayEdit.filter(id => id !== checkAppointmentsToolsEdit.toolId);
      }
      return { ...state, checkedToolsList: nextArrayEdit }
    }
    case UNDO_APPOINTMENTTOOLS: {
      return { ...state, checkedToolsList: action.checklist} 
    }
    default:
      return state;
  }
}

const deleteAppointment = (id, appointmentsList) => {
  appointmentsList.map((a, key) => {
    if (a.appointmentId === id) {
      delete appointmentsList[key]
    }
  });
  return appointments;
}

const selectAppointment = (appointmentId, appointmentsList) => {
  return appointmentsList.filter((a, key) => {
    if (a.appointmentId === appointmentId) {
      return appointmentsList[key]
    }
  })
}

const orderAppointments = appointmentsList => {
  return _.orderBy(appointmentsList, 'startDate', 'desc');
}

export default appointments;
