import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from '../TableFull/Table';
import Button from '../CustomButtons/Button';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import CustomInput from '../CustomInput/CustomInput';
import SelectInput from '../SelectInput';
import ButtonSpinner from '../ButtonSpinner';
import { MedicalDoctor } from '../../icons';
import ShowMoreLessButton from '../ShowMoreLess/ShowMoreLessButton';
import ShowMoreLessData from '../ShowMoreLess/ShowMoreLessData';
import './Employees.css';
import Cookies from 'universal-cookie';
import Active from '@material-ui/icons/LockOpen';
import Inactive from '@material-ui/icons/Lock';
import CustomSweetAlert from '../CustomSweetAlert';
import Snackbar from '../Snackbar/Snackbar';

const maxDataLength = 60;

class Employees extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = {
            employees: [],
            loading: false,
            filter: { zoneGeographic: [], employeeType: [] },
            employeeTypes: [],
            originalEmployeeTypes: [],
            rowsExpanded: [],
            geographics: [],
            page: 0,
            alertColor: null,
            alertMessage: null,
            alertOpen: false,
            pageSize: 25,
            showActive: false,
            showDeactivate: false,
            actives: [{ id: "all", value: t("state.all") }, { id: "true", value: t("state.active") }, { id: "false", value: t("state.deactive") }],
            pageSizeOriginal: 25
        }
    }
    componentDidMount() {
        let cookies = new Cookies();
        let filter = cookies.get("employee_filters");

        if (filter != null) {

            let page = cookies.get("employee_page");
            if (page == null) {
                page = 0;
            }

            this.setState({
                filter: filter,
                filterShow: filter,
                page: page
            }, () => {
                this.getEmployees(true);
            })
        } else {

            let page = cookies.get("appointment_page");
            if (page == null) {
                page = 0;
            } else {
                page = parseInt(page);
            }
            this.setState({
                page: page
            }, () => {
                this.getEmployees(true);
            })




            this.getEmployees();
        }
        this.getEmployeeType();
        this.getGeographics();
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.reduceEmployee(next.employees);
        }
    }

    reduceEmployee = (employees) => {
        if (employees.length) {
            const data = this.formatEmployees(employees, true);
            const oldAppointments = this.state.employees;
            oldAppointments.push(...data);
            const uniques = oldAppointments.filter((v, i, a) => a.findIndex(t => (t.personId === v.personId)) === i)
            this.setState({ employees: uniques, originalquery: uniques }, () => {
                if (this.state.employees.length > 2 && this.state.page > 0) {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    }

    getEmployeeType() {
        PersonApiInvoker.getEmployeesType('PROFESSIONAL', data => {
            const employeeTypes = this.formatEmployeeTypes(data);
            this.setState({ employeeTypes, originalEmployeeTypes: employeeTypes });
        }, e => {
            console.error('** error getEmployeesType', e);
            this.setState({ loading: false });
        });
    }

    getGeographics() {
        PersonApiInvoker.getGeographicsCompany(data => {
            const geographics = this.formatGeographics(data);
            this.setState({ geographics });
        }, e => {
            console.error('** error getGeographicsCompany', e);
            this.setState({ loading: false });
        });
    }

    getEmployees(load) {
        this.setState({ loading: true });
        const self = this;
        let params = Object.keys(this.state.filter).map((k) => {
            const value = self.state.filter[k];
            if (value != '') {
                return `${encodeURIComponent(k)}=${encodeURIComponent(value.toString())}`;
            } else {
                return null;
            }
        }).join('&')
        if (params !== '') {
            params = `${params}&`;
        }
        let page = this.state.page;
        let pageSize = this.state.pageSize;
        if (load && page != 0) {
            pageSize = this.state.pageSizeOriginal * (page);
            page = 0;
        }
        params = `${params}page=${page}&pageSize=${pageSize}`;
        PersonApiInvoker.getEmployeesWithGeoZoneFilter(params, data => {
            this.setState({ loading: false });
            if (data?.length) {
                this.props.onGetEmployees(data);
            }
        }, (error) => {
            console.error('** error getEmployeesWithGeoZoneFilter', error);
            this.setState({ loading: false });
        });
    }

    formatEmployeeTypes = (employee) => employee.map(e => ({
        id: e.employeeTypeId,
        value: e.employeeTypeName,
    }));

    formatGeographics = (geo) => geo.map(e => ({
        id: e.geographicZoneId,
        value: e.detail,
    }));

    toStringTypes = (employeeTypes) => {
        const types = [];
        employeeTypes.forEach(el => types.push(el.name));
        return types.join(', ');
    }

    renderEmployeesActions = (c, isFirstLoad) => isFirstLoad ? <div className="employees-actions">
        <Tooltip title={this.props.t('employees.table.view')}>
            <div>
                <Button id={`button-view-${c.personId}`} simple justIcon color="primary" onClick={() => browserHistory.push({
                    state: { mode: 'view' },
                    pathname: `/ver-profesional/${c.personId}`
                })}><AssignmentIcon /></Button>
            </div>
        </Tooltip>
        <Tooltip title={this.props.t('employees.table.edit')}>
            <div>
                <Button id={`button-edit-${c.personId}`} simple justIcon color="success"
                    onClick={() => browserHistory.push(`/editar-profesional/${c.personId}`)}>
                    <Edit />
                </Button>
            </div>
        </Tooltip>
        {c.active &&
            <Tooltip title={this.props.t('tooltip.inactive')}>
                <div>
                    <Button id={`button-edit-${c.personId}`} simple justIcon color="success"
                        onClick={() => this.setState({ showActive: true, current: c })}>
                        <Active />
                    </Button>
                </div>
            </Tooltip>
        }
        {!c.active &&
            <Tooltip title={this.props.t('tooltip.active')}>
                <div>
                    <Button id={`button-edit-${c.personId}`} simple justIcon color="success"
                        onClick={() => this.setState({ showDeactivate: true, current: c })}>
                        <Inactive />
                    </Button>
                </div>
            </Tooltip>
        }

    </div> : c.actions;

    formatEmployees = (employees, isFirstLoad, rowsExpanded = []) => {
        const { t } = this.props;
        const data = employees.map(c => {
            const employeeType = isFirstLoad ? this.toStringTypes(c.employeeTypes) : c.employeeTypes;
            const zoneGeographics = isFirstLoad ? c.zoneGeographics.map((zone) => zone.detail).join(', ') : c.zoneGeographics;
            const actions = this.renderEmployeesActions(c, isFirstLoad);
            return {
                personId: c.personId,
                lastName: c.lastName,
                firstName: c.firstName,
                identification: c.id,
                email: c.email,
                zoneGeographics,
                employeeType,
                personActive: c.active ? t('employee.active') : t('employee.deactive'),
                expandedColumn: '',
                actions,
            }
        });
        this.setState({ rowsExpanded });
        return data;
    }

    handleValue(value, field) {
        const { filter } = this.state;
        filter[field] = value;
        this.setState({ filter });
    }

    cleanFilters = () => {
        this.props.onCleanEmployees();
        this.setState({
            filter: { zoneGeographic: [], employeeType: [], page: 0 },
            employees: [],
        }, () => {
            let cookies = new Cookies();
            cookies.remove("employee_filters");
            cookies.remove("employee_page");
            this.getEmployees();
        });
    }

    findFilters = () => {
        this.props.onCleanEmployees();
        this.setState({
            employees: [],
            page: 0,
            loading: true,
        }, () => {
            let save = {}
            let f = this.state.filter;
            for (const [key, value] of Object.entries(f)) {
                save[key] = value;
            }
            let cookies = new Cookies();
            cookies.set("employee_filters", save);
            this.getEmployees();
        })
    }

    active = (active) => {
        this.setState({ showActive: false, showDeactivate: false });
        if (active) {

            PersonApiInvoker.activate(this.state.current.personId, data => {
                this.findFilters();
            }, error => {
                console.error('** error active', error);
                this.openAlert('danger', (error ? error.message : 'Error'));
            });

        } else {

            PersonApiInvoker.deactivate(this.state.current.personId, data => {
                this.findFilters();
            }, error => {
                console.error('** error deactive', error);
                this.openAlert('danger', (error ? error.message : 'Error'));
            });

        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    showMoreEmployees = () => {
        this.setState((prevState) => ({ page: prevState.page + 1, loading: true }), () => {
            let cookies = new Cookies();
            cookies.set("employee_page", this.state.page);
            this.getEmployees();
        })
    }

    onExpandedRow = (id) => {
        const selected = this.state.rowsExpanded;
        if (selected.includes(id)) {
            const index = selected.indexOf(id);
            if (index !== -1) {
                selected.splice(index, 1);
            }
        } else {
            selected.push(id);
        }
        this.formatEmployees(this.state.originalEmployeeTypes, false, selected);
    }

    getShowMoreLessData = (props, rowsExpanded) =>
        <ShowMoreLessData
            data={props.value}
            isExpandedRow={rowsExpanded.includes(props.original.personId)}
        />

    getShowMoreLessButton = (props, rowsExpanded) => {
        const isDataLength = props.original.employeeType.length > maxDataLength || props.original.zoneGeographics.length > maxDataLength;
        return <ShowMoreLessButton
            isDataLength={isDataLength}
            isExpandedRow={rowsExpanded.includes(props.original.personId)}
            onExpanded={() => this.onExpandedRow(props.original.personId)}
            id={props.original.personId}
        />
    }

    render() {
        const { t } = this.props;
        const { alertColor,alertMessage,alertOpen,loading, employees, employeeTypes, rowsExpanded, filter, geographics } = this.state;

        return (
            <GridContainer className="employees employees-list">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <MedicalDoctor />
                            </CardIcon>
                            <Snackbar
                                place="tr"
                                color={alertColor}
                                message={alertMessage}
                                open={alertOpen}
                            />
                            <GridContainer className="appointments-filters">
                                <GridItem xs={8} sm={9} md={10} className="no-padding">
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={2}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.id')}
                                                formControlProps={{ fullWidth: true }}
                                                value={filter.id !== undefined ? filter.id : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'id')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={4} md={3}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.last.name')}
                                                formControlProps={{ fullWidth: true }}
                                                value={filter.lastName}
                                                onChange={(value) => this.handleValue(value.target.value, 'lastName')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={4} md={3}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.first.name')}
                                                formControlProps={{ fullWidth: true }}
                                                value={filter.firstName}
                                                onChange={(value) => this.handleValue(value.target.value, 'firstName')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={6} md={4}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.email')}
                                                formControlProps={{ fullWidth: true }}
                                                type='email'
                                                value={filter.email !== undefined ? filter.email : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'email')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={6} md={6}>
                                            <SelectInput
                                                className="no-padding"
                                                label={t('employee.find.employeetype')}
                                                multi
                                                elements={employeeTypes}
                                                value={filter.employeeType}
                                                onSelectedValue={(value) => this.handleValue(value, 'employeeType')}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={3} md={3}>
                                            <SelectInput
                                                className="no-padding"
                                                label={t('employee.find.state')}
                                                elements={this.state.actives}
                                                value={filter.actives}
                                                onSelectedValue={(value) => this.handleValue(value, 'actives')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <SelectInput
                                                className="no-padding"
                                                label={t('employee.find.zone.geographics')}
                                                multi
                                                elements={geographics}
                                                value={filter.zoneGeographic}
                                                onSelectedValue={(value) => this.handleValue(value, 'zoneGeographic')}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={4} sm={3} md={2} style={{ marginTop: 40 }}>
                                    <GridContainer className="appointments-filters">
                                        <GridItem className="filters-actions" xs={12}>
                                            <Button id="button-find" block onClick={() => this.findFilters()} color="primary">
                                                {t('employee.find.find')}
                                            </Button>
                                        </GridItem>
                                        <GridItem className="filters-actions" xs={12}>
                                            <Button id="button-clean" block onClick={() => this.cleanFilters()} color="danger">
                                                {t('employee.find.clean')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody className="employees-table appointments-content-body not-scroll-table filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                sortable={true}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t('employees.table.last_name'), accessor: 'lastName' },
                                    { Header: t('employees.table.first_name'), accessor: 'firstName' },
                                    { Header: t('employees.table.identification'), accessor: 'identification' },
                                    { Header: t('employees.table.email'), accessor: 'email' },
                                    {
                                        Header: t('employees.table.specialties'), accessor: 'employeeType',
                                        Cell: (props) => this.getShowMoreLessData(props, rowsExpanded)
                                    },
                                    {
                                        Header: t('employees.table.geozones'), accessor: 'zoneGeographics',
                                        Cell: (props) => this.getShowMoreLessData(props, rowsExpanded)
                                    },
                                    { Header: t('employees.table.active'), accessor: 'personActive' },
                                    { Header: t('employees.table.actions'), accessor: 'actions', sortable: false, filterable: false },
                                    {
                                        Header: '', accessor: 'expandedColumn', sortable: false, filterable: false, with: 50, className: 'column-expandable',
                                        Cell: (props) => this.getShowMoreLessButton(props, rowsExpanded)
                                    },
                                ]}
                                tableData={employees}
                                defaultPageSize={1000}
                                showPaginationTop={false}
                                showPaginationBottom={false}
                                colorsColls={['primary']}
                            />
                            {employees.length ?
                                <GridItem className="bottom" xs={12} sm={12} md={12}>
                                    <ButtonSpinner
                                        onClick={() => this.showMoreEmployees()}
                                        label={t('employee.showmore')}
                                        labelLoading={t('employee.showmore')}
                                        loading={false}
                                        typeButton="submit"
                                        block
                                        disabled={loading}
                                        className="button-gray"
                                        id="employees-submit"
                                    />
                                </GridItem>
                                : <div />}
                        </CardBody>
                        {this.state.showActive &&
                            <CustomSweetAlert
                                type="information"
                                title={t('employee.active.popup.title')}
                                onConfirm={() => this.active(false)}
                                confirmBtnCssClass={"btn primary"}
                                confirmBtnText={t('common.accept')}
                                onCancel={() => this.setState({ showActive: false })}
                                cancelBtnCssClass="btn danger"
                                cancelBtnText={t('cancel')}
                                showCancel={true}
                                message={
                                    <div className="schedule-errors-list">
                                        <span className="schedule-errors-item">
                                            {t('employee.deactivate.popup.message')}
                                        </span>
                                    </div>
                                }
                            />
                        }
                        {this.state.showDeactivate &&
                            <CustomSweetAlert
                                type="information"
                                title={t('employee.active.popup.title')}
                                onConfirm={() => this.active(true)}
                                confirmBtnCssClass={"btn primary"}
                                confirmBtnText={t('common.accept')}
                                onCancel={() => this.setState({ showDeactivate: false })}
                                cancelBtnCssClass="btn danger"
                                cancelBtnText={t('cancel')}
                                showCancel={true}
                                message={
                                    <div className="schedule-errors-list">
                                        <span className="schedule-errors-item">
                                            {t('employee.active.popup.message')}
                                        </span>
                                    </div>
                                }
                            />
                        }
                    </Card>
                </GridItem>

            </GridContainer>
        )
    }
}

Employees.propTypes = {
    t: PropTypes.func,
    onGetEmployees: PropTypes.func.isRequired,
    onCleanEmployees: PropTypes.func.isRequired,
    onUnmountEmployee: PropTypes.func,
}

export default withTranslation()(Employees);
