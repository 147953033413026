import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import ChartistGraph from 'react-chartist';
import CardBody from '../../Card/CardBody';
import CardIcon from '../../Card/CardIcon';
import Timeline from '@material-ui/icons/Timeline'
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import DashboardApiInvoker from "../../../api/DashboardApiInvoker";

class QuantityChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            series: [],
        }
    }

    componentWillReceiveProps(next) {
        if (next.employeeId) {
            this.getQuantitySeries(next.employeeId)
        }

        if (next.clear) {
            this.setState({
                series: [],
            })
        }
    }

    getQuantitySeries(employeeId) {
        if (employeeId
            && this.props.find) {
            DashboardApiInvoker.postEmployeeDashboardQuantity({
                dateFrom: this.props.dateFrom,
                dateTo: this.props.dateTo,
                employeeId: employeeId
            }, data => {
                this.formatSeries(data)
            }, null)
        }
    }

    formatSeries(data) {
        let labels = []
        let series = []
        if (data?.length) {
            data.forEach(s => {
                labels.push(moment(s.scheduleDateTime).format('DD-MM'))
                series.push(s.scheduleQuantity)
            });
        }

        this.setState({ series: { labels, series: [series] } });
    }

    render() {
        const { t } = this.props;
        return (
            <Card className="quantity-chart">
                <CardHeader icon>
                    <CardIcon color="primary">
                         <Timeline />
                    </CardIcon>
                    <h3 className="card-icon-title">
                        {t("dashboard.chart.quantity")}
                    </h3>
                </CardHeader>
                <CardBody>
                    <ChartistGraph
                        className="ct-chart-white-colors"
                        data={this.state.series}
                        type="Line"
                        options={{
                            ...this.props.options,
                            low: 0,
                            high: 15,
                            axisY: {
                                ...this.props.options.axisY,
                                onlyInteger: true
                            }
                        }}
                    />
                </CardBody>
            </Card>
        )
    }
}

QuantityChart.propTypes = {
    t: PropTypes.func,
    options: PropTypes.object,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    find: PropTypes.bool,
}

export default withTranslation()(QuantityChart);