import { CircularProgress, makeStyles } from '@material-ui/core';
import { Color, TermsPolicyType } from '../../util/interfaces';
import React, { useEffect, useState } from 'react';
import { TermsPolicyProfile, onCloseTermsParams, useTermsAndPolicy, useTermsPolicyProfile } from '../../hooks';

import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import CustomDialog from '../CustomDialog';
import CustomSweetAlert from '../CustomSweetAlert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import { initialValueTermsPolicyProfile } from '../../util';
import { useTranslation } from 'react-i18next';

export type ComponentsProps = {
  open: boolean;
  onCloseTerms: ({ isAccept, error }: onCloseTermsParams) => void;
  onCloseModal?: () => void;
  showButtons: boolean;
  type: TermsPolicyType;
  isLoggedUser: boolean;
}

const useStyles = makeStyles({
  loading: {
    textAlign: 'center',
    padding: 100,
  },
  textTerms: {
    minHeight: 100,
  },
  contentText: {
    maxHeight: 'calc(100vh - 250px)',
    minHeight: 50,
    overflow: 'auto',
    '& h5': {
      fontWeight: 500,
      fontSize: '1.12rem',
      textAlign: 'center',
    },
    '& h6': { marginTop: 15 },
    '& a': {
      color: '#3C4858',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    '& ol': {
      '& > li': {
        '& > ul': {
          paddingLeft: 20,
          margin: '5px 0 0',
        },
        '& + li': {
          marginTop: 8,
        },
      },
    },
    '& ul': {
      margin: '0 0 15px',
      '& li': {
        marginTop: 3,
      }
    },
  },
  submitTerms: {
    padding: '30px 15px 15px !important',
    textAlign: 'right',
    '& .disabled': {
      opacity: 0.5,
      cursor: 'default',
    }
  },
  alertWarning: { marginTop: '-200px !important' },
});

const TermsAndPolicyModal = ({ open, onCloseTerms, onCloseModal, showButtons, type, isLoggedUser }: ComponentsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    loading,
    snackbar,
    alertWarning,
    termsPolicy,
    busy,
    onRejectTermsPolicy,
    setAlertWarning,
    updateTermsPolicy,
    onClose,
    getTexts,
    getTermsAndPolicy,
  } = useTermsAndPolicy({ onCloseTerms, onCloseModal, isLoggedUser });

  const { getTermsAndPolicyFromProfile } = useTermsPolicyProfile();

  const [termsPolicyProfile, setTermsPolicyProfile] = useState<TermsPolicyProfile>(initialValueTermsPolicyProfile);

  const onConfirmWarning = async (): Promise<void> => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
    await updateTermsPolicy(false, type);
    onClose();
  }

  useEffect(() => {
    const dataTerms = getTermsAndPolicyFromProfile();
    setTermsPolicyProfile(dataTerms);
    getTermsAndPolicy(dataTerms);
  }, []);

  const onCancelWarning = (): void => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
  }

  const buttonAccept = !termsPolicyProfile.requiereTermsAccept ? 'buttonSpinner disabled' : 'buttonSpinner';
  
  const buildTermsPolicy = (): JSX.Element => {
    const content = type === TermsPolicyType.TERMS ? termsPolicy?.contentTerms : termsPolicy?.contentPolicy;
    return <GridContainer>
      <GridItem xs={12} className={classes.textTerms}>
        <div className={classes.contentText} dangerouslySetInnerHTML={{ __html: content ?? t('table.empty') }} />
      </GridItem>
      {(content && showButtons) && <GridItem xs={12} className={classes.submitTerms}>
        <Button
          color={Color.danger}
          onClick={() => onRejectTermsPolicy(type)}
          id="button-reject"
        >{t('common.reject')}</Button>
        <ButtonSpinner
          className={buttonAccept}
          loading={busy}
          onClick={() => updateTermsPolicy(true, type)}
          disabled={busy}
          label={t('common.accept')}
          labelLoading={t('common.saving')}
          id="button-save-book"
        />
      </GridItem>}
    </GridContainer>;
  }

  return (
    <CustomDialog
      title={getTexts(termsPolicyProfile.requestTermsVersion)[type].titleModal}
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
    >
      <>
        <Snackbar
          place="tr"
          color={snackbar.color}
          message={snackbar.message}
          open={snackbar.open}
        />
        {loading ?
          <div className={classes.loading}>
            <CircularProgress className="circular-progress" />
          </div> : buildTermsPolicy()
        }
        {alertWarning.show &&
          <CustomSweetAlert
            className={classes.alertWarning}
            type="warning"
            confirmBtnCssClass="danger"
            confirmBtnText={t('common.reject')}
            onConfirm={() => onConfirmWarning()}
            showCancel={true}
            cancelBtnText={t('common.cancel')}
            onCancel={() => onCancelWarning()}
            message={
              <>
                {alertWarning?.title && <h5>{alertWarning?.title}</h5>}
                <p>{alertWarning.message}</p>
              </>
            }
          />
        }
      </>
    </CustomDialog>
  )
}

TermsAndPolicyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseTerms: PropTypes.func,
  showButtons: PropTypes.bool,
  type: PropTypes.oneOf(['TERMS', 'POLICY', 'TERMS_POLICY']),
  isLoggedUser: PropTypes.bool,
};

export default TermsAndPolicyModal;
