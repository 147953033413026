export class ReasonRejected {
  id: number;
  detail: string;
  companyId: number;
  isActive: boolean;

  constructor({ id, detail, companyId, isActive }: ReasonRejectedDto) {
    this.id = id;
    this.detail = detail;
    this.companyId = companyId;
    this.isActive = isActive;
  }
}

export interface ReasonRejectedDto {
  id: number;
  detail: string;
  companyId: number;
  isActive: boolean;
}
