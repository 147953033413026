import { connect } from 'react-redux';
import { getPhoneTypes } from '../actions/phone';
import CreatePhone from '../components/CreatePhone';

const mapStateToProps = (state) => {
    return {
        phoneTypes: state.phone.phoneTypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetPhoneTypes: (phoneTypes) => {
            dispatch(getPhoneTypes(phoneTypes));
        }
    }
}

const PhoneService = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePhone);

export default PhoneService;
