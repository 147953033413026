import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function DownloadIcon(props) {
  return (

    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z" />
    </SvgIcon>

  );
}