import '../Modules/ModalSuggestedProfessionals.css';

import React, { Component } from 'react';

import Button from '../../../../CustomButtons/Button';
import CustomDialog from '../../../../CustomDialog';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import SuggestedProfesional from '../../../../SuggestedProfessional';
import { withTranslation } from 'react-i18next';

class ModalSuggestedDrivers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            component: null,
            current: null,
            type: '',
            action: '',
            shifts: [],
            suggested: [],
            itemRequestDetails: [],
        }
    }

    componentDidMount() {
        this.setState({ itemRequestDetails: this.props.itemRequestDetails });
    }

    componentWillReceiveProps(next) {
        if (next.itemRequestDetails) {
            this.setState({ itemRequestDetails: this.props.itemRequestDetails });
        }
        this.setState({ open: next.open });
    }

    closeModal = () => {
        this.setState({
            component: null,
            open: false,
            type: '',
            current: null,
        })
        this.props.onCloseModal();
    }

    handledValue(value, state, i) {
        const { itemRequestDetails } = this.state;
        itemRequestDetails[i] = value;
        this.setState({ itemRequestDetails })
	}

    getShifts = () => {
        const { t, customer, practiceType, itemRequestDetails } = this.props;
        let itemRequestDtls = itemRequestDetails
        const hasShift = itemRequestDtls && itemRequestDtls.length > 1;
        if (!itemRequestDtls) itemRequestDtls = [];
        return itemRequestDtls.map((s, i) => {
            const order = i+1;
            const shiftsText = `${t('suggested.shift')} ${(order)}:`;
            return (
                <div 
                    className="speciality-group" 
                    key={order}
                >
                    <div className="speciality-item">
                        {hasShift &&
                            (<InputLabel className="title-item title-shifts">
                                <small>
                                    {shiftsText}
                                </small>
                            </InputLabel>)
                        }
                        <SuggestedProfesional
                            hasShift={hasShift ? shiftsText : ''}
                            customer={customer}
                            lender={this.props.lender}
                            itemRequestDetails={itemRequestDtls[i]}
                            order={order}
                            inline
                            practiceType={practiceType.id}
                            onChangeValue={(value, state) => this.handledValue(value, state, i)}
                        />
                    </div>
                </div>
            )
        });
    }

    render() {
        const { t, open, practiceType } = this.props;

        return (
            <CustomDialog
                open={open}
                maxWidth="sm"
                title="Asignar chofer"
                onClose={() => this.closeModal()}
            >
                <GridContainer 
                    className="ps-child modules-form assign-professionals-form"
                >
                    <GridItem 
                        xs={12} 
                        className="item-assign"
                    >
                        <InputLabel className="title-item title-practice">
                            <small>
                                {t('Practice')}
                            </small>
                            {practiceType.value}
                        </InputLabel>
                        {this.getShifts()}
                    </GridItem>
                    
                    <GridItem className="schedule-form-submit" xs={12}>
                        <Button 
                            id="suggested-employees" 
                            color="primary" 
                            onClick={() => this.props.onConfirmModal(this.state.itemRequestDetails)}
                            >
                            {t('suggestedprofessionals.save')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CustomDialog>
        );
    }
}

ModalSuggestedDrivers.defautlProps = {
    practiceType: {},
}

ModalSuggestedDrivers.propTypes = {
    t: PropTypes.func,
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    practiceType: PropTypes.object,
    itemRequestDetails: PropTypes.array,
    lender: PropTypes.number,
    onConfirmModal: PropTypes.func,
    customer: PropTypes.object,
}

export default withTranslation()(ModalSuggestedDrivers);
