import React from 'react';
import PropTypes from 'prop-types';

const ShowMoreLessData = (props) => {
  const { data, isExpandedRow } = props;
  const dataCollapseRow = data.length > 60 ? `${data.slice(0, 60)}...` : data;
  const dataText = isExpandedRow ? data : dataCollapseRow;

  return <div className="more-less-txt">{dataText}</div>
};

ShowMoreLessData.propTypes = {
  data: PropTypes.any.isRequired,
  isExpandedRow: PropTypes.bool.isRequired,
};

export default ShowMoreLessData;
