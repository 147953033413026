import { ADD_SUPPLYCATALOG, EDIT_SUPPLYCATALOG, SUPPLYCATALOG_DETAIL, UNMOUNT_SUPPLYCATALOG } from "../actions/supplycatalog";

const supplycatalog = (state = {}, action, root) => {
    
    switch(action.type) {
        case UNMOUNT_SUPPLYCATALOG:
            return {}
        case SUPPLYCATALOG_DETAIL:
            return {...state, supplycatalog: action.supplycatalog}
        case ADD_SUPPLYCATALOG: {
                const result = addSupplyCatalog(state.supplycatalog, action.supplycatalog)
                return {...state, supplycatalog: result}
            }
        case EDIT_SUPPLYCATALOG: {
                const updated = updateSupplyCatalog(state.supplycatalog, action.supplycatalog)
                return {...state, supplycatalog: updated}
            }
        default:
            return state;
    }
}

const addSupplyCatalog = (supplycatalogs, supply) => {
    if (supplycatalogs?.length) {
        supplycatalogs.push(supply);
        return supplycatalogs;
    }
}

const updateSupplyCatalog = (supplycatalogs, supply) => {
    if (supplycatalogs?.length) {
        supplycatalogs.map((c, i) => {
            if (c.supplyCatalogId === supply.supplyCatalogId) {
                supplycatalogs[i] = supply;
            }
        });
    }
    return supplycatalogs;
}

export default supplycatalog;
