import { ScheduleFee, ScheduleStatus } from "../domain";

import { ApiGetSchedulesFeesFromMap } from "./api-get-schedules-fees.from-map";

export class ApiGetSchedulesFeesFromMapper {
  fromMap(map: ApiGetSchedulesFeesFromMap): ScheduleFee {
    const schedule = new ScheduleFee({
      id: map.scheduleFeesId,
      scheduleId: map.scheduleId,
      scheduleDateTime: map.scheduleDateTime,
      scheduleStatus: map.scheduleStatus as ScheduleStatus,
      practiceFeesId: map.practiceFeesId,
      isPaid: map.isPaid,
      amount: map.totalAmount,
    });
    return schedule;
  }
}
