import { ApiReasonRejectedFromMap } from "./api-reason-rejected.from-map";
import { ReasonRejected } from "../domain/rejected-reason";

export class ApiReasonRejectedFromMapper {
  fromMap(map: ApiReasonRejectedFromMap): ReasonRejected {
    const availableSchedule = new ReasonRejected({
      id: map.rejectedPracticeModuleId,
      detail: map.detail,
      isActive: map.active,
      companyId: map.companyId,
    });
    return availableSchedule;
  }
}
