import React, { useState } from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';

import Button from '../CustomButtons/Button';
import { InfoOutlined } from '@material-ui/icons';
import InfoScheduleItemModal from '../NewSchedule/ScheduleDetail/InfoScheduleItemModal';
import PropTypes from 'prop-types';

type Observations = {
  key: number;
  label: string;
  practice?: string;
}
type DataProps = {
  titleModal: string;
  label: string;
  tooltip: string;
  observations: Observations[];
}

interface ComponentProps {
  showInfo: boolean;
  data: DataProps;
}

const useStyles = makeStyles({
  labelWithInfo: {
    textWrap: 'nowrap',
    '& span button.MuiButtonBase-root': {
      padding: '2px 5px 5px',
      width: 'auto',
      height: 'auto',
      minWidth: 20,
    }
  },
});

const LabelWidthInfo = ({ data, showInfo }: ComponentProps) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const onCloseInfoModal = (isOpen: boolean) => {
    setOpenModal(isOpen);
  }

  const icon = showInfo ?
    <Tooltip title={data.tooltip}>
      <span><Button simple justIcon color='warning' onClick={() => setOpenModal(true)}><InfoOutlined /></Button></span>
    </Tooltip>
    : '';
  
  return (
    <>
      <span className={classes.labelWithInfo}><span className="label">{data.label}</span> {icon}</span>
      {
        openModal &&
          <InfoScheduleItemModal
            data={data.observations}
            open={openModal}
            onCloseModal={(isOpen) => onCloseInfoModal(isOpen)}
            title={data.titleModal}
          />
      }
    </>
  );
}

LabelWidthInfo.propTypes = {
  showInfo: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
}

export default LabelWidthInfo;
