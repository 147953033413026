import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException, VoidSuccess } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { FinanciersRepository } from "../repositories";

@injectable()
export class DeleteCustomerFinancierUsecase {

  @inject(FinanciersRepository) protected repository!: FinanciersRepository;

  async execute(personId: number, financierId: number): Promise<Either<DeleteCustomerFinancierError, VoidSuccess>> {
    if (!personId && financierId) {
      return Either.left(new DeleteCustomerFinancierInvalidDataError());
    }
    const result = await this.repository.deleteCustomerFinancier(personId, financierId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): DeleteCustomerFinancierError {
    if (exception instanceof NotFoundException) {
      return new DeleteCustomerFinancierNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new DeleteCustomerFinancierConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new DeleteCustomerFinancierOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new DeleteCustomerFinancierAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new DeleteCustomerFinancierBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in DeleteCustomerFinancierUsecase");
  }
}

export abstract class DeleteCustomerFinancierError {
  error?: string;
}

export class DeleteCustomerFinancierInvalidDataError extends DeleteCustomerFinancierError { }
export class DeleteCustomerFinancierNotFoundError extends DeleteCustomerFinancierError { }
export class DeleteCustomerFinancierConnectionError extends DeleteCustomerFinancierError { }
export class DeleteCustomerFinancierOperationalError extends DeleteCustomerFinancierError { }
export class DeleteCustomerFinancierAuthorizationError extends DeleteCustomerFinancierError { }
export class DeleteCustomerFinancierBadRequestError extends DeleteCustomerFinancierError {
  constructor(public readonly error: string) {
    super();
  }
}
