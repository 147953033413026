import { connect } from 'react-redux';
import Tools from '../components/Tools';
import { getTools, unmountTool} from '../actions/tools';

const mapStateToProps = (state) => {
    return {
        tools: state.tools.tools,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetTools: (tools) => {
            dispatch(getTools(tools));
        },
        unmountTools: () => {
            dispatch(unmountTool());
        },
    }
}

const ToolsService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Tools);

export default ToolsService;
