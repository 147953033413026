import '../TechnicalFile.css';

import { InputLabel, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '../../Card/Card'
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datetime from 'react-datetime';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import { Stethoscope } from '../../../icons';
import config from '../../../config/config';
import lodash from 'lodash';
import moment from 'moment';
import { useVitalSigns } from '../../../hooks/useVitalSigns';
import { withTranslation } from 'react-i18next'

const dateFormat = config.getDateFormat();


const VitalSigns = props => {
  const { t } = props;

  const [isLoading, setIsLoading] = useState(true)

  const [technicalFile, setTechnicalFile] = useState({
    schedulesList: []
  })
  const [professionalsList, setProfessionalsList] = useState([])
  const [profesionalFilter, setProfesionalFilter] = useState('')

  const [specialitiesList, setSpecialitiesList] = useState([])
  const [specialityFilter, setSpecialityFilter] = useState('')

  const [dateFromFilter, setDateFromFilter] = useState(moment.utc().startOf('month'))
  const [dateToFilter, setDateToFilter] = useState(moment.utc().endOf('month'))

  const chartOptionsPulse = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',
    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
    low: 45,
    high: 200
  }
  const chartOptionsOxigen = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',

    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
    low: 50,
    high: 100
  }
  const chartOptionsTemperature = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',

    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }), low: 34,
    high: 45
  }
  const chartOptionsPressure = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',

    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
    low: 40,
    high: 250
  }
  

  useEffect(() => {
    fetchTechnicalFile(props.appointmentId);
  }, [props.appointmentId])


  const fetchTechnicalFile = async (appointmentId) => {
    setIsLoading(true)
    AppointmentApiInvoker.getAppointmentTechnicalFile(
      appointmentId,
      data => {
        setIsLoading(false)
        if (!data) {
          return console.error('** No data!!');
        } else {
          setTechnicalFile(data)
          const profList = data.schedulesList.map(s => s.professional);
          setProfessionalsList(lodash.uniq(profList));
          const specList = data.schedulesList.map(s => s.speciality);
          setSpecialitiesList(lodash.uniq(specList));
          setDateFromFilter(moment.utc(data.startDate).startOf(data.startDate));
          setDateToFilter(moment.utc(data.endDate).endOf(data.endDate));
        }
      },
    )
  }
  const handleFilterChange = (type) => (e, value) => {
    if (type === 'professional') {
      setProfesionalFilter(value)
    } else if (type === 'dateTo') {
      setDateToFilter(e)
    } else if (type === 'dateFrom') {
      setDateFromFilter(e)
    } else if (type === 'speciality') {
      setSpecialityFilter(value)
    } 
  }

  const [pulse, oxigen, arterialTension, temperature] = useVitalSigns(technicalFile.schedulesList, {
    profesional: profesionalFilter,
    speciality: specialityFilter,
    dateFrom: dateFromFilter,
    dateTo: dateToFilter,
  });

  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader icon>
          <CardIcon color="primary">
            <Stethoscope />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {isLoading ?
            <div className="progressContent">
              <CircularProgress />
            </div>
            :
            <GridContainer className="vitalSignsView">
              <GridItem container className="rowItem">
                <GridItem xs={6} sm={6}>
                  <InputLabel className="label-form">{t('appointment.find.startDate')}</InputLabel>
                  <Datetime
                    isValidDate={(current) => {
                      return current.isBefore(dateToFilter)
                    }}
                    timeFormat={false}
                    viewMode="days"
                    dateFormat={dateFormat}
                    onChange={handleFilterChange('dateFrom')}
                    value={dateFromFilter}
                    closeOnSelect={true}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <InputLabel className="label-form">{t('appointment.find.endDate')}</InputLabel>
                    <Datetime
                      isValidDate={(current) => {
                        return current.isAfter(dateFromFilter)
                      }}
                      timeFormat={false}
                      viewMode="days"
                      dateFormat={dateFormat}
                      onChange={handleFilterChange('dateTo')}
                      value={dateToFilter}
                      closeOnSelect={true}
                    />
                </GridItem>
              </GridItem>
              <GridItem container className="rowItem">
                <GridItem xs={6} sm={6}>
                    <Autocomplete
                      options={professionalsList}
                      getOptionLabel={option => option}
                      getOptionSelected={option => option}
                      renderInput={params => (
                        <TextField {...params} label={t('technicalfile.professional')} fullWidth />
                      )}
                      onChange={handleFilterChange('professional')}
                      value={profesionalFilter}
                    />
                </GridItem>
                <GridItem xs={6} sm={6}>
                    <Autocomplete
                      options={specialitiesList}
                      getOptionLabel={option => option}
                      getOptionSelected={option => option}
                      renderInput={params => (
                        <TextField {...params} label={t('technicalfile.speciality')} fullWidth />
                      )}
                      onChange={handleFilterChange('speciality')}
                      value={specialityFilter}
                    />
                </GridItem>
              </GridItem>
              <GridItem xs={12} className="rowItem chartistGraph first">
                <h3 className="chart-header headerVitalSigns">{t('technicalfile.temperature')}</h3>
                <div className="chartistGraphBody">
                  <ChartistGraph
                    options={chartOptionsTemperature}
                    data={temperature}
                    type="Line"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} className="rowItem chartistGraph">
                <h3 className="chart-header headerVitalSigns">{t('technicalfile.pulse')}</h3>
                <div className="chartistGraphBody">
                  <ChartistGraph
                    options={chartOptionsPulse}
                    data={pulse}
                    type="Line"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} className="rowItem chartistGraph">
                <h3 className="chart-header headerVitalSigns">{t('technicalfile.oxigen')}</h3>
                <div className="chartistGraphBody">
                  <ChartistGraph
                    options={chartOptionsOxigen}
                    data={oxigen}
                    type="Line"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} className="rowItem chartistGraph">
                <h3 className="chart-header headerVitalSigns">{t('technicalfile.arterialTension')}</h3>
                <div className="chartistGraphBody">
                  <ChartistGraph
                    options={chartOptionsPressure}
                    data={arterialTension}
                    type="Line"
                  />
                  <h4 className="chart-legend">
                    <span className="chart-legend-blue">{t('technicalfile.pressureMax')}</span>
                    <span className="chart-legend-red">{t('technicalfile.pressureMin')}</span>
                  </h4>
                </div>
              </GridItem>
            </GridContainer>
          }
        </CardBody>
      </Card>
    </GridItem>
  )
}

VitalSigns.propTypes = {
  t: PropTypes.func,
  appointmentId: PropTypes.string.isRequired,
}

export default withTranslation()(VitalSigns);
