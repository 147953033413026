import { connect } from 'react-redux';
import { igetEmployees, igetEmployeesType } from "../actions/Integraldashboardcustomer";
import IntegralFilters from '../components/IntegralTechnicalFile/IntegralForm/IntegralDashboard/Filters';

const mapStateToProps = (state) => {
    return {
        employees: state.dashboard.employees,
        employeesType: state.dashboard.employeesType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEmployees: (employees) => {
            dispatch(igetEmployees(employees))
        },
        onGetEmployeesType: (employeesType) => {
            dispatch(igetEmployeesType(employeesType))
        },
    }
}


const IntegralFiltersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(IntegralFilters)

export {IntegralFiltersService};
