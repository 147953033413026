import '../NewSchedule.css';

import React, { Component } from 'react';

import BusinessApiInvoker from '../../../api/BusinessApiInvoker';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { HomeRepairService } from '../../../icons';
import PropTypes from 'prop-types';
import SelectInput from '../../SelectInput';
import { Typography } from '@material-ui/core';
import ValidationInput from '../../ValidationInput';
import lodash from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class ProviderForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            financiers: [],
            financier: '',
            affiliateId: '',
            providers: [],
            provider: '',
            dateStart: '',
            dateEnd: '',
            isDateRangeValid: true,
            dateRangeInvalidText: '',
        }
    }

    componentDidMount() {
        const { value } = this.props;
        this.getProviders();
        this.getFinanciers();

        if (value) {
            this.handleValue(value.financier, 'financier');
            this.handleValue(value.provider, 'provider');
            this.handleValue(value.dateStart, 'dateStart');
            this.handleValue(value.dateEnd, 'dateEnd');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.customerId && this.props.customerId.customer !== nextProps.customerId.customer) {
            this.getFinanciers({ customerId: nextProps.customerId });
        }
    }

    getDataFinanciers(value, customer) {
        BusinessApiInvoker.getCustomersFinanciers(customer, (data) => {
            if (!data.length) {
                this.setState({ financiers: [], financier: '', affiliateId: '' }, () => {
                    this.props.onFilledFinancier({ financierId: this.state.financier, affiliateId: '' });
                });
                return;
            }
            if (data.length === 1) {
                this.setState({ financiers: data }, () => {
                    this.setFirstFinancier();
                });
                return;
            }
            const financierIndex = this.state.financiers.findIndex(item => item.financierId === (value || (this.props.value?.financier?.financierId)))
            if (financierIndex !== -1) {
                this.setState((prevState) => ({
                    financiers: data,
                    financier: value || (this.props.value?.financier?.financierId),
                    affiliateId: prevState.financiers[financierIndex]?.affiliateId
                }), () => {
                    if (this.props.onFilledFinancier) {
                        this.props.onFilledFinancier({ financierId: this.state.financier, affiliateId: this.state.affiliateId });
                    }
                });
            } else {
                this.setState({ financiers: data }, () => {
                    this.setFirstFinancier();
                });
            }
        }, null);
    }

    getFinanciers = (value) => {
        let customerId;
        if (lodash.get(value, 'customerId.customer', null)) {
            customerId = value.customerId;
        } else if (lodash.get(this, 'props.customerId.customer', null)) {
            customerId = this.props.customerId;
        } else if (lodash.get(this, 'props.value.customerId.customer', null)) {            
            customerId = this.props.value.customerId;
        }
        if (customerId?.customer) {
            this.getDataFinanciers(value, customerId.customer);
        }
    }

    setFirstFinancier(){
        const { financiers } = this.state;
        this.setState((prevState) => ({
            financier: prevState.financiers[0]?.financierId ?? '',
            affiliateId: prevState.financiers[0]?.affiliateId ?? '',
        }));
        this.props.onFilledFinancier({
            financierId: financiers[0]?.financierId ?? '',
            affiliateId: financiers[0]?.affiliateId ?? '',
        });
    }

    getProviders (data) {
        const { value, disabled } = this.props;
        BusinessApiInvoker.getServiceProviders(providers => {
            this.setState({
                providers: this.formatProviders(providers),
                provider: data || ((disabled && value.provider) && value.provider.serviceProviderId),
            }, () => {
                if (this.state.providers.length === 1) {
                    this.setState((prevState) => ({ provider: prevState.providers[0].id }));
                }
            })
        }, null);
    }

    formatProviders(providers) {
        const result = providers.map(p => ({
            id: p.serviceProviderId,
            value: p.fantasyName,
        }));
        return result;
    }

    formatFinanciers(financiers) {
        const result = financiers.map(f => ({
            id: f.financierId,
            value: f.financier.fantasyName,
            affiliateId: f.affiliateId,
        }));
        return result;
    }

    onDatesValue(value, state) {
        if (state === 'dateStart' && this.state.dateEnd && this.state.dateEnd < value) {
            this.setState({ dateEnd: '' });
        }
        if ((state === 'dateStart' || state === 'dateEnd') && this.state.dateStart && this.state.dateEnd) {
            this.onDateRange();
        }
    }

    onFinancerValue(value) {
        if (!this.state.financiers) {
            this.getFinanciers(value);
            return;
        }
        const financierIndex = this.state.financiers.findIndex(item => item.financierId === value);
        if (financierIndex !== -1) {
            this.setState((prevState) => ({ affiliateId: prevState.financiers[financierIndex].affiliateId,
            }), () => {
                this.props.onFilledFinancier({ financierId: this.state.financier, affiliateId: this.state.affiliateId });
            });
        }
    }

    handleValue(value, state) {
        this.setState({ [state]: value }, () => {
            this.onDatesValue(value, state);

            this.props.onFilledProvider({
                provider: this.state.provider,
                dateStart: this.state.dateStart,
                dateEnd: this.state.dateEnd,
            });

            if (state === 'financier') {
                this.onFinancerValue();
            }
        });
    }

    getMaxDate() {
        return moment().add(1, 'years').format('YYYY-MM-DD');
    }

    onDateRange() {
        const { t } = this.props;
        const { dateStart, dateEnd } = this.state;
        const date1 = new Date(dateStart);
        const date2 = new Date(dateEnd);
        const isValidDates = date2 >= date1;
        if (!isValidDates) {
            this.props.isDateRangeValid(isValidDates);
            this.setState({ dateRangeInvalidText: t('fees.validDates'), isDateRangeValid: isValidDates });
            return;
        }
        const differenceMs = Math.abs(date2 - date1);
        const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365);
        const isValidRange = differenceYears >= 0 && differenceYears <= 1;
        if (!isValidRange) {
            this.setState({ dateRangeInvalidText: t('appointment.new.invalidRangeDates'), isDateRangeValid: isValidRange });
            this.props.isDateRangeValid(isValidRange);
            return;
        }
        this.props.isDateRangeValid(true);
        this.setState({ dateRangeInvalidText: '', isDateRangeValid: true });
      }

    render() {
        const { financiers, financier, affiliateId, dateStart, dateEnd, dateRangeInvalidText, isDateRangeValid } = this.state;
        const { t, checkInvalid, disabled } = this.props;

        return (
            <GridItem id="provider-form" className="provider-form-component schedule-form" xs={12} sm={6} md={6}>
                <Card>
                    <CardHeader icon>
                        <CardIcon color="primary">
                            <HomeRepairService />
                        </CardIcon>
                        <h3 className="card-icon-title">{t('appointment.financialData')}</h3>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem className="fullWidth-input" xs={12} sm={6}>
                                <SelectInput
                                    id="select-financier"
                                    fullWidth
                                    disabled={disabled}
                                    label={t('appointment.new.provider.financier')}
                                    elements={this.formatFinanciers(financiers)}
                                    onSelectedValue={value => this.handleValue(value, 'financier')}
                                    onGetOptionLabel={(option) => option.value}
                                    value={financier}
                                    invalid={!financier && checkInvalid}
                                    errorText={!financier && checkInvalid
                                        ? t('error.required')
                                        : ''
                                    }
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input validation-input" xs={12} sm={6}>
                                <ValidationInput
                                    id="input-affiliateId"
                                    fullWidth
                                    value={affiliateId || ''}
                                    onChangeValue={() => {}}
                                    disabled
                                    text={t('appointment.new.provider.affiliate_identifier')}
                                    invalid={!affiliateId && checkInvalid}
                                    errorText={!affiliateId && checkInvalid
                                        ? t('error.required')
                                        : ''
                                    }
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input" xs={12} sm={6}>
                                <DateInput
                                    id="datetime-start"
                                    disabled={disabled}
                                    text={`${t('appointment.find.startDate')} *`}
                                    minDate
                                    onChangeValue={value => this.handleValue(value, 'dateStart')}
                                    value={dateStart}
                                    error={!dateStart && checkInvalid}
                                    errorText={!dateStart && checkInvalid
                                        ? t('error.required')
                                        : ''
                                    }
                                    inputProps={{
                                        disabled: disabled,
                                    }}
                                />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={12} sm={6}>
                                    <DateInput
                                        id="datetime-end"
                                        disabled={disabled}
                                        text={`${t('appointment.find.endDate')} *`}
                                        minDate
                                        min={dateStart}
                                        maxDate
                                        max={this.getMaxDate()}
                                        onChangeValue={value => this.handleValue(value, 'dateEnd')}
                                        value={dateEnd}
                                        error={(!dateEnd && checkInvalid) || isDateRangeValid}
                                        errorText={!dateEnd && checkInvalid
                                            ? t('error.required')
                                            : ''
                                        }
                                        inputProps={{
                                            disabled: disabled,
                                        }}
                                    />
                            </GridItem>
                            <GridItem style={{ minHeight: 80 }} className="fullWidth-input" xs={12}>
                                <Typography className="MuiFormHelperText-root helper-error" variant="body2" color="error">
                                    {dateRangeInvalidText}
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        )
    }
}

ProviderForm.propTypes = {
    t: PropTypes.func,
    onFilledProvider: PropTypes.func.isRequired,
    onFilledFinancier: PropTypes.func.isRequired,
    value: PropTypes.shape({
        dateEnd: PropTypes.string,
        dateStart: PropTypes.string,
        financier: PropTypes.any,
        provider: PropTypes.any,
        customerId: PropTypes.any,
    }),
    checkInvalid: PropTypes.bool,
    disabled: PropTypes.bool,
    customer: PropTypes.object,
    customerId: PropTypes.object,
    isDateRangeValid: PropTypes.func,
}

export default withTranslation()(ProviderForm);
