import { connect } from 'react-redux';
import MedicalRecordList from '../components/MedicalRecord/MedicalRecordList';
import { setList } from '../actions/medicalRecordAction';

const mapStateToProps = (state) => {    
    return {
        medicalRecord: state.medicalRecord,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetList: (list) => {
            dispatch(setList(list));
        }
    }
}

const MedicalRecordService = connect(
    mapStateToProps,
    mapDispatchToProps
)(MedicalRecordList);

export default MedicalRecordService;
