import { APPOINTMENT, STATUS, PROFESSIONALS, NEW_TOOLS, CUSTOMERS, ADDRESS, MODULES, RECURRENCES, APPOINTMENT_TYPES, PERSON_ADDRESS } from "../actions/newAppointment";

const newAppointment = (state = {}, action, root) => {
    switch(action.type) {
        case STATUS: {
            const status = action.status
            return {...state, status}
        }
        case PROFESSIONALS: {
            const professionals = action.professionals
            return {...state, professionals}
        }
        case NEW_TOOLS: {
            const tools = action.tools
            return {...state, tools}
        }
        case CUSTOMERS: {
            const customers = action.customers
            return {...state, customers}
        }
        case ADDRESS: {
            const address = action.address
            return {...state, address}
        }
        case MODULES: {
            const modules = action.modules
            return {...state, modules}
        }
        case RECURRENCES: {
            const recurrences = action.recurrences
            return {...state, recurrences}
        }
        case APPOINTMENT_TYPES: {
            const appointmentTypes = action.appointmentTypes
            return {...state, appointmentTypes}
        }
        case APPOINTMENT: {
            const appointment = action.appointment
            return {...state, appointment}
        }
        case PERSON_ADDRESS: {
            const personAddress = action.personAddress
            return {...state, personAddress}
        }
        default:
            return state;
    }
}

export default newAppointment;
