import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function MedicalInformation(props) {
  return (
    <SvgIcon width="131" height="131" viewBox="0 0 131 131" fill="none" {...props}>
      <path d="M109.167 38.2084H81.875V21.8334C81.875 15.8292 76.9625 10.9167 70.9583 10.9167H60.0417C54.0375 10.9167 49.125 15.8292 49.125 21.8334V38.2084H21.8333C15.8292 38.2084 10.9167 43.1209 10.9167 49.125V109.167C10.9167 115.171 15.8292 120.083 21.8333 120.083H109.167C115.171 120.083 120.083 115.171 120.083 109.167V49.125C120.083 43.1209 115.171 38.2084 109.167 38.2084ZM60.0417 21.8334H70.9583V49.125H60.0417V21.8334ZM109.167 109.167H21.8333V49.125H49.125C49.125 55.1292 54.0375 60.0417 60.0417 60.0417H70.9583C76.9625 60.0417 81.875 55.1292 81.875 49.125H109.167V109.167ZM60.0417 87.3334H49.125V98.25H38.2083V87.3334H27.2917V76.4167H38.2083V65.5H49.125V76.4167H60.0417V87.3334ZM70.9583 79.1459V70.9584H103.708V79.1459H70.9583ZM70.9583 95.5209V87.3334H92.7917V95.5209H70.9583Z" />
    </SvgIcon>
  );
}
