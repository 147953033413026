// Constants for initial values and settings
export const filtersChips = {
  appointmentId: '',
  customerId: '',
  dayOfWeek: '',
  employeeId: '',
  evenDay: null,
  practiceTypeId: null,
  startTime: '',
};

// Helper function to create initial values
const createInitialValues = (stepValues) => ({
  busyButton: false,
  graphics: false,
  ...stepValues,
});

// Initial values for different steps
const initialValuesStep0 = {
  startDate: '',
  endDate: '',
  chips: [],
  filters: filtersChips,
  practicesType: [],
  schedules: [],
  schedulesSelected: [],
  schedulesSelectedData: [],
  originalschedule: [],
  originalquery: [],
};

const initialValuesStep1 = {
  originalProfessionalsToReassign: [],
  professionalsSelected: [],
  professionalsToReassign: [],
};

const initialValuesStep2 = {
  dateTimeSchedules: '',
  dateSchedulesHasChanged: false,
};

const initialValuesStep3 = {
  scheduleTimeUpdate: [],
  timeSchedules: '',
  timeSchedulesHasChanged: false,
};

const initialValuesStep5 = {
  bookings: [],
  bookingChanged: [],
  bookingsError: [],
  currentEvent: {},
  events: [],
  hasChangedSchedules: false,
  loadingevents: false,
  schedulesNotAssigned: [],
  showConfirmSave: false,
  showConfirmUndo: false,
};

// Consolidated initial values
export const initialValues = createInitialValues({
  ...initialValuesStep0,
  ...initialValuesStep1,
  ...initialValuesStep2,
  ...initialValuesStep3,
  ...initialValuesStep5,
});

// Initial notification settings
export const initialNotification = {
  color: 'warning',
  message: '',
  open: false,
};

// Step configurations
export const steps = [
  { number: 0, label: 'Configuración del trayecto Ida', isOpcional: true, completed: false, saveButton: false, skipped: true },
  { number: 1, label: 'Configuración del trayecto Vuelta', isOpcional: true, completed: false, saveButton: false, skipped: true },
  { number: 2, label: 'Datos generales', isOpcional: true, completed: false, saveButton: false, skipped: true },
  { number: 3, label: 'Resumen', isOpcional: true, completed: false, saveButton: false, skipped: true },
];

export const getSteps = (transferWay) => {
  let filteredSteps = steps;

  if (transferWay === 1) {
    filteredSteps = steps.filter(step => step.number !== 1);
  } else if (transferWay === 2) {
    filteredSteps = steps.filter(step => step.number !== 0);
  }

 
  filteredSteps = filteredSteps.map((step, index) => ({
    ...step,
    number: index, 
  }));

  return filteredSteps

}

// Function to get color based on status
export const getColor = (status) => {
  const colors = [
    'F06292', '1BD893', 'D26D0B', '79009B', 'D81B60', '00B359',
    'DEC02B', 'BF00B0', '880E4F', '006E2A', 'E8DD6C', 'E157CA'
  ];
  return colors[status.group % colors.length];
};

// Initial alert warning settings
export const initialAlertWarning = {
  open: false,
  message: '',
  status: 'warning',
  confirmBtnText: '',
  cancelBtnText: '',
  title: '',
  type: '',
};