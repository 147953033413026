import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import config from '../../config/config';
import './ScheduleDetail.css';

const dateTimeSmallFormat = config.getDateTimeSmallFormat();

class ScheduleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            customer: '',
            schedule: {},                        
            scheduleParameters: [],
            supplyList:[],
        }
    }

    componentWillMount() {
        this.setState({
            open: this.props.open,
            schedule: this.props.schedule,
            customer: this.props.customer            
        });
    }

    componentWillReceiveProps(next) {
        if (next.open) {
            this.setState({ open: next.open });
        }

        if (next.schedule) {
            this.setState({
                schedule: next.schedule,
                scheduleParameters: this.getParameters(next.schedule),
            });
        }

        if (next.customer) {
            this.setState({ customer: next.customer });
        }

        if(next.supplyList) {
            this.setState({ supplyList : next.supplyList });
        }
    }

    handleClose() {
        this.setState({ open: false });
        this.props.onClose();
    }

    getParameters(schedule) {
        if (schedule.metadata?.vital_signs) {
            return [
                {parameter: 'Temperatura', value: schedule.metadata.vital_signs.temperature},
                {parameter: 'Pulso', value: schedule.metadata.vital_signs.pulse},
                {parameter: 'Respiración', value: schedule.metadata.vital_signs.breathing},
                {parameter: 'Presión Máxima', value: schedule.metadata.vital_signs.pressure.max},
                {parameter: 'Presión Mínima', value: schedule.metadata.vital_signs.pressure.min},
            ];
        }
        return [];
    }

    render() {
        const { t } = this.props;
        const { customer, employee, open, scheduleDateTime, schedule, scheduleParameters, supplyList } = this.state;
        return (
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                keepMounted
                onClose={() => this.handleClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
                className="schedule-detail"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className="schedule-detail-title"
                >
                    <h4 className="schedule-detail-name">{t('appointment.calendar.scheduleDialogTitle')} - {customer}</h4>
                    <Button
                        justIcon
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.handleClose()}
                        id="button-close"
                    >
                        <Close />
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="classic-modal-slide-description"
                    className="schedule-detail-content"
                >
                    <GridContainer>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>{t('common.date')}/{t('common.hour')}: </b> {moment(scheduleDateTime).format(dateTimeSmallFormat)}
                        </GridItem>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>{t('common.professional')}: </b> {`${employee ? employee.lastName : ''} ${employee ? employee.firstName : ''}`}
                        </GridItem>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>{t('status')}: </b> {schedule.scheduleStatus ? t(`technicalfile.state.${schedule.scheduleStatus.name}`) : t(`technicalfile.state.PENDING_APPROVAL`)}
                        </GridItem>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>{t('label.appointmentType')}: </b> {schedule.appointmentDetail?.practiceType?.name ?? ''}
                        </GridItem>
                    </GridContainer>
                    <GridContainer className="schedule-detail-clinic">
                        <GridItem md={4} className="schedule-detail-table">
                            <Table
                                sortable={false}
                                tableHeaderColor="primary"
                                tableHead={[{ Header: t('label.parameter'), accessor: "parameter" }, { Header: t('label.value'), accessor: "value" }]}
                                tableData={scheduleParameters}
                                colorsColls={["primary"]}
                            />
                        </GridItem>
                        <GridItem md={4} className="schedule-detail-table">
                            <Table
                                sortable={false}
                                tableHeaderColor="primary"
                                tableHead={[{ Header: t('sypplycatalog.label'), accessor:"supplies" }, { Header: t('appointment.table.quantity'), accessor:"quantity" }]}
                                tableData={supplyList}
                                colorsColls={["primary"]}
                            />
                        </GridItem>
                        <GridItem md={4} className="schedule-detail-notes">
                            {(schedule.metadata?.note) && <p>{schedule.metadata.note}</p>}
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleClose()}
                        color="danger"
                        simple
                        id="button-close-modal"
                    >{t('common.close')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ScheduleDetail.propTypes = {
    t: PropTypes.func,
    schedule: PropTypes.object,
    open: PropTypes.bool,
    customer: PropTypes.string,
    onClose: PropTypes.func,
}
export default withTranslation()(ScheduleDetail)