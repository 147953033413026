import '@material/react-chips/dist/chips.css';

import { Chip, ChipSet } from '@material/react-chips';

import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  chipSet: {
    position: 'relative',
  }
}));

const ChipFilters = ({
  chips,
  onChangeChips,
  customers,
  filterData,
  professionals,
  appointments,
  t,
}) => {
  const classes = useStyles();

  const addFilter = (s) => {
    const chipsData = chips;
    chipsData.push(s);
    return onChangeChips(chipsData);
  }

  const onChipRemove = (s) => {
    const chipsData = chips;
    return chipsData.forEach((item, key) => {
      let label = `${item.title} '${item.name}'`;
      if (item.multiple) {
        label = `${item.title} ${item.name}`;
      }
      if (label === s) {        
        chipsData.splice(key, 1);
        onChangeChips(chipsData);
      }
    });
  }

  const renderChipsLabel = (chips) => chips.map((item, key) => {
    let label = `${item.title} '${item.name}'`;
    if (item.multiple) {
      label = `${item.title} ${item.name}`;
    }
    return (
      <Chip
        key={item.id}
        className="ChipsInFilter"
        filter="true"
        selecteditems={chips}
        trailingIcon={<Cancel />}
        shouldRemoveOnTrailingIconClick={false}
        handleTrailingIconInteraction={() => onChipRemove(label)}
        id={item.id}
        label={label}
      />
    );
  })

  return (
    <ChipSet className={classes.chipSet}>
      <CustomInputChip
        selecteditems={chips}
        id="add-filter"
        label={t('common.addFilter')}
        customers={customers}
        filterData={filterData}
        professionals={professionals}
        chipsData={chips}
        appointments={appointments}
        onApply={addFilter}
      />
      {renderChipsLabel(chips)}
    </ChipSet>
  )
}

ChipFilters.defaultProps = {
  chips: [],
};

ChipFilters.propTypes = {
  t: PropTypes.func,
  chips: PropTypes.array,
  onChangeChips: PropTypes.func,
  customers: PropTypes.array,
  filterData: PropTypes.object,
  professionals: PropTypes.array,
  appointments: PropTypes.array,
}

export default withTranslation()(ChipFilters);
