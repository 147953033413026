import { connect } from 'react-redux';
import { getEmployees, getEmployeesType, getSupplies, getTools } from "../actions/dashboard";
import Dashboard from '../components/Dashboard';
import Filters from '../components/Dashboard/Filters';

const mapStateToProps = (state) => {
    return {
        employees: state.dashboard.employees,
        supplies: state.dashboard.supplies,
        tools: state.dashboard.tools,
        employeesType: state.dashboard.employeesType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEmployees: (employees) => {
            dispatch(getEmployees(employees));
        },
        onGetEmployeesType: (employeesType) => {
            dispatch(getEmployeesType(employeesType));
        },
        onGetSupplies: (supplies) => {
            dispatch(getSupplies(supplies));
        },
        onGetTools: (tools) => {
            dispatch(getTools(tools));
        }
    }
}

const DashboardService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters);

export { DashboardService, FiltersService };
