import { TOOLTYPES_DETAIL, ADD_TOOLTYPE, EDIT_TOOLTYPE } from "../actions/tooltypes";

const tooltypes = (state = {}, action, root) => {
    switch(action.type) {
        case TOOLTYPES_DETAIL:
            return {...state, tooltypes: action.tooltypes}
        case ADD_TOOLTYPE: {
                const result = addToolType(state.tooltypes, action.tooltype)
                return {...state, customers: result}
            }
        case EDIT_TOOLTYPE: {
                const updated = updateToolType(state.tooltypes, action.tooltype)
                return {...state, customers: updated}
            }
        default:
            return state;
    }
}

const addToolType = (tTypes, tooltype) => {
    if (tTypes?.length) {
        tTypes.push(tooltype);
        return tTypes;
    }
}

const updateToolType = (tTypes, tooltype) => {
    if (tTypes?.length) {
        tTypes.forEach((c, i) => {
            if (c.toolTypeId === tooltype.perstoolTypeIdonId) {
                tTypes[i] = tTypes
            }
        });
    }
    return tTypes;
}

export default tooltypes;
