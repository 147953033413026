import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';

import Button from '../CustomButtons/Button';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { grayColor } from '../../assets/components/material-dashboard-pro-react';

const pxToRem = (px) => `${px / 16}rem`;

const useStyles = makeStyles(() => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h2': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: `${pxToRem(21)} !important`,
            fontWeight: 300,
            paddingLeft: 50,
            textAlign: 'center',
            width: '100%',
            '&> span': {
                display: 'inline-block',
                paddingTop: 15,
                width: 'calc(100% - 50px)',
                '& strong': {
                    fontWeight: 500,
                },
            },
            '& button': {
                float: 'right',
                '& MuiButton-label': {
                    lineHeight: .5,
                },
            },
        },
        '& h6': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
    },
    dialogBody: {
        '& p, li': {
            color: grayColor[2],
            fontSize: pxToRem(14),
        },
    }
}));

const CustomDialog = ({ children, actions, maxWidth, onClose, open, title, className }) => {
    const classes = useStyles()
    return (
        <Dialog
            className={className}
            fullWidth
            maxWidth={maxWidth}
            open={open}
        >
            <DialogTitle className={classes.dialogTitle}>
                <span dangerouslySetInnerHTML={{__html: title}} />
                <Button
                    justIcon
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={onClose}
                    id="button-dialog-close"
                >
                    <Close />
                </Button>
            </DialogTitle>
            <DialogContent className={classes.dialogBody}>
                {children}
            </DialogContent>
            {actions &&
                <DialogActions>
                    {actions}
                </DialogActions>
            }
        </Dialog>
    )
}

CustomDialog.defaultProps = {
    className: '',
    maxWidth: 'md',
    onClose: () => {},
    open: false,
    title: '',
}

CustomDialog.propTypes = {
    className: PropTypes.string,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.element, PropTypes.string,
    ]),
    actions: PropTypes.node,
    children: PropTypes.node,
}

export default CustomDialog;
