import { ADD_CUSTOMER, CLEAN_CUSTOMER, CUSTOMERS_ADDRESS, CUSTOMERS_DETAIL, CUSTOMERS_PERSON_ADDRESS, EDIT_CUSTOMER } from "../actions/customers";

const customers = (state = {}, action, root) => {
    switch(action.type) {
        case CLEAN_CUSTOMER:
            return {};
        case CUSTOMERS_DETAIL:
            return { ...state, customers: action.customers };
        case CUSTOMERS_ADDRESS: {
            const address = action.address;
            return { ...state, address };
        }
        case CUSTOMERS_PERSON_ADDRESS: {
            const personAddress = action.personAddress;
            return { ...state, personAddress };
        }
        case ADD_CUSTOMER: {
            const result = addCustomer(state.customers, action.customer);
            return { ...state, customers: result };
        }
        case EDIT_CUSTOMER: {
            const updated = updateCustomer(state.customers, action.customer);
            return { ...state, customers: updated };
        }
        default:
            return state;
    }
}

const addCustomer = (customers, customer) => {
    if (customers?.length) {
        customers.push(customer);
        return customers;
    }
}

const updateCustomer = (customers, customer) => {
    if (customers?.length) {
        customers.map((c, i) => {
            if (c.personId === customer.personId) {
                customers[i] = customer
            }
        })
    }
    return customers;
}

export default customers;
