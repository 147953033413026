import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from '@material-ui/core';
import SelectInput from '../../SelectInput';

const SelectInputDebounced = ({ id, data, getOptionsAPI, handleClearOptions, handleValue }) => {
  const { options, filters, label, field, value, ref } = data;

  const getListAPIDebounced = (v) => debounce(getOptionsAPI(v), 500);

  const handleListOptions = (event) => {
    const v = event?.target?.value;
    const isValueLength = !!(event?.target && (v && v.length >= 3));
    if (!event) {
      clearFieldSearch();
      return;
    }
    if (isValueLength) {
      getListAPIDebounced(v);
      return;
    }
    const isInvalidValueAndLists = !!((value && value.length < 3) && options.length > 0);
    if (isInvalidValueAndLists) {
      clearFieldSearch();
    }
  }

  const onFocusSelectFilter =(event) => {
    event.persist();
    if (!(filters[field])) {
      clearFieldSearch();
    }
  }

  const clearFieldSearch = () => {
    handleValue(null);
    handleClearOptions();
  }

  const handleSelectValue = (v) => {
    handleValue(v)
    if (!v || !field) {
      handleClearOptions();
    }
  }

  return (
    <SelectInput
      id={id}
      className="no-padding"
      ref={ref}
      label={label}
      elements={options}
      value={value}
      onSelectedValue={(ev) => handleSelectValue(ev)}
      onInputChange={(ev) => handleListOptions(ev)}
      freeSolo={true}
      isWaitLetters={true}
      getOptionSelected={option => option?.id ?? null}
      onFocus={(ev) => onFocusSelectFilter(ev)}
    />
  )
}

SelectInputDebounced.propTypes = {
  id: PropTypes.string,
  data: PropTypes.objectOf({
    ref: PropTypes.any,
    label: PropTypes.string.isRequired,
    filters: PropTypes.any,
    value: PropTypes.number,
    options: PropTypes.array,
    field: PropTypes.string.isRequired,
  }),
  getOptionsAPI: PropTypes.func.isRequired,
  handleClearOptions: PropTypes.func.isRequired,
  handleValue: PropTypes.func.isRequired,
}

export default SelectInputDebounced;
