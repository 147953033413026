import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import store from '../../../store';
import '../NewSchedule.css';
import Car from "../../../icons/Drivers";
import PinSource from "../../../assets/img/pin_source.png";
import Button from '../../CustomButtons/Button';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
import { GoogleMap, Marker, Polyline } from 'react-google-maps';
import PinDestination from "../../../assets/img/pin_destination.png";
import PinCurrent from "../../../assets/img/pin_current.png";

const {
    DrawingManager,
  } = require("react-google-maps/lib/components/drawing/DrawingManager");
  
  const RegularMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={14}
        x
        defaultOptions={{
          scrollwheel: false,
        }}
      >
        {props.customer && (
          <Marker
            key={"customer"}
            className="routes-marker"
            defaultLabel={""}
            defaultIcon={{
              url: PinSource,
              scaledSize: { width: 48, height: 48 },
              size: { width: 48, height: 48 },
              labelOrigin: new window.google.maps.Point(20, 15),
            }}
            position={{
              lat: parseFloat(props.customer.latitude),
              lng: parseFloat(props.customer.longitude),
            }}
          />
        )}
        {props.nosocomio?.latitude && (
          <Marker
            key={"nosocomio"}
            className="routes-marker"
            defaultLabel={""}
            defaultIcon={{
              url: PinDestination,
              scaledSize: { width: 48, height: 48 },
              size: { width: 48, height: 48 },
              labelOrigin: new window.google.maps.Point(20, 15),
            }}
            position={{
              lat: parseFloat(props.nosocomio.latitude),
              lng: parseFloat(props.nosocomio.longitude),
            }}
          />
        )}
  
        {props.currentPosition && (
          <Marker
            key={"currentposicion"}
            className="routes-marker"
            defaultLabel={""}
            defaultIcon={{
              url: PinCurrent,
              scaledSize: { width: 48, height: 48 },
              size: { width: 48, height: 48 },
              labelOrigin: new window.google.maps.Point(20, 15),
            }}
            position={props.currentPosition}
          />
        )}
  
        {props.mode && props.mode === "inprogress" ? (
          <div>
            <Polyline
              path={props.path1}
              options={{
                strokeColor: "#099107",
                strokeOpacity: 0.7,
                strokeWeight: 7,
              }}
            />
            <Polyline
              path={props.path2}
              options={{
                strokeColor: "#01abc2",
                strokeOpacity: 0.7,
                strokeWeight: 7,
              }}
            />
          </div>
        ) : (
          <div>
            {props.directions.length > 0 && (
              <Polyline
                path={props.directions[1]}
                options={{
                  strokeColor: "#099107",
                  strokeOpacity: 0.7,
                  strokeWeight: 7,
                }}
              />
            )}
            <Polyline
              path={props.directions[0]}
              options={{
                strokeColor: props.colorStroke,
                strokeOpacity: 0.7,
                strokeWeight: 7,
              }}
            />
          </div>
        )}
      </GoogleMap>
    ))
  );

  
class CustomerFormServiceDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            customer: '',
            customerId: '',
            addresses: [],
            address: '',
            zone: '',
            location: '',
            province: '',
            directions: [],
        }
    }

    componentDidMount() {
        this.getCustomers();
        const { value } = this.props;
        if (value) {
            this.handleValue(value.customer, 'customer');
            this.handleValue(value.address, 'address');
        }
    }

    componentWillReceiveProps(next) {
        const { value } = this.props;
        const { customers } = this.state;
        if (next.customers) {
            const newCustomers = this.formatCustomers(next.customers);
            this.setState({ customers: newCustomers });
        }
        
        if (value?.customer && customers) {
            const customerIndex = customers.findIndex(item => item.id === value.customer);
            if (customerIndex !== -1) {
                const customerId = customers[customerIndex].customerId;
                this.setState({ customerId })
            }
        }
    }

    formatCustomers = (customers) => customers.map(c => ({
        id: c.personId,
        customerId: c.id,
        value: `${c.lastName} ${c.firstName}`,
    }));

    formatAddresses = (addresses) => addresses.map(a => ({
        id: a.addressId,
        value: `${a.street} ${a.number}`,
        zone: a.geographicZone ? a.geographicZone.detail : '',
        geographicZoneId: a.geographicZone  ? a.geographicZone.geographicZoneId : 0,
        location: a.location.name,
        province: a.provinceName,
    }));

    getCustomers() {
        if (store.getState().customers.customers &&
            store.getState().customers.customers.length > 0) {
            const customers = this.formatCustomers(store.getState().customers.customers);
            this.setState({ customers })
            return
        }
        PersonApiInvoker.getCustomers(true, data => {
            const customers = this.formatCustomers(data);
            this.setState({ customers })
            this.props.onGetCustomers(data)
        }, _ => {
            this.setState({ loading: false })
        })
    }

    propsOnFilledCustomer() {
        const { address, customer, customerId, geographicZoneId, location, province, zone } = this.state;
        this.props.onFilledCustomer({
            customer,
            zone,
            geographicZoneId,
            location,
            customerId,
            address,
            province,
        });
    }

    setAddress() {
        const { addresses } = this.state;
        let address = {
            address: '',
            location: '',
            province: '',
            zone: '',
            geographicZoneId: '',
        };
        
        if (addresses.length === 1) {
            const selectedAddress = addresses[0];
            address = {
                address: selectedAddress.id,
                location: selectedAddress.location,
                province: selectedAddress.province,
                zone: selectedAddress.zone,
                geographicZoneId: selectedAddress.geographicZoneId,
            };
        }
        if (addresses.length > 1) {
            const addressIndex = addresses.findIndex(item => item.id === this.state.address)
            if (addressIndex !== -1) {
                const selectedAddress = addresses[addressIndex];
                address = {
                    address: selectedAddress.id,
                    location: selectedAddress.location,
                    province: selectedAddress.province,
                    zone: selectedAddress.zone,
                    geographicZoneId: selectedAddress.geographicZoneId,
                };
            }
        }
        this.setState({
            address: address.address,
            location: address.location,
            province: address.province,
            zone: address.zone,
            geographicZoneId: address.geographicZoneId
        }, () => { this.propsOnFilledCustomer() });
    }

    getAddresses(personId) {
        PersonApiInvoker.getPersonsAddress(personId,false, data => {
            if (data?.length) {
                this.setState({ addresses: this.formatAddresses(data)}, () => {
                    this.setAddress();
                });
            } else {
                this.setState({
                    addresses: [],
                    address: '',
                    province: '',
                    geographicZoneId: '',
                    zone: '',
                    location: '',
                }, () => {   
                    this.propsOnFilledCustomer();
                });
            }
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
        const { addresses, customer, customerId, customers } = this.state;

        if (state === 'customer') {
            this.getAddresses(value);
            const customerIndex = customers.findIndex(item => item.id === value);
            if (customerIndex !== -1) {
                this.setState({ customerId: customers[customerIndex].customerId }, () => {
                        this.props.onFilledCustomer({ customer, customerId });
                    }
                )
            }
        }
        if (state === 'address') {
            const addressIndex = addresses.findIndex(item => item.id === value)
            if (addressIndex !== -1) {
                this.setState({
                    address: addresses[addressIndex].id,
                    geographicZoneId: addresses[addressIndex].geographicZoneId,
                    zone: addresses[addressIndex].zone || '',
                    location: addresses[addressIndex].location || '',
                    province: addresses[addressIndex].province || '',
                }, () => {
                    this.propsOnFilledCustomer();
                });
            }
        }
    }

    setDirection = () => {
        if (!this.props.customer || !this.state.nosocomioObject) {
          return;
        }
        let DirectionsService = null;
        try {
          DirectionsService = new window.google.maps.DirectionsService();
        } catch (error) {
          return;
        }
    
        let alternative = false;
        if (
          this.props.appointment?.appointmentId == 101 &&
          this.isTodayMenus(1)
        ) {
          alternative = true;
        }
    
        DirectionsService.route(
          {
            origin: {
              lat: parseFloat(this.props.customer.latitude),
              lng: parseFloat(this.props.customer.longitude),
            },
            destination: {
              lat: parseFloat(this.state.nosocomioObject.latitude),
              lng: parseFloat(this.state.nosocomioObject.longitude),
            },
            waypoints: [],
            optimizeWaypoints: true,
            provideRouteAlternatives: alternative,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              let distance_suggested = "-";
              let time_end = 0;
              if (result.routes.length > 0 && result.routes[0].legs.length > 0) {
                distance_suggested = result.routes[0].legs[0].distance["text"];
                time_end = result.routes[0].legs[0].duration["value"];
              }
              let raw = [];
              let extras = [];
    
              if (this.props.viewMode === "apointment") {
                raw = result["routes"][0]["overview_path"];
                let startp = parseInt(raw.length / 2);
                this.dividePath(raw, startp);
              }
              if (result["routes"].length > 1) {
                result["routes"][1]["overview_path"].map(function (
                  value,
                  index,
                  array
                ) {
                  extras.push(value);
                });
              }
    
              let directions = [];
              if (result["routes"].length > 0)
                directions.push(result["routes"][0]["overview_path"]);
              if (result["routes"].length > 1)
                directions.push(result["routes"][1]["overview_path"]);
              if (result["routes"].length > 2)
                directions.push(result["routes"][2]["overview_path"]);
    
              this.setState(
                {
                  directions: directions,
                  distance_suggested: distance_suggested,
                  time_end: time_end,
                  rawPath: raw,
                  pathExtra: extras,
                },
                () => {
                  this.recalculateStartTime();
                }
              );
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      };
      isInvalid = () => {
        if (
          !this.state.nosocomio ||
          !this.state.traslado ||
          !this.state.startDate ||
          !this.state.recurrence ||
          !this.state.startTime
        ) {
          return true;
        } else {
          if (!this.state.recurrence.recurrenceType) return true;
          if (
            this.state.recurrence.recurrenceType == "DAY" &&
            this.state.recurrence.every <= 0
          )
            return true;
          if (
            this.state.recurrence.recurrenceType == "MONTH" &&
            (!this.state.recurrence.monthRecurrence ||
              this.state.recurrence.monthRecurrence <= 0 ||
              this.state.recurrence.monthRecurrence > 31 ||
              !this.state.recurrence.every ||
              this.state.recurrence.every <= 0)
          )
            return true;
          if (
            this.state.recurrence.recurrenceType == "WEEK" &&
            (this.state.recurrence.weekRecurrence.length == 0 ||
              !this.state.recurrence.every ||
              this.state.recurrence.every <= 0)
          )
            return true;
          return false;
        }
      };
    render() {
        
        const { customers, customer, customerId, addresses, address, zone, location, province, type } = this.state;
        const { disabled, checkInvalid, t } = this.props;
        let hora_real_fin = "-";
        let center = this.props.customer;
        let color = "#099107";
        let finished = false;
        let isDisabled = this.props.disabled || this.isInvalid();
    let viewMode = "short";
    
    if (this.props.viewMode) {
      viewMode = this.props.viewMode;
    } else {
      viewMode =
        this.props.appointment?.appointmentStatus ===
          "TRANSFER_SCHEDULES_ASSIGNED"
          ? "large"
          : "short";
    }
        return (
            <Card id="card-customer" className="card-customer-form">
                <CardHeader icon>
                    <CardIcon color="primary">
                        <Car />
                    </CardIcon>
                    <h3 className="card-icon-title">Datos generales del traslado</h3>
                </CardHeader>
                <CardBody>
                    
                    <GridContainer className="card-customer-container">
                        <GridContainer  xs={12} sm={12}>
                           
                            <GridContainer>
                                <GridItem className="fullWidth-input" xs={12} sm={6}>
                                    <SelectInput
                                        id="select-customer"
                                        disabled={disabled}
                                        label="Prestador del traslado"
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={customer}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className="fullWidth-input" xs={12} sm={12}>
                                
                                <GridItem className="fullWidth-input" xs={12} sm={6}>
                                    <SelectInput
                                        id="select-customer"
                                        disabled={disabled}
                                        label="Tipo de recurrencia"
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={customer}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={12} sm={2}>
                                    <TextField
                                        id="input-zone"
                                        
                                        fullWidth
                                        label="Repetir cada"
                                        value={((customer && address) && zone) || ''}
                                    />
                                </GridItem>
                                
                            </GridContainer>
                            
                        </GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <GridItem xs={12} sm={12} md={12}>
                            <RegularMap
                                customer={this.props.customer}
                                location={this.state.currentLocation}
                                directions={this.state.directions}
                                waypoints={this.state.waypoints}
                                mode=""
                                center={center}
                                colorStroke={color}
                                
                                googleMapURL="/googlemap"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={
                                <div
                                    style={{
                                    height: `500px`,
                                    borderRadius: "6px",
                                    overflow: "hidden",
                                    width: `100%`,
                                    }}
                                />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                            </GridItem>
                            <GridContainer style={{ textAlign: "right" }}>
                            <GridItem xs={12} sm={12} md={12}>
                                {finished && (
                                <Button
                                    onClick={() => this.toPDF()}
                                    style={{ textAlign: "right" }}
                                    color="warning"
                                >
                                    {t("service.download.pdf")}
                                </Button>
                                )}
                            </GridItem>
                            </GridContainer>
                        </GridItem>

                        
                        <GridContainer xs={6} sm={6} md={6}>
                            <h2>Viaje IDA</h2>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                            <TextField
                                            type="text"
                                            fullWidth
                                            label="Distancia estimada"
                                            value="7.8 km"
                                            />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                    type="text"
                                    fullWidth
                                    label="Tiempo estimado"
                                    value="19 min."
                                    />
                                </GridItem>
                            </GridContainer>
                            <h2>Viaje VUELTA</h2>
                            <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                        <TextField
                                        type="text"
                                        fullWidth
                                        label="Distancia estimada"
                                        value="8.3 km"
                                        />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                type="text"
                                fullWidth
                                label="Tiempo estimado"
                                value="19 min."
                                />
                            </GridItem>
                            </GridContainer>
                        </GridContainer>
                        </GridContainer>
                        
                </CardBody>
            </Card>
        )
    }
}

CustomerFormServiceDetail.propTypes = {
    t: PropTypes.func,
    onGetCustomers: PropTypes.func,
    onFilledCustomer: PropTypes.func,
    value: PropTypes.object,
    disabled: PropTypes.bool,
    checkInvalid: PropTypes.bool,
}

export default withTranslation()(CustomerFormServiceDetail);
