import React, { useState, useEffect } from 'react'
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check, Assignment } from '@material-ui/icons';
import Card from '../../Card/Card';
import CardIcon from '../../Card/CardIcon';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';
import Button from '../../CustomButtons/Button';
import ConfirmAlert from '../../ConfirmAlert';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import RadioInput from '../../RadioInput';
import Table from '../../Table/Table';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import { DeleteDocument } from '../../../icons';
import stylesCheckbox from '../../../assets/components/customCheckboxRadioSwitch';
import config from '../../../config/config';

const dateFormatConfig = config.getDateFormat();
const dateTimeSmallFormat = config.getDateTimeSmallFormat();

const useStyles = makeStyles(stylesCheckbox);

const styles = makeStyles({
  radioInputContainer: { display: "flex", flexDirection: "column !important"
  },
  spaceBetween: { display: "flex", justifyContent: "space-between"
  },
})

const AppointmentCancel = ({ t, params }) => {
  const classes = styles();
  const classesCheck = useStyles();
  
  const [appointment, setAppointment] = useState({
    createdDateTime: new Date(),
  });
  const [ detailText, setDetailText ] = useState('');
  const [ appointmentCancellableDetail, setAppointmentCancellableDetail ] = useState([]);
  const [ alreadyCancelled, setAlreadyCancelled ] = useState([])
  const [ confirmationModal, setConfirmationModal ] = useState(false);
  const [ successModal, setSuccessModal ] = useState(false);
  const [ errorModal, setErrorModal ] = useState(false);
  const [ cancellationReason, setCancellationReason ] = useState('');
  const [ cancellationType, setCancellationType ] = useState('1');
  const [ markedToBeCancelled, setMarkedToBeCancelled ] = useState([]);
  const [ markedToBeOrderCancelled, setMarkedToBeOrderCancelled ] = useState([]);

  const changeCancelationType = (type) => {
    if (type === '1') {
      setMarkedToBeCancelled([]);
    }
    setCancellationType(type);
  }

  const markToBeCanceled = (checked, newOne, order) => {
    if (checked) {
      setMarkedToBeCancelled(markedToBeCancelled.concat([newOne]));
      setMarkedToBeOrderCancelled(markedToBeOrderCancelled.concat([order]));
      return;
    }
    setMarkedToBeCancelled(markedToBeCancelled.filter(id => id !== newOne));
    setMarkedToBeOrderCancelled(markedToBeOrderCancelled.filter(id => id !== order));
  }

  const performCancelation = (type) => {
    const body = {
      "motive": cancellationReason,
      "items": markedToBeCancelled,
    }
    if(Number(type) === 1){
      AppointmentApiInvoker.cancelTransferAppointment(params.id, body, () => {
        setConfirmationModal(false);
        setErrorModal(false);
        setSuccessModal(true);
      }, () => {
        setConfirmationModal(false);
        setSuccessModal(false);
        setErrorModal(true);
      });
    }else{
      AppointmentApiInvoker.cancelPartialTransferAppointment( body, () => {
        setConfirmationModal(false);
        setErrorModal(false);
        setSuccessModal(true);
      }, () => {
        setConfirmationModal(false);
        setSuccessModal(false);
        setErrorModal(true);
      });
    }
    
  }

  const cancel = () => { browserHistory.push('/solicitudes-traslado') }

  useEffect(() => {
    AppointmentApiInvoker.getAppointmentById(params.id, 
      (res) => {

        let canceled = res.items?.filter(p => p.transferItemRequestStatus === "CANCELLED")
        .map(p => p.transferItemRequestId);
        setAlreadyCancelled(canceled);
        setAppointmentCancellableDetail(res.items);
      }, (e) => {
        console.error("Error getting cancelable practices: ", e)
      });
      setAppointment((prev) => ({
        ...prev,
        id: params.id,
      }));
  }, [params.id])

  useEffect(() => {

    const marked = markedToBeCancelled.map((value, index) => ({
      id: value,
      order: markedToBeOrderCancelled[index]
  }));
    const text = cancellationType === '2' ? marked.map((detailId) => {
        const detail = appointmentCancellableDetail.find(app => app.transferItemRequestId === detailId.id) || {};
        return `- Traslado ${detailId.order}: ${t('status.transfer.'+detail.transferType)}`;
      }) : ''
    setDetailText(text);
  }, [cancellationType, markedToBeCancelled, appointmentCancellableDetail])

  const radioElements = [
    { id: '1', value: t('appointment.cancel.total_cancellation'), component: true },
    { id: '2', value: t('appointment.cancel.partial_cancellation'), component: false }
  ];

  const isChecked = (appointmentItemRequestId) => {
    const markedCancelled = markedToBeCancelled.indexOf(appointmentItemRequestId) > -1;
    const canceled = alreadyCancelled.indexOf(appointmentItemRequestId) > -1
    return markedCancelled || canceled
  }

  const formatAppointmentCancellableDetail = (data) => {
    return data.map((d,index) => {
      const appointmentDetailId = d.transferItemRequestId;
      const select = <Checkbox
        tabIndex={-1}
        onChange={(e) => markToBeCanceled(e.target.checked, d.transferItemRequestId,index+1)}
        checkedIcon={<Check className={classesCheck.checkedIcon} />}
        disabled={alreadyCancelled.indexOf(d.transferItemRequestId) > -1}
        checked={isChecked(d.transferItemRequestId)}
        icon={<Check className={classesCheck.uncheckedIcon} />}
        classes={{
            checked: classesCheck.checked,
            root: classesCheck.checkRoot,
        }}
      />;
      return {
        select,
        id: index+1,
        appointmentDetailId,
        type: 'Traslado', //t(d.type),
        speciality: d.transferType === "STANDAR" ? t('status.transfer.STANDAR') :d.transferType === "RAMPA" ? t('status.transfer.RAMPA'): d.transferType === "AMBULANCIA" ? t('status.transfer.AMBULANCIA'):'',
        startDate: moment(d.startDate).format(dateFormatConfig),
        quantity: 1//d.count,
      }
    });
  }

  return (
    <GridContainer className="cancellation-form">
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer className={classes.spaceBetween}>
              <GridItem xs={6} sm={4}>
                <CustomInput
                  id="appointment-id"
                  value={params.id}
                  labelText={t('appointment.input.appointment_id')}
                  formControlProps={{ disabled: true}}
                />
              </GridItem>
              <GridItem xs={6} sm={8}>
                <CustomInput
                  labelText={t('appointment.input.init_date')}
                  id="init_date"
                  value={
                    appointment ? moment(appointment.createdDateTime).format(dateTimeSmallFormat)
                    : null
                  }
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <DeleteDocument />
            </CardIcon>
            <h3 className="card-icon-title">
              {t('appointment.cancel.title')}
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem className="cancellation-content" xs={12}>
                <RadioInput
                  value={cancellationType}
                  className={classes.radioInputContainer}
                  title={''}
                  onChangeValue={(value) => { changeCancelationType(value) }}
                  elements={radioElements}
                />
                {cancellationType === '2' && (
                  <Table 
                    loading={false}
                    tableHeaderColor="primary"
                    defaultPageSize={5}
                    sortable={false}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    tableHead={[
                      { Header: '', accessor: 'select' },
                      { Header: t('cancellations.order'), accessor: 'id' },
                      { Header: t('cancellations.type'), accessor: 'type' },
                      { Header: t('cancellations.nameType'), accessor: 'speciality' },
                      { Header: t('cancellations.date_init'), accessor: 'startDate' },
                      { Header: t('cancellations.quantity'), accessor: 'quantity' }
                    ]}
                    colorsColls={["primary"]}
                    tableData={formatAppointmentCancellableDetail(appointmentCancellableDetail)}
                  />
                  )
                }
              </GridItem>
              <GridItem xs={6} sm={6}>
                <h4> {t('appointment.cancel.cancellation_reason')} </h4>
                <CustomInput
                  id="cancellation_reason"
                  value={cancellationReason}
                  labelText={t('appointment.cancel.cancellation_reason')}
                  onChange={(e) => setCancellationReason(e.target.value)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} className={classes.spaceBetween}>
        <Button id="button-cancel" onClick={cancel}>{t('appointment.cancel.cancel')}</Button>
        <Button
          id="button-cancel-save"
          disabled={cancellationType === '2' && !markedToBeCancelled.length} 
          onClick={()=> setConfirmationModal(true)}
          color="primary"
        >
          {t('appointment.cancel.save')}
        </Button>
      </GridItem>
      <ConfirmAlert
        closeOnClickOutside={false}
        confirmTitle={'appointment.cancel.modal.confirmTitle'}
        confirmBtnText={'appointment.cancel.modal.confirmBtnText'}
        cancelBtnText={'appointment.cancel.modal.cancelBtnText'}
        confirmBodyText={(cancellationType === '2' ? t('appointment.cancel.modal.confirmBodyTextPartialTransfer') : t('appointment.cancel.modal.confirmBodyText', { appointmentId: params.id }))}
        confirmBodyTextSecondLine={detailText}
        successTitle={t('appointment.cancel.modal.successTitle')}
        successBodyTextSecondLine={detailText}
        successBodyText={cancellationType === '2' ? t('appointment.cancel.modal.successBodyTextPartial') : t('appointment.cancel.modal.successBodyText', { appointmentId: params.id })}
        successBodyTextThirdLine={cancellationType === '2' && t('appointment.cancel.modal.successBodyTextPartialSecondLine') ? t('appointment.cancel.modal.successBodyTextPartialSecondLine') : '' }
        errorBodyText={t('appointment.cancel.modal.errorBodyText', { appointmentId: params.id })}
        onConfirm={()=> {
          performCancelation(cancellationType);
        }}
        onSuccess={() => browserHistory.push('/solicitudes-traslado')}
        onClose={()=>{
          setConfirmationModal(false);
          setSuccessModal(false);
          setErrorModal(false);
        }}
        success={successModal}
        error={errorModal}
        showConfirmWithText={confirmationModal}
        useCustomStyles={cancellationType === '2'}
      />
    </GridContainer>
  )
}

AppointmentCancel.propTypes = {
  t: PropTypes.func,
  params: PropTypes.object,
}

export default withTranslation()(AppointmentCancel);
