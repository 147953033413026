import { connect } from 'react-redux';
import Users from '../components/Users';
import { getTools, unmountTool} from '../actions/tools';

const mapStateToProps = (state) => {
    return {
        tools: state.tools.tools,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetTools: (tools) => {
            dispatch(getTools(tools));
        },
        unmountTools: () => {
            dispatch(unmountTool());
        },
    }
}

const UsersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);

export default UsersService;
