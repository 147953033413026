import './Navbars.css';

import {
  Badge,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';

// core components
import About from '../About';
import BaseInvoker from '../../api/BaseInvoker';
import Button from '../CustomButtons/Button';
import ChangePassword from '../ChangePassword';
import Cookies from 'universal-cookie';
import CustomDialog from '../CustomDialog';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
// @material-ui/icons
import Help from '@material-ui/icons/Help';
import ImageUpload from '../CustomUpload/ImageUpload';
import LetterAvatar from '../Avatar/LetterAvatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Person from '@material-ui/icons/Person';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '../Snackbar/Snackbar';
import TermsAndPolicyModal from '../TermsAndPolicyModal';
import TermsAndPolicyOnUpdated from '../TermsAndPolicyOnUpdated';
import { TermsPolicyType } from '../../util/interfaces';
import adminNavbarLinksStyle from '../../assets/components/adminNavbarLinksStyle';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import config from '../../config/config';
import logoHMM from '../../assets/img/hmm-logo-shadow.png';
import map from 'lodash/map';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();


const baseInvoker = new BaseInvoker();

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openProfile: false,
    openAbout: false,
    openNotifications: false,
    openAboutModal: false,
    alertsLength: 0,
    alertsIgnored: [],
    alertsFailed: [],
    multipleSelect: [],
    companies: [],
    user: {},
    email: '',
    companySelect: '',
    subdomain: '',
    changingCompany: false,
    checking: false,
    notRead: 0,
    showSelectCompanies: false,
    notifications: [],
    openChangePass: false,
    showTermsOrPolicy: false,
    showTermsType: null,
    showTermsPolicy: false,
    showTermsPolicyMessage: '',
    snackbar: {},
  };

  UNSAFE_componentWillMount = () => {
    if (localStorage.getItem('companies')) {
      this.setState({ companies: JSON.parse(localStorage.getItem("companies")) });
    }

    if (localStorage.getItem('itlg_default_company_id') == -1) {
      this.setState({ selectCompany: true });
    }

    this.setState({ user: localStorage.getItem('username'), email: localStorage.getItem('email') })
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.companies) {
      const storedCompany = parseInt(localStorage.getItem('itlg_default_company_id'), 10);
      const companySelect = storedCompany && this.companyBelongsToUser(next.companies, storedCompany) ? storedCompany : next.companies[0].companyId;
      this.setState({ companies: next.companies, companySelect });
    }
  }

  componentDidMount() {
    const subdomain = window.location.host.split('.')[0];
    this.setState({ subdomain });
    this.getNotifications();
    this.interval = setInterval(()=> {
      this.getNotifications();
      this.checkCurrentTAndPVersion();
    }, 60000*4);
  }

  compareTAndPVersions = (newVersion, termsPolicyProfile, data) => {
    const compareVersions = newVersion === termsPolicyProfile.requestTermsVersion;
    if (compareVersions) {
      return;
    }
    if (!this.state.showTermsPolicy) {
      const termsPolicy = {
        userId: termsPolicyProfile.userId,
        firstName: termsPolicyProfile.firstName,
        lastName: termsPolicyProfile.lastName,
        requiereTermsAccept: data.requiereTermsAccept,
        requestTermsVersion: data.requestTermsVersion,
      }
      localStorage.setItem('terms_policy', JSON.stringify(termsPolicy));
      const message = this.props.t('termsPolicy.checkUpdate', { version: data.requestTermsVersion });
      this.setState((prev) => ({
        showTermsPolicy: !prev.showTermsPolicy,
        showTermsPolicyMessage: message,
        showTermsOrPolicy: false,
      }));
    }
  }

  checkCurrentTAndPVersion = () => {
    if (this.state.showTermsPolicy) {
      return;
    }
    const termsPolicyProfileStorage = localStorage.getItem('terms_policy');
    const termsPolicyProfile = JSON.parse(termsPolicyProfileStorage);
    if (!termsPolicyProfile.userId || termsPolicyProfile.userId === 0) {
      console.error('** error getCurrentTAndCVersion no data on storage');
      return;
    }
    const employeeId = termsPolicyProfile.userId;
    PersonApiInvoker.getCurrentTermsPolicyVersion(employeeId, (data) => {
      this.compareTAndPVersions(data.requestTermsVersion, termsPolicyProfile, data);
    }, (error) => console.error('** error getCurrentTAndCVersion', error));
  }

  setCompanySelected = (c) => {
    this.setState({ selectCompany: false });
    this.setCompany(c.companyId, c.companyPartnership_id);
    localStorage.setItem('company', JSON.stringify(c));
    localStorage.setItem('itlg_default_company_id', c.companyId);
    localStorage.setItem('itlg_default_company_name', c.name);
    localStorage.setItem('company_partnership_id', c.companyPartnership_id);
  }

  setCompany = (companyId, companyPartnership_id) => {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({c: companyId, p: companyPartnership_id }),
   };

    fetch('/api/setcompany', requestOptions)
      .then(response => response.json())
      .then(async () => {
        this.props.onClearData();
        if (window.location.href.indexOf('/solicitudes') > -1) {
          window.location.reload();
        } else {
          browserHistory.push('/solicitudes');
        }
      });
  }


  handleProfileClick = () => {
    this.setState((prevState) => ({ openProfile: !prevState.openProfile }));
  };

  handleProfileClose = () => {
    if (this.state.changingCompany) {
      this.setState({ changingCompany: false });
    } else {
      this.setState({ openProfile: false });
    }
  };

  handleClickNotifications = () => this.setState((prevState) => ({ openNotifications: !prevState.openNotifications }));
  
  handleCloseNotifications = () => this.setState({ openNotifications: false });
  
  handleClickAbout = () => this.setState((prevState) => ({ openAbout: !prevState.openAbout }));
  
  handleCloseAbout = () => this.setState({ openAbout: false });

  handleClickChangePass = () => this.setState((prevState) => ({ openChangePass: !prevState.openChangePass }));
  
  handleCloseChangePass = () => this.setState({ openChangePass: false });
  
  handleSubmitChangePass = () => {
    this.handleCloseChangePass();
    this.logoutUser();
  }

  handleAbout = () => this.setState((prevState) => ({ openAboutModal: !prevState.openAboutModal }));

  closeAboutModal = () => this.setState({ openAboutModal: false });

  companyBelongsToUser = (companies, companyId) => companies && companies.length > 0 && map(companies, 'companyId').includes(companyId);

  clearStorageUser = () => {
    const storages = ['user_id', 'username', 'email', 'company_currency_id', 'profesional', 'APP_VERSION', 'RELEASE_DATE', 'company_partnership_id', 'terms_policy'];
    storages.forEach(storage => localStorage.removeItem(storage));
  }

  logoutUser = () => {
    const cookies = new Cookies();
    cookies.remove('sessionActive');
    this.clearStorageUser();
    this.props.onLogout();
    this.props.onClearData();
    baseInvoker.post('/oauth/logout');
    browserHistory.push('/login');
    clearInterval(this.interval);
  }

  getCompanyLogged = () => {
    const { companies, selectedCompany } = this.props;
    let company;
    if (companies && companies.length > 0) {
      company = companies.find(item => item.companyId === selectedCompany);
    }
    return company;
  }

  getCompanyLoggedName = () => localStorage.getItem('itlg_default_company_name');

  parseNotificationContent(data) {
    try {
      const JsonData = JSON.parse(data);
      return JsonData;
    } catch (error) {  
      console.error('** invalid parseNotificationContent', error);  
      return data;
    }
  }

  getNotifications() {
    PersonApiInvoker.getNotification(notifications => {
      const notRead = notifications.filter(noti => !noti.readDateTime).length;
      const data = notifications.map(noti => (
        { ...noti, notificationContent: this.parseNotificationContent(noti.notificationContent) }
      ));
      data.sort(
        (objA, objB) => Number(moment(objB.createDateTime).format('x')) - Number(moment(objA.createDateTime).format('x')),
      );

      this.setState({ notifications: data, notificationsNotRead: notRead });
    }, (error) => console.error('** error getNotification', error));
  }

  getFormattedDateTime(datetime) {
    const { t } = this.props;
    const messaged = moment(datetime).local().format();
    const now = moment.utc(new Date());
    const duration = moment.duration(now.diff(messaged));
    const hours = duration.asHours();
    if (hours > 24) {
      const numberDays = (hours/24).toFixed(0);
      return t('notification.daysAgo', { days: numberDays });
    }
    if (hours > 1) {
      const numberHours = (hours).toFixed(0);
      return t('notification.hoursAgo', { hours: numberHours });
    }
    const minsHours = (hours*60).toFixed(0);
    return t('notification.minutesAgo', { mins: minsHours });
  }

  getNotificationByType = (data) => {
    const { t } = this.props;
      if (data.notificationType === 'NEW_USER') {
        const roleTypeProf = data.notificationContent.roles.find(f => f === 'PROF');
        if (roleTypeProf === 'PROF') {
          const roleName = roleTypeProf.toLowerCase();
          const role = t(`common.role.${roleName}`);
          const fullName = `${data.notificationContent.lastname} ${data.notificationContent.firstname}`;
          return t('notification.newUser', {
            role,
            fullName,
            email: data.notificationContent.email,
          });
        }
      }
      if (data.notificationType === 'REJECT_ITEM_REQUEST') {
        let c = {};
        let str = '';
        try {
          c = data.notificationContent;
          str = t('notification.reject.practice', {
            practiceTypeName: c.PracticeTypeName,
            appointmentId: c.AppointmentId,
            companyName: c.CompanyName,
            reasonReject: c.reasonReject,
            patientName: c.CustomerFullName
          });
        } catch {}
        return str;
      }
      if (data.notificationType === 'MOBILITY_CANCELACION_TOTAL') {
        let c = {}
        let str = t('notification.cancel.transfer');
        try {
          c =  data.notificationContent;
          str = t('notification.cancel.transfer', {
            transferId: c.transferId,
            patientName: c.nameCostumer + ' ' +c.lastnameCostumer,
            day: c.day,
            hours: c.hour,
          });
        } catch {
          
        }
        return str;
    }
    if (data.notificationType === 'MOBILITY_CANCELACION_PARCIAL') {
        let c = {}
        let str = t('notification.cancel.partial.transfer');
        try {
          c =  data.notificationContent;
          str = t('notification.cancel.partial.transfer', {
            transferId: c.transferId,
            transferItemRequestId:c.idTranferitemRequest,
            patientName: c.nameCostumer + ' ' +c.lastnameCostumer,
            day: c.day,
            hours: c.hour,
          });
        } catch {
          
        }
        return str;
    }
    if (data.notificationType === 'MOBILITY_RECHAZO_VIAJE_CHOFER') {
      let c = {}
      let str = t('notification.reject.driver');
      try {
        c =  data.notificationContent;
        str = t('notification.reject.driver', {
          scheduleId: c.scheduleId,
          rejectId: c.rejectId,
          reasonReject:c.reasonReject,
          
        });
      } catch {
        
      }
      return str;
  }
    if (data.notificationType === 'SCHEDULE_REJECTED') {
      let c = {};
      let str = '';
      try {
        c = data.notificationContent;
        let d = moment(c.ScheduleDateTime);
        let date = d.format(dateFormat);
        let time = d.format("HH:mm");
        str = t('notification.reject.schedule', {
          scheduleId: c.ScheduleId,
          appointmentId: c.AppointmentId,
          customerFullName: c.CustomerFullName,
          employeeFullName: c.EmployeeFullName,
          practiceName: c.PracticeTypeName,
          scheduleDate: date,
          scheduleTime: time
        });
      } catch {}
      return str;
    }
  }

  reasign() {
    this.setState({ openNotifications: false });
    browserHistory.push({ pathname: `/reasign-appointments` });
  }

  setRead = (data) => {
    PersonApiInvoker.setNotificationRead(data.notificationId, () => {
      this.getNotifications();
    }, (error) => {
      this.getNotifications();
      console.error('** error setNotificationRead', error);
    });
  }

  handleClickChangeCompany = () => {
    if (localStorage.getItem('itlg_default_company_id') == -1) {
      return;
    }
    this.setState((prevState) => ({ selectCompany: !prevState.selectCompany }))
  }

  buildNotifications = (data) => {
    const { t } = this.props;
    return data.map(notification => {
      const dateTime = this.getFormattedDateTime(notification.createDateTime);
      const notificationTitleTooltip = notification.readDateTime ? t('notifications.markAsUnread') : t('notifications.markAsRead');
      const notificationClass = notification.readDateTime ? 'notification-container read' : 'notification-container';
      return (
        <GridContainer className={notificationClass} key={notification.notificationId}>
          <GridItem xs={11}>
            <p className="message" dangerouslySetInnerHTML={{__html: this.getNotificationByType(notification)}} />
            {notification.notificationType === 'SCHEDULE_REJECTED' && 
                <Button link className="notification-link" onClick={() => this.reasign()}>{t('apoinment.table.notification.reasign')}</Button>
              }
          </GridItem>
          <GridItem xs={11}>
            <p className="datetime">{dateTime}</p>
          </GridItem>
          <GridItem xs={1} className="marked-row">
            <Tooltip title={notificationTitleTooltip}>
              <Button simple className="marked marked-link" onClick={() => this.setRead(notification)}></Button>
            </Tooltip>
          </GridItem>
        </GridContainer>
      );
    })
  }

  renderNotifications = (data, openNotifications) => {
    const { t, classes } = this.props;
    const classesPopper = classNames({
      [classes.popperClose]: !openNotifications,
      [classes.pooperResponsive]: true,
      [classes.pooperResponsiveAbout]: true,
      [classes.pooperNav]: true
    });
    if (!data.length) {
      return <></>;
    }

    return (
      <Popper
        open={openNotifications}
        anchorEl={this.anchorEl}
        transition
        disablePortal
        placement="bottom-end"
        className={classesPopper}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="messages-list"
            >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={this.handleCloseNotifications}>
                <div className="notifications-container">
                  <div className="notifications-header">
                    <h4>{ t('appointment.table.notification') }</h4>
                  </div>
                  {this.buildNotifications(data)}
                  <div className="notifications-footer">
                    <img src={logoHMM} alt="HMM" />
                    <div>
                      <p>{t('notifications.lastDays', { days: 10 })}</p>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }

  handleCloseTermsPolicy = ({ isAccept, error = {} }) => {
    if (error.isErrorUpdated) {
      setTimeout(() => {
        this.setState({ showTermsOrPolicy: false });
      }, 200);
      setTimeout(() => {
        this.setState({ showTermsPolicy: true, showTermsPolicyMessage: error.message });
      }, 200);
      return;
    }
    if (error.isError) {
      this.openSnackbar('danger', error.message);
      return;
    }
    if (!isAccept) {
      this.setState({ showTermsPolicy: false });
      this.openSnackbar('danger', this.props.t('termsPolicy.rejectedSuccess'));
      setTimeout(() => {
        this.logoutUser();
      }, 2000);
      return;
    }
    this.setState({ showTermsPolicy: false });
    this.openSnackbar('success', this.props.t('termsPolicy.acceptSuccess'));
  }

  openSnackbar = (color, message) => {
    this.setState({ snackbar: { color, message, open: true } });
    setTimeout(() => {
      this.setState((prev) => ({ ...prev, snackbar: { ...prev.snackbar, open: false }}));
    }, 5000);
  }

  renderProfileButton(managerClasses) {
    const { t, classes, rtlActive } = this.props;
    const { openProfile, user, email } = this.state;
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );

    return <div className={`${managerClasses} profile-menu`}>
      <Button
        color="transparent"
        justIcon
        aria-label="Profile User"
        aria-owns={openProfile ? "profile-menu-list" : null}
        aria-haspopup="true"
        onClick={this.handleProfileClick}
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{ label: rtlActive ? classes.labelRTL : '' }}
        buttonRef={node => { this.anchorEl = node }}
      >
        <Person
          className={ classes.headerLinksSvg + " " +
            (rtlActive
              ? classes.links + " " + classes.linksRTL
              : classes.links)
          }
        />
      </Button>
      <Popper
        open={openProfile}
        anchorEl={this.anchorEl}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !openProfile,
          [classes.pooperResponsive]: true,
          [classes.pooperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={this.handleProfileClose}>
                <MenuList className="menu-list-user" role="menu">
                  <MenuItem key="user-1" className={dropdownItem}>
                    <div className="current-user">
                      <div className="user-avatar">
                        <div className="avatar">
                          { user?.photo ? ( // Once user profile is ready change this.
                              <ImageUpload
                                avatar
                                photo={user.photo}
                                addButtonProps={{ color: "transparent" }}
                                changeButtonProps={{ color: "transparent" }}
                                removeButtonProps={{ color: "transparent" }}
                              />
                            ) : <div className="letter-avatar"><LetterAvatar user={user} /></div>
                          }
                        </div>
                      </div>
                      <div className="user-info">
                        <div className="data-name">
                          <strong>{user}</strong>
                          <small>{email}</small>
                          <span className="company-name">
                            {this.getCompanyLoggedName()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </MenuItem>
                  <hr className="divider-root divider-light" />
                  <h5 className="divider-title">{t('label.settings')}</h5>
                  <MenuItem key="user-2"
                    className={`${dropdownItem} light-grey item-menu-link`}
                    onClick={() => this.handleClickChangePass()}
                  >
                    {t('label.changePass')}
                  </MenuItem>
                  {this.state.companies.length > 1 &&
                    <MenuItem key="user-3"
                      className={`${dropdownItem} light-grey item-menu-link`}
                      onClick={() => this.handleClickChangeCompany()}
                    >
                      {t('label.changeCompany')}
                    </MenuItem>
                  }
                  <hr className="divider-root divider-light" />
                  <MenuItem key="user-4" className={`${dropdownItem} light-grey text-right`}>
                    <Button
                      aria-label="logout"
                      onClick={() => this.logoutUser()}
                    >{t('header.navbar.logout')}</Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  }

  render() {
    const { t, classes, rtlActive } = this.props;
    const {
      companies, notifications, openAbout, openAboutModal, email, openChangePass, openNotifications, snackbar,
    } = this.state;
    const company = this.getCompanyLogged();
    const wrapper = classNames({ [classes.wrapperRTL]: rtlActive });
    const managerClasses = classNames({ [classes.managerClasses]: true });

    return (
      <>
        <Snackbar place="tr" color={snackbar?.color} message={snackbar?.message} open={snackbar?.open} />
        <div id="rightMenuTopStyle" className={wrapper}>
          <div className={`${managerClasses} notifications-menu`}>
            <Button
              color="transparent"
              justIcon
              aria-label="Notifications"
              aria-owns={openNotifications ? "notifications-list" : null}
              aria-haspopup="true"
              onClick={this.handleClickNotifications}
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ''
              }}
              buttonRef={node => {
                this.anchorEl = node;
              }}
            >
              <Badge badgeContent={this.state.notificationsNotRead} color="secondary">
                <NotificationsIcon style={{ fontSize: 'large' }} color="action" />
              </Badge>
            </Button>

            { this.renderNotifications(notifications, openNotifications) }
          </div>

          <div className={`${managerClasses} about-menu`}>
            <Button
              color="transparent"
              justIcon
              aria-label="About"
              aria-owns={openAbout ? "about-menu-list" : null}
              aria-haspopup="true"
              onClick={this.handleClickAbout}
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ""
              }}
              buttonRef={node => {
                this.anchorEl = node;
              }}
            >
              <Help
                className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                }
              />
            </Button>
        
            <Popper
              open={openAbout}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              placement="left"
              className={classNames({
                [classes.popperClose]: !openAbout,
                [classes.pooperResponsive]: true,
                [classes.pooperResponsiveAbout]: true,
                [classes.pooperNav]: true
              })}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="about-menu-list"
                  style={{ transformOrigin: "0 0 0" }}
                >
                  <Paper className={classes.dropdown}>
                    <ClickAwayListener onClickAway={this.handleCloseAbout}>
                      <MenuList role="menu">
                        <MenuItem key="about-1">
                          <a className="menu-item" rel="noopener noreferrer" href="https://hmmglobal.com/soporte/" target="_blank">{t('label.support')}</a>
                        </MenuItem>
                        <MenuItem key="about-2" onClick={() => this.setState({ showTermsOrPolicy: true, showTermsType: TermsPolicyType.TERMS })}>
                          <span className="menu-item">{t('termsUse.mainTitle')}</span>
                        </MenuItem>
                        <MenuItem key="about-3" onClick={() => this.setState({ showTermsOrPolicy: true, showTermsType: TermsPolicyType.POLICY })}>
                          <span className="menu-item">{t('policy.mainTitle')}</span>
                        </MenuItem>
                        <MenuItem key="about-4" onClick={this.handleAbout}>
                          <span className="menu-item">{t('header.navbar.about')}</span>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>

          {this.renderProfileButton(managerClasses)}

          <CustomDialog
            maxWidth="sm"
            open={openAboutModal}
            onClose={this.closeAboutModal}
            title={t('header.navbar.about.title')}
          ><About /></CustomDialog>

          <CustomDialog
            maxWidth="sm"
            open={this.state.selectCompany}
            onClose={this.handleClickChangeCompany}
            title={t('header.navbar.selectcompany.title')}
          >
            <GridContainer className="about text-center">
              <GridItem sm={12} xs={12} className="about-information">
                {companies.map((c) => {
                  return(
                    <p key={`text-${c.company_id}`}>
                      <Button link key={c.company_id} onClick={(e) => this.setCompanySelected(c)} className="selected-company-value">
                        <span>{c.name} {c.companyPartnership_name != null ? "-" : ""} {c.companyPartnership_name}</span> 
                      </Button>
                    </p>
                  );
                })}
              </GridItem>
            </GridContainer>
          </CustomDialog>

          <CustomDialog
            maxWidth="sm"
            open={openChangePass}
            onClose={this.handleCloseChangePass}
            title={t('label.changePass')}
          >
            <ChangePassword
              company={company}
              email={email}
              onSubmitChangePass={this.handleSubmitChangePass}
            />
          </CustomDialog>
          {this.state.showTermsOrPolicy && <TermsAndPolicyModal
            open={this.state.showTermsOrPolicy}
            type={this.state.showTermsType}
            onCloseTerms={(data) => this.handleCloseTermsPolicy(data)}
            onCloseModal={() => this.setState({ showTermsOrPolicy: false }) }
            showButtons={true}
            isLoggedUser={true}
          />}
          {this.state.showTermsPolicy && <TermsAndPolicyOnUpdated
            message={this.state.showTermsPolicyMessage}
            open={this.state.showTermsPolicy}
            onCloseTerms={(data) => this.handleCloseTermsPolicy(data)}
          />}
        </div>
      </>
    );
  }
}

HeaderLinks.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  companies: PropTypes.any,
  onSelectedCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.number,
  setDefaultCompany: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
  onClearData: PropTypes.func.isRequired,
};

export default withStyles(adminNavbarLinksStyle)(withTranslation()(HeaderLinks));
