import { Checkbox, Grid } from '@material-ui/core';

import { Check } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import ShowMoreLessButton from '../../../ShowMoreLess/ShowMoreLessButton';
import ShowMoreLessData from '../../../ShowMoreLess/ShowMoreLessData';
import Table from '../../../Table/Table';
import clsx from 'clsx';
import config from '../../../../config/config';
import moment from 'moment';
import useProfessionalsStep from './useProfessionalsStep';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const maxDataLength = 60;

const ProfessionalsStep = ({
  handleChange,
  isConfirmStep,
  showSnackbarNotification,
  setRefSchedules,
  refStepsValues,
  values,
  t,
}) => {
  const {
    checkAllProfessionals,
    classes,
    classesChecks,
    loadingSchedule,
    loadingTable,
    onSelectAllProfessionals,
    professionalsToReassign,
    schedulesSelectedData,
    rowsExpanded,
    onExpandedRow,
  } = useProfessionalsStep(handleChange, showSnackbarNotification, refStepsValues, values);

  const getShowMoreLessData = (data) => <ShowMoreLessData
    data={data.value}
    isExpandedRow={rowsExpanded.includes(data.original.id)}
  />

  const getShowMoreLessButton = (data) => {
    const isDataLength = data.original.employeeTypes.length > maxDataLength || data.original.geographicZone.length > maxDataLength;
    return <ShowMoreLessButton
        isDataLength={isDataLength}
        isExpandedRow={rowsExpanded.includes(data.original.id)}
        onExpanded={() => onExpandedRow(data.original.id)}
        id={data.original.id}
    />
  }

  const sortDatetime = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if(a1 > b1) {
      return 1;
    }
    if (a1 < b1) {
      return -1;
    }
    return 0;
  }
  
  return (
    <Grid container className={clsx(classes.formStepper, classes.employeesTable, {[classes.readOnlyForm]: isConfirmStep})}>
      <Grid item xs={12}>
        <h4 className={classes.stepTitle}>{t('title.suggestedProfessionals')}</h4>
        <Table
          id="employees-table"
          loading={loadingTable}
          tableHeaderColor="primary"
          sortable={true}
          filterable
          tableHead={[
            { Header: <div className="tooltypes-actions">
              <Checkbox
                tabIndex={-1}
                id="allProfessionals"
                onClick={(e) => onSelectAllProfessionals(e)}
                checked={checkAllProfessionals}
                checkedIcon={<Check className={classesChecks.checkedIcon} />}
                icon={<Check className={classesChecks.uncheckedIcon} />}
                classes={{
                  checked: classesChecks.checked,
                  root: classesChecks.checkRoot
                }}
                disabled={professionalsToReassign.length === 0}
              /></div>, sortable: false, filterable: false, accessor: 'selector', width: 60,
            },
            { Header: t('reassignAppointments.table.profesional.lastName'), accessor: 'lastName' },
            { Header: t('reassignAppointments.table.profesional.firstName'), accessor: 'firstName' },
            {
              Header: t('reassignAppointments.table.profesional.employeeTypes'),
              accessor: 'employeeTypes',
              Cell: (data) => getShowMoreLessData(data),
            },
            {
              Header: t('reassignAppointments.table.profesional.geographicZone'), accessor: 'geographicZone',
              Cell: (data) => getShowMoreLessData(data),
            },
            { Header: '', accessor: 'employeeTypes', sortable: false, filterable: false, with: 50, className: 'column-expandable',
              Cell: (data) => getShowMoreLessButton(data),
            },
          ]}
          tableData={professionalsToReassign}
          defaultPageSize={professionalsToReassign && professionalsToReassign.length > 5 ? 20 : 5}
          colorsColls={['primary']}
        />
      </Grid>

      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.appointmentsToModify')}</h4>
        <Table
          id="schedules-table"
          loading={loadingSchedule}
          tableHeaderColor="primary"
          sortable
          setRef={setRefSchedules}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.scheduleId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.professional'), accessor: 'professional' },
            { Header: t('common.date'), accessor: 'scheduleDate',
              sortMethod: (a, b) => sortDatetime(a, b)
            },
            { Header: t('label.hour'), accessor: 'scheduleTime' },
          ]}
          tableData={schedulesSelectedData}
          defaultPageSize={schedulesSelectedData && schedulesSelectedData.length > 5 ? schedulesSelectedData.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>
    </Grid>
  )
}

ProfessionalsStep.propTypes = {
  t: PropTypes.func,
  handleChange: PropTypes.func,
  isConfirmStep: PropTypes.bool,
  showSnackbarNotification: PropTypes.func,
  setRefSchedules: PropTypes.func,
  refStepsValues: PropTypes.object,
  values: PropTypes.object,
}

export default withTranslation()(ProfessionalsStep);
