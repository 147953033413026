import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function VolunteerActivism(props) {
  return (
    <SvgIcon width="42" height="42" viewBox="0 0 42 42" fill="none" {...props}>
      <path d="M28.875 22.3125C34.2825 17.395 39.375 12.7925 39.375 8.8375C39.375 5.6 36.8375 3.0625 33.6 3.0625C31.78 3.0625 30.0125 3.92 28.875 5.25C27.72 3.92 25.97 3.0625 24.15 3.0625C20.9125 3.0625 18.375 5.6 18.375 8.8375C18.375 12.7925 23.4675 17.395 28.875 22.3125ZM24.15 6.5625C24.92 6.5625 25.7075 6.93 26.215 7.525L28.875 10.6575L31.535 7.525C32.0425 6.93 32.83 6.5625 33.6 6.5625C34.895 6.5625 35.875 7.5425 35.875 8.8375C35.875 10.7975 32.305 14.385 28.875 17.57C25.445 14.385 21.875 10.78 21.875 8.8375C21.875 7.5425 22.855 6.5625 24.15 6.5625Z" fill="#383838"/>
      <path d="M34.125 27.5625H30.625C30.625 25.4625 29.3125 23.5725 27.3525 22.8375L16.5725 18.8125H2.625V38.0625H13.125V35.5425L25.375 38.9375L39.375 34.5625V32.8125C39.375 29.9075 37.03 27.5625 34.125 27.5625ZM6.125 34.5625V22.3125H9.625V34.5625H6.125ZM25.3225 35.28L13.125 31.9025V22.3125H15.9425L26.1275 26.11C26.7225 26.3375 27.125 26.915 27.125 27.5625C27.125 27.5625 23.6425 27.475 23.1 27.3L18.935 25.9175L17.8325 29.2425L21.9975 30.625C22.89 30.9225 23.8175 31.08 24.7625 31.08H34.125C34.8075 31.08 35.42 31.4825 35.7 32.06L25.3225 35.28Z" fill="#383838"/>
    </SvgIcon>
  );
}
