import {
  Grid,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';

import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import CustomSweetAlert from '../../CustomSweetAlert';
import PropTypes from 'prop-types';
import customCheckboxRadioSwitch from '../../../assets/components/customCheckboxRadioSwitch';
import { initialAlertWarning } from '../data';
import { useStylesReassignAppStepper } from '../useStylesReassignAppStepper';
import { withTranslation } from 'react-i18next';

const useStylesChecks = makeStyles(customCheckboxRadioSwitch);

export const ButtonsSteps = ({
  clearInformation,
  handleBack,
  handleChange,
  handleNext,
  handleSkipStep,
  handleGoBack,
  handleConfirm,
  showSnackbarNotification,
  removeCompletedStep,
  recurrence,
  steps,
  t,
  transferWay,
  transfer,
  values,
  buttonType,
  customerDetail
}) => {
  const classes = useStylesReassignAppStepper();

  const [alertWarning, setAlertWarning] = useState(initialAlertWarning);

  const { activeStep, refStepsValues } = values;

  const {
    busyButton,
    loadingevents,
    schedules,
  } = values;

  const firstStep = activeStep === 0;
  const secondStep = activeStep === 1;
  const thirdStep = activeStep === 2;
  const fourthStep = activeStep === 3;
  const fifthStep = activeStep === 4;
  const sixthStep = activeStep === 5;

  const isConfirmStep = activeStep === steps.length - 1;

  const backAction = () => {
    handleBack();
    if (sixthStep) {
      removeCompletedStep(5);
    }
  }

  const firstStepNextAction = () => {
    const schedulesCkecked = schedules.filter(f => f.checkedSchedule === true);
    handleChange('schedulesSelectedData', schedulesCkecked);
  };

  const nextAction = () => {
    handleNext(true);
    firstStep && firstStepNextAction();
  }

  const disabledNextButton = () => {
    if(transferWay === 1){
      switch (true) {
        case firstStep:
          return firstStep && (!customerDetail.trasladoIda || !customerDetail.prestador || !customerDetail.nosocomio || !customerDetail.startDateDeparture );
        case secondStep:
          return secondStep && (!recurrence.every || (Number(recurrence.every) >= 1 ? false : true) || 
          (recurrence.recurrenceType === "MONTH" ? (Number(recurrence.monthRecurrence) >= 1 ? false : true) : false) || 
          (recurrence.recurrenceType === "WEEK" ? ((Number(recurrence.every) >= 1  && recurrence.weekRecurrence.length > 0 ) ? false : true) : false) ||
          (recurrence.recurrenceType === "INTRADAY" ? ((Number(recurrence.quantityIntraday) >= 1 && recurrence.weekRecurrence.length > 0 ) ? false : true) : false))
        case thirdStep:
          return thirdStep
        default:
          break;
      }
    }
    if(transferWay === 2){
      switch (true) {
        case firstStep:
          return firstStep && (!customerDetail.trasladoVuelta || !customerDetail.prestadorVuelta || !customerDetail.nosocomioVuelta || !customerDetail.shiftDateReturn );
        case secondStep:
          return secondStep && (!recurrence.every || (Number(recurrence.every) >= 1 ? false : true) || 
          (recurrence.recurrenceType === "MONTH" ? (Number(recurrence.monthRecurrence) >= 1 ? false : true) : false) || 
          (recurrence.recurrenceType === "WEEK" ? ((Number(recurrence.every) >= 1  && recurrence.weekRecurrence.length > 0 ) ? false : true) : false) ||
          (recurrence.recurrenceType === "INTRADAY" ? ((Number(recurrence.quantityIntraday) >= 1 && recurrence.weekRecurrence.length > 0 ) ? false : true) : false));
        case thirdStep:
          return thirdStep 
        default:
          break;
      }
    }
    if(transferWay === 3){
      switch (true) {
        case firstStep:
          return firstStep && (!customerDetail.trasladoIda || !customerDetail.prestador || !customerDetail.nosocomio || !customerDetail.startDateDeparture );
        case secondStep:
          return secondStep && (!customerDetail.trasladoVuelta || !customerDetail.prestadorVuelta || !customerDetail.nosocomioVuelta || !customerDetail.shiftDateReturn );
        case thirdStep:
          return thirdStep && (!recurrence.every || (Number(recurrence.every) >= 1 ? false : true) || 
          (recurrence.recurrenceType === "MONTH" ? (Number(recurrence.monthRecurrence) >= 1 ? false : true) : false) || 
          (recurrence.recurrenceType === "WEEK" ? ((Number(recurrence.every) >= 1  && recurrence.weekRecurrence.length > 0 ) ? false : true) : false) ||
          (recurrence.recurrenceType === "INTRADAY" ? ((Number(recurrence.quantityIntraday) >= 1 && recurrence.weekRecurrence.length > 0 ) ? false : true) : false));
        case fourthStep:
          return fourthStep
        default:
          break;
      }
    }
    
  }

  const isVisibleNextButton = transferWay == 3 ?  (!fourthStep && !fifthStep) :  (!thirdStep && !fourthStep);

  const skipAction = () => {
    handleSkipStep();
    
    switch (true) {
      case secondStep:
        //return resetAllProfessionals();
        return
      case thirdStep:
        //return handleClearDates();
        return
      case fourthStep:
        //return handleClearTimes();
        return
      default:
        break;
    }
  }

  const aletCancelAction = () => {
    //handleDeleteBookings(bookingsToDelete);
    handleGoBack();
    clearInformation();
  }

  const alertWarningAction = (type) => {
    switch (type) {
      case 'cancel':
        return aletCancelAction();
      case 'confirm':
        return handleConfirm();
      default:
        break;
    }
  }

  const alertCancel = {
    open: true,
    message:
      <>
        <p>{t('message.cancellationReassignment')}</p>
        <p>{t('message.cancellationReassignmentConfirm')}</p>
      </>,
    confirmBtnText: t('common.accept'),
    cancelBtnText: t('common.cancel'),
    title: t('title.cancellationTransfer'),
    type: 'cancel',
  }

  return (
    <>
      
      <Grid container className={classes.buttons}>
        <Grid item xs={6} className={classes.buttonsStart}>
        {(buttonType && activeStep === 0) &&
            <>
              <Button
                id="button-cancel"
                className={classes.button}
                onClick={() => setAlertWarning(alertCancel)}
              >
                {t('common.cancel')}
              </Button>
            </>
          }
          {activeStep !== 0 &&
            <>
              <Button
                id="button-cancel"
                onClick={() => setAlertWarning(alertCancel)}
                className={classes.button}
              >
                {t('common.cancel')}
              </Button>
              <Button
                id="button-prev"
                className={classes.button}
                onClick={backAction}
                disabled={activeStep === 0}
                color="violet"
              >
                {t('common.prev')}
              </Button>
            </>
          }
        </Grid>
        <Grid item xs={6} className={classes.buttonsEnd}>
          
          {(isVisibleNextButton) &&
            <Button
              id="button-next"
              variant="contained"
              color="violet"
              onClick={() => nextAction()}
              className={classes.button}
              disabled={disabledNextButton()}
            >
              {t('common.next')}
            </Button>
          }

          {fifthStep && (
            <Tooltip title={t('tooltipInfo.stepReassingConfirm')}>
              <span className={classes.button}>
                <ButtonSpinner
                  id="button-calculate"
                  className={classes.button}
                  //onClick={() => calculateReassignment(true)}
                  disabled={loadingevents}
                  label={t('label.calculateReassignment')}
                  labelLoading={t('label.calculateReassignment')}
                  loading={loadingevents}
                  typeButton="submit"
                  color="primary"
                />
              </span>
            </Tooltip>
          )}

          {isConfirmStep
            && <ButtonSpinner
              id="button-confirm"
              className={classes.button}
              label={t('appointment.save.service')}
              labelLoading={t('appointment.save.service')}
              loading={busyButton}
              color="primary"
              disabled={false}
              onClick={() =>{
                 transfer()
                 setAlertWarning({
                    open: true,
                    message: <p>{t('message.confirmTransfer')}</p>,
                    cancelBtnText: t('common.cancel'),
                    title: t('appointment.save.service'),
                    confirmBtnText: t('common.save'),
                    type: 'confirm',
                  })
                }
            }
              typeButton="submit"
            />
          }
        </Grid>
      </Grid>

      {alertWarning.open &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => {
            setAlertWarning(initialAlertWarning);
            alertWarningAction(alertWarning.type)
          }}
          onCancel={() => setAlertWarning(initialAlertWarning)}
          cancelBtnCssClass="danger"
          cancelBtnText={alertWarning.cancelBtnText}
          title={alertWarning.title}
          confirmBtnCssClass="primary"
          confirmBtnText={alertWarning.confirmBtnText}
          message={alertWarning.message}
      />}
    </>
  )
}

ButtonsSteps.propTypes = {
  t: PropTypes.func,
  handleChange: PropTypes.func,
  handleNext: PropTypes.func,
  clearInformation: PropTypes.func,
  handleBack: PropTypes.func,
  handleSkipStep: PropTypes.func,
  showSnackbarNotification: PropTypes.func,
  removeCompletedStep: PropTypes.func,
  values: PropTypes.object,
  steps: PropTypes.array,
}

export default withTranslation()(ButtonsSteps);
