import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';


const NotificationState = ({ notification }) => {
  const { color, message, open } = notification;
  return (
    <Snackbar
      place="tr"
      open={open}
      message={message}
      color={color}
    />
  );
}

NotificationState.propTypes = {
  notification: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
};

export default NotificationState;
