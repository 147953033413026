import { makeStyles } from "@material-ui/core";

export const useStylesAppointmentItemsDetailModal = makeStyles(() => ({
  dialogPM: {
    '& .MuiDialogContent-root': {
      paddingBottom: '2rem',
    },
  },
  progressContent: {
    minHeight: '18vh',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  items: {
    marginBottom: '2rem',
    '& li': {
      marginBottom: '1.2rem',
      marginRight: 30,
      '& + li': {
        borderTop: '1px solid #f3f0f0',
        marginTop: 8,
        paddingTop: 15,
      },
      '& > strong': {
        fontWeight: 500,
        display: 'block',
        marginBottom: '0.5rem',
        fontSize: '1rem',
      },
      '& > p > span': {
        fontSize: '0.875rem',
        display: 'block',
        '& + span': {
          marginTop: '0.2rem',
        },
        '& strong': {
          fontWeight: 500,
        }
      },
    },
  },
}));
