import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import CustomDialog from '../../CustomDialog';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const styles = makeStyles({
  dialogSignature: {
    '& .MuiDialogTitle-root h2': {
      paddingLeft: 8,
    }
  },
  progressContent: {
    minHeight: '40vh',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  signatureModal: {
    minHeight: '40vh',
    padding: '0 12px 24px',
    '& h4': {
      marginBottom: 18,
      '& svg': {
        verticalAlign: 'middle',
      },
      '& span': {
        display: 'inline-block',
        fontSize: '1.2rem',
        paddingLeft: 8,
        verticalAlign: 'middle',
      },
    },
    '& h5': {
      marginTop: 24,
      fontSize: '1.1rem',
    },
    '& img': {
      maxWidth: 200,
    }
  },
  label: {
    opacity: .8,
    padding: 4,
  },
  count: {
    textAlign: 'right',
  },
})

const SignatureModal = ({ t, scheduleId, open, onCloseSignature }) => {
  const classes = styles();

  const [signature, setSignature] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notResultMsg, setNotResultMsg] = useState('');

  const getSignature = () => {
    const message = t('signaturePatient.notResult');

    setIsLoading(true);
    PersonApiInvoker.getCustomerSignature(scheduleId, sig => {
      setSignature(sig);
      sig.length === 0 && setNotResultMsg(message);
      setIsLoading(false);
    }, () => {
      setIsLoading(false);
      setNotResultMsg(message);
    });
  }

  useEffect(() => {
    getSignature();
    return () => {
      setSignature([]);
      setNotResultMsg('');
    }
  }, [])

  const renderSignature = () => signature.map((sg, i) => {
    const key = `signature-${scheduleId}${i+1}`;
    return (
      <Grid container key={key} className={classes.signatureModal}>
        <Grid item xs={12} className="text-center">
          <img src={sg.fullUrl} alt="signature" className="padding-vertical" />
        </Grid>
        <Grid item xs={12} className="text-center">
          <small className={classes.label}>{ t('label.clarification') }: </small>
          <span className={classes.label}>{sg.clarification}</span>
        </Grid>
      </Grid>
    )
  })
  
  return (
    <CustomDialog
      title={t('label.signaturePatient.title')}
      open={open}
      onClose={() => onCloseSignature(false)}
      maxWidth={'sm'}
      className={classes.dialogSignature}
    >
      {(isLoading || notResultMsg) &&
        <Grid container className={classes.progressContent}>
          <Grid item xs={12}>
            {isLoading && <CircularProgress />}
            {notResultMsg}
          </Grid>
        </Grid>
      }
      {(signature && signature.length > 0) && renderSignature()}
    </CustomDialog>
  )
}

SignatureModal.defaultProps = {
  open: false,
}

SignatureModal.propTypes = {
  t: PropTypes.func,
  open: PropTypes.bool,
  scheduleId: PropTypes.number.isRequired,
  onCloseSignature: PropTypes.func.isRequired,
}

export default withTranslation()(SignatureModal);
