import { CoreException, Either, VoidSuccess } from "../../../../sdk";
import { PracticeFeeRequest, PracticeFee, ScheduleFee, ScheduleFeeRequest } from "../../domain";

export abstract class PracticeFeesRepository {
  abstract getByEmployeeAndPracticeType(employeeId: number, employeeTypeId: number): Promise<Either<CoreException, PracticeFee[]>>;
  abstract getSchedulesFeesByPracticeType(practiceTypeId: number): Promise<Either<CoreException, ScheduleFee[]>>;
  abstract createFee(data: PracticeFeeRequest): Promise<Either<CoreException, PracticeFee[]>>;
  abstract deleteFee(id: number): Promise<Either<CoreException, VoidSuccess>>;
  abstract updateFee(practiceFeeId: number, data: PracticeFeeRequest): Promise<Either<CoreException, PracticeFee[]>>;
  abstract updateScheduleFee(data: ScheduleFeeRequest[]): Promise<Either<CoreException, VoidSuccess>>;
}
