import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { PracticeFee, PracticeFeeRequest } from "../../domain";
import { inject, injectable } from "inversify";

import { PracticeFeesRepository } from "../repositories/practice-fees.repository";

@injectable()
export class UpdateFeeUsecase {

  @inject(PracticeFeesRepository) protected repository!: PracticeFeesRepository;

  async execute(practiceFeeId: number, data: PracticeFeeRequest): Promise<Either<UpdateFeeError, PracticeFee[]>> {
    if (!practiceFeeId || !data) {
      return Either.left(new UpdateFeeInvalidDataError());
    }
    const result = await this.repository.updateFee(practiceFeeId, data);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): UpdateFeeError {
    if (exception instanceof NotFoundException) {
      return new UpdateFeeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new UpdateFeeConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new UpdateFeeOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new UpdateFeeAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new UpdateFeeBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in UpdateFeeUsecase");
  }
}

export abstract class UpdateFeeError {
  error?: string;
}

export class UpdateFeeInvalidDataError extends UpdateFeeError { }
export class UpdateFeeNotFoundError extends UpdateFeeError { }
export class UpdateFeeConnectionError extends UpdateFeeError { }
export class UpdateFeeOperationalError extends UpdateFeeError { }
export class UpdateFeeAuthorizationError extends UpdateFeeError { }
export class UpdateFeeBadRequestError extends UpdateFeeError {
  constructor(public readonly error: string) {
    super();
  }
}
