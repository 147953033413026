import { makeStyles } from "@material-ui/core";

export const useStylesLoadProfile = makeStyles(() => ({
  loadProfile: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  checkInput: {
    '& .check-label': {
      margin: '0 3.2vw',
      '& .MuiFormControlLabel-label': {
        display: 'block',
        '& .MuiButtonBase-root': {
          color: '#022E5E !important',
          padding: 0,
          fontSize: '.95rem',
          textDecoration: 'underline',
          textTransform: 'none',
          margin: 0,
          display: 'inline',
          whiteSpace: 'normal',
          '&:hover': {
            textDecoration: 'none',
          }
        }
      }
    }
  },
  textTop: {
    fontSize: '1rem',
    textAlign: 'center',
    padding: '15px 0 5px',
  },
  submitTerms: {
    padding: '45px 15px 0 !important',
    textAlign: 'center',
  },
  progressButton: {
    color: 'white',
    marginLeft: 0,
    '& svg': {
      width: 18,
      height: 18,
    }
  },
  alertWarning: { marginTop: '-200px !important' },
}))
