import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException, VoidSuccess } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { PracticeFeesRepository } from "../repositories/practice-fees.repository";
import { ScheduleFeeRequest } from "../../domain";

@injectable()
export class UpdateScheduleFeeUsecase {

  @inject(PracticeFeesRepository) protected repository!: PracticeFeesRepository;

  async execute(data: ScheduleFeeRequest[]): Promise<Either<UpdateScheduleFeeError, VoidSuccess>> {
    if (!data) {
      return Either.left(new UpdateScheduleFeeInvalidDataError());
    }
    const result = await this.repository.updateScheduleFee(data);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): UpdateScheduleFeeError {
    if (exception instanceof NotFoundException) {
      return new UpdateScheduleFeeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new UpdateScheduleFeeConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new UpdateScheduleFeeOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new UpdateScheduleFeeAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new UpdateScheduleFeeBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in UpdateScheduleFeeUsecase");
  }
}

export abstract class UpdateScheduleFeeError {
  error?: string;
}

export class UpdateScheduleFeeInvalidDataError extends UpdateScheduleFeeError { }
export class UpdateScheduleFeeNotFoundError extends UpdateScheduleFeeError { }
export class UpdateScheduleFeeConnectionError extends UpdateScheduleFeeError { }
export class UpdateScheduleFeeOperationalError extends UpdateScheduleFeeError { }
export class UpdateScheduleFeeAuthorizationError extends UpdateScheduleFeeError { }
export class UpdateScheduleFeeBadRequestError extends UpdateScheduleFeeError {
  constructor(public readonly error: string) {
    super();
  }
}
