import { CoreException, Either, VoidSuccess } from "../../../../sdk";
import { CustomerFinancier, Financier } from "../../domain";

import { CreateCustomerFinancierUsecaseDto } from "../usecases";

export abstract class FinanciersRepository {
  abstract getFinanciers(): Promise<Either<CoreException, Financier[]>>;
  abstract getCustomerFinanciers(personId: number): Promise<Either<CoreException, CustomerFinancier[]>>;
  abstract createCustomerFinancier(customerFinancier: CreateCustomerFinancierUsecaseDto): Promise<Either<CoreException, CustomerFinancier>>;
  abstract deleteCustomerFinancier(personId: number, financierId: number): Promise<Either<CoreException, VoidSuccess>>;
}
