import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from '@material-ui/core/Tooltip';
import { InfoOutlined } from '@material-ui/icons';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import CustomSweetAlert from '../CustomSweetAlert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import RadioInput from '../RadioInput';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import EmployeeTypesDialogSelector from '../EmployeeTypesDialogSelector';
import Authority from "../../util/Authority";

import './NewUser.css';
import { first } from 'lodash';

class NewUser extends Component {
    constructor(props) {
        super(props);

        const { t } = this.props; 
        
        const toolTipProf =
            <>
                <span>{t('common.role.prof') }</span>
                <Tooltip className="toolTipEdit" aria-label="info" title={t('users.practice.help')}>
                    <InfoOutlined className="infoIcon" />
                </Tooltip>
            </>

        const roles = [
            { id: "ADM", roleId: 2, label: t('common.role.adm'), disabled: true },
            { id: "GTE", roleId: 3, label: t('common.role.gte'), disabled: true },
            { id: "COG", roleId: 8, label: t('common.role.cog'), disabled: true },
            { id: "COP", roleId: 4, label: t('common.role.cop'), disabled: true },
            { id: "COM", roleId: 5, label: t('common.role.com'), disabled: true },
            { id: "CHOF", roleId: 8, label: t('common.role.chf'), disabled: Authority.hasPermission('menu_mobility_transfer') ? false : true },
            { id: "PROF", roleId: 6, label: toolTipProf, disabled: false },
        ];
        
        this.state = {
            email: '',
            name: '',
            lastName: '',
            firstName: '',
            roles,
            selectedRole: 'PROF',
            openDialog: false,
            employeeTypes: [],
            selectedEmployeeTypes: [],
            save: false,
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            loading: true,
            openCancelWarning: false,
            isEmailAlreadyRegistred: false,
            messageError: '',
        }
    }

    componentWillMount() {
        this.getEmployeesTypes();
    }

    cleanFields() {
        this.setState({
            email: '',
            name: '',
            lastName: '',
            selectedRole: 'PROF',
            openDialog: false,
            selectedEmployeeTypes: [],
            save: false,
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            loading: true,
            openCancelWarning: false,
            isEmailAlreadyRegistred: false,
            messageError: '',
        });
    }

    openAlert(alertColor, alertMessage) {
        this.setState({
            alertColor,
            alertMessage,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (alertColor === 'success') {
                browserHistory.push('/users');
                this.cleanFields();
            }
        }, 5000);
    }

    getEmployeesTypes = () => {
        this.setState({ loading: true });
        return PersonApiInvoker.getEmployeesType('PROFESSIONAL', employeeTypes => {
            this.setState({ employeeTypes, loading: false });
        }, () => {
            this.setState({ loading: false });
        });
    }

    showEmployeeTypes = () => {
        this.setState((prevState) => ({
            ...prevState,
            openDialog: !prevState.openDialog,
        }));
    }

    onAcceptEmployeeTypes = (selected) => {
        this.setState({
            openDialog: false,
            selectedEmployeeTypes: selected,
        });
    }
    
    alertCancelChanges = () => {
        const { t } = this.props;
        return <CustomSweetAlert
        title={t('users.cancel.changes.title')}
        type="warning"
        onConfirm={this.goBack}
        confirmBtnCssClass="primary"
        confirmBtnText={t('common.accept')}
        onCancel={() => this.setState({ openCancelWarning: false })}
        cancelBtnText={t('common.cancel')}
        cancelBtnCssClass="danger"
        message={
            <>
                <p>{t('message.cancel.changes')}</p>
                <p>{t('message.cancel.changes.question')}</p>
            </>
        }
      />
    }

    alertEmailAlreadyRegistered = () => <CustomSweetAlert
        title={''}
        type="warning"
        onConfirm={() => this.setState({ isEmailAlreadyRegistred: false, messageError: '' })}
        confirmBtnText={this.props.t('common.accept')}
        confirmBtnCssClass="primary"
        showCancel={false}
        message={<p>{this.state.messageError}</p>}
    />

    handleValue = (value, state) => this.setState({ [state]: value });

    handleValueRadio = (value) => this.setState({ selectedRole: value });

    validEmail = (value) => {
        if (value.length === 0) return true;

        const emailRex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRex.test(value);
    }

    validFields = () => {
        const { email, firstName, lastName, selectedEmployeeTypes, selectedRole } = this.state;
        const isRoleProfOk = selectedEmployeeTypes.length > 0;
        const isRoleOk = selectedRole === 'PROF' ? isRoleProfOk : true;
        const validFields = email && firstName && lastName && selectedRole && isRoleOk;
        return validFields;
    }

    saveData = () => {
        const { t } = this.props;
        const { email, firstName, lastName, selectedRole, roles, selectedEmployeeTypes } = this.state;
        this.setState({ save: true, busy: true });
        if (email && !this.validEmail(email)) {
            this.openAlert('warning', t('message.invalidEmail'));
            this.setState({ busy: false, save: false });
            return;
        }
        if (this.validFields() && this.validEmail(email)) {
            const roleSelectedObject = roles.find(r => r.id === selectedRole);

            PersonApiInvoker.postInvitation({
                firstName,
                lastName,
                mail: email,
                practiceList: selectedEmployeeTypes,
                rolId: roleSelectedObject.roleId,
            }, () => {
                this.openAlert('success', t('users.form.success'));
                this.setState({ busy: false, save: false });
            }, error => {
                if (error.status === 400) {
                    this.setState({
                        isEmailAlreadyRegistred: true,
                        messageError: error.message,
                        busy: false,
                        save: false,
                    });
                    return;
                }
                const message = error.message ?? error;
                this.openAlert('danger', message + '');
                this.setState({ busy: false, save: false });
            });
        } else {
            this.openAlert('warning', t('common.messageWarning.fieldsComplete'));
            this.setState({ busy: false, save: false });
        }
    }

    goBack = () => browserHistory.goBack();

    render() {
        const { t } = this.props;
        const {
            alertColor, alertMessage, alertOpen, busy, email, firstName, lastName, openCancelWarning,
            isEmailAlreadyRegistred, openDialog, selectedRole, roles, loading, employeeTypes, selectedEmployeeTypes,
        } = this.state;
    
        const disabledSave = busy || !this.validFields();

        return (
            <GridContainer className="new-user-form">
                { openDialog &&
                    <EmployeeTypesDialogSelector
                        loading={loading}
                        open={openDialog}
                        data={employeeTypes}
                        selectedData={selectedEmployeeTypes}
                        onAccept={(d) => this.onAcceptEmployeeTypes(d)}
                        onCancel={() => this.showEmployeeTypes()}
                    />
                }
                { openCancelWarning &&  this.alertCancelChanges() }
                { isEmailAlreadyRegistred && this.alertEmailAlreadyRegistered() }
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <Add />
                            </CardIcon>
                            <h4 className="card-title">{t('users.form.title')}</h4>
                        </CardHeader>
                        <CardBody className="create-new-user-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <ValidationInput
                                            text={t('users.form.email')}
                                            type="email"
                                            value={email}
                                            onChangeValue={(value) => this.handleValue(value, 'email')}
                                            invalid={!this.validEmail(email)}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <ValidationInput
                                            text={t('users.form.firstName')}
                                            type="text"
                                            value={firstName}
                                            onChangeValue={(value) => this.handleValue(value, 'firstName')}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <ValidationInput
                                            text={t('users.form.lastName')}
                                            type="text"
                                            value={lastName}
                                            onChangeValue={(value) => this.handleValue(value, 'lastName')}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ paddingLeft: 20 }}>
                                    <GridItem xs={2} sm={2} md={2}>
                                        <FormControl className="alerts-dropdown">
                                            <InputLabel htmlFor="status-select">
                                                {t('users.form.role')}
                                            </InputLabel>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs>
                                        <RadioInput
                                            elements={roles}
                                            value={selectedRole}
                                            onChangeValue={value => this.handleValueRadio(value)}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </form>
                            <p className="required-text"><small>*</small> {t('common.fields.required')}</p>
                        </CardBody>
                        <CardFooter className="new-tool-submit padding-horizontal">
                            <GridContainer>
                                <GridItem xs={3}>
                                    <Button id="button-cancel" onClick={() => this.setState({ openCancelWarning: true })}>
                                        {t('common.cancel')}
                                    </Button>
                                </GridItem>
                                <GridItem xs={9} className="item-flex-end">
                                    {
                                        selectedRole !== 'CHF' &&
                                        <Button
                                        id="button-show"
                                        color="secondary"
                                        simple
                                        onClick={() => this.showEmployeeTypes()}
                                        disabled={selectedRole !== 'PROF'}
                                    >
                                        {t('users.form.asign')}
                                    </Button>
                                    }
                                    

                                    <ButtonSpinner
                                        id="button-save"
                                        onClick={() => this.saveData()}
                                        disabled={disabledSave}
                                        label={t('users.form.save')}
                                        labelLoading={t('users.form.saving')}
                                        loading={busy}
                                        typeButton="submit"
                                        color="primary"
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardFooter>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

NewUser.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(NewUser);
