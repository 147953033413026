import { connect } from 'react-redux';
import Employees from '../components/Employees';
// Components
import NewEmployee from '../components/NewEmployee/new-employee';
import NewEmployeeType from '../components/EditEmployee/NewEmployeeType/NewEmployeeType';
import NewEmployeeGeographic from '../components/EditEmployee/NewEmployeeGeographic/NewEmployeeGeographic';
import EmployeeGreographic from '../components/EditEmployee/EmployeeGeographic/EmployeeGreographic';
import EmployeeDriverGreographic from '../components/Driver/DriverGeographic/EmployeeDriverGeographic';
// Actions
import { getEmployees, getEmployee } from '../actions/employees';
import { getEmployeeTypes, checkType, uncheckAll, 
        putEmployeeType, uncheckAllGeographic, getEmployeeGeographics,
        getGeographics, putEmployeeGeographic, checkGeographic,
        deleteItemGeographic,cleanEmployees, checkAllTypes,
} from '../actions/newEmployee';


const mapStateToProps = (state) => {
    return {
        employees: state.employees.employees,
        employeesType: state.employees?.employeesType,
        currentEmployeeGeographics: state.employees?.employee?.employeesGeographics,
        geographics: state.employees?.geographics,
        checkType: state.employees?.checkType,
        checkGeographic: state.employees?.checkGeographic,
        employee: state.employees?.employee,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEmployees: (employees) => {
            dispatch(getEmployees(employees));
        },
        onCleanEmployees: () => {
            dispatch(cleanEmployees());
        },
        onGetEmployee: (employee) => {
            dispatch(getEmployee(employee));
        },
        onCleanEmployee: () => {
            dispatch(cleanEmployees());
        },

        onGetEmployeeTypes: (Types) => {
            dispatch(getEmployeeTypes(Types));
        },
        onCheckEmployeeType: (Type) => {
            dispatch(checkType(Type));
        },
        onUncheckAll: () => {
            dispatch(uncheckAll());
        },
        onCheckAllTypes: (Types) => {
            dispatch(checkAllTypes(Types));
            Types.forEach(type => {
                dispatch(checkType(type));
            });
        },
        onUncheckAllGeographic: () => {
            dispatch(uncheckAllGeographic());
        },
        onGetEmployeeGeographics: (geographics) => {
            dispatch(getEmployeeGeographics(geographics));
        },
        onGetGeographics: (geographics) => {
            dispatch(getGeographics(geographics));
        },
        onPutEmployeeType: (employeeTypeId) => {
            dispatch(putEmployeeType(employeeTypeId));
        },
        onPutEmployeeGeographic: (geographic) => {
            dispatch(putEmployeeGeographic(geographic));
        },
        onCheckEmployeeGeographic: (geographic) => {
            dispatch(checkGeographic(geographic));
        },
        onDeleteItemGeographic: (geographicId) => {
            dispatch(deleteItemGeographic(geographicId));
        },
    }
}

const EmployeesService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Employees);

const NewEmployeeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewEmployee);

const EmployeesTypeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewEmployeeType);

const EmployeesGeographicModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewEmployeeGeographic);

const EmployeesGeographicForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeeGreographic);

const EmployeesDriverGeographicForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeeDriverGreographic);

export default { EmployeesService, NewEmployeeService, EmployeesTypeService, EmployeesGeographicModal, EmployeesGeographicForm, EmployeesDriverGeographicForm  }