import { Checkbox, FormControlLabel, Grid, Tooltip, makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import { Check } from '@material-ui/icons';
import CustomSweetAlert from '../../CustomSweetAlert';
import PropTypes from 'prop-types';
import customCheckboxRadioSwitch from '../../../assets/components/customCheckboxRadioSwitch';
import { initialAlertWarning } from '../../ReassignAppointmentsStepper/data';
import useCalendarStep from './CalendarStep/useCalendarStep';
import useDatestep from './DateStep/useDateStep';
import useProfessionalsStep from './ProfessionalsStep/useProfessionalsStep';
import { useStylesReassignAppStepper } from '../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import useTimeStep from './TimeStep/useTimeStep';
import { withTranslation } from 'react-i18next';

const useStylesChecks = makeStyles(customCheckboxRadioSwitch);

export const ButtonsStep = ({ clearInformation, handleBack, handleChange, handleNext, handleSkipStep, showSnackbarNotification, removeCompletedStep, steps, t, values,
}) => {
  const classes = useStylesReassignAppStepper()
  const classesChecks = useStylesChecks()
  const [alertWarning, setAlertWarning] = useState(initialAlertWarning)
  const [filterByZone, setFilterByZone] = useState(true)
  const { activeStep, refStepsValues } = values

  const { getProfessionals, resetAllProfessionals } = useProfessionalsStep(handleChange, showSnackbarNotification, refStepsValues, values);

  const {
    calculateReassignment,
    confirmReassignment,
    handleDeleteBookings,
  } = useCalendarStep(clearInformation, handleChange, handleNext, showSnackbarNotification, values, t);
  
  const {
    handleClearTimes,
  } = useTimeStep(handleChange, values);

  const {
    handleClearDates,
  } = useDatestep(handleChange, values);
  
  const {
    bookings, bookingsError, busyButton,
    dateSchedulesHasChanged,
    loadingevents,
    professionalsSelected,
    schedules, schedulesSelected,
    timeSchedulesHasChanged,
  } = values;

  const firstSteps = activeStep === 0;
  const secondSteps = activeStep === 1;
  const thirdStep = activeStep === 2;
  const fourthStep = activeStep === 3;
  const fifthStep = activeStep === 4;
  const sixthSteps = activeStep === 5;

  const isConfirmStep = activeStep === steps.length - 1;

  const bookingsToDelete = [...bookings, ...bookingsError];

  const backActions = () => {
    handleBack();
    if (sixthSteps) {
      handleDeleteBookings(bookingsToDelete);
      removeCompletedStep(5);
    }
  }

  const firstStepNextActions = () => {
    const schedulesCkecked = schedules.filter(f => f.checkedSchedule === true);
    handleChange('schedulesSelectedData', schedulesCkecked);
    getProfessionals(filterByZone);
  };

  const nextAction = () => {
    handleNext(true);
    firstSteps && firstStepNextActions();
  }

  const disabledNextButton = () => {
    switch (true) {
      case firstSteps:
        return firstSteps && schedulesSelected.length < 1;
      case secondSteps:
        return secondSteps && professionalsSelected.length < 1;
      case thirdStep:
        return thirdStep && !dateSchedulesHasChanged;
      case fourthStep:
        return fourthStep && !timeSchedulesHasChanged;
      default:
        break;
    }
  }

  const isVisibleNextButton = !fifthStep && !sixthSteps;

  const skipAction = () => {
    handleSkipStep();
    
    switch (true) {
      case secondSteps:
        return resetAllProfessionals();
      case thirdStep:
        return handleClearDates();
      case fourthStep:
        return handleClearTimes();
      default:
        break;
    }
  }

  const aletCancelAction = () => {
    handleDeleteBookings(bookingsToDelete);
    clearInformation();
  }

  const alertCancel = {
    open: true,
    message:
      <>
        <p>{t('message.cancellationReassignment')}</p>
        <p>{t('message.cancellationReassignmentConfirm')}</p>
      </>,
    confirmBtnText: t('common.accept'),
    cancelBtnText: t('common.cancel'),
    title: t('title.transfer.cancellationReassignment'),
    type: 'cancel',
  }

  const alertWarningActions = (type) => {
    switch (type) {
      case 'cancel':
        return aletCancelAction();
      case 'confirm':
        return confirmReassignment();
      default:
        break;
    }
  }

  return (
    <>
      {firstSteps &&
        <div className="padding-horizontal text-right">
         <FormControlLabel
           className="checkLabel geographics"
           control={
             <Checkbox
              id="filer-by-zone"
              tabIndex={-1}
              indeterminate={false}
              checked={filterByZone}
              onClick={() => setFilterByZone(!filterByZone)}
              checkedIcon={<Check className={classesChecks.checkedIcon} />}
              icon={<Check className={classesChecks.uncheckedIcon} />}
              classes={{
                checked: classesChecks.checked,
                root: classesChecks.checkRoot,
              }}
             />
           }
           classes={{ label: classes.label, root: 'check-label' }}
           label={t('reassignAppointments.transfer.filterByZone')}
          />
       </div>
      }
      <Grid container className={classes.buttons}>
        <Grid item xs={6} className={classes.buttonsStart}>
          {activeStep !== 0 &&
            <>
              <Button
                id="button-cancel"
                onClick={() => setAlertWarning(alertCancel)}
                className={classes.button}
              >
                {t('common.cancel')}
              </Button>
              <Button
                id="button-prev"
                className={classes.button}
                onClick={backActions}
                color="violet"
                disabled={activeStep === 0}
              >
                {t('common.prev')}
              </Button>
            </>
          }
        </Grid>
        <Grid item xs={6} className={classes.buttonsEnd}>
          {steps[activeStep].isOpcional &&
            <Button
              id="button-skip"
              onClick={() => skipAction()}
              className={classes.button}
              variant="contained"
              color="violet"
              disabled={activeStep === 0 && schedulesSelected.length < 1}
            >
              {t('label.skip')}
            </Button>
          }
          {(isVisibleNextButton) &&
            <Button
              id="button-next"
              onClick={() => nextAction()}
              className={classes.button}
              variant="contained"
              color="violet"
              disabled={disabledNextButton()}
            >
              {t('common.next')}
            </Button>
          }

          {fifthStep && (
            <Tooltip title={t('tooltipInfo.stepReassingConfirm')}>
              <span className={classes.button}>
                <ButtonSpinner
                  id="button-calculate"
                  className={classes.button}
                  label={t('label.calculateReassignment')}
                  labelLoading={t('label.calculateReassignment')}
                  loading={loadingevents}
                  onClick={() => calculateReassignment(true)}
                  disabled={loadingevents}
                  typeButton="submit"
                  color="primary"
                />
              </span>
            </Tooltip>
          )}

          {isConfirmStep
            && <ButtonSpinner
              id="button-confirm"
              className={classes.button}
              disabled={busyButton || bookings.length === 0}
              label={t('label.confirmReassignment')}
              labelLoading={t('label.confirmReassignment')}
              color="primary"
              loading={busyButton}
              onClick={() => setAlertWarning({
                open: true,
                message: <p>{t('message.transfer.confirmReassignment')}</p>,
                title: t('label.confirmReassignment'),
                type: 'confirm',
                confirmBtnText: t('common.save'),
                cancelBtnText: t('common.cancel'),
              })}
              typeButton="submit"
            />
          }
        </Grid>
      </Grid>

      { alertWarning.open &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => {
            setAlertWarning(initialAlertWarning);
            alertWarningActions(alertWarning.type)
          }}
          onCancel={() => setAlertWarning(initialAlertWarning)}
          confirmBtnText={alertWarning.confirmBtnText}
          confirmBtnCssClass="primary"
          cancelBtnCssClass="danger"
          title={alertWarning.title}
          cancelBtnText={alertWarning.cancelBtnText}
          message={alertWarning.message}
      />}
    </>
  )
}

ButtonsStep.propTypes = {
  t: PropTypes.func,
  clearInformation: PropTypes.func,
  handleBack: PropTypes.func,
  handleChange: PropTypes.func,
  handleNext: PropTypes.func,
  handleSkipStep: PropTypes.func,
  showSnackbarNotification: PropTypes.func,
  steps: PropTypes.array,
  removeCompletedStep: PropTypes.func,
  values: PropTypes.object,
}

export default withTranslation()(ButtonsStep);
