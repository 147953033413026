import { combineReducers } from 'redux';
import user from './user';
import dashboard from './dashboard';
import dashboardprofesional from './dashboardprofesional';
import appointments from './appointments';
import newAppointment from './newAppointment';
import routes from './routes';
import alerts from './alerts';
import customers from './customers';
import employees from './employees';
import tools from './tools';
import practiceTypes from './practiceTypes';
import supplycatalog from './supplycatalog';
import schedules from './schedules';
import newCustomer from './newCustomer';
import newEmployee from './newEmployee';
import editEmployee from './newEmployee';
import editCustomer from './newCustomer';
import newSchedule from './newSchedule';
import address from './address';
import phone from './phone';
import geographicZones from './geographicZones';
import modules from './modules';
import bookings from './bookings';
import companyFinanciers from './companyFinanciers';
import tooltypes from './tooltypes';
import records from './records';
import dashboardcustomer from './dashboardcustomer';
import medicalRecord from './medicalRecord';
import dashboardeconomictool from './dashboardeconomictool';

export default combineReducers({
  user,
  dashboard,
  appointments,
  newAppointment,
  routes,
  alerts,
  customers,
  schedules,
  newCustomer,
  newEmployee,
  editCustomer,
  editEmployee,
  newSchedule,
  address,
  phone,
  geographicZones,
  modules,
  bookings,
  companyFinanciers,
  tooltypes,
  supplycatalog,
  employees,
  tools,
  records,
  dashboardeconomictool,
  dashboardprofesional,
  dashboardcustomer,
  medicalRecord,
  practiceTypes
});
