import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ButtonSpinner from '../../../../ButtonSpinner';
import ComponentDisabledWithTooltip from '../../../../ComponentDisabledWithTooltip';
import ChipFilters from './ChipFilters';
import Button from '../../../../CustomButtons/Button';
import DateInput from '../../../../DateInput';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import PersonApiInvoker from '../../../../../api/PersonApiInvoker';
import { filtersChips } from '../../../data';

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      dateFrom: '',
      dateTo: '',
      employees: [],
      employeeTypes:[],
      oemployeeTypes: [],
      ofinaciers: [],
      professionals: [],
      provincies: [],
      oprovincies: [],
    }
  }

  formatFinaciers = (financiers) => financiers.map(e => ({
    id: e.financierId,
    value: e.fantasyName,
  }));

  formatCustomers = (customers) => {
    if(!customers?.length) {
      return [];
    }
    return customers.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
  }

  getCustomers = () => {
    PersonApiInvoker.getCustomers(false, customers => {
      this.setState({ customers: this.formatCustomers(customers) });
    }, (err) => console.error('** error getCustomers', err));
  }

  getEmployeesData = () => {
    PersonApiInvoker.getEmployees(data => {
      this.setState({ professionals: this.formatEmployee(data), employees: data });
    }, error => console.error('** error getEmployees', error));
  }

  componentDidMount() {
    if (this.props.customers?.length) {
      this.setState({
        customers: this.props.customers,
        ocustomers: this.formatFinaciers(this.props.customers)
      });
    } else {
      this.getCustomers();
    }

    if (this.props.professionals) {
      this.setState({ professionals: this.props.professionals });
    } else {
      this.getEmployeesData();
    }
    if (this.props.filterData?.startDate && this.props.filterData?.endDate !== undefined) {
      this.setState({dateFrom: this.props.filterData.startDate, dateTo: this.props.filterData.endDate})
    }
  }

  componentDidUpdate(next) {
    if (next.customers?.length) {
      this.setState({
        customers: next.customers,
        ocustomers: this.formatCustomers(next.customers),
      });
    }

    if (next.professionals) {
      this.setState({ professionals: next.professionals });
    }
  }

  formatEmployee = (employee) => {
    if(!employee?.length) {
      return [];
    }
    return employee.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
  }

  handleValue = (value, state) => {
    if (state === 'dateFrom') this.setState({ errorStartDate: false });
    if (state === 'dateTo') this.setState({ errorEndDate: false });

    this.setState({ [state]: value });
  }

  sendFilters() {
    let errorStartDate = false;
    if (this.state.dateFrom === '') {
      errorStartDate = true;
    }

    let errorEndDate = false;
    if (this.state.dateTo === '') {
      errorEndDate = true;
    }

    if (errorStartDate || errorEndDate) {
      this.setState({
        errorStartDate,
        errorEndDate,
      });
    } else {
      this.props.onChange({
        startDate: this.state.dateFrom,
        endDate: this.state.dateTo,
        chips: [],
      });
    }
  }

  onChangeChips = (chips) => this.props.onChangeChips({ chips });

  cleanFilters = () => {
    this.setState({
      dateFrom: '',
      dateTo: '',
    }, () => {
      this.props.onClearFilters();
      this.props.onChange({
        startDate: this.state.dateFrom,
        endDate: this.state.dateTo,
        chips: [],
        filters: filtersChips,
      });
    });
  }

  render() {
    const { t, chips, loading, filterData } = this.props;
    const { appointments, customers, dateFrom, dateTo, errorEndDate, errorStartDate, professionals } = this.state;
    const disabledButton = !(dateFrom && dateTo);

    return (
      <GridContainer className="filters">
        <GridItem xs={12} sm={3} className="date-reassing">
          <DateInput
            id="date-filter-from"
            className="date-filter"
            text={t('dashboardprofesional.filters.from')}
            minDate
            min={dateFrom}
            value={dateFrom}
            onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
            error={errorEndDate}
          />
        </GridItem>

        <GridItem xs={12} sm={3} className="date-reassing">
          <DateInput
            id="date-filter-to"
            className="date-filter"
            text={t('dashboardprofesional.filters.to')}
            minDate
            min={dateFrom}
            value={dateTo}
            onChangeValue={(value) => this.handleValue(value, 'dateTo')}
            error={errorStartDate}
          />
        </GridItem>

        <GridItem xs={12} sm={4}>
          <ChipFilters
            chips={chips}
            onChangeChips={this.onChangeChips}
            customers={customers}
            filterData={filterData}
            professionals={professionals}
            appointments={appointments}
          />
        </GridItem>
  
      <GridItem className="filters-actions" xs={12} sm={2} md={2}>
        <ComponentDisabledWithTooltip
          tooltipText={t('reassignAppointments.buttonTooltip')}
          disabled={disabledButton}
          loading={loading}
          component={
            <ButtonSpinner
              id="button-search"
              className={disabledButton ? 'buttonDisabled' : ''}
              onClick={() => this.sendFilters()}
              disabled={disabledButton || loading}
              label={t('dashboard.filters.search')}
              labelLoading={t('dashboard.filters.searching')}
              loading={loading}
              typeButton="submit"
              color="primary"
              block
            />
          }
        />
        <Button id="button-clear" block onClick={() => this.cleanFilters()} color="danger">
          {t('dashboard.filters.clean')}
        </Button>
      </GridItem>
    </GridContainer>
    )
  }
}

Filters.defaultProps = {
  chips: [],
  loading: false,
  onChangeChips: () => {},
  filterData: {
    dateFrom: '',
    dateTo: '',
    practicesType: [],
  },
};

Filters.propTypes = {
  t: PropTypes.func,
  chips: PropTypes.array,
  onChangeChips: PropTypes.func,
  onChange: PropTypes.func,
  onClearFilters: PropTypes.func,
  loading: PropTypes.bool,
  filterData: PropTypes.objectOf(PropTypes.any),
  customers: PropTypes.array,
  professionals: PropTypes.array,
};

export default withTranslation()(Filters);
