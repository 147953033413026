import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function ItineraryOutlined(props) {
  return (
    <SvgIcon width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M120 863 c-46 -24 -80 -76 -80 -123 0 -34 86 -223 125 -274 15 -20 15 -20 30 0 8 10 40 70 71 131 49 97 56 118 51 153 -13 101 -112 157 -197 113z m100 -83 c11 -11 20 -27 20 -36 0 -21 -51 -134 -60 -134 -9 0 -60 113 -60 134 0 22 36 56 60 56 11 0 29 -9 40 -20z" />
        <path d="M720 463 c-46 -24 -80 -76 -80 -123 0 -34 86 -223 125 -274 15 -20 15 -20 30 0 8 10 40 70 71 131 49 97 56 118 51 153 -13 101 -112 157 -197 113z m100 -83 c11 -11 20 -27 20 -36 0 -21 -51 -134 -60 -134 -9 0 -60 113 -60 134 0 22 36 56 60 56 11 0 29 -9 40 -20z" />
        <path d="M160 320 l0 -40 40 0 40 0 0 40 0 40 -40 0 -40 0 0 -40z" />
        <path d="M320 320 l0 -40 40 0 40 0 0 40 0 40 -40 0 -40 0 0 -40z" />
        <path d="M480 320 l0 -40 40 0 40 0 0 40 0 40 -40 0 -40 0 0 -40z" />
      </g>
    </SvgIcon>
  );
}
