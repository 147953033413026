import { connect } from 'react-redux';
import DashboardOperative from '../components/DashboardsPB/DashboardOperativeindex';

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const DashboardOperativeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardOperative);

export default DashboardOperativeService;
