import './SelectInput.css';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Check } from '@material-ui/icons';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import customCheckboxRadioSwitch from '../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(customCheckboxRadioSwitch);
const filter = createFilterOptions();

const SelectInput = (props) => {
  const {
    disabled,
    elements,
    onGetOptionLabel,
    onGetOptionSelected,
    label,
    multi,
    onSelectedValue,
    invalid,
    errorText,
    valueAccessor,
    disableCloseOnSelect,
    onInputChange,
    placeholder,
    isWaitLetters,
    freeSolo,
    onFocus,
    onBlur,
    id,
    margin,
    t,
  } = props;
  const classesCheck = useStyles();

  const [internalState, setInternalState] = useState([]);
  
  const emitValue = (event, value) => {
    setInternalState(value);
    if (!value) {
      onSelectedValue('');
      return;
    }
    if(value[valueAccessor]) {
      onSelectedValue(value[valueAccessor]);
    } else if (value?.length) {
      onSelectedValue(value.map(v => v[valueAccessor]));
    }
  }

  useEffect(() => {
    if(props.multi) {
      if (props.value) {
        setInternalState(props.elements.filter(e => props.value.indexOf(e[valueAccessor]) > -1));
      }
    } else {
      const valueId = props.value && typeof props.value === 'object' ? props.value[valueAccessor] : props.value;
      setInternalState(props?.elements?.find(e => e && e[valueAccessor] === valueId));
    }
  }, [props.value, props.elements])

  const isAdorned = (propsInput) => props.isAdornedStart ?
    {
      ...propsInput,
      startAdornment: (
        <InputAdornment position="start">
          <props.iconAdornedStart />
        </InputAdornment>
      )
    } : { ...propsInput };

  return (
    <GridContainer className={`select-input ${props.className}`}>
      <GridItem xs={12} sm={7} className="select-input-container">
        {multi ?
          <Autocomplete
            id={id}
            disabled={disabled}
            options={elements}
            getOptionLabel={onGetOptionLabel}
            getOptionSelected={onGetOptionSelected}
            disableCloseOnSelect={disableCloseOnSelect}
            renderInput={params => (
              <TextField
                {...params}
                autoComplete='off'
                label={label}
                placeholder={placeholder}
                fullWidth
              />
            )}
            renderOption={(option, { selected }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checkedIcon={<Check className={classesCheck.checkedIcon} />}
                    icon={<Check className={classesCheck.uncheckedIcon} />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    classes={{
                      checked: classesCheck.checked,
                      root: classesCheck.checkRoot,
                  }}
                  />
                }
                label={option.name || option.value}
                classes={{ label: classesCheck.label, root: 'check-select' }}
              />
            )}
            onChange={emitValue}
            onInputChange={onInputChange}
            multiple={multi}
            value={internalState}
          /> :
          <Autocomplete
            
            id={id}
            disabled={disabled}
            freeSolo={freeSolo}
            options={elements}
            getOptionLabel={onGetOptionLabel}
            getOptionSelected={onGetOptionSelected}
            noOptionsText={t('common.noOptions')}
            clearOnBlur={true}
            onFocus={onFocus}
            onBlur={onBlur}
            renderInput={params => (
              <TextField
                style={margin ? {marginTop:'5px'}:{}}
                {...params}
                label={<Typography variant="body2"noWrap>{label}</Typography>}
                placeholder={placeholder}
                fullWidth
                InputProps={isAdorned(params.InputProps)}
                error={invalid}
              />
            )}
            onChange={emitValue}
            onInputChange={onInputChange}
            multiple={multi}
            value={internalState}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.length > 0;
              if (isWaitLetters && (!inputValue || inputValue.length <= 2)) {
                filtered.push({
                  inputValue,
                  value: t('common.waitingCharacters', { number: 3 }),
                });
              }
              if (inputValue && inputValue.length >= 3 && !isExisting) {
                filtered.push({
                  inputValue,
                  value: t('common.noResultsMatching', { word: inputValue }),
                });
              }
              return filtered;
            }}
          />
        }
        {(invalid && errorText) &&
          <FormHelperText className="helper-error">
            {errorText}
          </FormHelperText>
        }
      </GridItem>
    </GridContainer>
   )
}

SelectInput.defaultProps = {
  onGetOptionLabel: (option) => option ? (option.name || option.value) : '',
  onGetOptionSelected: () => {},
  invalid: false,
  multi: false,
  elements: [],
  className: '',
  disableCloseOnSelect: false,
  value: null,
  valueAccessor: 'id',
  onInputChange: () => {},
  placeholder: '',
  freeSolo: false,
  isWaitLetters: false,
  isAdornedStart: false,
  iconAdornedStart: null,
  id: '',
}

SelectInput.propTypes = {
  t: PropTypes.func,
  label: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
  onSelectedValue: PropTypes.func,
  onInputChange: PropTypes.func,
  onGetOptionSelected: PropTypes.func,
  onGetOptionLabel: PropTypes.func,
  onClose: PropTypes.func,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  multi: PropTypes.bool,
  chips: PropTypes.bool,
  className: PropTypes.string,
  valueAccessor: PropTypes.string,
  placeholder: PropTypes.string,
  freeSolo: PropTypes.bool,
  isWaitLetters: PropTypes.bool,
  isAdornedStart: PropTypes.bool,
  iconAdornedStart: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default withTranslation()(SelectInput);
