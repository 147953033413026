import { connect } from 'react-redux';
import Customers from '../components/Customers';
import { getCustomers, getAddress, getPersonAddress } from '../actions/customers';

const mapStateToProps = (state) => {
    return {
        customers: state.customers.customers,
        address: state.customers.address,
        personAddress: state.customers.personAddress,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCustomers: (customers) => {
            dispatch(getCustomers(customers));
        },
        onGetAddress: (address) => {
            dispatch(getAddress(address));
        },
        onGetPersonAddress: (personAddress) => {
            dispatch(getPersonAddress(personAddress));
        },
    }
}

const CustomersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Customers);

export default CustomersService;
