import { isArray } from "lodash";

const getAuth = (company) => {
    let auth = company.authorities;
    if (!isArray(company.authorities)) {
        auth = company.authorities.split(",");
    }
    return auth;
}

class Authority {
    

    static hasPermission = (authority) => {
        let result = true;
        let companyStr = localStorage.getItem('company');
        if (!companyStr) {
            return result;
        }
        const company = JSON.parse(companyStr);
        const auth = getAuth(company);

        if (company?.authorities && authority && isArray(auth)) {
            const splitAuthority = authority.split(",")
            for (let item of splitAuthority) {
                try {
                    result = item ? company.authorities.split(",").includes(item) : true;
                } catch (Exception) {
                    return false;
                }
                if (result) {
                    break;
                }
            }
        }
        return result;
    }
}

export default Authority;
