import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

const HomeRepairService = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18 16H16V15H8V16H6V15H2V20H22V15H18V16Z" fill={props.fill} />
      <path d="M20 8H17V6C17 4.9 16.1 4 15 4H9C7.9 4 7 4.9 7 6V8H4C2.9 8 2 8.9 2 10V14H6V12H8V14H16V12H18V14H22V10C22 8.9 21.1 8 20 8ZM15 8H9V6H15V8Z" fill={props.fill} />
    </SvgIcon>
  );
}

HomeRepairService.propTypes = {
  fill: PropTypes.string,
};

HomeRepairService.defaultProps = {
  fill: "white",
};

export default HomeRepairService;
