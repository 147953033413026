import { EndType, RecurrenceType, RequestStatus, RequestType, ScheduleRequest } from "../domain";

import { ApiScheduleRequestFromMap } from "./api-schedule-request.from-map";

export class ApiScheduleRequestFromMapper {
  fromMap(map: ApiScheduleRequestFromMap): ScheduleRequest {
    const schedule = new ScheduleRequest({
      id: map.appointmentItemRequestId,
      order: map.order,
      requestType: map.itemRequestDetails ? RequestType.PRACTICE : RequestType.PACKAGE,
      startDate: map.startDate,
      endDate: map.providerEndDate,
      requestStatus: map.appointmentItemRequestStatus as RequestStatus,
      companyIdPartnership: {
        companyId: map.companyIdPartnership.companyId,
        name: map.companyIdPartnership.name,
      },
      practiceTypeId: map.practiceTypeId ?? undefined,
      packageId: map.packageId ?? undefined,
      recurrence: {
        id: map.schedulePlan?.schedulePlanId,
        every: map.schedulePlan?.every,
        endType: map.schedulePlan.endType as EndType,
        recurrenceType: this._getRecurrenceTypeFromMap(map.schedulePlan?.recurrenceType),
        ocurrences: map.schedulePlan?.ocurrences,
        weekDays: map.schedulePlan?.weekDays,
        monthDay: map.schedulePlan?.monthDay,
      },
    });
    return schedule;
  }

  private _getRecurrenceTypeFromMap(map: string): RecurrenceType | null {
    switch (map) {
      case RecurrenceTypeMap.DAY:
        return RecurrenceType.DAY;
      case RecurrenceTypeMap.WEEK:
        return RecurrenceType.WEEK;
      case RecurrenceTypeMap.MONTH:
        return RecurrenceType.MONTH;
      case RecurrenceTypeMap.NULL:
        return RecurrenceType.NULL;
      default:
        return null;
    }
  }
}

export enum RecurrenceTypeMap {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  NULL = 'null',
}
