import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { FormControlLabel, Checkbox, Tooltip, TextField, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import CustomSweetAlert from '../CustomSweetAlert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import './EditPracticeTypes.css';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import config from '../../config/config';

class EditPracticeType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genericName: '',
            customName: '',
            specialities: [],
            types: [],
            condition: [],
            selectedCondition: '',
            specialty: '',
            detail: '',
            duration: 0,
            code: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            save: false,
            refresh: true,
            practiceType: 0,
            active: false,
            showWarningDialog: false,
            busy: false,
        }
    }

    componentWillMount() {
        if (this.props.specialties?.length > 0) {
            const specialities = this.formatSpecialties(this.props.specialties);
            this.setState({
                specialities,
                types: this.props.specialties,
            });
        } else {
            this.getSpecialtiesData();
        }
        this.findPractice();
    }

    componentWillReceiveProps(next) {
        if (next.specialties) {
            const specialities = this.formatSpecialties(next.specialties);
            this.setState({
                specialities,
                types: next.specialties,
            });
        }
    }

    formatSpecialties(specialities) {
        const result = specialities.map(d => {
            return {
                id: d.practiceTypeGroupId,
                value: d.name,
            };
        });
        return result;
    }

    getSpecialtiesData() {
        AppointmentApiInvoker.getPracticeTypesGroup(data => {
            this.setState({ specialities: this.formatSpecialties(data) });
        }, null);
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                browserHistory.push('/practice-types');
            }
        }, 5000);
    }

    handleValue(value, state) {

        const regex = config.regex_normal;
        if (regex.test(value)) {
            return;
        }

        this.setState({
            [state]: value,
            refresh: false,
        });
    }

    findPractice() {
        AppointmentApiInvoker.getCompanyPracticeType(this.props.routeParams.id, practice => {
            const duration =  `${moment.utc().startOf('day').add({ minutes: practice.estimatedDuration }).format('HH:mm')} ${this.props.t('estimated_minutes')}`;
            this.setState({
                id: practice.companyPracticeTypeId,
                active: practice.active,
                specialty: practice.practiceTypeGroup,
                genericName: practice.practiceName,
                duration,
                code: practice.practiceTypeCode,
                practiceType: practice.practiceType,
                customName: practice.practiceTypeName,
                companyId: practice.companyId,
            });
        }, null);
    }

    evaludateAndSave = () => {
        this.setState({ showWarningDialog: false,  save: true, busy: true });
        if (!this.state.specialty || this.state.specialty === '') {
            this.setState({ busy: false });
            this.openAlert('warning', this.props.t('tools.edit.warning'));
            return;
        }
        if (!this.state.active || this.state.active === false) {
            this.setState({ showWarningDialog: true, busy: false });
        } else {
            this.savePractice();
        }
    }

    savePractice() {
        this.setState({ busy: true });
        const { active, code, companyId, customName, genericName, id, practiceType, specialty } = this.state; 
        const customNameData = customName && customName.trim().length > 0 ? customName : genericName;
        const body = {
            practiceType: practiceType,
            practiceTypeGroup: specialty,
            practiceTypeCode: (code ? code.trim() : ''),
            practiceTypeName: (customNameData ? customNameData.trim() : ''),
            active: !active ? false : active,
            companyId: companyId,
        };

        if (id !== 0) {
            body.companyPracticeTypeId = id;
        }

        AppointmentApiInvoker.postCompanyPracticeType(body, (data) => {
            this.openAlert('success', this.props.t('practices.success'));
        }, err => {
            console.error('** Error on save practice', err);
            this.openAlert('danger', err.message);
            this.setState({ busy: false });
        });
    }

    back() {
        browserHistory.goBack();
    }

    render() {
        const { t, classes } = this.props;
        const {
            active, alertColor, alertMessage, alertOpen, busy, duration,
            genericName, save, specialities, specialty, showWarningDialog, 
        } = this.state;

        return (
            <GridContainer className="edit-practice">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <EditIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('practices.edit.title')}</h3>
                        </CardHeader>
                        <CardBody className="edit-practice-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <ValidationInput
                                            id="regular"
                                            type="text"
                                            disabled
                                            text={t('practices.genericName')}
                                            onChangeValue={(value) => this.handleValue(value, 'genericName')}
                                            value={genericName}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <ValidationInput
                                            text={t('practices.duration')}
                                            disabled
                                            onChangeValue={(value) => this.handleValue(value, 'duration')}
                                            value={duration}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <Tooltip title={`${t('practicetype.name.tooltip')}`} aria-label="custom name" arrow>
                                            <div className="input-form-text-field  no-padding">
                                                <ValidationInput
                                                    fullWidth
                                                    text={t('practices.customName')}
                                                    value={this.state.customName}
                                                    onChangeValue={(value) => this.handleValue(value, 'customName')}
                                                />
                                            </div>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div className="input-form-text-field no-padding">
                                            <ValidationInput
                                                fullWidth
                                                text={t('practices.code')}
                                                value={this.state.code}
                                                onChangeValue={(value) => this.handleValue(value, 'code')}
                                                />
                                            </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <SelectInput
                                            label={`${t('practices.specility')} *`}
                                            elements={specialities}
                                            onSelectedValue={(value) => this.handleValue(value, 'specialty')}
                                            value={specialty}
                                            invalid={(!specialty || specialty === '') && save} errorText={t('error.required')}
                                        />
                                    </GridItem>
                                    <GridItem className="label-checkbox" xs={12} sm={12} md={5}>
                                        <FormControlLabel
                                            value="start"
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    checked={active}
                                                    onChange={(value) => this.handleValue(value.target.checked, 'active')}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            label={t('practices.active')}
                                            labelPlacement="start"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className="new-practices-submit padding-horizontal">
                            <Button id="button-cancel" onClick={this.back.bind(this)}>
                                {t('practices.cancel')}
                            </Button>

                            <ButtonSpinner
                                id="button-save"
                                onClick={() => this.evaludateAndSave()}
                                disabled={busy}
                                label={t('practices.save')}
                                labelLoading={t('common.saving')}
                                loading={busy}
                                typeButton="submit"
                                color="primary"
                            />
                        </CardFooter>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>
                {showWarningDialog &&
                    <CustomSweetAlert
                        type="warning"
                        title={t('practices.save.warning.title')}
                        onConfirm={() => this.savePractice()}
                        showCancel={false}
                        showConfirm={false}
                        onCancel={() => this.setState({ showWarningDialog: false })}
                        message={
                            <>
                                <p>{t('practices.save.warning.message')}</p>
                                <div className="customAlertButtons">
                                    <Button id="button-alert-cancel" color="danger" onClick={() => this.setState({ showWarningDialog: false })}>
                                        {t('common.cancel')}
                                    </Button>
                                    <ButtonSpinner
                                        id="button-alert-save"
                                        onClick={() => this.savePractice()}
                                        disabled={busy}
                                        label={t('common.save')}
                                        labelLoading={t('common.saving')}
                                        loading={busy}
                                        typeButton="submit"
                                        color="primary"
                                    />
                                </div>
                            </>
                        }
                    />
                }
            </GridContainer>
        )
    }
}

EditPracticeType.propTypes = {
    specialties: PropTypes.array,
    routeParams: PropTypes.object,
    t: PropTypes.func,
    classes: PropTypes.object,
}

export default withStyles(styles)(withTranslation()(EditPracticeType));
