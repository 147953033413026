import React, { FC, useEffect, useState } from 'react';
import { SnackbarColor, TermsPolicyType } from '../../../util/interfaces';
import { TermsPolicyProfile, useTermsAndPolicy } from '../../../hooks';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../CustomButtons/Button';
import CheckInput from '../../CheckInput';
import { CircularProgress } from '@material-ui/core';
import CustomSweetAlert from '../../CustomSweetAlert';
import { DataCloseTerms } from '../../TermsAndPolicyOnUpdated/TermsAndConditionsForm';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import LetterAvatar from '../../Avatar/LetterAvatar';
import PropTypes from 'prop-types';
import Snackbar from '../../Snackbar/Snackbar';
import TermsAndPolicyModal from '../../TermsAndPolicyModal';
import { useStylesLoadProfile } from './useStylesLoadProfile';

type ComponentProps = {
  data: TermsPolicyProfile;
  onCloseTermsPolicy: (data: DataCloseTerms) => void;
}

type TermsPolicy = {
  show: boolean;
  policyText?: string;
  termsText?: string;
  type: TermsPolicyType;
}

const initTermsPolicy = {
  show: false,
  type: TermsPolicyType.TERMS_POLICY,
}

const LoadProfile: FC<ComponentProps> = ({ data, onCloseTermsPolicy }) => {
  const { t } = useTranslation();
  const classes = useStylesLoadProfile();

  const [acceptCheck, setAcceptCheck] = useState<boolean>(false);
  const [termsPolicy, setTermsPolicy] = useState<TermsPolicy>(initTermsPolicy);
  const [loading, setLoading] = useState<boolean>(true);

  const handleClose = (): void => setTermsPolicy((prev) => ({ ...prev, show: false }));

  const hookParams = {
    onCloseTerms: handleClose,
    onCloseModal: handleClose,
    isLoggedUser: false,
  };
  
  const {
    snackbar,
    busy,
    updateTermsPolicy,
    alertWarning,
    openSnackbar,
    setAlertWarning,
  } = useTermsAndPolicy(hookParams);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
    
    return () => {
      setAcceptCheck(false);
      setTermsPolicy(initTermsPolicy);
      setLoading(true);
    }
  }, [])

  const handleTermsPolicy = async (isAccept: boolean = true): Promise<void> => {
    if (!acceptCheck) {
      const message = t('termsPolicy.checkWarning');
      openSnackbar(SnackbarColor.warning, message);
      return;
    }
    if (!isAccept) {
      onRejectTermsPolicyPolicy();
      return;
    }
    await updateTermsPolicy(isAccept, termsPolicy.type);
    onCloseTermsPolicy({ isAccept });
  }

  const onRejectTermsPolicyPolicy = (): void => {
    const message = t('termsPolicy.rejectedWarning.message');
    const title = t('termsPolicy.rejectedWarning.title');
    setAlertWarning({ show: true, message, title });
  }

  const onConfirmRejectedWarning = async (): Promise<void> => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
    await updateTermsPolicy(false, termsPolicy.type);
    setTimeout(() => {
      onCloseTermsPolicy({ isAccept: false });
    }, 1000);
  }

  const onCancelRejectedWarning = (): void => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
  }

  const buildButtonAccept = (): JSX.Element => {
    const acceptText = t('common.saving');
    return <Button
      color="primary"
      onClick={() => handleTermsPolicy()}
      id="button-accept"
      disabled={busy}
    >
      {busy
        ? <><CircularProgress className={classes.progressButton} size={18} /> <span className="labelCont">{acceptText}</span></>
        : t('common.accept')
      }
    </Button>
  }
  
  const renderTermsPolicyToAccept = (): JSX.Element => {
    const label = <Trans
      i18nKey="termsPolicy.checkTextAccept"
      values={{ termsText: t('termsUse.mainTitle'), policyText: t('policy.mainTitle') }}
      components={[
        <Button key="1" link onClick={() => setTermsPolicy({ show: true, type: TermsPolicyType.TERMS })} />,
        <Button key="2" link onClick={() => setTermsPolicy({ show: true, type: TermsPolicyType.POLICY })} />,
      ]}
    />
    const text = t('termsPolicy.text');

    return (
      <>
        <Snackbar
          place="tr"
          color={snackbar.color}
          message={snackbar.message}
          open={snackbar.open}
        />
        <GridContainer>
          <GridItem xs={12}><p className={classes.textTop}>{text}</p></GridItem>
          <GridItem xs={12} className={classes.checkInput}>
            <CheckInput
              onChangeValue={() => setAcceptCheck(!acceptCheck)}
              checked={acceptCheck}
              label={label}
              labelPlacement="end"
            />
          </GridItem>
          <GridItem xs={12} className={classes.submitTerms}>
            <Button
              color="danger"
              onClick={() => handleTermsPolicy(false)}
              id="button-reject"
            >{t('common.reject')}</Button>
            {buildButtonAccept()}
          </GridItem>
        </GridContainer>
      </>
    )
  }

  const welcomeText = t('login.welcome');
  return (
    <>
      <div className={classes.loadProfile}>
        <LetterAvatar user={`${data?.firstName} ${data?.lastName}`} />
        <span className="loading-profile-welcome">{welcomeText}</span>
        <span className="loading-profile-username">
          {`${data?.firstName} ${data?.lastName}`}
        </span>
        {(!data.requiereTermsAccept || loading)
          ? <CircularProgress className="circular-progress" />
          : renderTermsPolicyToAccept()
        }
      </div>
      {termsPolicy.show && <TermsAndPolicyModal
        open={termsPolicy.show}
        type={termsPolicy.type}
        onCloseTerms={() => handleClose()}
        onCloseModal={() => handleClose()}
        showButtons={false}
        isLoggedUser={false}
      />}
      {alertWarning.show &&
        <CustomSweetAlert
          className={classes.alertWarning}
          type="warning"
          confirmBtnCssClass="danger"
          confirmBtnText={t('common.reject')}
          onConfirm={() => onConfirmRejectedWarning()}
          showCancel={true}
          cancelBtnText={t('common.cancel')}
          onCancel={() => onCancelRejectedWarning()}
          message={
            <>
              {alertWarning?.title && <h5>{alertWarning?.title}</h5>}
              <p>{alertWarning.message}</p>
            </>
          }
        />
      }
    </>
  )
}

LoadProfile.propTypes = {
  onCloseTermsPolicy: PropTypes.func.isRequired,
}

export default LoadProfile;
