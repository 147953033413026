import './NewToolType.css';

import React, { Component } from 'react';

import Add from '@material-ui/icons/Add';
import ApiInvoker from '../../api/ApiInvoker';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';

class NewToolType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            toolTypeCode: '',
            id: '',
            documentType: '',
            detail: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            save: false,
            busy: false,
        }
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                browserHistory.push(`/tool-types/`);
                this.cleanFields();
            }
        }, 5000);
    }

    typeClick() {
        this.setState({ save: true, busy: true });
        const isName = this.state.name.trim();
        if (isName && this.state.toolTypeCode && this.state.toolTypeCode.trim()) {
            ApiInvoker.postToolType({
                id: this.state.toolTypeId,
                name: this.state.name,
                toolTypeCode: this.state.toolTypeCode,
                detail: this.state.detail,
                metadata: {},
                companyId: this.state.companyId,
                qr: ''
            }, data => {
                this.setState({ busy: false });
                this.props.onAddToolType(data);
                this.openAlert('success', this.props.t('supplycatalog.new.succes'), data);
            }, null);
        } else {
            this.setState({ busy: false });
            this.openAlert('warning', this.props.t('supplycatalog.new.warning'));
        }
    }

    cleanFields() {
        this.setState({
            name: '',
            toolTypeCode: '',
            detail: '',
            save: false,
            busy: false,
        })
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
    }

    back() {        
        browserHistory.goBack();
    }

    render() {
        const { t } = this.props;
        return (
            <GridContainer className="new-tooltype">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <Add />
                            </CardIcon>
                            <h3 className="card-icon-title">{t("tooltypes.new.title")}</h3>
                        </CardHeader>
                        <CardBody className="new-tooltype-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('tooltypes.new.toolTypeCode')} onChangeValue={(value) => this.handleValue(value, 'toolTypeCode')} value={this.state.toolTypeCode} invalid={!(this.state.toolTypeCode.trim()) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('tooltypes.new.name')} onChangeValue={(value) => this.handleValue(value, 'name')} value={this.state.name} invalid={!(this.state.name.trim()) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('tooltypes.new.detail')}  onChangeValue={(value) => this.handleValue(value, 'detail')} value={this.state.detail}  />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className="new-tooltype-submit padding-horizontal">
                            <Button id="button-cancel" onClick={this.back.bind(this)}>
                                {t('supplycatalog.new.cancel')}
                            </Button>
                            <ButtonSpinner
                                id="button-save"
                                onClick={this.typeClick.bind(this)}
                                disabled={this.state.busy}
                                label={t('tooltypes.new.save')}
                                labelLoading={t('tooltypes.new.saving')}
                                loading={this.state.busy}
                                typeButton="submit"
                                color="primary"
                            />
                        </CardFooter>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

NewToolType.propTypes = {
    t: PropTypes.func,
    onAddToolType: PropTypes.func.isRequired,
}

export default withTranslation()(NewToolType);
