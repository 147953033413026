import { CoreException, Either, VoidSuccess } from "../../../../sdk";

import { Profile } from "../../domain/profile";
import { TermPolicy } from "../../domain/term-policy";

export abstract class UsersRepository {
  abstract confirmUser(hash: string, companyId: string): Promise<Either<CoreException, string>>;
  abstract getProfile(): Promise<Either<CoreException, Profile>>;
  abstract getTermsAndPolicy(version: string): Promise<Either<CoreException, TermPolicy>>;
  abstract updateUserTerms(employeeId: number, version: string, isAccept: boolean): Promise<Either<CoreException, VoidSuccess>>;
}
