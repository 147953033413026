import BaseInvoker from './BaseInvoker';
import { Component } from 'react';

const urlInvoke = `/api`;
const urlInvokeMobility = `/api/mobility`;
const baseInvoker = new BaseInvoker();

export default class DriverApiInvoker extends Component {

	static getVehicleDriver(vehicleId, callback, callerror) {
		const url = `${urlInvokeMobility}/driver/company/vehicle/${vehicleId}/get`
		baseInvoker.get(url, callback, callerror);
	}
	static getDriverVehicle( callback, callerror) {
		const url = `${urlInvokeMobility}/driver/vehicle/get`
		baseInvoker.get(url, callback, callerror);
	}

	static getAssignedDriver(vehicleId, callback, callerror) {
		const url = `${urlInvokeMobility}/drivervehicle/${vehicleId}/get`
		baseInvoker.get(url, callback, callerror);
	}

	static postAddDriverToVehicle(vehicleId,driverId,body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/driver/${driverId}/vehicle/${vehicleId}/add`, body, callback, callerror);
	}

	static deleteDriverToVehicle(personId, callback, callerror) {
		const url = `${urlInvokeMobility}/driver/vehicle/delete/${personId}`;
		baseInvoker.delete(url, {}, callback, callerror);
	}

	static putEditVehicle(body, callback, callerror) {
		baseInvoker.put(`${urlInvokeMobility}/vehicle/update/`, body, callback, callerror);
	}

	static createVehicle(body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/vehicle/add/`, body, callback, callerror);
	}

	static getDriverVehicleByIdDriver(driverId, callback, callerror) {
		const url = `${urlInvokeMobility}/driver/vehicle/${driverId}/get`
		baseInvoker.get(url, callback, callerror);
	}

	static vehicleSaveCompany(body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/company/vehicle/save`, body, callback, callerror);
	}

	static getDocuments(vehicleId, callback, callerror) {
		const url = `${urlInvokeMobility}/vehicle/get/${vehicleId}`
		baseInvoker.get(url, callback, callerror);
	}

	static getDriverPersonalDocument(driverId, callback, callerror) {
		const url = `${urlInvokeMobility}/driver-documents/${driverId}/`
		baseInvoker.get(url, callback, callerror);
	}

	static updateStatusDocumentoToVehicle(idVehicle, approvedTitleFile, aproveedSecureFile, aproveedVerificationFile, aproveedTargetFile, body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/documents/vehicle/${idVehicle}/${approvedTitleFile}/${aproveedSecureFile}/${aproveedVerificationFile}/${aproveedTargetFile}`, body, callback, callerror);
	}

	static putDocumentsDriver(driverId,body, callback, callerror) {
		baseInvoker.put(`${urlInvokeMobility}/documents/driver/${driverId}`, body, callback, callerror);
	}

}