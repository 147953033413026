import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'timepicker';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import './TimePickerGCalendar.css';

class TimePickerGCalendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
    }

    this.timePickerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== '') {
      this.setState({ value: this.props.value });
    }

    this.initTimePicker();
  }

  initTimePicker() {
    let defaultOptions = {
      timeFormat: 'h:i a',
      maxTime: '11:59 pm',
    }

    if (this.props.beginLimit) {
      defaultOptions = {
        ...defaultOptions,
        minTime: this.props.beginLimit,
      }
    }

    if (this.props.endLimit) {
      defaultOptions = {
        ...defaultOptions,
        maxTime: this.props.endLimit,
      }
    }
    $(`#${this.props.name}`).timepicker(defaultOptions)
    $(this.timePickerRef.current).on('change', e => this.handleChange(e))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: moment(this.props.value, ['hh:mm a', 'HH:mm']).format('hh:mm a') });
    }
  }

  handleChange = (e) => {
    const regex = /^[0-9:\b]{1,5}$/;
    const { value } = e.target;

    if (!value || regex.test(value)) {
      this.setState({ value });
    }
  }

  handleOnBlur = (e) => {
    this.handleChange(e);
    this.props.onSelectedValue(e.target.value);
  }

  render() {
    const { defaultValue, disabled, errorText, invalid, name, text } = this.props;

    return (
      <GridContainer className="select-input time-picker">
        <GridItem xs={12} sm={7}>
          <FormControl fullWidth>
            <TextField
              ref={this.timePickerRef}
              id={name}
              label={text}
              error={invalid}
              helperText={errorText}
              onChange={e => this.handleChange(e)}
              value={this.state.value}              
              defaultValue={defaultValue}
              onBlur={(e) => this.handleOnBlur(e)}
              disabled={disabled}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
    )
  }
}

TimePickerGCalendar.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  defaultValue: PropTypes.string,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  onSelectedValue: PropTypes.func,
  beginLimit: PropTypes.string,
  endLimit: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default TimePickerGCalendar
