import { RecurrenceType } from "./appointment-item";

export class ScheduleRequest {
  id: number;
  order: number;
  requestType: RequestType;
  requestStatus: RequestStatus;
  startDate: string;
  endDate: string;
  companyIdPartnership: CompanyPartnership;
  practiceTypeId?: number;
  packageId?: number;
  recurrence: Recurrence;

  constructor({
    id, order, requestType, startDate, endDate, requestStatus, companyIdPartnership, practiceTypeId, packageId, recurrence,
  }: ScheduleRequestDto) {
    this.id = id;
    this.order = order;
    this.requestType = requestType;
    this.requestStatus = requestStatus;
    this.startDate = startDate;
    this.endDate = endDate;
    this.companyIdPartnership = companyIdPartnership;
    this.practiceTypeId = practiceTypeId;
    this.packageId = packageId;
    this.recurrence = recurrence;
  }
}

export type CompanyPartnership = {
  companyId: number;
  name: string;
}

export interface ScheduleRequestDto {
  id: number;
  order: number;
  requestType: RequestType;
  requestStatus: RequestStatus;
  startDate: string;
  endDate: string;
  companyIdPartnership: CompanyPartnership;
  practiceTypeId?: number;
  packageId?: number;
  recurrence: Recurrence;
}
export type Recurrence = {
  id: number | null;
  every: number;
  recurrenceType: RecurrenceType | null;
  endType: EndType;
  ocurrences: number | null;
  weekDays: string | null;
  monthDay: number | null;
}

export enum RequestStatus {
  REJECT = 'REJECT',
  APROVEED = 'APROVEED',
  CREATED = 'CREATED',
  SCHEDULES_ASSIGNED = 'SCHEDULES_ASSIGNED',
  CANCELED = 'CANCELED',
}
export enum RequestType {
  PACKAGE = 'PACKAGE',
  PRACTICE = 'PRACTICE',
}
export enum EndType {
  DATE = 'DATE',
  OCURRENCES = 'OCURRENCES',
};

