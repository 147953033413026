import './TechnicalFile.css';

import React, { useState } from 'react'
import { Tab, Tabs, } from '@material-ui/core';

import Authority from '../../util/Authority';
import Button from '../CustomButtons/Button'
import DateRangeIcon from '@material-ui/icons/DateRange';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import HeartWithPulse from '../../icons/HeartWithPulse';
import PropTypes from 'prop-types';
import TechnicalFileForm from './Form/Form';
import TechnicalFileVitalSigns from './VitalSigns/VitalSigns';
import { browserHistory } from 'react-router';
import useStyles from '../../assets/components/useStylesTabsNav';
import { withTranslation } from 'react-i18next';

const TechnicalFile = (props) => {
  const { t, location, routeParams } = props;
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState('visits-history');

  const changeTabHandler = (event, value) => setActiveTab(value);

  const onBack = () => browserHistory.goBack();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Tabs
          className={classes.tabsNav}
          value={activeTab}
          onChange={changeTabHandler}
          centered
        >
          <Tab
            className={classes.tabNav}
            icon={<DateRangeIcon />}
            label={t('technicalfile.title')}
            value="visits-history"
            selected={activeTab === 'visits-history'}
          />
          {Authority.hasPermission('customers_view_mr_view_schedule_vital_signs')
            ? <Tab
              className={classes.tabNav}
              icon={<HeartWithPulse />}
              label={t('technicalfile.signsHistory')}
              value="vital-signs-history"
              selected={activeTab === 'vital-signs-history'}
            />
            : ''
          }
        </Tabs>
      </GridItem>
      <GridItem container xs={12}>
        {activeTab === 'visits-history'
          ? <TechnicalFileForm
              mode={location.state.mode}
              appointmentId={routeParams.appointmentId}
              patientId={routeParams.patientId}
            />
          : <TechnicalFileVitalSigns
              appointmentId={routeParams.appointmentId}
              patientId={routeParams.patientId}
            />
        }
      </GridItem>
      <GridItem className="actions-buttons text-center">
        <Button id="button-back" onClick={() => onBack()}>
          {t('appointment.new.back')}
        </Button>
      </GridItem>
    </GridContainer>
  )
}

TechnicalFile.propTypes = {
  t: PropTypes.func,
  location: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withTranslation()(TechnicalFile);
