import { ApiGetTermsAndPolicyFromMap } from "./api-get-terms-and-policy.from-map";
import { TermPolicy } from "../domain";

export class ApiGetTermsAndPolicyFromMapper {
  fromMap(map: ApiGetTermsAndPolicyFromMap): TermPolicy {
    const term = new TermPolicy({
      id: map.termsAndConditionId,
      companyId: map.companyId.companyId,
      contentTerms: map.contentTerms,
      contentPolicy: map.contentPolicy,
      version: map.version,
    });
    return term;
  }
}
