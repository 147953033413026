import { Delete, InfoOutlined } from '@material-ui/icons';
import React, { Component } from 'react';

import Button from '../../CustomButtons/Button';
import ConfirmAlert from '../../ConfirmAlert';
import CustomDialog from '../../CustomDialog';
import Fees from './Fees';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class EmployeeType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employeeId: null,
            employeeType: [],
            personId: '',
            loading: true,
            deleteAlert: false,
            success: false,
            error: false,
            selectedTypeId: null,
            selectedTypeName: '',
            openFees: false,
        }
    }

    componentWillReceiveProps(next) {        
        if (next.personId && next.refresh) {
            PersonApiInvoker.getEmployeeEmployeeType(next.personId, (data) => {
                const employeeType = this.formatType(data);
                this.setState({ employeeType, loading: false, employeeId: next.personId });
            });
        }
    }

    infoTextData = (data) => {
        const { t } = this.props;
        return data.length === 1 ? t('employees.infoPractice', { practice: data })
            : t('employees.infoPractices', { practices: data.join(', ') });
    }

    buildInfoData = (data) => {
        const { t } = this.props;
        const practicesTypeInactive = data.practiceType.filter((f) => f.active === false).map((p) => p.name);
        const infoText = this.infoTextData(practicesTypeInactive);
        const configuration = t('configuration.practice');
        return data.hasPracticeNotActive ? 
        <Tooltip title={<div><span>{infoText}</span><br/><span>{configuration}</span></div>} aria-label="info" placement="right-end"><InfoOutlined className="infoIcon" /></Tooltip> : <div/>
    }

    onOpenFees(typeId, name) {
        this.setState({ openFees: true, selectedTypeName: name, selectedTypeId: typeId });
    }
    
    onCloseFees() {
        this.setState({ openFees: false, selectedTypeName: '', selectedTypeId: null });
    }

    buildEmployeeTypeActions = (data) => {
        const { t } = this.props;
        const { employeeTypeId, employeeTypeName } = data;
        return <>
            <Tooltip title={t('common.fees')}>
                <span>
                    <Button
                        id={`button-fees-${employeeTypeId}`}
                        simple justIcon
                        color="success"
                        onClick={() => this.onOpenFees(employeeTypeId, employeeTypeName)}
                        authority="fees"
                    ><LocalAtmIcon /></Button>
                </span>
            </Tooltip>
            <Tooltip title={t('remove')}>
                <span>
                    <Button
                        id={`button-delete-${employeeTypeId}`}
                        simple justIcon
                        color="danger"
                        onClick={() => this.onDeleteEmployeeType(employeeTypeId, employeeTypeName)}
                    ><Delete /></Button>
                </span>
            </Tooltip>
        </>
    }

    formatType = (data) => {
        const result = data.map((d) => {
            const practiceTypeGroupList = d.practiceTypeGroup?.length ? d.practiceTypeGroup.map((p) => p.name).join(', ')  : '';
            const practiceTypeList = d.practiceType?.length ? d.practiceType.map((p) => p.name).join(', ') : '';
            const info = this.buildInfoData(d);
            return {
                info,
                employeeTypeId: d.employeeTypeId,
                employeeType: d.employeeTypeName,
                practiceTypeGroup: practiceTypeGroupList,
                praticeType: practiceTypeList,
                actions: this.buildEmployeeTypeActions(d),
            }
        });
        return result;
    }

    onDeleteEmployeeType(typeId, name) {
        this.setState({ selectedTypeName: name, selectedTypeId: typeId });
        
        setTimeout(() => {
            this.setState({ deleteAlert: true });
        });
    }

    deleteItem() {
        const { employeeType, selectedTypeId } = this.state;
        PersonApiInvoker.deleteEmployeeType(this.props.personId, selectedTypeId, (data) => {
            const newEmployeeType = employeeType.filter((f) => f.employeeTypeId !== selectedTypeId);
            this.props.onDeleteItem(selectedTypeId);
            this.setState({ success: true, employeeType: newEmployeeType });
        }, () => {
            this.setState({ error: true });
        });
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
            selectedTypeName: '',
            selectedTypeId: null,
        });
    }

    onSuccess = () => {
        this.setState({
            deleteAlert: false,
            success: false,
            selectedTypeName: '',
            selectedTypeId: null,
        });
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: '', accessor: 'info', width: 50 },
            { Header: t('employees.practiceTypeGroup'), accessor: 'practiceTypeGroup' },
            { Header: t('employees.praticeType'), accessor: 'praticeType' },
        ];
        if (componenMode !== 'view') {
            tableHead.push({ Header: t('employee.new.actions'), accessor: 'actions', sortable: false, width: 150 });
        }
        return tableHead;
    }

    render() {
        const { t } = this.props;
        const { deleteAlert, employeeId, employeeType, loading, success, error, selectedTypeId, selectedTypeName, openFees } = this.state;
        return (
            <>
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={employeeType > 5 ? 20 : 5}
                    sortable={false}
                    tableHead={this.renderTableHead()}
                    tableData={employeeType}
                    colorsColls={["primary"]}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                />

                <CustomDialog
                    title={t('fees.title.practiceType', { name: selectedTypeName })}
                    maxWidth="md"
                    open={openFees}
                    onClose={() => this.onCloseFees()}
                    className="fees-dialog"
                >
                    <Fees
                        practiceTypeName={selectedTypeName}
                        employeeTypeId={selectedTypeId}
                        employeeId={employeeId}
                    />
                </CustomDialog>
                
                <ConfirmAlert
                    text={`${t('employee.type.confirm')} ${selectedTypeName}`}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    onSuccess={this.onSuccess}
                    show={deleteAlert}
                    success={success}
                    error={error}
                />
            </>
        )
    }
}

EmployeeType.defaultProps = {
    componenMode: '',
    personId: '',
    onDeleteItem: () => {},
}

EmployeeType.propTypes = {
    t: PropTypes.func,
    componenMode: PropTypes.string,
    personId: PropTypes.number,
    onDeleteItem: PropTypes.func,
}

export default withTranslation()(EmployeeType);
