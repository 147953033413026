import '../../IntegralDashboard/integralDashboard.css';

import React, { Component } from 'react';

import AppointmentApiInvoker from '../../../../../api/AppointmentApiInvoker';
import Button from '../../../../CustomButtons/Button';
import ChipFilters from './ChipFilters';
import DateInput from '../../../../DateInput';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import PersonApiInvoker from '../../../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class IntegralFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            professionals: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            errorStartDate: false,
            errorEndDate: false,
            employeesType: [],
            oemployeesType: [],
            employees: [],
        }
    }

    componentDidMount() {
        if (this.props.employees?.length) {
            this.setState({
                employees: this.props.employees,
                professionals: this.formatEmployees(this.props.employees),
            })
        } else {
            this.getEmployees();
        }
        if (this.props.employeesType?.length) {
            this.setState({
                employeesType: this.props.employeesType,
                oemployeesType: this.formatEmployeesType(this.props.employeesType),
            });
        } else {
            this.getEmployeeTypes();
        } 
    }

    componentWillReceiveProps(next) {
        if (next.employees?.length) {
            this.setState({
                employees: next.employees,
                professionals: this.formatEmployees(next.employees),
            });
        }
        if (next.employeesType?.length) {
            this.setState({
                employeesType: next.employeesType,
                oemployeesType: this.formatEmployeesType(next.employees),
            });
        }
    }

    formatEmployees(employees) {
        if(!employees?.length) {
            return [];
        }
        return employees.map(e => ({
            id: e.personId,
            value: `${e.lastName} ${e.firstName}`,
        })).sort((a, b) => a.value.localeCompare(b.value));
    }

    formatEmployeeTypes(employeesType) {
        if (employeesType?.length) {
            return employeesType.map(e => ({
                id: e.practiceTypeId,
                value: e.name,
            }));
        }
    }

    getEmployeeTypes() {
        AppointmentApiInvoker.getPracticeTypesAll(practices => {
            if (practices?.length) {
                this.props.onGetEmployeesType(practices);
                this.setState({
                    employeesType: this.formatEmployeeTypes(practices),
                    oemployeesType: practices,
                });
            }
        }, (error) => {
            console.error('Error on onGetEmployeesType: ', error);
        });
    }

    getEmployees() {
        PersonApiInvoker.getEmployees(employees => {
            if (employees?.length) {
                this.props.onGetEmployees(employees);
            }
        }, (error) => {
            console.error('** Error on getEmployees', error);
        });
    }

    handleValue = (value, state) => {
        if (state === 'dateFrom') {
            this.setState({ errorStartDate: false });
        }
        if (state === 'dateTo') {
            this.setState({ errorEndDate: false });
        }
        this.setState({ [state]: value });
    }

    sendFilters() {
        let errorStartDate = false;
        if (!this.state.dateFrom) {
            errorStartDate = true;
        }
        let errorEndDate = false;
        if (!this.state.dateTo) {
            errorEndDate = true;
        }

        if (errorStartDate || errorEndDate) {
            this.setState({errorStartDate: errorStartDate, errorEndDate: errorEndDate});
        } else {
            this.props.onChange({
                start_date: this.state.dateFrom,
                end_date: this.state.dateTo,
            });
        }
    }

    onChangeChips = (index, chips) => this.props.onChangeChips({ chips: chips });

    cleanFilters() {
        this.setState({
            professional: [],
            dateFrom: '',
            dateTo: '',
        }, () => {
            this.props.onClearFilters();
        });
    }

    render() {
        const { chips, employees, t } = this.props;
        const { dateFrom, dateTo, employeesType, errorStartDate, errorEndDate } = this.state;
        return (
            <GridContainer className="filters" justify="center">
                <GridItem xs={12} sm={3} md={2} className="date-no-padding">
                    <DateInput
                        text={t('dashboardcustomer.filters.from')}
                        value={dateFrom}
                        onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
                        error={errorStartDate}
                        isRequired={true}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={2} className="date-no-padding">
                    <DateInput
                        text={t('dashboardcustomer.filters.to')}
                        minDate
                        min={dateFrom}
                        value={dateTo}
                        onChangeValue={(value) => this.handleValue(value, 'dateTo')}
                        error={errorEndDate}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={6}> 
                    <ChipFilters 
                        chips={chips}
                        onChangeChips={this.onChangeChips}
                        employeesType={employeesType}
                        employees={this.formatEmployees(employees)}
                    />
                </GridItem>
                <GridItem className="filters-actions" xs={12} sm={2} md={2}>
                    <Button id="button-filters-search" disabled={!dateFrom || !dateTo} block onClick={() => this.sendFilters()} color="primary">
                        {t('dashboard.filters.search')}
                    </Button>
                    <Button id="button-filters-clean" block onClick={() => this.cleanFilters()} color="danger">
                        {t('dashboard.filters.clean')}
                    </Button>
                </GridItem>
        </GridContainer>
        )
    }
}

IntegralFilters.propTypes = {
    t: PropTypes.func,
    onGetEmployees: PropTypes.func.isRequired,
    onGetEmployeesType: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    employeesType: PropTypes.array,
    onChangeChips: PropTypes.func,
    chips: PropTypes.array,
}

export default withTranslation()(IntegralFilters);
