import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

const MapComponent = compose(
    withProps({
        googleMapURL: '/googlemap',
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ position: 'absolute', width: '100%', height: '100%' }} />,
        mapElement: <div style={{ height: '100%' }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={16}
        center={{
            lat: Number(props.lat) || -31.41070438150723,
            lng: Number(props.lng) || -64.19620923199496,
        }}>
        <Marker position={{ lat: Number(props.lat), lng: Number(props.lng) }} />
    </GoogleMap>
));

export default MapComponent;
