import { SCHEDULES, SCHEDULES_STATUS, SCHEDULES_TYPES, SAVE_NEW_SCHEDULE } from "../actions/schedules";

const schedules = (state = {}, action) => {
    switch(action.type) {
        case SCHEDULES:
            return {...state, schedules: action.schedules}
        case SCHEDULES_STATUS: {
            let status = action.status
            return {...state, status}
        }
        case SCHEDULES_TYPES: {
            let types = action.types
            return {...state, types}
        }
        case SAVE_NEW_SCHEDULE: {
            let result = addSchedule(state.schedules, action.schedule)
            return {...state, schedules: result}
        }
        default:
            return state;
    }
}

const addSchedule = (scheds, schedule) => {
    if (scheds && schedule && scheds.length) {
        scheds.push(schedule);
        return scheds;
    }
}

export default schedules;