import './SuggestedProfessional.css';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Component } from 'react';

import { Check } from '@material-ui/icons';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import RadioInput from '../RadioInput';
import SelectInput from '../SelectInput';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

class SuggestedProfessional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: '',
      radioselected: '1',
      filerByZone: true,
      personId: null,
    }
  }

  componentDidMount() {
    const { itemRequestDetails, practiceType } = this.props;
    if (itemRequestDetails) {
      this.setState({
        personId: itemRequestDetails.suggestedEmployeeId,
        filerByZone: itemRequestDetails.filerByZone,
        order: itemRequestDetails.order,
      });
      this.getEmployees(practiceType, itemRequestDetails.filerByZone);
    }
  }

  componentWillReceiveProps(next) {
    if (!this.props.inline) {
      if (next.practiceType) {
        if (next.practiceType !== this.state.practiceType) {
          this.setState({ practiceType: next.practiceType });
          this.getEmployees(next.practiceType, true);
        }
      }
      if (next.itemRequestDetails) {
        this.setState({
          practiceTypeId: next.itemRequestDetails.practiceTypeId,
          filerByZone: next.itemRequestDetails.filerByZone,
          personId: next.itemRequestDetails.suggestedEmployeeId,
          radioselected: (next.itemRequestDetails.suggestedEmployeeId ? '2' : '1'),
          orer: next.itemRequestDetails.order,
        });
        if (next.itemRequestDetails.filerByZone !== this.state.filerByZone) {
          this.getEmployees(next.itemRequestDetails.practiceTypeId, next.itemRequestDetails.filerByZone);
        }
      }
    }
  }

  getEmployees = (practiceType, checked) => {
    const { customer, lender } = this.props;
    if (practiceType) {
      let zone = null;
      if (checked) {
        zone = (customer?.geographicZoneId ?? customer?.zone);
      }
      PersonApiInvoker.getEmployeesByPracticetypeAndGeographics(lender, practiceType, zone, data => {
        const vv = this.formatEmployees(data);
        this.setState({ professionals: vv });
      }, null);
    }
  }

  formatEmployees = (employees) => {
    if (!employees.length) {
      return [];
    }
    return employees.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
  }

  changeSelectedValue = (value) => {
    this.setState({ personId: value });
    if (this.props.hasShift) {
      this.props.onChangeValue({
        suggestedEmployeeId: value,
        filerByZone: this.state.filerByZone,
        packageDetailId: this.props.packageDetailId,
        practiceTypeId: this.props.practiceType,
        order: this.state.order,
      }, 'itemRequestDetails');
    } else {
      this.props.onChangeValue({
        suggestedEmployeeId: value,
        filerByZone: this.state.filerByZone,
        packageDetailId: this.props.packageDetailId,
        practiceTypeId: this.props.practiceType,
      }, 'itemRequestDetails');
    }
  }

  handleGeographics = (value) => {
    this.setState({ filerByZone: value.target.checked, personId: null, professionals: [] });
    this.getEmployees(this.props.practiceType, value.target.checked);

    if (this.props.hasShift) {
      this.props.onChangeValue({
        suggestedEmployeeId: null,
        filerByZone: value.target.checked,
        packageDetailId: this.props.packageDetailId,
        practiceTypeId: this.props.practiceType,
        order: (this.props.hasShift ? this.props.order : null),
      }, 'itemRequestDetails');
    } else {
      this.props.onChangeValue({
        suggestedEmployeeId: null,
        filerByZone: value.target.checked,
        packageDetailId: this.props.packageDetailId,
        practiceTypeId: this.props.practiceType,
      }, 'itemRequestDetails');
    }
  }

  changeRadio = (value) => {
    this.setState({ radioselected: value });
    if (value === '1') {
      this.setState({ personId: null });
      this.props.onChangeValue({
        suggestedEmployeeId: null,
        filerByZone: this.state.filerByZone,
        packageDetailId: this.props.packageDetailId,
        practiceTypeId: this.props.practiceType,
        order: (this.props.hasShift ? this.props.order : null),
      }, 'itemRequestDetails');
    }
  }

  renderComponent = (id) => {
    const { t, classes, checkInvalid, disabled, order } = this.props;
    const { filerByZone, professionals, responsable } = this.state;
    return (
      <>
        <GridItem xs={12}>
          <div className="padding-horizontal">
            <FormControlLabel
              className="checkLabel geographics"
              control={
                <Checkbox
                  id={`filer-by-zone-${order}`}
                  tabIndex={-1}
                  checked={filerByZone}
                  onChange={this.handleGeographics}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('suggestedprofessional.geographics')}
              classes={{ label: classes.label, root: 'check-label' }}
            />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <SelectInput
            id="select-employees"
            disabled={disabled}
            label={t('suggestedprofessionals.professional')}
            elements={professionals}
            onSelectedValue={this.changeSelectedValue}
            value={id}
            invalid={!responsable && checkInvalid}
            errorText={!responsable && checkInvalid ? t('error.required') : ''}
          />
        </GridItem>
      </>)
    ;
  }

  render() {
    const { t, inline, itemRequestDetails } = this.props;
    const { radioselected, personId } = this.state;

    const radioInputElements = [{
      id: '1',
      value: t('suggestedprofessional.suggested_system'),
      component: true,
    }, {
      id: '2',
      value: t('suggestedprofessional.selected'),
      component: false,
    }];

    const itemRequestDtls = itemRequestDetails?.suggestedEmployeeId;

    return (
      inline ? (
        <GridContainer className="speciality">
          {this.renderComponent(itemRequestDtls || personId)}
        </GridContainer>
      ) : (
        <GridContainer className="suggested-employee">
          <GridItem className="base-font" xs={12}>
            <h4 className="padding-horizontal">{t('suggestedprofessional.title')}</h4>
            <RadioInput
              id="suggested-employees"
              onChangeValue={value => this.changeRadio(value)}
              elements={radioInputElements}
              value={radioselected}
            />
          </GridItem>
          {radioselected === '2' && this.renderComponent(personId)}
        </GridContainer>
      )
    )
  }
}

SuggestedProfessional.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object,
  checkInvalid: PropTypes.bool,
  itemRequestDetails: PropTypes.object,
  practiceType: PropTypes.string,
  inline: PropTypes.bool,
  customer: PropTypes.object,
  lender: PropTypes.object,
  hasShift: PropTypes.bool,
  onChangeValue: PropTypes.func,
  packageDetailId: PropTypes.number,
  disabled: PropTypes.any,
  order: PropTypes.number,
}

export default withStyles(styles)(withTranslation()(SuggestedProfessional));
