import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { PracticeFee, PracticeFeeRequest } from "../../domain";
import { inject, injectable } from "inversify";

import { PracticeFeesRepository } from "../repositories/practice-fees.repository";

@injectable()
export class CreateFeeUsecase {

  @inject(PracticeFeesRepository) protected repository!: PracticeFeesRepository;

  async execute(data: PracticeFeeRequest): Promise<Either<CreateFeeError, PracticeFee[]>> {
    if (!data) {
      return Either.left(new CreateFeeInvalidDataError());
    }
    const result = await this.repository.createFee(data);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): CreateFeeError {
    if (exception instanceof NotFoundException) {
      return new CreateFeeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new CreateFeeConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new CreateFeeOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new CreateFeeAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new CreateFeeBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in CreateFeeUsecase");
  }
}

export abstract class CreateFeeError {
  error?: string;
}

export class CreateFeeInvalidDataError extends CreateFeeError { }
export class CreateFeeNotFoundError extends CreateFeeError { }
export class CreateFeeConnectionError extends CreateFeeError { }
export class CreateFeeOperationalError extends CreateFeeError { }
export class CreateFeeAuthorizationError extends CreateFeeError { }
export class CreateFeeBadRequestError extends CreateFeeError {
  constructor(public readonly error: string) {
    super();
  }
}
