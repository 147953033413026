import '../TechnicalFile.css';

import { CircularProgress, FormLabel, Grid, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Assignment from '@material-ui/icons/Assignment';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CustomInput from '../../CustomInput/CustomInput';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { HealthBook } from '../../../icons';
import HotelIcon from '@material-ui/icons/Hotel';
import PropTypes from 'prop-types';
import RadioInput from '../../RadioInput';
import Table from '../../Table/Table';
import ViewList from '@material-ui/icons/ViewList';
import Work from '@material-ui/icons/Work';
import { browserHistory } from 'react-router';
import config from '../../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateTimeSmallFormat();

const TechnicalFile = (props) => {
  const { t, appointmentId, mode, patientId } = props;

  const castToInt = (fn) => value => {
    fn(parseInt(value));
    setHasChange(true);
  }

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [somnambulism, setSomnambulism] = useState(0);
  const [sensibility, setSensibility] = useState(1);
  const [sphincterControl, setSphincterControl] = useState(0);
  const [hasTubes, setHasTubes] = useState(0);
  const [notes, setNotes] = useState('');
  const [hasChange, setHasChange] = useState(false);
  const [technicalFile, setTechnicalFile] = useState({
    id: null,
    startDate: '',
    endDate: '',
    financier: '',
    affiliateId: '',
    metadata: {
      medicalData: {
        requestor: {
          name: '',
          licenseNumber: '',
          telephone: '',
          homeMedicalCenter: '',
        },
        auditor: {
          name: '',
          licenseNumber: '',
          telephone: '',
        },
        family: {
          name: '',
          telephone: '',
          observations: '',
        },
      },
      diagnostic: {
        principalDiagnostic: {
          id: null,
          description: '',
          category: '',
          subcategory: '',
        },
        hospitalizationDiagnostic: {
          id: null,
          description: '',
          category: '',
          subcategory: '',
        },
        complexity: {
          id: null,
          description: '',
        },
        barthelIndex: {
          id: null,
          description: '',
        },
        observations: '',
      },
      disability: {
        activeDisabilityCertificate: false,
        disabledPatient: false,
        disabilityCertificateCode: null,
      },
      somnambulism: null,
      sensibility: null,
      sphincterControl: null,
      hasTubes: null,
      notes: null,
    },
    specialitiesList: [],
    customer: {
      personId: null,
      firstName: '',
      lastName: '',
      idType: {
        companyId: null,
        idTypeId: null,
        name: '',
        type: '',
      },
      id: '',
      birthdate: '',
      email: '',
      avatarUrl: '',
      companyId: null,
      metadata: {},
      verificationId: null,
      edad: '',
    },
    schedulesList: [],
  });
  const [hasTubesWhitch, setHasTubesWhitch] = useState('');

  useEffect(() => {
    fetchTechnicalFile();
  }, []);

  useEffect(()=>{
    setDisabled(mode === 'view');
  },[mode]);

  const fetchTechnicalFile = async () => {
    setIsLoading(true);
    AppointmentApiInvoker.getAppointmentTechnicalFile(
      appointmentId,
      data => {
        setIsLoading(false);
        if (data) {
          setTechnicalFile(data);
          setSomnambulism(data.metadata.somnambulism > 0 ? 1 : 0);
          setSensibility(data.metadata.sensibility > 0 ? 1 : 0);
          setSphincterControl(data.metadata.sphincterControl > 0 ? 1 : 0);
          setHasTubes(data.metadata.hasTubes ? data.metadata.hasTubes : 0);
          setHasTubesWhitch(data.metadata.hasTubesWhitch);
          setNotes(data.metadata.notes);
          setHasChange(false);
        } else {
          console.error('** empty data!!');
          setHasChange(false);
        }
      }, error => {
        console.error(error);
        setHasChange(false);
      }
    );
  }

  const updateTechnicalFile = () => {
    const body = technicalFile.metadata;
    body.somnambulism = somnambulism;
    body.sensibility = sensibility;
    body.sphincterControl = sphincterControl;
    body.hasTubes = hasTubes ?? 0;
    body.notes = notes;
    
    if (hasTubes !== 4 ) {
      body.hasTubesWhitch = '';
      setHasTubesWhitch('');
    } else {
      body.hasTubesWhitch = hasTubesWhitch;
    }
    setIsLoadingUpdate(true);
    AppointmentApiInvoker.updateAppointmentTechnicalFile(
      appointmentId,
      body,
      data => {
        setIsLoadingUpdate(false);
        setHasChange(false);
      }, error => {
        console.error(error);
      }
    );
  }

  const pushMedicalRecord = (order) => browserHistory.push(`/patient/${patientId}/medical-record/sign-vitals/${order}`)

  const formatTableDataSchedules = (data) => {
    if (!technicalFile?.schedulesList) {
      return [];
    } 
    return data.map((d) => {
      return { 
        ...d,
        scheduleFormatDateTime: moment(d.scheduleDateTime).format(dateFormat),
        state: t(`technicalfile.state.${d.state}`),
        actions: <Tooltip title={t('common.view')}>
          <span>
            <Button
              key={`key-${patientId}-${appointmentId}-${d.order}`}
              id={`button-view-${patientId}-${appointmentId}-${d.order}`}
              simple
              justIcon
              color="primary"
              authority="customers_view_mr_view_schedule_evo"
              onClick={() => pushMedicalRecord(d.order)}
            >
              <Assignment />
            </Button>
          </span>
        </Tooltip>
      }
    });
  }

  const formatTableDataSpecialities = (data) => {
    if (!technicalFile.specialitiesList.length) {
      return [];
    }
    return data.map(d => ({
      speciality: d.practiceTypeGroupName,
      practice: d.practiceName,
    }));
  }

  const sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if(a1 < b1)
      return 1;
    else if(a1 > b1)
      return -1;
    else
      return 0;
  }

  const customerName = `${technicalFile?.customer?.lastName} ${technicalFile?.customer?.firstName}`;

  return (
    isLoading 
      ? <GridItem xs={12}>
        <Card>
          <CardBody>
            <div className="progressContent">
              <CircularProgress />
            </div>
          </CardBody>
        </Card>
      </GridItem>
    : <>
      <GridItem className="customer-info" xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <HotelIcon />
            </CardIcon>
          </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('technicalfile.lastNameAndName')}
                    value={customerName}
                    inputProps={{ shrink: !!(customerName) }}
                    formControlProps={{
                      disabled: true,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('technicalfile.docType')}
                    value={technicalFile?.customer?.idType?.name}
                    inputProps={{ shrink: !!(technicalFile?.customer?.idType?.name) }}
                    formControlProps={{
                      disabled: true,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('technicalfile.docNumber')}
                    value={technicalFile.customer?.id} 
                    inputProps={{ shrink: !!(technicalFile.customer?.id) }}
                    formControlProps={{
                      disabled: true,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('technicalfile.age')}
                    value={technicalFile.customer?.edad} 
                    inputProps={{ shrink: !!(technicalFile.customer?.edad) }}
                    formControlProps={{
                      disabled: true,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('technicalfile.nationality')}
                    value={technicalFile.customer?.nationality?.name} 
                    inputProps={{ shrink: !!(technicalFile.customer?.nationality?.name) }}
                    formControlProps={{
                      disabled: true,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
      </GridItem>
      <GridItem xs={12} className="appointment-info">
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <Assignment/>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('technicalfile.apppointmentId')}
                    value={technicalFile.id}
                    formControlProps={{
                      disabled: true,
                      fullWidth: false,
                    }}
                    inputProps={{ shrink: !!(technicalFile.id) }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('appointment.find.appointmentStatus')}
                    value={t(`appointment.table.status.${technicalFile.appointmentStatus}`)}
                    formControlProps={{
                      disabled: true,
                      fullWidth: false,
                    }}
                    inputProps={{ shrink: !!(technicalFile.appointmentStatus) }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('appointment.find.startDate')}
                  value={moment(technicalFile.startDate).format(dateFormat)} 
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </Grid>
              {/* Fecha Fin Prestacion */}
              <Grid item xs={6} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('appointment.find.endDate')}
                  value={moment(technicalFile.endDate).format(dateFormat)}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem className="financer-table" xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <Work />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer>
            {/* Financiador */}
            <GridItem xs={6} sm={6}>
              <CustomInput
                classes={{
                  input: "input-technical-file",
                }}
                labelText={t('technicalfile.financer')}
                value={technicalFile.financier}
                formControlProps={{
                  disabled: true,
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                classes={{
                  input: "input-technical-file",
                }}
                labelText={t('technicalfile.customerId')}
                value={technicalFile.affiliateId} 
                formControlProps={{
                  disabled: true,
                  fullWidth: true,
                }}
              />
            </GridItem>
            </GridContainer>
            <GridContainer>
              {/* Medico Solicitante */}
              <GridItem xs={12} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.requestor')}
                  value={technicalFile.metadata?.medicalData?.requestor?.name}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={3}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.licenseNumber')}
                  value={technicalFile.metadata?.medicalData?.requestor?.licenseNumber}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={3}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.phoneNumber')}
                  value={technicalFile.metadata?.medicalData?.requestor?.telephone}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.medicalCenter')}
                  value={technicalFile.metadata?.medicalData?.requestor?.homeMedicalCenter}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              {/* Medico Auditor */}
              <GridItem xs={12} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.auditor')}
                  value={technicalFile.metadata?.medicalData?.auditor?.name}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={3}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.licenseNumber')}
                  value={technicalFile.metadata?.medicalData?.auditor?.licenseNumber}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={3}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.phoneNumber')}
                  value={technicalFile.metadata?.medicalData?.auditor?.telephone}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} className="radio-technical-file margin-top radio-input-inline">
                <p className="radio-legend">
                  {t('technicalfile.hasFamily')}
                </p>
                <RadioInput
                  value={technicalFile?.metadata?.medicalData?.family?.name ? 1 : 2}
                  elements={[
                    { id: 1, value: t('technicalfile.yes'), component: true },
                    { id: 2, value: t('technicalfile.no'), component: false },
                  ]}
                  inputProps={{
                    disabled: true,
                  }}
                />
              </GridItem>
              {technicalFile?.metadata?.medicalData?.family?.name &&
                (<>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      classes={{
                        input: "input-technical-file"
                      }}
                      labelText={t('technicalfile.name')}
                      value={technicalFile.metadata.medicalData.family?.name}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      classes={{
                        input: "input-technical-file",
                      }}
                      labelText={t('technicalfile.phoneNumber')}
                      value={technicalFile.metadata.medicalData.family?.telephone}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      classes={{
                        input: "input-technical-file",
                      }}
                      labelText={t('label.observations')}
                      value={technicalFile.metadata.medicalData.family?.observations}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </>)
              }
            </GridContainer>
            <GridContainer className="margin-top">
              <GridItem xs={12} sm={6} md={5} className="radio-technical-file radio-input-inline">
                <p className="radio-legend">
                  {t('appointment.disabledPatient')}
                </p>
                <RadioInput
                  name="disabledPatient"
                  value={technicalFile.metadata?.disability?.disabledPatient ? '1' : '2'}
                  elements={[
                    { id: '1', value: t('form.option.yes') },
                    { id: '2', value: t('form.option.no') },
                  ]}
                  inputProps={{
                    disabled: true,
                  }}
                />
              </GridItem>
              {technicalFile.metadata?.disability?.disabledPatient && 
                <GridItem xs={12} sm={6} md={5} className="radio-technical-file radio-input-inline">
                  <p className="radio-legend">{t('appointment.activeDisabilityCertificate')}</p>
                  <RadioInput
                    value={technicalFile.metadata?.disability?.activeDisabilityCertificate ? '1' : '2'}
                    elements={[
                      { id: '1', value: t('form.option.yes') },
                      { id: '2', value: t('form.option.no') },
                    ]}
                    inputProps={{
                      disabled: true,
                    }}
                  />
                </GridItem>
              }
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem className="diagnosis" xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <HealthBook />
            </CardIcon>
            <h3 className="card-icon-title">{t('technicalfile.diagnostic')}</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {/* Diagnosticos */}
              <GridItem xs={12} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.principalDiagnostic')}
                  value={technicalFile.metadata?.diagnostic?.principalDiagnostic?.description}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.atentionDiagnostic')}
                  value={technicalFile.metadata?.diagnostic?.hospitalizationDiagnostic?.description}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              {/* Complejidad e Indices */}
              <GridItem xs={6} sm={4}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }} 
                  labelText={t('technicalfile.complexity')} 
                  value={(technicalFile.metadata?.diagnostic?.complexity?.description) ? t(`${technicalFile.metadata?.diagnostic?.complexity?.description}`) : ''} 
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={4}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('technicalfile.barthelIndex')}
                  value={technicalFile.metadata?.diagnostic?.barthelIndex?.description}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  classes={{
                    input: "input-technical-file",
                  }}
                  labelText={t('label.observations')}
                  value={technicalFile.metadata?.diagnostic?.observations}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem className="required-practices" xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <ViewList />
            </CardIcon>
            <h3 className="card-icon-title">{t('technicalfile.requiredSpecialities')}</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <Table
                  loading={isLoading}
                  tableHeaderColor="primary"
                  sortable={false}
                  showPaginationBottom={false}
                  defaultPageSize={7}
                  tableHead={[
                    { Header: t('common.speciality'), accessor: 'speciality' },
                    { Header: t('Practice'), accessor: 'practice' },
                  ]}
                  tableData={formatTableDataSpecialities(technicalFile.specialitiesList)}
                  colorsColls={['primary']}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem className="additional-info" xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <HealthBook />
            </CardIcon>
            <h3 className="card-icon-title">
              {t('technicalfile.aditionalInformation')}
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer className="aditional-information">
              <GridItem className="item-radio" xs={12}>
                <RadioInput
                  inputProps={{
                    disabled,
                  }}
                  onChangeValue={castToInt(setSomnambulism)}
                  title={t('technicalfile.information.somnambulism')}
                  value={(somnambulism)}
                  elements={[
                    { id: 1, label: t('technicalfile.yes'), component: false },
                    { id: 0, label: t('technicalfile.no'), component: false },
                  ]}
                />
              </GridItem>
              <GridItem className="item-radio" xs={12}>
                <RadioInput
                  inputProps={{
                    disabled,
                  }}
                  onChangeValue={castToInt(setSensibility)}
                  title={t('technicalfile.information.sensibility')}
                  value={(sensibility)}
                  elements={[
                    { id: 1, label: t('technicalfile.information.convervative'), component: false },
                    { id: 0, label: t('technicalfile.information.altered'), component: false },
                  ]}
                />
              </GridItem>
              <GridItem className="item-radio" xs={12}>
                <RadioInput
                  inputProps={{
                    disabled,
                  }}
                  onChangeValue={castToInt(setSphincterControl)}
                  title={t('technicalfile.information.sphincterControl')}
                  value={(sphincterControl)}
                  elements={[
                    { id: 1, label: t('technicalfile.yes'), component: false },
                    { id: 0, label: t('technicalfile.no'), component: false },
                  ]}
                />
              </GridItem>
              <GridItem className="item-radio" xs={4} sm={4}>
                <RadioInput
                  inputProps={{
                    disabled
                  }}
                  onChangeValue={castToInt(setHasTubes)}
                  title={t('technicalfile.information.hasTubes')}
                  value={(hasTubes > 0 ? 1 : 0)}
                  elements={[
                    { id: 1, label: t('technicalfile.yes'), component: true },
                    { id: 0, label: t('technicalfile.no'), component: false },
                  ]}
                />
                </GridItem>
                <GridItem className="item-radio" xs={4} sm={4}>
                  {hasTubes < 1 ? ''
                    : <RadioInput
                        inputProps={{
                          disabled
                        }}
                        onChangeValue={castToInt(setHasTubes)}
                        title={t('technicalfile.information.hasTubesDetails')}
                        value={(hasTubes)}
                        elements={[
                          { id: 1, label: t('technicalfile.information.nasogastrica'), component: true },
                          { id: 2, label: t('technicalfile.information.button'), component: true },
                          { id: 3, label: t('technicalfile.information.yes'), component: true },
                          { id: 4, label: t('technicalfile.information.other'), component: false }
                        ]}
                      />
                    }
                </GridItem>
                <GridItem xs={4} sm={4} className="radio-others-container">
                  {hasTubes !== 4 ? '' :
                    <div>
                      <FormLabel className="radio-input-title" component="legend">
                        {t('technicalfile.information.whichone')}
                      </FormLabel>
                      <CustomInput
                        classes={{
                          input: "input-technical-file"
                        }}
                        labelText={t('technicalfile.information.hasTubeWhich')}
                        value={hasTubesWhitch}
                        onChange={(e) => setHasTubesWhitch(e.target.value)}
                        formControlProps={{
                          fullWidth: true,
                          disabled,
                        }}
                      />
                    </div>
                  }
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    classes={{
                      input: "input-technical-file",
                    }}
                    labelText={t('label.observations')}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                      setHasChange(true);
                    }}
                    formControlProps={{
                      fullWidth: true,
                      shrink: !!(notes),
                      disabled,
                    }}
                  />
                </GridItem>
                {!disabled && 
                  <GridItem xs={12}>
                    <ButtonSpinner
                      id="button-save"
                      color="primary"
                      disabled={isLoadingUpdate || !hasChange}
                      label={t('common.save')}
                      labelLoading={t('common.saving')}
                      loading={isLoadingUpdate}
                      typeButton="submit"
                      onClick={updateTechnicalFile}
                    />
                  </GridItem>
                }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem className="medical-visits" xs={12}>
          <Card className="filtrable-table">
            <CardHeader icon>
              <CardIcon color="primary">
                <DateRangeIcon />
              </CardIcon>
              <h3 className="card-icon-title">{t('technicalfile.medicalVisits')}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* Visitas Lista */}
                <GridItem xs={12}>
                  <Table
                    loading={isLoading}
                    tableHeaderColor="primary"
                    sortable
                    filterable
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    tableHead={[
                      { Header: t('technicalfile.table.orden'), accessor: 'order' },
                      { Header: t('technicalfile.table.speciality'), accessor: 'speciality' },
                      { Header: t('technicalfile.table.profesional'), accessor: 'professional' },
                      { Header: t(`technicalfile.table.status`), accessor: 'state' },
                      { Header: t('technicalfile.table.date'), accessor: 'scheduleFormatDateTime', sortMethod: (a, b) => sortDate(a, b) },
                      {
                        Header: t('technicalfile.table.actions'),
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    tableData={formatTableDataSchedules(technicalFile.schedulesList)}
                    colorsColls={['primary']}
                  />
                </GridItem>
              </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </>
  )
}

TechnicalFile.propTypes = {
  t: PropTypes.func,
  mode: PropTypes.string,
  appointmentId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
}

export default withTranslation()(TechnicalFile);
