import React from 'react';
import PropTypes from 'prop-types';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client";
import '../../assets/plugins/react-charts.css';
import './DashboardPB.css';


const PowerBiComponent = ({ reportId, reportToken, lang }) => {
        return (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',
                    id: reportId,
                    accessToken: reportToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        localeSettings: {
                            language: lang,
                            formatLocale: lang,
                        },
                        filterPaneEnabled: false,
                        navContentPaneEnabled: false,
                        layoutType: models.LayoutType.Custom,
                        displayOption: models.DisplayOption.FitToWidth,
                    }
                }}
                cssClassName="report-style-class"
            />
        );
}

PowerBiComponent.defaultProps = {
    lang: 'es',
}

PowerBiComponent.propTypes = {
    reportId: PropTypes.string.isRequired,
    reportToken: PropTypes.string.isRequired,
    lang: PropTypes.string,
}

export default PowerBiComponent;
