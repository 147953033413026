import { CircularProgress, Grid } from '@material-ui/core';
import { GetAppointmentItemsConnectionError, GetAppointmentItemsError, GetAppointmentItemsUsecase, AppointmentItem as Item } from '../../../core';
import React, { useEffect, useState } from 'react';
import { SnackbarColor, SnackbarProps } from '../../../util/interfaces';

import AppointmentItem from './AppointmentItem';
import Button from '../../CustomButtons/Button';
import CustomDialog from '../../CustomDialog';
import { DIContainer } from '../../../Root';
import PropTypes from 'prop-types';
import Snackbar from '../../Snackbar/Snackbar';
import { useStylesAppointmentItemsDetailModal } from './useStylesAppointmentItemsDetailModal';
import { useTranslation } from 'react-i18next';

interface AppointmentItemsDetailProps {
  appointmentId: number;
  onConfirm: (show: boolean) => void;
}

const initialValueSnackbar = {
  color: SnackbarColor.danger,
  message: '',
  open: false,
}

const AppointmentItemsDetailModal = ({ appointmentId, onConfirm }: AppointmentItemsDetailProps) => {
  const { t } = useTranslation();
  const classes = useStylesAppointmentItemsDetailModal();

  const getUsecase = DIContainer.get<GetAppointmentItemsUsecase>(GetAppointmentItemsUsecase);
  
  const [itemsRequests, setItemsRequests] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);

  const openSnackbar = (color: SnackbarColor, message: string) => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
        setSnackbar(initialValueSnackbar);
    }, 5000);
  }

  const getDataErrors = async (dataError: GetAppointmentItemsError) => {
    let message = t('common.errorService');
    if (dataError instanceof GetAppointmentItemsConnectionError) {
      message = t('confirm.error');
    }
    return openSnackbar(SnackbarColor.danger, message);
  }

  const getItemRequest = async () => {
    const result = await getUsecase.execute(appointmentId);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      await getDataErrors(dataError);
      setLoading(false);
      return;
    }

    const resultData = result.getRight();
    setItemsRequests(resultData);
    setLoading(false);
  }

  useEffect(() => {
    getItemRequest();
    return () => {
      setItemsRequests([]);
      setLoading(true);
    }
  }, [])

  const renderItems = () => {
    const message = t('table.empty');
    if (itemsRequests.length > 0) {
      return itemsRequests.map((e: Item, index: number) => <AppointmentItem key={`req-${index +1}`} item={e} />)
    }
    return <li>{message}</li>
  }

  return (
    <>
      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <CustomDialog
        className={classes.dialogPM}
        title={t('appointment.practice.list.dialog.title')}
        maxWidth="sm"
        open={true}
        onClose={() => onConfirm(false)}
      >
        {loading ? 
          <Grid container className={classes.progressContent}>
            <Grid item xs={12}><CircularProgress /></Grid>
          </Grid> :
           <ul className={classes.items}>
            {renderItems()}
          </ul>
        }
        <div className="text-center">
          <Button color="primary" onClick={() => onConfirm(false)}>{t('common.accept')}</Button>
        </div>
      </CustomDialog>
    </>
  )
}

AppointmentItemsDetailModal.propTypes = {
  appointmentId: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default AppointmentItemsDetailModal;
