import { AcceptScheduleRequestUsecase, CompanyPartnership, GetSchedulesRequestUsecase, RequestStatus, ScheduleRequest } from '../../../core';
import { Package, PracticeType } from '../../../util';
import React, { FC, useEffect, useState } from 'react';
import { SnackbarColor, SnackbarProps } from '../../../util/interfaces';

import Button from '../../CustomButtons/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmRejectedItem from './ConfirmRejectedItem';
import CustomSweetAlert from '../../CustomSweetAlert';
import { DIContainer } from '../../../Root';
import PropTypes from 'prop-types';
import Snackbar from '../../Snackbar/Snackbar';
import Table from '../../Table/Table';
import { Tooltip } from '@material-ui/core';
import config from '../../../config/config';
import { initialValueSnackbar } from '../../../util/constants';
import moment from 'moment';
import { useQuantityRequests as setQuantityRequests } from '../../../hooks/useQuantityRequests';
import { useTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();

type Appointment = {
  appointmentId: number;
  endDate: string;
}

type Props = {
  selectedAppointment: Appointment;
  onChangeItemsRequest: (isChange: boolean) => void;
  packages: Package[];
  practiceTypes: PracticeType[];
}

type ItemRequest = {
  order: number;
  startDate: string;
  endDate: string;
  name: string;
  quantity: number;
  lender: string;
  status: string;
  type: string;
  actions?: JSX.Element;
}

const ItemsRequest: FC<Props> = ({ selectedAppointment, onChangeItemsRequest, packages, practiceTypes }) => {
  const { t } = useTranslation();

  const getSchedulesReqUsecase = DIContainer.get<GetSchedulesRequestUsecase>(GetSchedulesRequestUsecase);
  const acceptScheduletUsecase = DIContainer.get<AcceptScheduleRequestUsecase>(AcceptScheduleRequestUsecase);

  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<ItemRequest[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [showConfirmAcceptItem, setShowConfirmAcceptItem] = useState<boolean>(false); 
  const [showConfirmRejectItem, setShowConfirmRejectItem] = useState<boolean>(false); 
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);

  const onAcceptItemAction = (id: number): void => {
    setSelectedItemId(id);
    setShowConfirmAcceptItem(true);
  }

  const onRejectItemAction = (id: number): void => {
    setSelectedItemId(id);
    setShowConfirmRejectItem(true);
  }

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });

    setTimeout(() => {
      setSnackbar((prev) => ({ ...prev, open: false }));
    }, 5000);
  }

  const onAcceptItem = async (): Promise<void> => {
    if (!selectedItemId) {
      return;
    }
    const result = await acceptScheduletUsecase.execute(selectedItemId);
    if (result.isLeft()) {
      console.error('** Error postAcceptItemRequest: ', result.getLeft());
      setShowConfirmAcceptItem(false);
      openSnackbar(SnackbarColor.danger, t('confirm.error'));
      return;
    }
    setShowConfirmAcceptItem(false);
    const data = formatDataTable(result.getRight());
    setItems(data);
    onChangeItemsRequest(true);
    openSnackbar(SnackbarColor.success, t('confirm.success.acceptItem'));
  }

  const onRejectItem = (isRejected: boolean): void => {
    if (!isRejected) {
      return;
    }
    getItemRequest();
    onChangeItemsRequest(true);
  }

  const renderActionItemRequestComponent = (id: number): JSX.Element => {
    const acceptText = t('common.accept');
    const rejectText = t('common.reject');
    return (
      <div className="appointment-action">
        <Tooltip title={acceptText}>
          <span>
            <Button
              id="button-accept"
              simple
              justIcon
              color="success"
              onClick={() => onAcceptItemAction(id)}
            ><CheckIcon /></Button>
          </span>
        </Tooltip>
        <Tooltip title={rejectText}>
          <span>
            <Button
              id="button-reject"
              simple
              justIcon
              color="secondary"
              onClick={() => onRejectItemAction(id)}
            ><ClearIcon style={{ color: 'grey' }} /></Button>
          </span>
        </Tooltip>
      </div>
    );
  }

  const isMeModulePractice = (partnership: CompanyPartnership): boolean => {
    const storedCompanyId = localStorage.getItem('company_partnership_id');
    const isLocalStorageEmpty = storedCompanyId === null;
  
    if (!partnership || isLocalStorageEmpty) {
      return true;
    }
  
    return partnership.companyId === parseInt(storedCompanyId);
  }

  const getLenderName = (partnership: CompanyPartnership): string => {
    const isMe = isMeModulePractice(partnership);
    const lenderName = isMe ? (partnership?.name ?? '') : t('common.other');
    return lenderName;
  }

  const getItemRequestName = (r: ScheduleRequest): string => {
    let practiceName: string = '';
    let packageName: string = '';
    
    if (r.practiceTypeId) {
      practiceName = practiceTypes.find(e => e.practiceTypeId === r.practiceTypeId)?.name ?? '';
    }
    if (r.packageId) {
      packageName = packages.find(e => e.packageId === r.packageId)?.name ?? '';
    }
 
    return practiceName || packageName;
  }

  const formatDataTable = (res: ScheduleRequest[]): ItemRequest[] => {
    const companyPartnershipId = getCompanyPartnershipId();
    const itemsData = res.map((r: ScheduleRequest) => {
      const edit = r.companyIdPartnership && r.companyIdPartnership.companyId === companyPartnershipId;
      const requestType = (r.requestType).toLowerCase();
      const type = t(requestType);
      const name = getItemRequestName(r);
      const appointmentEndDate = moment(selectedAppointment.endDate, dateFormat).format('YYYY-MM-DD');
      const itemStartDate = moment(r.startDate);
      const [ quantity ] = setQuantityRequests(r, itemStartDate, r.endDate, appointmentEndDate);
      const lender = r.companyIdPartnership ? getLenderName(r.companyIdPartnership) : '';
      const status = t(`status.appointmentItemRequest.${r.requestStatus}`);
      const endDate = r.endDate ? moment(r.endDate).format(dateFormat) : selectedAppointment.endDate;

      const data: ItemRequest = {
        order: r.order,
        type,
        name,
        startDate: moment(r.startDate).format(dateFormat),
        endDate,
        quantity,
        lender,
        status,
      }
      if (edit && r.requestStatus === RequestStatus.CREATED) {
        data.actions = renderActionItemRequestComponent(r.id);
      }
      return data;
    });
    return itemsData;
  }

  const getCompanyPartnershipId = () => {
    let companyPartnershipId = -1;
    const companyPartnershipIdStored = localStorage.getItem('company_partnership_id');
    if (companyPartnershipIdStored) {
      companyPartnershipId = parseInt(companyPartnershipIdStored, 10);
    }
  
    if (companyPartnershipId == null || companyPartnershipId === -1) {
      const defaultCompanyIdStored = localStorage.getItem('itlg_default_company_id');
      return defaultCompanyIdStored ? parseInt(defaultCompanyIdStored, 10) : companyPartnershipId;
    }
  
    return companyPartnershipId;
  }
  
  const getItemRequest = async (): Promise<void> => {
    setLoading(true);
    if (!selectedAppointment.appointmentId) {
      setLoading(false);
      return;
    }

    const result = await getSchedulesReqUsecase.execute(selectedAppointment.appointmentId);
    if (result.isLeft()) {
      console.error('** Error getAppointmentItems:', result.getLeft());
      openSnackbar(SnackbarColor.danger, t('confirm.error'));
      setLoading(false);
      return;
    }
    const data = formatDataTable(result.getRight());
    setItems(data);
    setLoading(false);
  }

  useEffect(() => {
    onChangeItemsRequest(false);
    getItemRequest();
    return () => {
      setItems([]);
      onChangeItemsRequest(false);
      setSnackbar(initialValueSnackbar);
    }
  }, [selectedAppointment.appointmentId]);

  const handlerShowConfirmRejectItem = () => {
    setShowConfirmRejectItem(false);
    setSelectedItemId(null);
  }

  const renderTableHead = () => {
    return [
      { Header: t('appointment.table.orden'), accessor: 'order' },
      { Header: t('appointment.new.schedule.practice.lender'), id: 'lender', accessor: 'lender' },
      { Header: t('appointment.table.type'), className: 'text-capitalize', accessor: 'type' },
      { Header: t('appointment.table.name'), accessor: 'name' },
      { Header: t('appointment.table.status'), accessor: 'status' },
      { Header: t('appointment.table.startDate'), accessor: 'startDate' },
      { Header: t('common.endDate'), accessor: 'endDate' },
      { Header: t('appointment.table.quantity'), accessor: 'quantity' },
      { Header: t('appointment.table.actions'), accessor: 'actions' },
    ];
  }

  const tableProps = {
    filterable: true,
    loading,
    tableHeaderColor: 'primary',
    sortable: true,
    tableHead: renderTableHead(),
    tableData: items,
    colorsColls: ['primary'],
    className: 'items-request-component -striped -highlight filtrable sticky',
    defaultPageSize: items.length <= 25 ? 25 : items.length,
    showPaginationTop: false,
    showPaginationBottom: false,
  }

  const acceptMessageText = t('appointment.item.accept.message');

  return (
    <>
      <Table {...tableProps} />

      {showConfirmAcceptItem &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => onAcceptItem()}
          onCancel={() => {
            setShowConfirmAcceptItem(false);
            setSelectedItemId(null);
          }}
          title={t('appointment.item.accept.title')}
          confirmBtnCssClass="primary"
          cancelBtnCssClass="danger"
          cancelBtnText={t('appointment.created.cancel.cancel')}
          confirmBtnText={t('appointment.created.cancel.confirm')}
          showCancel={true}
          message={<p>{acceptMessageText}</p>}
        />
      }

      {showConfirmRejectItem && selectedItemId &&
        <ConfirmRejectedItem
          selectedItemId={selectedItemId}
          setShowConfirmRejectItem={handlerShowConfirmRejectItem}
          onRejectItem={onRejectItem}
        />
      }
      <Snackbar
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
        place="tr"
      />
    </>
  )
}

ItemsRequest.propTypes = {
  selectedAppointment: PropTypes.shape({
    appointmentId: PropTypes.number.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  onChangeItemsRequest: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  practiceTypes: PropTypes.array.isRequired,
}

export default ItemsRequest;
