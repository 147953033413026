/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ButtonSpinner from '../../../components/ButtonSpinner';
import DateInput from '../../../components/DateInput';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import SelectInput from '../../../components/SelectInput';
import ValidationInput from '../../../components/ValidationInput';

const PersonalInfo = (props) => {
  const { data, handleDriverValue, saveDriver, t } = props;
  const { documentTypes, driver, nationalities, genderTypes, mode, save } = data;

  const maxDate = moment().format('YYYY-MM-DD');
  const minDate = moment().subtract(120, 'years').format('YYYY-MM-DD');

  return (
    
      <GridContainer>
        
        <GridItem xs={12} sm={12} md={6}>
          <ValidationInput
            className={(mode === 'view') ? 'viewmode' : ''}
            disabled={mode === 'view'}
            text={t('employee.new.first_name')}
            onChangeValue={(value) => handleDriverValue('firstName', value)}
            value={driver?.firstName}
            invalid={driver?.firstName === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <ValidationInput
            text={t('employee.new.last_name')}
            onChangeValue={(value) => handleDriverValue('lastName', value)}
            value={driver.lastName}
            disabled={mode === 'view'}
            invalid={driver.lastName === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Autocomplete
            disabled={mode === 'view'}
            className="nationality-autocomplete select-input"
            options={nationalities}
            getOptionLabel={(option) => option.name ? option.name : ''}
            getOptionSelected={option => option.id}
            renderInput={params => ( 
              <TextField {...params} label={t('customers.new.nationality')} fullWidth/>
            )}
            onChange={(event, value) => handleDriverValue('nationalityId', value)}
            value={driver.nationalityId}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <SelectInput
            className="select-input"
            disabled={mode === 'view'}
            label={t('customers.new.id_type')}
            elements={documentTypes}
            onSelectedValue={(value) => handleDriverValue('idType', value)}
            value={driver.idType}
            invalid={driver.idType === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <ValidationInput
            text={t('employee.new.identification')}
            type="text"
            onChangeValue={(value) => handleDriverValue('identification', value)}
            value={driver.identification}
            disabled={mode === 'view'}
            invalid={driver.identification === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <SelectInput
            className="select-input"
            disabled={mode === 'view'}
            label={t('customers.new.gender')}
            elements={genderTypes}
            onSelectedValue={(value) => handleDriverValue('sex', value)}
            value={driver.sex}
            invalid={driver.sex === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <DateInput text={t('employee.new.birth_date')}
            minDate
            min={minDate}
            max={maxDate}
            onChangeValue={(value) => handleDriverValue('birthdate', value)}
            disabled={mode === 'view'}
            value={driver.birthdate}
            invalid={driver.birthdate ? driver.birthdate <= maxDate : !driver.birthdate && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <ValidationInput
            text={t('employee.new.email')}
            type="email" onChangeValue={(value) => handleDriverValue('email', value)}
            value={driver.email}
            disabled={mode === 'view'}
          />
        </GridItem>
        {mode !== 'view' &&
          <GridItem xs={12} className="edit-customer-submit flex-end">
            <ButtonSpinner
              onClick={() => saveDriver()}
              
              label={"Editar chofer"}
              labelLoading={t('customers.new.saving')}
         
              typeButton="submit"
              color="primary"
            />
          </GridItem>
        }
      </GridContainer>
    
  )
}
PersonalInfo.propTypes = {
  data: PropTypes.object.isRequired,
  handleDriverValue: PropTypes.func.isRequired,
  saveDriver: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(PersonalInfo);
