import { ADD_TOOL, EDIT_TOOL, TOOLS_DETAIL, TOOLTYPES_DETAIL, UNMOUNT_TOOL } from "../actions/tools";

const tools = (state = {}, action, root) => {
    switch(action.type) {
        case UNMOUNT_TOOL:
            return {}
        case TOOLTYPES_DETAIL: {
            let tooltypes = action.tooltypes
            return {...state, tooltypes}
        }
        case TOOLS_DETAIL: {
            let tools = action.tools
            return {...state, tools}
        }
        case ADD_TOOL: {
            const result = addTool(state.tools, action.tool)
            return {...state, tools: result}
        }
        case EDIT_TOOL: {
            const updated = updateTool(state.tools, action.tool)
            return {...state, tools: updated}
        }
        default:
            return state;
    }
}


const addTool = (tls, tool) => {
    if (tls?.length) {
        tls.push(tool);
        return tls;
    }
}

const updateTool = (tls, tool) => {
    if (tls?.length) {
        tls.map((c, i) => {
            if (c.toolId === tool.toolId) {
                tls[i] = tool;
            }
        });
    }
    return tls;
}

export default tools;
