import { PRACTICE_TYPE_DETAIL, SPECIALITIES_DETAIL, EDIT_PRACTICE_TYPE, UNMOUNT_PRACTICE_TYPE } from "../actions/practiceTypes";

const practiceTypes = (state = {}, action) => {
    switch(action.type) {
        case UNMOUNT_PRACTICE_TYPE:
            return {};
        case SPECIALITIES_DETAIL: {
            let specialities = action.specialities;
            return {...state, specialities}
        }
        case PRACTICE_TYPE_DETAIL:
            return {...state, practiceTypes: action.practiceTypes};
        case EDIT_PRACTICE_TYPE: {
            const updated = updatePracticeTypes(state.practiceTypes, action.practiceType);
            return {...state, practiceTypes: updated};
        }
        default:
            return state;
    }
}

const updatePracticeTypes = (pTypes, practiceType) => {
    if (pTypes?.length) {
        pTypes.map((pt, i) => {
            if (pt.toolId === practiceType.toolId) {
                pTypes[i] = practiceType
            }
            return pt;
        });
    }
    return pTypes;
}

export default practiceTypes;
