import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { CustomerFinancier } from "../../domain";
import { FinanciersRepository } from "../repositories";

@injectable()
export class GetCustomerFinanciersUsecase {
  @inject(FinanciersRepository) protected repository!: FinanciersRepository;

  async execute(personId: number): Promise<Either<GetCustomerFinanciersError, CustomerFinancier[]>> {
    const result = await this.repository.getCustomerFinanciers(personId);
    if (!personId) {
      return Either.left(new GetCustomerFinanciersInvalidDataError());
    }
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetCustomerFinanciersError {
    if (exception instanceof NotFoundException) {
      return new GetCustomerFinanciersNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetCustomerFinanciersConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetCustomerFinanciersOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetCustomerFinanciersAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetCustomerFinanciersBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetCustomerFinanciersUsecase");
  }
}

export abstract class GetCustomerFinanciersError {
  error?: string;
}

export class GetCustomerFinanciersInvalidDataError extends GetCustomerFinanciersError { }
export class GetCustomerFinanciersNotFoundError extends GetCustomerFinanciersError { }
export class GetCustomerFinanciersConnectionError extends GetCustomerFinanciersError { }
export class GetCustomerFinanciersOperationalError extends GetCustomerFinanciersError { }
export class GetCustomerFinanciersAuthorizationError extends GetCustomerFinanciersError { }
export class GetCustomerFinanciersBadRequestError extends GetCustomerFinanciersError {
  constructor(public readonly error: string) {
    super();
  }
}
