interface GeolocationPermissions {
  hasPermission: boolean;
  isBrowserUnsupported: boolean;
}
interface GeolocationData {
  latitude: number | null;
  longitude: number | null;
}

interface Geolocation extends GeolocationPermissions, GeolocationData {}

export const GetGeoLocation = async (): Promise<GeolocationData> => {
  const navigatorGeolocation = navigator.geolocation;

  const getPermissions = async (): Promise<GeolocationPermissions> => {
    let hasPermission = false;
    let isBrowserUnsupported = !navigatorGeolocation;

    if (navigatorGeolocation) {
      try {
        const result = await navigator.permissions.query({ name: "geolocation" });
        hasPermission = result.state !== "denied";
        isBrowserUnsupported = true;
      } catch (error) {
        console.error('Error checking geolocation permissions:', error);
      }
    }

    return { hasPermission, isBrowserUnsupported };
};

  const getCoords = (): Promise<GeolocationData> =>
    new Promise((resolve, reject) => {
      navigatorGeolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        }, (error) => {
          console.error('Error getting geolocation:', error);
          reject(error);
        },
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
      );
    });


  const getAllGeoLocation = async (): Promise<Geolocation> => {
    try {
      const { hasPermission, isBrowserUnsupported } = await getPermissions();
  
      if (!hasPermission || !isBrowserUnsupported) {
        return { latitude: null, longitude: null, hasPermission, isBrowserUnsupported };
      }
  
      const { latitude, longitude } = await getCoords();
  
      return { latitude, longitude, hasPermission, isBrowserUnsupported };
    } catch (error) {
      console.error('Error on getAllGeoLocation:', error);
      throw error;
    }
  };

  return await getAllGeoLocation();
};
