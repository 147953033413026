import React, { Component } from 'react';

import Assignment from '@material-ui/icons/Assignment';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import RecordsApiInvoker from '../../../api/RecordsApiInvoker';
import { TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class DiagnosticForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patologyDetails: {},
      hospitalPatologyDetails: {}
    }
  }

  componentDidMount() {
    this.getPatologies();
    this.getComplexities();
    this.getBarthels();
  }

  getPatologies() {
    RecordsApiInvoker.getAllPatologies(data => {
      this.props.onGetPatologies(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  getComplexities() {
    RecordsApiInvoker.getAllComplexities(data => {
      this.props.onGetComplexities(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  getBarthels() {
    RecordsApiInvoker.getAllBarthel(data => {
      this.props.onGetBarthels(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  componentWillReceiveProps(next) {
    if (next?.patology?.id) {
      this.selectPatology(next.patology);
    }
    if (next?.hospitalPatology?.id) {
      this.selectHospitalPatology(next.hospitalPatology);
    }
  }

  selectPatology(value) {
    if(!value?.id) {
      return;
    }
    RecordsApiInvoker.getPatologyDetails(value.id, data => {
      this.setState({ patologyDetails: data });
    }, () => {
      this.setState({ loading: false });
    });
  }

  selectHospitalPatology(value) {
    if(!value?.id) {
      return;
    }
    RecordsApiInvoker.getPatologyDetails(value.id, data => {
      this.setState({ hospitalPatologyDetails: data });
    }, () => {
      this.setState({ loading: false });
    });
  }


  render() {
    const { t, barthel, complexity, hospitalPatology, observations, patology } = this.props;
    const { hospitalPatologyDetails, patologyDetails, } = this.state;
    return (
      <GridContainer className="diagnostic-form read-only">
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="secondary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <h4 className="margin-top">
                    {t('appointment.new.diagnostic.main-diagnostic')}
                  </h4>
                </GridItem>
                <GridItem sm={4}>
                  <TextField
                    id="main-pathology"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!(patology?.description)}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.patology')}
                    value={(patology?.description)}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <TextField
                    id="main-pathology-cat"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!(patologyDetails?.parent?.name)}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.category')}
                    value={((patologyDetails?.parent?.name))}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <TextField
                    id="main-pathology-subcat"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!(patologyDetails?.parent?.parent?.name)}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.subcategory')}
                    value={(patologyDetails?.parent?.parent?.name)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <h4 className="margin-top">{t('appointment.new.diagnostic.internation-diagnostic')}</h4>
                </GridItem>
                <GridItem sm={4}>
                <TextField
                  id="pathology"
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: !!(hospitalPatology?.description)}}
                  className="diagnostic-form-text-field"
                  label={t('appointment.new.diagnostic.patology')}
                  value={(hospitalPatology?.description)}
                />
                </GridItem>
                <GridItem sm={4}>
                <TextField
                  id="pathology-cat"
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: !!(hospitalPatologyDetails?.parent?.name)}}
                  className="diagnostic-form-text-field"
                  label={t('appointment.new.diagnostic.category')}
                  value={(hospitalPatologyDetails?.parent?.name)}
                />
                </GridItem>
                <GridItem sm={4}>
                <TextField
                  id="pathology-subcat"
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: !!(hospitalPatologyDetails?.parent?.parent?.name)}}
                  className="diagnostic-form-text-field"
                  label={t('appointment.new.diagnostic.subcategory')}
                  value={(hospitalPatologyDetails?.parent?.parent?.name)}
                />
                </GridItem>
              </GridContainer>
              <GridContainer className="diagnostic-form-grid-container">
                <GridItem xs={12}>
                  <h4 className="margin-top">{t('label.observations')} </h4>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <TextField
                    id="diagnostic-complexity"
                    disabled
                    fullWidth
                    className="diagnostic-form-text-field"
                    InputLabelProps={{ shrink: !!(complexity?.description) }}
                    label={t('appointment.new.diagnostic.complexity')}
                    value={complexity?.description && t(t(`appointment.new.diagnostic.complexities.${complexity?.description}`))}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <TextField
                    id="diagnostic-barthel"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!(barthel?.description)}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.barthelIndex')}
                    value={(barthel?.description)}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    id="diagnostic-observations"
                    fullWidth
                    label={t('label.observations')}
                    value={observations}
                    disabled
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

DiagnosticForm.defaultProps = {
  onGetPatologies: () => {},
  onGetComplexities: () => {},
  onGetBarthels: () => {},
  complexity: {},
  barthel: {},
  patology: {},
  observations: '',
}

DiagnosticForm.propTypes = {
  t: PropTypes.func,
  onGetPatologies: PropTypes.func,
  onGetComplexities: PropTypes.func,
  onGetBarthels: PropTypes.func,
  patology: PropTypes.object,
  observations: PropTypes.string,
  barthel: PropTypes.object,
  complexity: PropTypes.object,
  hospitalPatology: PropTypes.object,
}

export default withTranslation()(DiagnosticForm);
