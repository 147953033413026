import { connect } from 'react-redux';
import { getEmployees, getEmployeesType, getFinanciers, getProvincies, getPatologies, getSupplies, getTools } from "../actions/dashboardcustomer";
import DashboardCustomer from '../components/DashboardCustomer';
import Filters from '../components/DashboardCustomer/Filters';

const mapStateToProps = (state) => {
    return {
        employees: state.dashboard.employees,
        employeesType: state.dashboard.employeesType,
        financiers: state.dashboardcustomer.financiers,
        patologies: state.dashboardcustomer.patologies,
        provincies: state.dashboardcustomer.provincies,
        supplies: state.dashboard.supplies,
        tools: state.dashboard.tools,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEmployees: (employees) => {
            dispatch(getEmployees(employees));
        },
        onGetEmployeesType: (employeesType) => {
            dispatch(getEmployeesType(employeesType));
        },
        onGetFinanciers: (financiers) => {
            dispatch(getFinanciers(financiers));
        },
        onGetProvincies: (provincies) => {
            dispatch(getProvincies(provincies));
        },
        onGetPatologies: (patologies) => {
            dispatch(getPatologies(patologies));
        },
        onGetSupplies: (supplies) => {
            dispatch(getSupplies(supplies));
        },
        onGetTools: (tools) => {
            dispatch(getTools(tools));
        }
    }
}

const DashboardCustomerService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardCustomer);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters);

export { DashboardCustomerService, FiltersService };
