import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import RejectIcon from '../../assets/img/cancel_schedule_calendar.png';
import './EventWithIcon.css'

const getCustomIconProps = (event) => {
  let CustomIcon, className;

  switch (event.status) {
    case 'WARNING':
      CustomIcon = ErrorRoundedIcon;
      className = 'warning';
      break;
    case 'ERROR':
      CustomIcon = CancelRoundedIcon;
      className = 'error';
      break;
    default:
      CustomIcon = CheckCircleRoundedIcon;
      className = 'success';
  }

  return { CustomIcon, className };
};
const EventWithIcon = ({ event }) => {
  let { CustomIcon, className } = getCustomIconProps(event);

  const Icon = <CustomIcon className={`miniIcon ${className}`} />;
  const isBooking = event.type === 'booking';

  const dataToRender = isBooking && (
    <Tooltip title={event?.status !== 'OK' && event?.errorDetail ? event?.errorDetail : ''}>{Icon}</Tooltip>
  );
  
  const classType = isBooking ? "event-with-icon" : "event-not-icon";
  let title = event.title;

  const eventTitle = (title) => {
    return event.view === 'month' ? `${event.time_start} - ${title}` : title;
  };

  if (event.view !== 'month' && title.length > 6) {
    const shouldShortenTitle = event.view !== 'agenda' && Math.floor((event.end - event.start) / (1000 * 60)) <= 30;
    
    if (shouldShortenTitle) {
      const truncatedTitle = title.length > 25 ? `${title.substring(0, 25)}...` : title;
      title = `${truncatedTitle} ${event.time_display}`;
    }
  }

  const renderExtraIcon = () => {
    if (event.hasRejected) {
      const classname = event.view === 'agenda' ? 'reject-icon-agenda' : 'reject-icon';
      return <img src={RejectIcon} alt="icon" className={classname} />;
    }
    return null;
  };

  return (
    <div id={`event-${event.id}`} className={`event-cont ${classType}`}>
      <div className="event-alert">
        {dataToRender}
      </div>
      <div className="event-container-title">
        <span className="event-title">{eventTitle(title)}</span>
      </div>
      {renderExtraIcon()}
    </div>
  )
}

EventWithIcon.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    errorDetail: PropTypes.string,
    title: PropTypes.string,
    view: PropTypes.string,
    time_start: PropTypes.string,
    hasRejected: PropTypes.bool,
    time_display: PropTypes.string,
    end: PropTypes.number,
    start: PropTypes.number,
    id: PropTypes.number,
  }),
}

export default EventWithIcon
