import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { PracticeFeesRepository } from "../repositories/practice-fees.repository";
import { ScheduleFee } from "../../domain";

@injectable()
export class GetSchedulesFeesByPracticeTypeUsecase {

  @inject(PracticeFeesRepository) protected repository!: PracticeFeesRepository;

  async execute(practiceTypeId: number): Promise<Either<GetSchedulesFeesByPracticeTypeError, ScheduleFee[]>> {
    if (!practiceTypeId) {
      return Either.left(new GetSchedulesFeesByPracticeTypeInvalidDataError());
    }
    const result = await this.repository.getSchedulesFeesByPracticeType(practiceTypeId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetSchedulesFeesByPracticeTypeError {
    if (exception instanceof NotFoundException) {
      return new GetSchedulesFeesByPracticeTypeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetSchedulesFeesByPracticeTypeConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetSchedulesFeesByPracticeTypeOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetSchedulesFeesByPracticeTypeAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetSchedulesFeesByPracticeTypeBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetSchedulesFeesByPracticeTypeUsecase");
  }
}

export abstract class GetSchedulesFeesByPracticeTypeError {
  error?: string;
}

export class GetSchedulesFeesByPracticeTypeInvalidDataError extends GetSchedulesFeesByPracticeTypeError { }
export class GetSchedulesFeesByPracticeTypeNotFoundError extends GetSchedulesFeesByPracticeTypeError { }
export class GetSchedulesFeesByPracticeTypeConnectionError extends GetSchedulesFeesByPracticeTypeError { }
export class GetSchedulesFeesByPracticeTypeOperationalError extends GetSchedulesFeesByPracticeTypeError { }
export class GetSchedulesFeesByPracticeTypeAuthorizationError extends GetSchedulesFeesByPracticeTypeError { }
export class GetSchedulesFeesByPracticeTypeBadRequestError extends GetSchedulesFeesByPracticeTypeError {
  constructor(public readonly error: string) {
    super();
  }
}
