import { PHONE_TYPES } from '../actions/phone';

const phone = (state = {}, action) => {
    if (action.type === PHONE_TYPES) {
        return { ...state, phoneTypes: action.phoneTypes };
    }
    return state;
}

export default phone;
