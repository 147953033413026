import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Key(props) {
  return (
    <SvgIcon width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.70492 6.70409C8.70492 7.33299 8.70492 7.94255 8.90326 8.5134C6.8182 10.9613 0.800049 17.9228 0.800049 17.9228L0.940343 19.6789C0.940343 19.6789 2.62388 21.2754 2.87544 21.0335C3.62045 20.3078 4.0752 19.8144 4.0752 19.8144L4.42545 17.7172L6.70451 17.5493L6.88738 15.2853L9.28835 15.2393L9.31205 12.8161L11.8611 12.9177L12.2965 12.0063C13.0463 12.3304 13.7284 12.461 14.6041 12.461C17.9179 12.461 20.6029 9.87765 20.6029 6.69441C20.6029 3.51118 17.916 0.9375 14.6021 0.9375C11.2883 0.9375 8.70492 3.52085 8.70492 6.70409ZM17.8362 5.58173C17.8362 5.95345 17.7259 6.31683 17.5194 6.62591C17.3129 6.93498 17.0194 7.17588 16.6759 7.31813C16.3325 7.46038 15.9546 7.4976 15.59 7.42508C15.2254 7.35256 14.8906 7.17356 14.6277 6.91071C14.3649 6.64786 14.1859 6.31298 14.1133 5.9484C14.0408 5.58382 14.078 5.20592 14.2203 4.86249C14.3625 4.51907 14.6034 4.22553 14.9125 4.01902C15.2216 3.8125 15.585 3.70227 15.9567 3.70227C16.2036 3.70201 16.4481 3.75045 16.6762 3.84482C16.9044 3.93918 17.1117 4.07761 17.2862 4.25218C17.4608 4.42676 17.5992 4.63405 17.6936 4.86219C17.788 5.09033 17.8364 5.33485 17.8362 5.58173Z" fill="white"/>
    </SvgIcon>
  );
}
