import { PracticeFee } from "../../../../core";
import { SnackbarProps } from "../../../../util/interfaces";
import config from "../../../../config/config";
import moment from "moment";

const dateFormat = config.getDateFormat();
const dateTimeFormat = config.getDateTimeSmallFormat();

export type TableHead = {
  Header: string | React.JSX.Element;
  accessor: string;
  width?: number;
  sortable?: boolean;
  filterable?: boolean;
  sortMethod?: (a: string, b: string) => number;
}

export enum Action {
  create = 'create',
  update = 'update',
}

export enum TypeAlert { EDIT = 'EDIT', LIQUIDATE = 'LIQUIDATE', VOID = 'VOID' };

export interface PracticeFeeItem extends PracticeFee {
  amountValue?: JSX.Element;
  actions?: JSX.Element;
  fromDateValue: string;
  toDateValue?: string;
}

export interface DataReturnAlert {
  snackbar?: SnackbarProps;
  fees?: PracticeFeeItem[];
}

export const useFees = () => {
  const sortDate = (a: string, b: string): number => {
    const a1 = moment(a, [dateFormat, dateTimeFormat]).valueOf();
    const b1 = moment(b, [dateFormat, dateTimeFormat]).valueOf();
    if (a1 < b1) {
      return -1;
    }
    if (a1 > b1) {
      return 1;
    }
    return 0;
  }

  return [sortDate];
}
