import React, { FC, useEffect, useState } from 'react';
import { SendReasonRejectedError, SendReasonsRejectedConnectionError, SendReasonsRejectedUsecase } from '../../../../core';
import { SnackbarColor, SnackbarProps } from '../../../../util/interfaces';

import CustomSweetAlert from '../../../CustomSweetAlert';
import { DIContainer } from '../../../../Root';
import PropTypes from 'prop-types';
import ReasonsRejectedOptions from './ReasonsRejectedOptions';
import Snackbar from '../../../Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

const initialValueSnackbar = {
  color: SnackbarColor.danger,
  message: '',
  open: false,
}

interface ConfirmRejectedItemProps {
  selectedItemId: number;
  setShowConfirmRejectItem: (value: boolean) => void;
  onRejectItem: (value: boolean) => void;
}

const ConfirmRejectedItem: FC<ConfirmRejectedItemProps> = ({ selectedItemId, setShowConfirmRejectItem, onRejectItem }) => {
  const { t } = useTranslation();

  const [reasonRejectedId, setReasonRejectedId] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);

  const openSnackbar = (color: SnackbarColor, message: string) => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 5000);
  }

  const getDataErrors = async (dataError: SendReasonRejectedError) => {
    let message = t('common.errorService');
    if (dataError instanceof SendReasonsRejectedConnectionError) {
      message = t('confirm.error');
    }
    return openSnackbar(SnackbarColor.danger, message);
  }

  const sendUsecase = DIContainer.get<SendReasonsRejectedUsecase>(SendReasonsRejectedUsecase);

  const onRejected = async () => {
    if (!reasonRejectedId) {
      openSnackbar(SnackbarColor.danger, t('rejectedReason.error'));
      return;
    }

    const rejectedReason = Number(reasonRejectedId);
    const result = await sendUsecase.execute(Number(selectedItemId), rejectedReason);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      await getDataErrors(dataError);
      return;
    }

    const isSend = result.getRight();
    if (isSend) {
      setShowConfirmRejectItem(false);
      setReasonRejectedId(null);
      onRejectItem(isSend);
      openSnackbar(SnackbarColor.success, t('confirm.success.rejectItem'));
    }
  }
  
  useEffect(() => {
    return () => {
      setReasonRejectedId(null);
      setSnackbar(initialValueSnackbar);
    }
  }, []);
  
  const showConfirmRejectItemMg = t('appointment.item.reject.message');

  return (
    <>
    <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <CustomSweetAlert
        type="warning"
        onConfirm={() => onRejected()}
        onCancel={() => {
          setShowConfirmRejectItem(false);
          setReasonRejectedId(null);
        }}
        confirmBtnCssClass="primary"
        cancelBtnCssClass="danger"
        title={t('appointment.item.reject.title')}
        cancelBtnText={t('appointment.created.cancel.cancel')}
        confirmBtnText={t('appointment.created.cancel.confirm')}
        showCancel={true}
        message={
          <>
            <p>{showConfirmRejectItemMg}</p>
            <ReasonsRejectedOptions
              reasonRejectedId={reasonRejectedId}
              setReasonRejectedId={setReasonRejectedId}
              openSnackbar={openSnackbar}
            />
          </>
        }
      />
    </>
  )
}

ConfirmRejectedItem.propTypes = {
  selectedItemId: PropTypes.number.isRequired,
  setShowConfirmRejectItem: PropTypes.func.isRequired,
  onRejectItem: PropTypes.func.isRequired,
}

export default ConfirmRejectedItem;
