import "./Traslado.css";

import {
  GoogleMap,
  Marker,
  Polyline,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import React, { Component } from "react";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Button from "../../CustomButtons/Button";
import CustomInput from "../../CustomInput/CustomInput";
import DateInput from "../../DateInput";
import Finished from "../../../assets/img/finished_trans.png";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Pending from "../../../assets/img/pending.png";
import PendingDo from "../../../assets/img/pending_do_trans.png";
import PinCurrent from "../../../assets/img/pin_current.png";
import PinDestination from "../../../assets/img/pin_destination.png";
import PinSource from "../../../assets/img/pin_source.png";
import PropTypes from "prop-types";
import Reject from "../../../assets/img/trasllat_cancel.png";
import SchedulePlan from "../../SchedulePlan";
import SelectInput from "../../SelectInput";
import TimePickerGCalendar from "../../TimePickerGCalendar";
import TrasladoPDF from "./TrasladoPDF.js";
import ValidationInput from "../../ValidationInput";
import memory from "../../../config/memory.js";
import moment from "moment";
import { withTranslation } from "react-i18next";


const RegularMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      center={{
        lat: parseFloat(props.center.latitude),
        lng: parseFloat(props.center.longitude),
      }}
      defaultOptions={{
        scrollwheel: false,
      }}
      defaultZoom={14}
    >
      {props.customer && (
        <Marker
          key={"customer"}
          className="routes-marker"
          defaultLabel={""}
          defaultIcon={{
            size: { width: 48, height: 48 },
            url: PinSource,
            scaledSize: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={{
            lat: parseFloat(props.customer.latitude),
            lng: parseFloat(props.customer.longitude),
          }} />
      )}
      { props.nosocomio?.latitude && (
        <Marker
          defaultLabel={""}
          key={"nosocomio"}
          className="routes-marker"
          defaultIcon={{
            url: PinDestination,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={{
            lat: parseFloat(props.nosocomio.latitude),
            lng: parseFloat(props.nosocomio.longitude),
          }}
        />
      )}

      {props.currentPosition && (
        <Marker
          className="routes-marker"
          key={"currentposicion"}
          defaultLabel={""}
          defaultIcon={{
            url: PinCurrent,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={props.currentPosition}
        />
      )}

      {props.mode && props.mode === "inprogress" ? (
        <div>
          <Polyline
            options={{
              strokeColor: "#099107",
              strokeOpacity: 0.7,
              strokeWeight: 7,
            }}
            path={props.path1}
          />
          <Polyline
            path={props.path2}
            options={{
              strokeColor: "#01abc2",
              strokeOpacity: 0.7,
              strokeWeight: 7,
            }}
          />
        </div>
      ) : (
        <div>
          {props.directions.length > 0 && (
            <Polyline
              path={props.directions[1]}
              options={{
                strokeColor: "#099107",
                strokeWeight: 7,
                strokeOpacity: 0.7,
              }}
            />
          )}
          <Polyline
            path={props.directions[0]}
            options={{
              strokeColor: props.colorStroke,
              strokeOpacity: 0.7,
              strokeWeight: 7,
            }}
          />
        </div>
      )}
    </GoogleMap>
  ))
);

class DialogoTraslado extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      open: false,
      types: [
        {
          id: 1,
          value: t("Practice"),
        },
        {
          id: 2,
          value: t("Traslado"),
        },
      ],
      type: "",
      module: "",
      component: null,
      nosocomios: memory.nosocomios,
      traslados: memory.traslados,
      current: null,
      action: "",
      address: "",
      traslado: 0,
      nosocomio: 0,
      appointmentAction: "",
      time_end_suggested: "-",
      distance_suggested: "-",
      waypoints: [],
      nosocomioObject: null,
      directions: [],
      indexPosition: 0,
      currentPosition: null,
      recurrence: {},
      startTime: "02:00",
      rawPath: null,
      path1: [],
      path2: [],
      pathExtra: null,
      time_recorrend: 720,
      distance_recorred: 5100,
    };
  }

  componentDidMount() {
    if (this.props.editData.length > 0) {
      this.setDataToEdit(this.props.editData);
    }
    if ( memory.tempService?.type) {
      this.setState({
        type: memory.tempService.type,
        nosocomios: this.props.hospitals,
        startTime: memory.tempService.startTime,
        startDate: memory.tempService.startDate,
        recurrence: memory.tempService.recurrence,
      });

      this.handledValue("traslado", memory.tempService.traslado.id);
      this.handledValue("nosocomio", memory.tempService.nosocomio.id);
    } else {
      this.handledValue("startTime", "09:00");
    }
    if (this.props.viewMode === "apointment") {
      let intervaln = setInterval(this.timer, 1000);
      this.setState({ interval: intervaln });
    }

    if (!window.google) {
      let interval2 = setInterval(this.checkMap, 1000);
      this.setState({ interval2: interval2 });
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.state.interval);
    clearInterval(this.state.interval2);
  };

  checkMap = () => {
    if (window.google) {
      this.setDirectionM();
      clearInterval(this.state.interval2)
    }
  };

  timer = () => {
    if (this.state.rawPath != null) {
      let index = this.state.indexPosition
      if (index >= this.state.rawPath.length) { clearInterval(this.state.interval)}
      let distance = 0;
      if (this.state.time_recorrend % 10 === 0) {
        distance = 100;
      }
      if (this.state.time_recorrend % 5 === 0) {
        this.dividePath(this.state.rawPath, index + 1);
      }

      this.setState((prevState) => ({
        time_recorrend: prevState.time_recorrend + 1,
        distance_recorred: prevState.distance_recorred + distance,
      }));
      
    }
  };

  handledValue = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === "startTime") {
        this.recalculateStartTimes();
      }
    });
    if (key == "nosocomio") {
      const moso = this.props.hospitals.filter((mo) => mo.id == value);
      if (moso.length > 0) {
        this.setState(
          {
            nosocomioObject: moso[0],
          },
          () => {
            this.setDirectionM();
          }
        );
      }
    }
    this.props.handleChangeValue(key, value);
  };

  setDirectionM = () => {
    if (!this.props.customer || !this.state.nosocomioObject) {
      return;
    }
    let DirectionsService = null;
    try {
      DirectionsService = new window.google.maps.DirectionsService();
    } catch (error) {
      return;
    }

    let alternative = false;
    if (
      
      this.props.appointment?.appointmentId == 101 &&
      this.isTodayMenu(1)
    ) {
      alternative = true;
    }

    DirectionsService.route(
      {
        origin: {
          lat: parseFloat(this.props.customer.latitude),
          lng: parseFloat(this.props.customer.longitude),
        },
        destination: {
          lat: parseFloat(this.state.nosocomioObject.latitude),
          lng: parseFloat(this.state.nosocomioObject.longitude),
        },
        waypoints: [],
        provideRouteAlternatives: alternative,
        travelMode: window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          let time_end = 0;
          let distance_suggested = "-";
          if (result.routes.length > 0 && result.routes[0].legs.length > 0) {
            distance_suggested = result.routes[0].legs[0].distance["text"];
            time_end = result.routes[0].legs[0].duration["value"];
          }
          let extras = [];
          let raw = [];

          if (this.props.viewMode === "apointment") {
            raw = result["routes"][0]["overview_path"];
            let startp = parseInt(raw.length / 2);
            this.dividePath(raw, startp);
          }
          if (result["routes"].length > 1) {
            result["routes"][1]["overview_path"].map(function ( value, index, array ) {
              extras.push(value)
            })
          }
          let directions = []
          if (result["routes"].length > 0)
            directions.push(result["routes"][0]["overview_path"]);
          if (result["routes"].length > 1)
            directions.push(result["routes"][1]["overview_path"]);
          if (result["routes"].length > 2)
            directions.push(result["routes"][2]["overview_path"]);
          this.setState(
            {
              directions: directions,
              time_end: time_end,
              pathExtra: extras,
              distance_suggested: distance_suggested,
              rawPath: raw,
            },
            () => {
              this.recalculateStartTimes();
            }
          );
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  dividePath = (raw, indexPath) => {
    let currentPosition = {};
    let path1 = [];
    let path2 = [];
    raw.map(function (value, index, array) {
      if (index < indexPath) {
        path1.push(value);
      } else if (index === indexPath) {
        path1.push(value);
        path2.push(value);
        currentPosition = { latitude: value.lat(), longitude: value.lng() };
      } else {
        path2.push(value);
      }
    });
    this.setState({
      path1: path1,
      indexPosition: indexPath,
      currentPosition: currentPosition,
      path2: path2,
      rawPath: raw,
    });
  };

  setDataToEdit(data) {
    let hospital = null;
    AppointmentApiInvoker.getHospital((response) => {
      if (data.length > 0) {
        hospital = response.filter((id) => id.hospitalId == data[0].hospitalId );
        this.setState({
          traslado:
            data.length > 0
              ? this.formatTransferTypeSend(data[0].serviceName)
              : "",
          nosocomio: data.length > 0 ? data[0].hospitalId : null,
          startDate: data.length > 0 ? data[0].startDateSend : null,
          startTime: data.length > 0 ? data[0].startTime : null,
          nosocomioObject: hospital[0],
          recurrence:
            data.length > 0 ? { recurrenceType: data[0].recurrenceType } : null,
        });
      }
    });
  }

  formatTransferTypeSend = (type) => {
    if (type === "Traslado estándar") { return 1; } 
    else if (type === "Traslado con rampa") { return 2; } 
    else if (type === "Traslado con ambulancia") { return 3; }
  };

  recalculateStartTimes = () => {
    this.setState((prevState) => {
      let start = moment(prevState.startTime, "HH:mm");
      start = start.add(-1 * prevState.time_end, "seconds");
      return {
        time_end_suggested: start.format("HH:mm"),
      };
    });
  };

  componentWillReceiveProps(next) {
    this.setState({ open: next.open });
  }

  onCreatedRecurrence = (value) => {
    this.setState({
      recurrence: value,
    });
    this.props.handleChangeValue("recurrence", value);
  };

  invalidPlan = () => {
    return false;
  };

  isInvalid = () => {
    if (
      !this.state.nosocomio ||
      !this.state.recurrence ||
      !this.state.traslado ||
      !this.state.startDate ||
      !this.state.startTime
    ) {
      return true;
    } else {
      if (!this.state.recurrence.recurrenceType) return true;
      if (
        this.state.recurrence.recurrenceType == "DAY" && this.state.recurrence.every <= 0
      )
        return true
      if (
        this.state.recurrence.recurrenceType == "MONTH" &&
        (!this.state.recurrence.monthRecurrence ||
          this.state.recurrence.monthRecurrence <= 0 ||
          this.state.recurrence.monthRecurrence > 31 ||
          !this.state.recurrence.every || this.state.recurrence.every <= 0)
      )
        return true
      if (
        this.state.recurrence.recurrenceType == "WEEK" &&
        (this.state.recurrence.weekRecurrence.length == 0 || !this.state.recurrence.every || this.state.recurrence.every <= 0)
      )
        return true
      return false
    }
  };

  formatTimeSeconds = () => {
    let totalSeconds = this.state.time_recorrend;
    let hours = Math.floor(totalSeconds / 3600) + "";
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60) + "";
    let seconds = (totalSeconds % 60) + "";
    return (
      hours.padStart(2, "0") + ":" +
      minutes.padStart(2, "0") + ":" +
      seconds.padStart(2, "0")
    );
  };

  isTodayMenu = (v) => {
    let nowmenusone = moment(new Date()).add(v * -1, "d").format("YYYY-MM-DD");
    let event = moment(new Date(this.props.event.start)).format("YYYY-MM-DD");
    return nowmenusone === event;
  };

  toPDF = () => {
    let hora_real_retiro = "";
    let hora_real_fin = "";
    let hora_real_distance = "";
    let time_real = "";

    if (this.isTodayMenu(1)) {
      hora_real_retiro = "10:04";
      hora_real_fin = "10:29";
      hora_real_distance = "10.1 km";
      time_real = "00:25:00";
    } else if (this.isTodayMenu(2)) {
      hora_real_retiro = "10:00";
      hora_real_fin = "10:30";
      hora_real_distance = "10.4 km";
      time_real = "00:30:00";
    } else if (this.isTodayMenu(3)) {
      hora_real_retiro = "9:58";
      hora_real_fin = "10:26";
      hora_real_distance = "10.2 km";
      time_real = "00:28:00";
    } else if (this.isTodayMenu(4)) {
      hora_real_retiro = "10:02";
      hora_real_fin = "10:35";
      hora_real_distance = "10.2 km";
      time_real = "00:33:00";
    } else if (this.isTodayMenu(5)) {
      hora_real_retiro = "10:04";
      hora_real_fin = "10:32";
      hora_real_distance = "10.3 km";
      time_real = "00:28:00";
    }

    let context = {
      appointmentId: this.props.event.id,
      startDate: moment(new Date(this.props.event.start)).format("DD/MM/YYYY"),
      startTime: hora_real_retiro,
      realDate: moment(new Date(this.props.event.start)).format("DD/MM/YYYY"),
      realTime: hora_real_fin,
      address_fin: "Juan Cruz Varela 2634, Córdoba",
      trasladoName: this.props.appointment.services.traslado.value,
      financier:{
        financer: localStorage.getItem('itlg_default_company_name'),
        provider: localStorage.getItem('username')
      },
      customer: {
        firstName: this.props.appointment.customer.firstName,
        lastName: this.props.appointment.customer.lastName,
        location: this.props.appointment.customer.location,
        province: this.props.appointment.customer.province,
        address: this.props.appointment.customer.address_name,
        typeDocument: this.props.appointment.customer.typeDocument,
        document: this.props.appointment.customer.document,
      },
      chofer: {
        firstName: this.props.appointment.services.driver.firstName,
        typeDocument: this.props.appointment.services.driver.typeDocument,
        lastName: this.props.appointment.services.driver.lastName,
        document: this.props.appointment.services.driver.document,
      },
      nosocomio: {
        address: this.props.appointment.services.nosocomio.address,
        province: "Córdoba",
        location: "Capital",
      },
      trayecto: {
        duration: time_real,
        distance: hora_real_distance,
      },
    };
    TrasladoPDF(context);
  };

  formatTransferTypeRecive = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") { return "Traslado con ambulancia";}
  };

  render() {
    const { t } = this.props;
    const { types } = this.state;
    let end_time = "end_time";
    let viewMode = "short";
    let isDisabled = this.props.disabled || this.isInvalid();
    let center = this.props.customer;
    if (this.props.viewMode) {
      viewMode = this.props.viewMode;
    } else {
      viewMode =
        this.props.appointment?.appointmentStatus ===
          "TRANSFER_SCHEDULES_ASSIGNED"
          ? "large"
          : "short";
    }
    if (this.state.currentPosition) {
      center = this.state.currentPosition;
    }

    if (viewMode == "large") {
      let nextDay = false;
      let today = new Date();
      let dateEvent;
      if (this.props.event) {
        dateEvent = new Date(this.props.event.start);
      }
      if (dateEvent > today) {
        nextDay = true;
      }
      let istoday = this.isTodayMenu(0);
      let todaymenusone = this.isTodayMenu(1);
      let todaymenustwo = this.isTodayMenu(2);

      let icon = Pending;
      let status = "PENDING_APPROVAL";
      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 100
      ) {
        icon = Reject;
        status = "REJECTED";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 101
      ) {
        if (istoday) {
          icon = PendingDo;
          status = "IN_PROGRESS";
        } else if (nextDay) {
          status = "APPROVED";
          icon = PendingDo;
        } else if (todaymenusone || todaymenustwo) {
          icon = Finished;
          status = "DONE";
        } else {
          status = "DONE";
          icon = Finished;
        }
      }

      let color = "#099107";
      if (nextDay) {
        color = "#01abc2";
      }

      if (
        this.props.appointment?.appointmentId == 101 &&
        this.isTodayMenu(1)
      ) {
        color = "#01abc2";
      }

      if (
        this.props.appointment?.appointmentId == 100
      ) {
        color = "#01abc2";
      }
      let hora_real_retiro = "-";
      let hora_real_fin = "-";
      let hora_real_distance = "-";
      let finished = false;
      if (
        this.props.appointment?.appointmentId == 101
      ) {
        if (this.isTodayMenu(0)) {
          hora_real_retiro = "10:04";
          color = "#01abc2";
        }
        if (this.isTodayMenu(1)) {
          hora_real_retiro = "10:04";
          hora_real_fin = "10:29";
          hora_real_distance = "10.1 km";
          finished = true;
        } else if (this.isTodayMenu(2)) {
          hora_real_retiro = "10:00";
          hora_real_fin = "10:30";
          hora_real_distance = "10.4 km";
          finished = true;
        } else if (this.isTodayMenu(3)) {
          hora_real_fin = "10:26";
          hora_real_distance = "10.2 km";
          hora_real_retiro = "9:58";
          finished = true;
        } else if (this.isTodayMenu(4)) {
          hora_real_retiro = "10:02";
          hora_real_fin = "10:35";
          finished = true;
          hora_real_distance = "10.2 km";
        } else if (this.isTodayMenu(5)) {
          hora_real_fin = "10:32";
          hora_real_retiro = "10:04";
          hora_real_distance = "10.3 km";
          finished = true;
        }
      }
      return (
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.traslado_detail")}
                  value={this.formatTransferTypeRecive(
                    this.props.event.origin.transferItemRequestId.transferType
                  )}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("service.scheduleId")}
                  value={this.props.event.id}
                  disabled={true}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.driver")}
                  value={
                    this.props.event.origin.driver
                      ? this.props.event.origin.driver.firstName +
                        ", " +
                        this.props.event.origin.driver.lastName
                      : "-"
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.start_date")}
                  onChangeValue={(value) =>
                    this.handledValue("startDate", value)
                  }
                  value={`${moment(
                    this.props.event.origin.startDateTime
                  ).format("DD/MM/YYYY")}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  text={t("service.customer.address")}
                  inputProps={{ style: { width: "100%" } }}
                  value={this.props.appointment.customer.address_name}
                  disabled={true}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={`${t("service.startTimeProg")}`}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  disabled={true}
                  value={`${moment(
                    this.props.event.origin.startDateTime
                  ).format("HH:mm")}`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.startTimeProgReal")}
                  onChangeValue={(value) =>
                    this.handledValue("startDate", value)
                  }
                  value={""}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  text={t("service.nosocomio.address")}
                  elements={""}
                  disabled={true}
                  value={
                    this.props.event.origin.transferItemRequestId.hospital
                      .businessName +
                    " " +
                    this.props.event.origin.transferItemRequestId.hospital
                      .address
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className="timeInput">
                <CustomInput
                  labelText={t("service.endTimeProg")}
                  disabled={true}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  value={`${moment(this.props.event.origin.endDateTime).add(45, "minutes").format("HH:mm")}`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  type="text"
                  labelText={`${t("service.endTimeReal")}`}
                  value={hora_real_fin}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  type="text"
                  labelText={`${t("service.distance_suggested")}`}
                  value={"-"}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled={true}
                  type="text"
                  labelText={`${t("service.distance_real")}`}
                  value={"-"}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  disabled={true}
                  type="text"
                  iconName={icon}
                  inputProps={{ style: { width: "100%" } }}
                  text={`${t("service.status")}`}
                  value={`${t(status)}`}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <GridItem xs={12} sm={12} md={12}>
              <RegularMapComponent
                customer={this.props.customer}
                mode=""
                location={this.state.currentLocation}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                center={center}
                colorStroke={color}
                nosocomio={
                  this.props.event.origin.transferItemRequestId.hospital
                }
                loadingElement={<div style={{ height: `100%` }} />}
                googleMapURL="/googlemap"
                containerElement={
                  <div
                    style={{
                      height: `500px`,
                      borderRadius: "6px",
                      width: `100%`,
                      overflow: "hidden",
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
            <GridContainer style={{ textAlign: "right" }}>
              <GridItem xs={12} sm={12} md={12}>
                {finished && (
                  <Button
                    onClick={() => this.toPDF()}
                    color="warning"
                    style={{ textAlign: "right" }}
                  >
                    {t("service.download.pdf")}
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      );
    } else if (viewMode === "short") {
      let min, max;
      if (!this.props.disabled) {
        min = moment(new Date(this.props.provider.dateStart)).format(
          "YYYY-MM-DD"
        );
        max = moment(new Date(this.props.provider.dateEnd)).format(
          "YYYY-MM-DD"
        );
      }
      return (
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={t("service.traslado")}
                onSelectedValue={(value) => this.handledValue("traslado", value)}
                elements={this.state.traslados}
                disabled={this.props.disabled}
                value={this.state.traslado}
              />
            </GridItem>
            <GridItem xs={12} className="margin-left">
              <h4 className="label-services">{t("service.destino")}</h4>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                elements={this.props.hospitals}
                label={t("service.nosocomio")}
                disabled={this.props.disabled}
                onSelectedValue={(value) =>
                  this.handledValue("nosocomio", value)
                }
                value={this.state.nosocomio}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationInput
                type="text"
                disabled={true}
                text={`${t("service.address")}`}
                value={
                  this.state.nosocomioObject
                    ? this.state.nosocomioObject.address
                    : ""
                }
              />
            </GridItem>
            <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
                <DateInput
                  minDate
                  text={t("service.start_date")}
                  disabled={this.props.disabled}
                  onChangeValue={(value) =>
                    this.handledValue("startDate", value)
                  }
                  value={this.state.startDate}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className="timeInput">
                <TimePickerGCalendar
                  text={t("service.time_end_destination")}
                  value={this.state.startTime}
                  name={end_time}
                  disabled={this.props.disabled}
                  onSelectedValue={(value) =>
                    this.handledValue("startTime", value)
                  }
                  defaultValue="02:00"
                  invalid={!this.state.startTime}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <SchedulePlan
                  isTraslado={true}
                  disabled={this.props.disabled}
                  insideModule={false}
                  current={this.state.recurrence}
                  indexArrayDays={0}
                  invalidPlan={this.invalidPlan}
                  onCreatedRecurrence={this.onCreatedRecurrence}
                  className="schedule-plan-component"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  disabled={this.props.disabled}
                  type="text"
                  text={`${t("service.distance")}`}
                  value={this.state.distance_suggested}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  disabled={this.props.disabled}
                  type="text"
                  text={`${t("service.time_start_destination")}`}
                  value={this.state.time_end_suggested}
                />
              </GridItem>
            </GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <RegularMapComponent
                customer={this.props.customer}
                location={this.state.currentLocation}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                nosocomio={
                  this.state.appointment
                    ? this.state.appointment.nosocomio
                    : this.state.nosocomioObject
                }
                googleMapURL="/googlemap"
                colorStroke={"#01abc2"}
                loadingElement={<div style={{ height: `100%` }} />}
                mode=""
                center={center}
                containerElement={
                  <div
                    style={{
                      height: `500px`,
                      overflow: "hidden",
                      borderRadius: "6px",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
            {!this.props.appointment && (
              <GridContainer className="button-translado">
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    onClick={() => this.props.openDrivers()}
                    disabled={this.props.disabled}
                    color="primary"
                  >
                    {t("service.driver.assign")}
                  </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    onClick={() => this.props.saveService()}
                    color={"success"}
                    disabled={isDisabled}
                  >
                    {t("service.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            )}
          </GridItem>
        </GridContainer>
      );
    } else {
      let date = new Date();
      date = moment(date).format("DD-MM-YYYY");

      let distance_recorred_str = this.state.distance_recorred / 1000 + " Km";
      let time_recorred_str = this.formatTimeSeconds()
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className="componentType">
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <CustomInput
                  labelText={t("service.scheduleId")}
                  disabled={true}
                  value={this.props.event.id}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  labelText={t("service.start_date")}
                  disabled={this.props.disabled}
                  onChangeValue={(value) =>
                    this.handledValue("startDate", value)
                  }
                  value={date}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3}>
                <ValidationInput
                  inputProps={{ style: { width: "100%" } }}
                  text={t("service.customer.address")}
                  disabled={true}
                  value={this.props.appointment.customer.address_name}
                />
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  labelText={t("service.startTimeProgReal")}
                  disabled={true}
                  onChangeValue={(value) =>
                    this.handledValue("startDate", value)
                  }
                  value={this.state.time_end_suggested}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.endTimeEstimated")}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  onChangeValue={(value) =>
                    this.handledValue("startDate", value)
                  }
                  value={this.state.time_end_suggested}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3}>
                <CustomInput
                  labelText={t("service.traslado_detail")}
                  value={memory.tempService.traslado.value}
                  disabled={true}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  labelText={t("service.driver")}
                  disabled={true}
                  value={
                    memory.tempService.driver
                      ? memory.tempService.driver.value
                      : "-"
                  }
                />
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <ValidationInput
                  disabled={true}
                  text={t("service.nosocomio.address")}
                  elements={this.state.nosocomios}
                  value={
                    memory.tempService.nosocomio.value +
                    " - " +
                    memory.tempService.nosocomio.address
                  }
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} className="no-padding">
                <ValidationInput
                  text={t("service.distance_recorred")}
                  disabled={true}
                  value={distance_recorred_str}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} className="no-padding">
                <ValidationInput
                  value={time_recorred_str}
                  text={t("service.time_recorred")}
                  disabled={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer></GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <RegularMapComponent
                customer={this.props.customer}
                center={center}
                path1={this.state.path1}
                path2={this.state.path2}
                location={this.state.currentLocation}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                nosocomio={
                  this.props.appointment
                    ? this.props.appointment.services.nosocomio
                    : {}
                }
                mode="inprogress"
                colorStroke={"#01abc2"}
                pathExtra={this.state.pathExtra}
                googleMapURL="/googlemap"
                currentPosition={this.state.currentPosition}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      borderRadius: "6px",
                      height: `500px`,
                      overflow: "hidden",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }}/>}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
      );
    }
  }
}

DialogoTraslado.defaultProps = {
  onGetModuleTypes: () => [],
  onGetPracticeTypes: () => [],
};

DialogoTraslado.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  editData:PropTypes.array,
  viewMode:PropTypes.string,
  handleChangeValue: PropTypes.func,
  current: PropTypes.object,
  customer: PropTypes.object,
  appointment: PropTypes.object,
  event: PropTypes.object,
  action: PropTypes.string,
  hospitals:PropTypes.array,
};

export default withTranslation()(DialogoTraslado);
