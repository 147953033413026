import { LOCATIONS } from '../actions/address';

const address = (state = {}, action) => {
    if (action.type === LOCATIONS) {
        return { ...state, locations: action.locations };
    }
    return state;
}

export default address;
