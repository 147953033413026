import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { TermPolicy } from "../../domain/term-policy";
import { UsersRepository } from "../repositories";

@injectable()
export class GetTermsAndPolicyUsecase {

  @inject(UsersRepository) protected repository!: UsersRepository;

  async execute(version: string): Promise<Either<GetTermsAndPolicyError, TermPolicy>> {
    if (!version) {
      return Either.left(new GetTermsAndPolicyInvalidDataError());
    }
    const result = await this.repository.getTermsAndPolicy(version);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetTermsAndPolicyError {
    if (exception instanceof NotFoundException) {
      return new GetTermsAndPolicyNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetTermsAndPolicyConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetTermsAndPolicyOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetTermsAndPolicyAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetTermsAndPolicyBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetTermsAndPolicyUsecase");
  }
}

export abstract class GetTermsAndPolicyError {
  error?: string;
}

export class GetTermsAndPolicyInvalidDataError extends GetTermsAndPolicyError { }
export class GetTermsAndPolicyNotFoundError extends GetTermsAndPolicyError { }
export class GetTermsAndPolicyConnectionError extends GetTermsAndPolicyError { }
export class GetTermsAndPolicyOperationalError extends GetTermsAndPolicyError { }
export class GetTermsAndPolicyAuthorizationError extends GetTermsAndPolicyError { }
export class GetTermsAndPolicyBadRequestError extends GetTermsAndPolicyError {
  constructor(public readonly error: string) {
    super();
  }
}
