import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { AppointmentItem } from "../../domain";
import { AppointmentsRepository } from "../repositories/appointments.repository";

@injectable()
export class GetAppointmentItemsUsecase {
  @inject(AppointmentsRepository) protected repository!: AppointmentsRepository;

  async execute(id: number): Promise<Either<GetAppointmentItemsError, AppointmentItem[]>> {
    if (!id) {
      return Either.left(new GetAppointmentItemsInvalidDataError());
    }
    const result = await this.repository.getAppointmentItems(id);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetAppointmentItemsError {
    if (exception instanceof NotFoundException) {
      return new GetAppointmentItemsNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetAppointmentItemsConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetAppointmentItemsOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetAppointmentItemsAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetAppointmentItemsBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetAppointmentItemsUsecase");
  }
}

export abstract class GetAppointmentItemsError {
  error?: string;
}

export class GetAppointmentItemsInvalidDataError extends GetAppointmentItemsError { }
export class GetAppointmentItemsNotFoundError extends GetAppointmentItemsError { }
export class GetAppointmentItemsConnectionError extends GetAppointmentItemsError { }
export class GetAppointmentItemsOperationalError extends GetAppointmentItemsError { }
export class GetAppointmentItemsAuthorizationError extends GetAppointmentItemsError { }
export class GetAppointmentItemsBadRequestError extends GetAppointmentItemsError {
  constructor(public readonly error: string) {
    super();
  }
}
