import * as FileSaver from 'file-saver';

import { createRef, useEffect, useState } from 'react';

import ApiInvoker from '../../../api/ApiInvoker';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import ScheduleApi from '../../../api/ScheduleApi';
import config from '../../../config/config';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const dateToServer = config.getDateToServer();

const initFilters = {
  startDate: '',
  endDate: '',
  customerId: null,
  employeeId: null,
  practiceTypeId: null,
  specialityId: null,
  financerId: null,
}

const initOptions = {
  customers: [],
  employees: [],
  practices: [],
  specialities: [],
  financers: [],
}

const initAlertBar = {
  type: 'danger',
  message: '',
  open: false,
}

export const useDoneAppointmentsReport = (t) => {
  const [filters, setFilters] = useState(initFilters);
  const [options, setOptions] = useState(initOptions);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alertBar, setAlertBar] = useState(initAlertBar);

  useEffect(() => {
    setDisabled(!!(!filters.startDate || !filters.endDate));
    return () => {
      setDisabled(true);
    }
  }, [filters]);

  const refStartDate = createRef();
  const refEndDate = createRef();
  const refCustomerId = createRef();
  const refEmployeeId = createRef();
  const refPracticeId = createRef();
  const refSpecialityId = createRef();
  const refFinancerId = createRef();

  const openAlertBar = (type, message) => {
    setAlertBar({
      type,
      message,
      open: true,
    });

    setTimeout(() => {
      setAlertBar((prev) => ({ ...prev, open: false }));
    }, 5000);
  }

  const handleValue = (value, filter) => {
    setFilters((prev) => ({
      ...prev,
      [filter]: value,
    }));
  }

  const handleClearOptions = (type) => {
    setOptions((prev) => ({
      ...prev,
      [type]: [],
    }));
  }

  const formatPersonOptions = (list) => {
    if(!list?.length) {
      return [];
    }
    return list.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
  }

  const formatOptions = (list) => list.map(e => ({
    id: e.practiceTypeId || e.practiceTypeGroupId || e.financierId,
    value: e.name,
  }));

  const getCustomerstAPI = (text) => PersonApiInvoker.getCustomersOptions(text, data => {
    const customers = formatPersonOptions(data);
    setOptions((prev) => ({ ...prev,  customers }));
  }, (error) => console.error('** error getCustomersOptions', error));

  const getEmployeesAPI = (text) => PersonApiInvoker.getEmployeesByName(text, data => {
    const employees = formatPersonOptions(data);
    setOptions((prev) => ({ ...prev,  employees }));
  }, (error) => console.error('** error getEmployeesByName', error));

  const getPracticeTypesAPI = (text) => ApiInvoker.getPracticeTypesByName(text, data => {
    const practices = formatOptions(data);
    setOptions((prev) => ({ ...prev,  practices }));
  }, (error) => console.error('** error getPracticeTypesByName', error));

  const getSpecialitiesAPI = (text) => ApiInvoker.getSpecialitiesByName(text, data => {
    const specialities = formatOptions(data);
    setOptions((prev) => ({ ...prev,  specialities }));
  }, (error) => console.error('** error getSpecialitiesByName', error));

  const getFinancersAPI = (text) => ApiInvoker.getFinancersByName(text, data => {
    const financers = formatOptions(data);
    setOptions((prev) => ({ ...prev,  financers }));
  }, (error) => console.error('** error getFinancersByName', error));

  const exportToExcelAndDownload = (data) => {    
    const fileName = `done-appointments-report${moment().format('YYYYMMDD')}`;
    const fileExtension = '.xlsx';
    const type ='application/vnd.ms-excel';
    const fileBlob =  new Blob([data], { type }); 
    FileSaver.saveAs(fileBlob, `${fileName}${fileExtension}`);
  }

  const getReportParams = () => {
    const filtersValues = Object.keys(filters);
    const params = filtersValues.map((k) => {
      let value = filters[k];
      if (value) {
        if (k === 'startDate' || k === 'endDate') {
          value = moment(value).format(dateToServer)
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
      }
      return null;
    }).filter(param => param).join('&');
    return params;
  }

  const handleReport = () => {
    const isValues = Object.values(filters).filter(values => values).length > 0;
    if (isValues) {
      setLoading(true);
      const params = getReportParams();
      ScheduleApi.getDoneSchedulesReport(params, data => {
        exportToExcelAndDownload(data);
        setLoading(false);
        openAlertBar(
          'success',
          t('generateReport.success'),
        );
      }, (error) => {
        // error
        setLoading(false);
        console.error('** getDoneSchedulesReport error', error);
        openAlertBar(
          'danger',
          t('common.errorService'),
        );
      });
      return;
    }
    openAlertBar(
      'danger',
      t('generateReport.requiredFields'),
    );
  }

  const cleanSearch = () => {
    setFilters(initFilters);
    setOptions(initOptions);
    setLoading(false);
  }

  return {
    filters,
    options,
    loading,
    disabled,
    alertBar,
    refStartDate, refEndDate, refCustomerId, refEmployeeId, refPracticeId, refSpecialityId, refFinancerId,
    handleValue,
    handleClearOptions,
    getCustomerstAPI,
    getEmployeesAPI,
    getPracticeTypesAPI,
    getSpecialitiesAPI,
    getFinancersAPI,
    handleReport,
    cleanSearch,
  }
}

export const useStylesDoneAppoReport = makeStyles(() => ({
  appoReportContent: {
    minHeight: '50vh',
    '& .filters-row .MuiGrid-item.no-padding': {
      padding: '0 !important',
    }
  },
  appointmentsReport: {
    alignItems: 'flex-end',
    '& .filters-actions': {
      paddingBottom: 15,
    },
    '& .date-input .MuiFormControl-root': {
      paddingTop: 21,
      '& .MuiInputLabel-shrink ~ div > .form-control': {
        paddingBottom: 2,
        paddingTop: 12,
      }
    }
  }
}));
