import { MODULES, ADD_MODULE, EDIT_MODULE, UNMOUNT_MODULE } from "../actions/modules";

const modules = (state = {}, action, root) => {
    switch(action.type) {
        case UNMOUNT_MODULE:
            return {};
        case MODULES:
            return {...state, modules: action.modules};
        case ADD_MODULE: {
                const result = addModule(state.modules, action.module)
                return {...state, modules: result}
            }
        case EDIT_MODULE: {
                const updated = updateModule(state.modules, action.module)
                return {...state, modules: updated}
            }
        default:
            return state;
    }
}

const addModule = (modls, mod) => {
    if (modls?.length) {
        modls.push(mod);
        return modls;
    }
}

const updateModule = (modls, mod) => {
    if (modls?.length) {
        modls.map((m, i) => {
            if (m.packageId === mod.packageId) {
                modls[i] = mod
            }
        });
    }
    return modules;
}

export default modules;
