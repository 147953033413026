import { AlertWarning, SnackbarColor } from '../../../../util/interfaces';
import { DataReturnAlert, PracticeFeeItem } from './useFees';
import { DeleteFeeBadRequestError, DeleteFeeConnectionError, DeleteFeeError, DeleteFeeNotFoundError, DeleteFeeUsecase } from '../../../../core';
import React, { useState } from 'react';

import { DIContainer } from '../../../../Root';
import FeesWarningDialog from './FeesWarningDialog';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
  alertWarning: AlertWarning;
  selectedFeeId: number | null;
  dataFees: PracticeFeeItem[];
  onCloseAlert: (data?: DataReturnAlert) => void;
}

const DeleteFeeAlert = ({ alertWarning, selectedFeeId, dataFees, onCloseAlert }: ComponentProps) => {
  const { t } = useTranslation();
  const deleteUsecase = DIContainer.get<DeleteFeeUsecase>(DeleteFeeUsecase);

  const [loading, setLoading] = useState<boolean>(false);

  const handleUpdateDataOnDeleteFee = (id: number): PracticeFeeItem[] => {
    const d = dataFees.filter((item: PracticeFeeItem) => item.id !== id);
    return d.sort((a: PracticeFeeItem, b: PracticeFeeItem) => b.id - a.id);
  }

  const getDataErrorsDelete = (dataError: DeleteFeeError): string => {
    let message = t('fees.delete.error.message');
    if (dataError instanceof DeleteFeeConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof DeleteFeeNotFoundError) {
      message = t('fees.delete.error.messageNotFound', { feeId: selectedFeeId });
    }
    if (dataError instanceof DeleteFeeBadRequestError) { 
      message = dataError.error;
    }
    return message;
  }

  const onConfirmAlert = async (): Promise<void> => {
    if (!selectedFeeId) {
      return;
    }
    setLoading(true);
    const result = await deleteUsecase.execute(selectedFeeId);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      const message = getDataErrorsDelete(dataError);
      const snackbar = { color: SnackbarColor.danger, message };
      setLoading(false);
      onCloseAlert({ snackbar });
      return;
    }
    result.getRight();
    const fees = handleUpdateDataOnDeleteFee(selectedFeeId);
    const message = t('fees.delete.success.message');
    const snackbar = { color: SnackbarColor.success, message };
    setLoading(false);
    onCloseAlert({ snackbar, fees });
  }
  
  return (
    <FeesWarningDialog
      onConfirm={() => onConfirmAlert()}
      onCancel={() => onCloseAlert()}
      alertWarning={alertWarning}
      disabledOnConfirmButton={loading}
    />
  )
}

DeleteFeeAlert.propTypes = {
  alertWarning: PropTypes.object.isRequired,
  selectedFeeId: PropTypes.number,
  dataFees: PropTypes.array.isRequired,
  onCloseAlert: PropTypes.func.isRequired,
}

export default DeleteFeeAlert;
