import { Checkbox, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Check } from '@material-ui/icons';
import styles from '../../../../assets/components/customCheckboxRadioSwitch';
import { useStylesGralSteps } from '../../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import AppointmentApiInvoker from '../../../../api/AppointmentApiInvoker';

const useStylesChecks = makeStyles(styles)
const useProfessionalsStep = (handleChange, showSnackbarNotification, refStepsValues, values) => {
  const { loadingSchedule, professionalId, professionalsToReassign, originalProfessionalsToReassign, schedules, schedulesSelected, schedulesSelectedData
  } = values;

  const classes = useStylesGralSteps();
  const classesChecks = useStylesChecks();

  const [loadingTable, setLoadingTable] = useState(false);
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [checkAllProfessionals, setCheckAllProfessionals] = useState(false);

  const formatEmployeeType = (types) =>types.map(e => e.name).join(', ');

  const formatEmployeeAdress = (address) => address.map(addr => addr.geographicZone.location.name).join(', ');

  const onSelectProfessional = (id) => {
    setCheckAllProfessionals(false);
    const selected = refStepsValues.current.professionalsSelected
    if (selected.includes(id)) {
      const index = selected.indexOf(id);
      if (index !== -1) selected.splice(index, 1);
    } else {
      selected.push(id)
    }
    handleChange('professionalsSelected', selected);
    formatTableDataProfessionals(refStepsValues.current.originalProfessionalsToReassign, selected);
  }

  const formatTableDataProfessionals = (data, selected, expanded = null) => {
    const result = data.filter(d => d.personId !== professionalId).map((d) => {
      const isChecked = selected.includes(d.personId);
      const action = <div className="tooltypes-actions">
        <Checkbox
          id={`check-${d.personId}`}
          onClick={() => onSelectProfessional(d.personId)}
          checkedIcon={<Check className={classesChecks.checkedIcon} />}
          tabIndex={-1}
          icon={<Check className={classesChecks.uncheckedIcon} />}
          checked={isChecked}
          classes={{
            checked: classesChecks.checked,
            root: classesChecks.checkRoot,
          }}
        />
      </div>
      return {
        id: d.personId,
        employeeTypes: "Chofer",
        firstName: d.firstName,
        lastName: d.lastName,
        geographicZone: d?.address?.length > 0 && d.address[0]?.geographicZone ? formatEmployeeAdress(d.address) : [],
        selector: action,
        checkedProfessional: isChecked,
      }
    });
    handleChange('professionalsToReassign', result);
    handleChange('originalProfessionalsToReassign', data);
    if (expanded) {
      setRowsExpanded(expanded);
    }
  };

  const getProfessionals = (filterByZone) => {
    const body = schedulesSelected;
    setLoadingTable(true);
    AppointmentApiInvoker.getDriverToReasing( body, data => {
      formatTableDataProfessionals(data, []);
      setLoadingTable(false);
      }, (error) => {
        const { message, statusText } = error;
        const notificationMessage = typeof message === "string" ? message : statusText;
        showSnackbarNotification(notificationMessage, 'danger')
        setLoadingTable(false);
      }
    );
  }

  const onSelectAllProfessionals = (value) => {
    setCheckAllProfessionals(value.target.checked);
    let selected = [];
    if (value.target.checked) {
      selected = professionalsToReassign.map(p => p.id)
    }
    handleChange('professionalsSelected', selected);
    formatTableDataProfessionals(originalProfessionalsToReassign, selected);  
  }

  const resetAllProfessionals = () => {
    setCheckAllProfessionals(false)
    handleChange('professionalsSelected', [])
    formatTableDataProfessionals(originalProfessionalsToReassign, [], []); 
  }

  const onExpandedRow = (id) => {
    const expanded = rowsExpanded;
    if (expanded.includes(id)) {
      const index = expanded.indexOf(id);
      if (index !== -1) { expanded.splice(index, 1) }
    } else {
      expanded.push(id);
    }
    formatTableDataProfessionals(originalProfessionalsToReassign, values.professionalsSelected, expanded);
  }

  return {
    checkAllProfessionals,
    classes,
    classesChecks,
    getProfessionals,
    loadingSchedule,
    loadingTable,
    onSelectAllProfessionals,
    schedulesSelectedData,
    schedules,
    rowsExpanded,
    professionalsToReassign,
    resetAllProfessionals,
    onExpandedRow,
  };
}

export default useProfessionalsStep;
