import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import Add from '@material-ui/icons/Add';
import ValidationInput from '../ValidationInput';
import Snackbar from '../Snackbar/Snackbar';
import ButtonSpinner from '../ButtonSpinner';
import { browserHistory } from 'react-router';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import ApiInvoker from '../../api/ApiInvoker';
import './NewSupplyCatalog.css';

class NewSupplyCatalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
           documentTypes: [],
            documents: [],
            lastName: '',
            document: '',
            documentType: '',
            email: '',
            birthDate: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            name: '',
            supplyCatalogCode: '',
            detail: '',
            save: false
        }
    }

    componentWillMount() {
        if (this.props.documentTypes?.length > 0) {
            const documentTypes = this.formatDocumentTypes(this.props.documentTypes)
            this.setState({
                documentTypes,
                documents: this.props.documentTypes,
            })
        } else {
            this.getDocumentTypes()
        }
    }

    componentWillReceiveProps(next) {
        if (next.documentTypes) {
            const documentTypes = this.formatDocumentTypes(next.documentTypes)
            this.setState({
                documentTypes,
                documents: next.documentTypes,
            })
        }
    }

    formatDocumentTypes(documentTypes) {
        const result = documentTypes.map(d => ({
            id: d.idTypeId,
            value: `${d.name} -  ${d.type}`,
        }));
        return result;
    }

    getDocumentTypes() {
        PersonApiInvoker.getIdTypes(data => {
            this.props.onGetDocumentTypes(data);
        }, null);
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false })
            if (color === 'success') {
                browserHistory.push(`/catalogo-insumos/`)
                this.cleanFields();
            }
        }, 5000);
    }
    

    typeClick() {
        this.setState({ busy: true });
        if (this.state.name && this.state.supplyCatalogCode) {
            ApiInvoker.postSupplyCatalog({
                name: this.state.name,
                supplyCatalogCode: this.state.supplyCatalogCode,
                detail: this.state.detail,
                companyId: localStorage.getItem('itlg_default_company_id')
            }, data => {
                this.props.onAddSupplyCatalog(data);
                this.openAlert('success', this.props.t('tools.created.success'), data)
                this.setState({ busy: false });
            }, (error) => {
                this.setState({ busy: false });
                console.error(error);

            })
        } else {
            this.openAlert('warning', this.props.t('common.messageWarning.fieldsComplete'));
            this.setState({ busy: false });
        }
    }

    back() {
        browserHistory.goBack();
    }

    cleanFields() {
        this.setState({
            name: '',
            supplyCatalogCode: '',
            detail: '',
            save: false,
        })
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
    }

    render() {

        const { t } = this.props
        return (
            <GridContainer className="new-sypplycatalog">
                <GridItem xs={12} >
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <Add />
                            </CardIcon>
                            <h3 className="card-icon-title">{t("sypplycatalog.new.title")}</h3>
                        </CardHeader>
                        <CardBody className="new-sypplycatalog-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t("supplycatalog.new.supplyCatalogCode")} onChangeValue={(value) => this.handleValue(value, 'supplyCatalogCode')} value={this.state.supplyCatalogCode} invalid={!(this.state.supplyCatalogCode) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t("supplycatalog.new.name")} onChangeValue={(value) => this.handleValue(value, 'name')} value={this.state.name} invalid={!(this.state.name) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t("supplycatalog.new.detail")} onChangeValue={(value) => this.handleValue(value, 'detail')} value={this.state.detail} />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className="padding-horizontal">
                            <Button id="button-cancel" onClick={this.back.bind(this)}>
                                {t("supplycatalog.new.cancel")}
                            </Button>
                            <ButtonSpinner
                                id="button-save"
                                onClick={this.typeClick.bind(this)}
                                disabled={this.state.busy}
                                label={t('supplycatalog.new.save')}
                                labelLoading={t('supplycatalog.new.saving')}
                                loading={this.state.busy}
                                typeButton="submit"
                                color="primary"
                            />
                        </CardFooter>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

NewSupplyCatalog.propTypes = {
    t: PropTypes.func,
    onGetDocumentTypes: PropTypes.func.isRequired,
    documentTypes: PropTypes.array,
    onAddSupplyCatalog: PropTypes.func,
}

export default withTranslation()(NewSupplyCatalog);
