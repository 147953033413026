import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import pwdBy from '../../assets/img/glin-white.png';
import SidebarFooter from './SidebarFooter';
import ChatBubble from '@material-ui/icons/ChatBubble';
import Button from '../CustomButtons/Button';

const SidebarWrapper = ({action, className, headerLinks, links, miniActive }) => {
  const sidebarWrapperRef = useRef(null);

  useEffect(() => {
    const isWindows = /Win/i.test(navigator.userAgent);

    if (isWindows) {
      const ps = new PerfectScrollbar(sidebarWrapperRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });

      return () => {
        ps.destroy();
      };
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const logos = [
    { id: 1, img: pwdBy, title: 'Glin', linkTo: 'https://glin-technologies.com/' }
  ];

  return (
    <div className={className} ref={sidebarWrapperRef}>
      {headerLinks}
      {links}
      {!miniActive && 
      
     <div/>
      
    }
    </div>
  );
};

SidebarWrapper.propTypes = {
    className: PropTypes.string,
    headerLinks: PropTypes.node,
    links: PropTypes.node,
    miniActive: PropTypes.bool,
}

export default SidebarWrapper;
