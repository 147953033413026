import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  letterAvatar: {
    background: 'linear-gradient(109.06deg, #5CC4B9 -4.55%, #FFC44C 102.69%)',
    fontSize: 30,
    height: 80,
    width: 80,
  },
}));

interface ComponentProps {
  user: string;
}

const LetterAvatar = ({ user }: ComponentProps) => {
  const classes = useStyles();

  const generateInitials = (): string => {
    if (!user) {
      return '';
    }
    const initials = user.trim().split(' ');
    if (initials.length > 1) {
      return `${initials[0][0].toUpperCase()}${initials[1][0].toUpperCase()}`;
    }
    return initials[0][0].toUpperCase();
  }

  return (
    <Avatar className={classes.letterAvatar}>
      {generateInitials()}
    </Avatar>
  )
}

LetterAvatar.propTypes = {
  user: PropTypes.string.isRequired,
}

export default LetterAvatar;
