import { connect } from 'react-redux';
import { getProfessionals } from "../actions/alerts";
import Alerts from '../components/Alerts';

const mapStateToProps = (state) => {
    return {
        professionals: state.alerts.professionals,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetProfessionals: (professionals) => {
            dispatch(getProfessionals(professionals));
        }
    }
}

const AlertsService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Alerts);

export default AlertsService;