import React, { useEffect, useState } from 'react';

import Assignment from '@material-ui/icons/Assignment';
import Card from '../../Card/Card';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import HotelIcon from '@material-ui/icons/Hotel';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';

const ActiveCustomers = ({ t, appointments, classes }) => {
  const [total, setTotal] = useState(0);
  const [active, setActive] = useState(0);

  useEffect(() => {
    setTotal(appointments.length);
    //Crea array de pacientes unicos
    const notTermined = appointments.filter(s => s.appointmentStatus !== 'OVERDUE' && s.appointmentStatus !== 'ENDED');
    const setCustomers = [ ...new Set(notTermined.map(app => app.customerId)) ];
    setActive(setCustomers.length);
  }, [appointments]);

  return (
    <GridContainer className={`${classes.gridContainer} cards-resume`}>
      <GridItem className="card-resume" xs={12} sm={4} md={3} lg={3}>
        <Card className={classes.cardContainer}>
          <CardHeader stats icon className={classes.cardHeaderContainer}>
            <CardIcon color="primary" className={classes.cardIcon}>
              <HotelIcon />
            </CardIcon>
            <p className="cardTitle">{t('dashboardcustomer.activecustomers.activetotal')}</p>
            <h3 className="cardValue">
              {active}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div />
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem className="card-resume" xs={12} sm={4} md={3} lg={3}>
        <Card className={classes.cardContainer}>
          <CardHeader stats icon className={classes.cardHeaderContainer}>
            <CardIcon color="primary" className={classes.cardIcon}>
              <Assignment className={`svgIcon ${classes.icon}`} />
            </CardIcon>
            <p className="cardTitle">{t('dashboardcustomer.activecustomers.appointmentstotal')}</p>
            <h3 className="cardValue">
              {total}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div />
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

const styles = {
  gridContainer: {
    'justifyContent': 'center'
  },
  cardContainer: {
    'maxWidth': '18vw',
    'minWidth': '18vw',
  },
  cardHeaderContainer: {
    'minHeight': 80,
  },
  cardIcon: {
    width: 31,
    height: 31,
    maxWidth: 31,
    minWidth: 31,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    margin: '0 10px 0 !important',
  }
}

ActiveCustomers.propTypes = {
  t: PropTypes.func,
  appointments: PropTypes.array,
  classes: PropTypes.object,
}

export default withStyles(styles)(withTranslation()(ActiveCustomers));
