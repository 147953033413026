import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { AppointmentsRepository } from "../repositories/appointments.repository";
import { ScheduleRequest } from "../../domain";

@injectable()
export class AcceptScheduleRequestUsecase {
  @inject(AppointmentsRepository) protected repository!: AppointmentsRepository;

  async execute(scheduleId: number): Promise<Either<AcceptScheduleRequestError, ScheduleRequest[]>> {
    if (!scheduleId) {
      return Either.left(new AcceptScheduleRequestInvalidDataError());
    }
    const result = await this.repository.acceptScheduleRequest(scheduleId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): AcceptScheduleRequestError {
    if (exception instanceof NotFoundException) {
      return new AcceptScheduleRequestNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new AcceptScheduleRequestConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new AcceptScheduleRequestOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new AcceptScheduleRequestAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new AcceptScheduleRequestBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in AcceptScheduleRequestUsecase");
  }
}

export abstract class AcceptScheduleRequestError {
  error?: string;
}

export class AcceptScheduleRequestInvalidDataError extends AcceptScheduleRequestError { }
export class AcceptScheduleRequestNotFoundError extends AcceptScheduleRequestError { }
export class AcceptScheduleRequestConnectionError extends AcceptScheduleRequestError { }
export class AcceptScheduleRequestOperationalError extends AcceptScheduleRequestError { }
export class AcceptScheduleRequestAuthorizationError extends AcceptScheduleRequestError { }
export class AcceptScheduleRequestBadRequestError extends AcceptScheduleRequestError {
  constructor(public readonly error: string) {
    super();
  }
}
