import '../Dashboard.css';

import React, { Component } from 'react';

import BarChart from '@material-ui/icons/BarChart';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import ChartistGraph from 'react-chartist';
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import PieChart from '@material-ui/icons/PieChart';
import PropTypes from 'prop-types';

const status_a = ["APPROVED", "IN_PROGRESS", "DONE"];
const status_r = ["REJECTED"];

class QuantityChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total: 0,
            series: {
                labels: [],
                series: [],
                colors: [],
            }
        }
    }

    componentWillReceiveProps(next) {
        if (next.data) {
            if (next.column === 'group_acept') {
                this.formatSeriesAcept(next.data, next.column)
            } else if (next.column == 'timeAcept') {
                this.formatSeriesTimes(next.data, next.column)

            } else {
                this.formatSeries(next.data, next.column)
            }
        }

        if (next.clear) {
            this.setState({
                series: [],
            });
        }
    }

    getJsonValue(data, column) {
        const json_data = {};
        if (data?.length) {
            data.forEach(s => {
                const value = s[column];
                let ss = [];
                if (json_data[value]) {
                    ss = json_data[value];
                    if (ss) {
                        if (status_a.includes(s['scheduleStatus'])) {
                            ss[0] = ss[0] + 1;
                        }
                        if (status_r.includes(s['scheduleStatus'])) {
                            ss[1] = ss[1] + 1;
                        }
                        json_data[value] = ss;
                    }
                }
                if (status_a.includes(s['scheduleStatus'])) {
                    ss[0] = 1;
                    ss[1] = 0;
                    json_data[value] = ss;
                }
                if (status_r.includes(s['scheduleStatus'])) {
                    ss[1] = 1;
                    ss[0] = 0;
                    json_data[value] = ss;
                }
            });
        }
        return json_data;
    }

    formatSeriesAcept(data, column = "employee") {
        let labels = [];
        let seriesacept = [];
        let seriesrechaz = [];
        const json_data = this.getJsonValue(data, column);

        for(let i in json_data) {
            if (json_data[i].length > 0) {
                labels.push(i);
                seriesacept.push({ value: json_data[i][0] });
                seriesrechaz.push({ value: json_data[i][1] });
            }
        }
        const colors = [{ color: 'ct-series-a', label: "Aceptadas"}, {color: 'ct-series-b', label: "Rechazadas" }];
        this.setState({ series: { colors, labels, series: [seriesacept,seriesrechaz] } });
    }

    formatSeries(data, column) {
        const labels = [];
        const series = [];
        const colors = [];
        const json_data = {};

        if (data?.length) {
            data.map(s => {
                const value = s[column];
                if (value) {
                    if (json_data[value]) {
                        json_data[value] = json_data[value] + 1;
                    } else {
                        json_data[value] = 1;
                    }
                }
            })
        }
        let idx = 0;
        let total = 0;
        for(let i in json_data) {
            labels.push(i);
            colors.push("pie_color" + (idx+1));
            series.push({value: json_data[i], className: colors[idx]});
            total = total + json_data[i];
            idx++;
        }
        this.setState({
            total,
            series: { colors, labels, series },
        });
    }

    calculateSeries(data, column) {
        const series = Array(4).fill(0);

        if (data?.length) {
            data.forEach(s => {
                if (s[column] <= 2 ) {
                    series[0] = series[0]+1;  
                }
                if (s[column] > 2 && s[column] <= 6 ) {
                    series[1] = series[1]+1;  
                }
                if (s[column] > 6 && s[column] <= 12 ) {
                    series[2] = series[2]+1;  
                }
                if (s[column] > 12 ) {
                    series[3] = series[3]+1;  
                }
            });
        }
        return series;
    }

    processSeriesData(series) {
        const labels = ["0 a 2 hs", "2 a 6 hs", "6 a 12 hs", "más de 12 hs"];
        const colors = ["pie_color1", "pie_color2", "pie_color3", "pie_color4"];
        let total = 0;
        const seriesup = [];
        const labelsup = [];
        const colorup = [];
    
        for (let i in series) {
            if (series[i] > 0) {
                seriesup.push({ value: series[i], className: colors[i] });
                labelsup.push(labels[i]);
                colorup.push(colors[i]);
                total = total + series[i];
            }
        }
    
        this.setState({ total, series: { labels: labelsup, series: seriesup, colors: colorup } });
    }

    formatSeriesTimes(data, column) {
        const series = this.calculateSeries(data, column);
        this.processSeriesData(series);
    }

    getOptions() {
        let options = {};
        let type = "Pie";
        if (this.props.column === 'group_acept') {
            type = "Bar"
        }
        if (this.props.column === 'timeAcept') {
            type = "Pie";
        }
        const self = this;

        if (type === "Pie") {
            options = {
                // If high is specified then the axis will display values explicitly up to this value and the computed maximum from the data is ignored
                // If low is specified then the axis will display values explicitly down to this value and the computed minimum from the data is ignored
                // This option will be used when finding the right scale division settings. The amount of ticks on the scale will be determined so that as many ticks as possible will be displayed, while not violating this minimum required space (in pixel).
                scaleMinSpace: 20,
                // Can be set to true or false. If set to true, the scale will be generated with whole numbers only.
                onlyInteger: true,
                // The reference value can be used to make sure that this value will always be on the chart. This is especially useful on bipolar charts where the bipolar center always needs to be part of the chart.
                referenceValue: 5,
                height: '330px',
                fontSize: '10',
                chartPadding: 35,
                labelOffset: 90,
                labelInterpolationFnc: function(label, index) {
                    if (self.state.series.series.length === 0) {
                        return '0';
                    } 
                    return Math.round(self.state.series.series[index].value / self.state.total * 100) + '%';
                }   
            };
        } else {
            options = {
                // If high is specified then the axis will display values explicitly up to this value and the computed maximum from the data is ignored
                // If low is specified then the axis will display values explicitly down to this value and the computed minimum from the data is ignored
                // This option will be used when finding the right scale division settings. The amount of ticks on the scale will be determined so that as many ticks as possible will be displayed, while not violating this minimum required space (in pixel).
                scaleMinSpace: 20,
                // Can be set to true or false. If set to true, the scale will be generated with whole numbers only.
                // The reference value can be used to make sure that this value will always be on the chart. This is especially useful on bipolar charts where the bipolar center always needs to be part of the chart.
                referenceValue: 5,
                height: '300px',
                axisX: {
                    // The offset of the chart drawing area to the border of the container
                    offset: 100,
                    // Position where labels are placed. Can be set to `start` or `end` where `start` is equivalent to left or top on vertical axis and `end` is equivalent to right or bottom on horizontal axis.
                    position: 'end',
                    // Allows you to correct label positioning on this axis by positive or negative x and y offset.
                    labelOffset: {
                      x: 0,
                      y: 0
                    },
                    // If labels should be shown or not
                    showLabel: true,
                    // If the axis grid should be drawn or not
                    showGrid: true,
                    // Interpolation function that allows you to intercept the value from the axis label
                    // This value specifies the minimum width in pixel of the scale steps
                    scaleMinSpace: 30,
                    // Use only integer values (whole numbers) for the scale steps
                    onlyInteger: true
                  },
                  axisY: {
                    // The offset of the chart drawing area to the border of the container
                    offset: 40,
                    // Position where labels are placed. Can be set to `start` or `end` where `start` is equivalent to left or top on vertical axis and `end` is equivalent to right or bottom on horizontal axis.
                    position: 'start',
                    // Allows you to correct label positioning on this axis by positive or negative x and y offset.
                    labelOffset: {
                      x: 0,
                      y: 0
                    },
                    // If labels should be shown or not
                    showLabel: true,
                    // If the axis grid should be drawn or not
                    showGrid: true,
                    // Interpolation function that allows you to intercept the value from the axis label
                    // This value specifies the minimum height in pixel of the scale steps
                    scaleMinSpace: 20,
                    // Use only integer values (whole numbers) for the scale steps
                    onlyInteger: true
                  },
            };
        }
        return { options, type };
    }

    render() {
        const { options, type } = this.getOptions();
        const self = this;

        return (
            <Card className="quantity-chart">
                <CardHeader color={this.props.color || "info"} icon>
                    <CardIcon color={this.props.color || "info"}>
                        {this.props.icon ? <PieChart/> : <BarChart />}
                    </CardIcon>
                    {type === 'Bar' ?
                        <div>
                            <h3 className="card-icon-title">
                                { this.props.title } <small>{ this.props.subtitle }</small>
                            </h3>

                            <div className="chart-references by-icon-title">
                                {this.state.series.colors && this.state.series.colors.length >0 && this.state.series.colors.map(function(item, i){
                                    return(
                                    <div className="item-reference" key={`${self.state.series.colors[i].color}-reference`}>
                                        <span className={`${self.state.series.colors[i].color} reference`}></span>
                                        <span>{item !== 'undefined' ? item.label : ""}</span>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                    : <h3 className="card-icon-title">{this.props.title}</h3>
                    }
                    </CardHeader>
                <CardBody>
                    <ChartistGraph
                        style={{height: '300px'}}
                        data={this.state.series}
                        options={options}
                        type={type}
                    ></ChartistGraph>
                </CardBody>
                <CardFooter>
                    <GridContainer className="dashboard-charts">
                        <GridItem xs={12} >
                            {type === 'Pie' ?
                                <div style={{width: '100%', justifyContent: 'center', display: 'block', alignItems: 'center', justifyItems: 'center'}}>
                                    <h6 className='legend'>Leyenda</h6>
                                    {this.state.series.colors && this.state.series.colors.length >0 && this.state.series.labels.map(function(item, i){
                                        return(
                                            <span key={`${self.state.series.colors[i]}-pie`}>
                                                <span style={{width: 10, height: 10, marginLeft: 10}} className={"dot "  + self.state.series.colors[i]}></span>
                                                <span className='labelLeyend'>{item !== 'undefined' ? item : ""}</span>
                                            </span>
                                        );
                                    })}
                                </div>
                            : <div/>
                            }
                        </GridItem>
                    </GridContainer>
                </CardFooter>
            </Card>
        )
    }
}

QuantityChart.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    column: PropTypes.string,
}

export default QuantityChart;