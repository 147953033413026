import { FETCH_COMPANY_FINANCIERS } from '../actions/financiers';

const companyFinanciers = (state = {}, action) => {
    if (action.type === FETCH_COMPANY_FINANCIERS) {
        return { ...state, companyFinanciers: action.companyFinanciers };
    }
    return state;
}

export default companyFinanciers;
