import '../../assets/plugins/react-charts.css';
import './Dashboard.css';

import { Element, Events, scroller } from 'react-scroll';
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps';
import React, { Component } from 'react';

import BarChartIcon from '@material-ui/icons/BarChart';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Chartist from 'chartist';
import CustomDialog from '../CustomDialog';
import DashboardApiInvoker from '../../api/DashboardApiInvoker';
import DesviationChart from './DesviationChart/index.js';
import DurationChart from './DurationChart/index.js';
import { FiltersService } from '../../containers/DashboardService';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { MedicalDoctor } from '../../icons';
import PinGreen from '../../assets/img/pin_green_employee.png';
import PinRed from '../../assets/img/pin_red.png';
import PopupSchedules from './Popups/PopupSchedules.js';
import PopupSchedulesRejected from './Popups/PopupSchedulesRejected.js';
import PropTypes from 'prop-types';
import QuantityChart from './QuantityChart/index.js';
import RoomIcon from '@material-ui/icons/Room';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import ThirdPartiesInvoker from '../../api/ThirdPartiesInvoker';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={15}
            center={{ lat: props.locationcustomer.lat, lng: props.locationcustomer.lng }}
            defaultOptions={{
                scrollwheel: false,
            }}
        >
            <Marker key={'customerpin'}
                className="routes-marker"
                defaultIcon={{
                    url: PinGreen,
                    scaledSize: { width: 22, height: 28 },
                    size: { width: 22, height: 28 },
                    labelOrigin: new window.google.maps.Point(20, 15)
                }}
                position={props.locationcustomer}
            />
            <Marker key={'schedulestartpin'}
                className="routes-marker"
                defaultIcon={{
                    url: PinRed,
                    scaledSize: { width: 22, height: 28 },
                    size: { width: 22, height: 28 },
                    labelOrigin: new window.google.maps.Point(20, 15)
                }}
                position={props.locationstartschedule}
            />
            <Marker key={'scheduleendpin'}
                className="routes-marker"
                defaultIcon={{
                    url: PinRed,
                    scaledSize: { width: 22, height: 28 },
                    size: { width: 22, height: 28 },
                    labelOrigin: new window.google.maps.Point(20, 15)
                }}
                position={props.locationendschedule}
            />
        </GoogleMap>
    ))
)

const options = {
    lineSmooth: Chartist.Interpolation.cardinal({
        tension: 10
    }),
    chartPadding: {
        top: 15,
        right: 30,
        bottom: 5,
        left: 30
    },
    axisY: {
        // The offset of the chart drawing area to the border of the container
        offset: 30
    },
    axisX: {
        showGrid: false,
        position: 'end'
    },
    seriesBarDistance: 5,
    fullWidth: true,
    low: -100,
    high: 100,
    height: '300px',
    classNames: {
        point: "ct-point ct-white",
        line: "ct-line ct-white"
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            employeeTypesFilter: [],
            professionals: [],
            schedules: [],
            dateFrom: '',
            dateTo: '',
            employeeId: '',
            graphics: false,
            clear: false,
            originalquery: [],
            locationcustomer: { lat: 0.0, lng: 0.0 },
            locationstartschedule: { lat: 0.0, lng: 0.0 },
            locationendschedule: { lat: 0.0, lng: 0.0 },
            locationcustomerstr: '',
            locationstartschedulestr: '',
            locationendschedulestr: '',
            open: false,
            openPopupSchedule: false,
            openPopupScheduleRejected: false,
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
        }
    }

    componentWillMount() {
        if (this.props.employees && this.props.employees.length > 0) {
            this.setState({ professionals: this.props.employees });
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.setState({
                employees: next.employees,
                professionals: next.employees,
            });
        }
    }

    componentDidMount() {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    showGraphicsInfo(employeeId) {
        this.setState({ graphics: true, employeeId }, () => {
            this.setState({ graphics: false });      
        });
        this.scrollTo();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-charts', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });
        setTimeout(() => {
            this.setState({ alertOpen: false })
            if (color === 'success') {
                this.cleanFields();
            }
        }, 5000);
    }

    setFilters(filters) {
        if (filters.professional || this.state.employeeTypesFilter) {
            this.setState({
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo,
                loadingEmployeesTable: true,
                openPopupSchedule:false,
                openPopupScheduleReject:false,
            });
            DashboardApiInvoker.postEmployeeDashboard({
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo,
                employees: filters.professional || [],
                employeeTypes: filters.employeeTypesFilter || [],
            }, data => {
                if (data.length > 0) {
                    this.formatTableData(data);
                    this.setState({ loadingEmployeesTable: false });
                } else {
                    this.setState({
                        employees: [],
                        loadingEmployeesTable: false,
                    });
                }
            }, err => {
                this.openAlert('danger', err.message + '');
                console.error(err);
                this.setState({
                    employees: [],
                    loadingEmployeesTable: false,
                });
            });
        }
    }

    formatEmployeeTypeString(types) {
        let typesv = '';
        types.map(e => {
            typesv = typesv + (typesv === '' ? '' : ',') + e.name
        });
        return typesv;
    }

    formatTableData(data) {
        const { t } = this.props;
        if (!data) {
            return [];
        }
        const result = data.map(({ employee, schedulesQuantityFinished, schedulesQuantity, customersQuantityVisited, deviationInAttentionTime, percentagePunctuality, percentageOfPatientIdentityChecks, schedulesRejectedQuantity }) => {
            if (employee) {
              const employeeId = employee.personId;
              const employeeName = `${employee.lastName} ${employee.firstName}`;
              const quantity = (
                <Button link className="dashboard-link" onClick={() => this.openPopup('openPopupSchedule', employeeId)}>
                  {schedulesQuantityFinished} / {schedulesQuantity}
                </Button>
              );
              const rejectedQuantity = (
                <Button link className="dashboard-link" onClick={() => schedulesRejectedQuantity > 0 ? this.openPopup('openPopupScheduleRejected', employeeId) : ''}>
                  {schedulesRejectedQuantity}
                </Button>
              );
              return {
                employeeId,
                employee: employeeName,
                quantity,
                customers: customersQuantityVisited,
                deviation: deviationInAttentionTime,
                punctuality: percentagePunctuality,
                verifications: percentageOfPatientIdentityChecks,
                rejectedQuantity,
                activeEmployee: employee.active ? t('employee.active') : t('employee.deactive'),
                employeeTypes: this.formatEmployeeTypeString(employee.employeeTypes),
                actions: (
                  <div className="dashboard-actions">
                    <Tooltip justIcon title={t('dashboard.table.professionals.charts.tooltip')}>
                      <Button id={`button-info-${employeeId}`} onClick={() => this.showGraphicsInfo(employeeId)} color="primary">
                        <BarChartIcon />
                      </Button>
                    </Tooltip>
                  </div>
                ),
              };
            }
            return null;
        }).filter(Boolean); 
        this.setState({ employees: result, originalquery: result, loadingEmployeesTable: false });
    }

    openPopup(name, employeeId) {
        this.setState({ employeeId, [name]:true });
    }

    closePopup = (name) => {
        this.setState({ [name]: false });
    }

    openMap = (schedule) => {
        const customeraddress = schedule.address.street + ' ' + String(schedule.address.number) + ' ' + schedule.address.zipCode +
            (schedule.address.locationName ? ' , ' + schedule.address.locationName : '') +
            (schedule.address.countryName ? ' , ' + schedule.address.countryName : '');

        this.setState({
            locationcustomer: { lat: schedule.customerLatitude, lng: schedule.customerLongitude },
            locationstartschedule: { lat: schedule.startLatitude, lng: schedule.startLongitude },
            locationendschedule: { lat: schedule.endLatitude, lng: schedule.endLongitude },
            locationcustomerstr: customeraddress,
            locationstartschedulestr: '',
            locationendschedulestr: '',
        }, () => this.showMap());
    }

    showMap() {
        ThirdPartiesInvoker.getGoogleLocationBasedOnLatLong(this.state.locationstartschedule.lat, this.state.locationstartschedule.lng, this.handleLocationFromGeopositionStartSchedule.bind(this));
        ThirdPartiesInvoker.getGoogleLocationBasedOnLatLong(this.state.locationendschedule.lat, this.state.locationendschedule.lng, this.handleLocationFromGeopositionEndSchedule.bind(this));

        this.setState({ showMap: true, selectedRow: false });
    }

    handleLocationFromGeopositionStartSchedule(responseJson) {
        if (responseJson.status === 'OK') {
            if (responseJson.results.length > 0) {
                this.setState({ locationstartschedulestr: responseJson.results[0].formatted_address });
            }
        }
    }

    handleLocationFromGeopositionEndSchedule(responseJson) {
        if (responseJson.status === 'OK') {
            if (responseJson.results.length > 0) {
                this.setState({ locationendschedulestr: responseJson.results[0].formatted_address });
            }
        }
    }
    
    clearInformation() {
        this.setState({
            employees: [],
            employeeId: '',
            dateFrom: '',
            dateTo: '',
            clear: true,
            open: false,
        });
    }

    render() {
        const { t } = this.props;
        const { alertColor, alertMessage, alertOpen, clear, dateFrom, dateTo, employees, employeeId,
            graphics, loadingEmployeesTable, locationcustomer, locationcustomerstr,
            locationstartschedulestr, locationendschedule, locationendschedulestr, locationstartschedule,
            openPopupSchedule, openPopupScheduleRejected, showMap,
        } = this.state;

        return (
            <div className="dashboard profesionals-dashboard">
                <GridContainer>
                    <GridItem className="table-container" xs={12}>
                        <Card className="dashboard-table">
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <MedicalDoctor />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('dashboard.table.professionals')}</h3>
                                <FiltersService
                                    loading={loadingEmployeesTable}
                                    onChange={(filters) => this.setFilters(filters)}
                                    onClearFilters={() => this.clearInformation()}
                                />
                            </CardHeader>
                            <CardBody className="dashboard-content-body">
                                <Snackbar
                                    place="tr"
                                    color={alertColor}
                                    message={alertMessage}
                                    open={alertOpen}
                                />
                                <Table
                                    loading={loadingEmployeesTable}
                                    tableHeaderColor="primary"
                                    sortable={true}
                                    tableHead={[
                                        { Header: t('dashboard.table.professionals.professional'), accessor: 'employee' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.quantity_visits.tooltip')}><span>{t('dashboard.table.professionals.quantity_visits')}</span></Tooltip>, accessor: 'quantity' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.quantity_customers.tooltip')}><span>{t('dashboard.table.professionals.quantity_customers')}</span></Tooltip>, accessor: 'customers' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.attention_deviation.tooltip')}><span>{t('dashboard.table.professionals.attention_deviation')}</span></Tooltip>, accessor: 'deviation' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.puntuality.tooltip')}><span>{t('dashboard.table.professionals.puntuality')}</span></Tooltip>, accessor: 'punctuality' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.rejectedQuantity')}><span>{t('dashboard.table.professionals.rejectedQuantity')}</span></Tooltip>, accessor: 'rejectedQuantity' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.verifications.tooltip')}><span>{t('dashboard.table.professionals.verifications')}</span></Tooltip>, accessor: 'verifications' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.state')}><span>{t('dashboard.table.professionals.state')}</span></Tooltip>, accessor: 'activeEmployee' },
                                        { Header: <Tooltip title={t('dashboard.table.professionals.charts.tooltip')}><span>{t('dashboard.table.professionals.actions')}</span></Tooltip>, accessor: 'actions' },
                                    ]}
                                    tableData={employees}
                                    defaultPageSize={employees.length > 5 ? 20 : 5}
                                    colorsColls={['primary']}
                                />
                            </CardBody>
                        </Card>
                        <Element name="scroll-to-charts">
                            <GridContainer className="dashboard-charts">
                                <GridItem xs={12}>
                                    <QuantityChart options={options} employeeId={employeeId} dateFrom={dateFrom} dateTo={dateTo} find={graphics} clear={clear} />
                                </GridItem>
                                <GridItem xs={12}>
                                    <DurationChart options={options} employeeId={employeeId} dateFrom={dateFrom} dateTo={dateTo} find={graphics} clear={clear} />
                                </GridItem>
                                <GridItem xs={12}>
                                    <DesviationChart options={options} employeeId={employeeId} dateFrom={dateFrom} dateTo={dateTo} find={graphics} clear={clear} />
                                </GridItem>
                            </GridContainer>
                        </Element>
                    </GridItem>
                </GridContainer>
                
                <PopupSchedules
                    t={t}
                    open={openPopupSchedule}
                    dateFrom={dateFrom}
                    dateTo={dateTo} 
                    employeeId={employeeId}
                    openMap={this.openMap}
                    closePopup={this.closePopup}
                />

                <PopupSchedulesRejected
                    t={t}
                    open={openPopupScheduleRejected}
                    dateFrom={dateFrom}
                    dateTo={dateTo} 
                    employeeId={employeeId}
                    openMap={this.openMap}
                    closePopup={this.closePopup}
                />

                <CustomDialog
                    className="customer-address-detail"
                    open={showMap}
                    title={t('dashboard.schedule.address')}
                    onClose={() => this.setState({ selectedRow: true, showMap: false })}
                >
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <RoomIcon />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <RegularMap
                                locationcustomer={locationcustomer}
                                locationstartschedule={locationstartschedule}
                                locationendschedule={locationendschedule}
                                googleMapURL="/googlemap"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={
                                    <div
                                        style={{
                                            height: `500px`,
                                            borderRadius: '6px',
                                            overflow: 'hidden',
                                            width: `100%`,
                                        }}
                                    />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </CardBody>
                    </Card>
                    <GridContainer className="address-legend">
                        <GridItem xs={12} className="address-customer">
                            <p>
                                <img alt="address" src={PinGreen} style={{ width: 16, height: 24 }} />
                                <span className="label-legend-map"><strong>{t('dashboard.schedule.map.addresscustomer')}</strong>: {locationcustomerstr}</span>
                            </p>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <p>
                                <img alt="address" src={PinRed} style={{ width: 16, height: 24 }} />
                                <span className="label-legend-map"><strong>{t('dashboard.schedule.map.addressstartschedule')}</strong>: {locationstartschedulestr}</span>
                            </p>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <p>
                                <img alt="address" src={PinRed} style={{ width: 16, height: 24 }} />
                                <span className="label-legend-map"><strong>{t('dashboard.schedule.map.addressendschedule')}</strong>: {locationendschedulestr}</span>
                            </p>
                        </GridItem>
                    </GridContainer>
                </CustomDialog>
            </div>
        )
    }
}

Dashboard.propTypes = {
    t: PropTypes.func,
    employees: PropTypes.array,
}


export default withTranslation()(Dashboard);
