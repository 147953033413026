import Cookies from 'universal-cookie';
import { browserHistory } from 'react-router';

const contentTypeBlob = 'blob';
const langMap = { "es": "es-ES", "en": "en-US", "pt": "pt-BR", "it": "it-IT", "fr": "fr-FR" }

export default class BaseInvoker  {

	get(url, callback, callerror) {
		this.invoke(url, 'GET', null, callback, callerror, true);
	}

	getBlob(url, callback, callerror) {
		this.invoke(url, 'GET', null, callback, callerror, true, contentTypeBlob);
	}

	getWithoutSecurityHeaders(url, callback, callerror) {
		this.invoke(url, 'GET', null, callback, callerror, false);
	}

	post(url, body, callback, callerror) {
		this.invoke(url, 'POST', body, callback, callerror, true);
	}

	put(url, body, callback, callerror) {
		this.invoke(url, 'PUT', body, callback, callerror, true);
	}

	delete(url, body, callback, callerror) {
		this.invoke(url, 'DELETE', body, callback, callerror, true);
	}

	patch(url, body, callback, callerror) {
		this.invoke(url, 'PATCH', body, callback, callerror, true);
	}

	option(url, body, callback, callerror) {
		this.invoke(url, 'OPTION', body, callback, callerror, true);
	}

	invoke(url, type, body, callback, callerror, secureHeaders, contentType = 'application/json') {
		const params = this.buildRequestParams(type, body, contentType);
		fetch(url, params)
			.then((response) => {
				if(response.redirected) {
					browserHistory.push('/');
					return;
				}
				if (response.ok) {
					if (contentType === contentTypeBlob) {
						const reader = response.blob();
						return reader;
					}
					return response.text().then(resp => (resp ? JSON.parse(resp) : {}));
				} else {
					// BAD REQUEST
					this.handleFailure(response, callerror);
				}
			}).then(data => {	
				if(!data) return;	

				if(data && !data.message){
					if (callback) {
						callback(data);
					}
				} else if (callerror) {
					callerror(data);
				}				
			}).catch(e => {	
				console.error('** Error', e);					

				if (callerror) {
					callerror(e);
				}
			})
	}

	redirectLogin() {
		localStorage.removeItem('user_id');
		browserHistory.push('/login');
	}

	getLangByCookies() {
		const cookies = new Cookies();
        let lang = cookies.get("lang");
		if (lang == null) {
			return langMap['es']
		} else {
			return langMap[lang] == null ? langMap['es']: langMap[lang];
		}
	}

	buildRequestParams(type, body, contentType) {
		const params = {
			method: type,
			headers: {
				'Content-Type': contentType,
				'Accept-Language': this.getLangByCookies()
			}
		};
		if (body) {
			params.body = JSON.stringify(body);
		}
		return params;
	}

	buildResponseError(response, callerror) {
		response.text().then((text) => {
			try {
				const t = JSON.parse(text);
				if (callerror && t && t.hasOwnProperty('message')) {
					callerror(new Error(t.message));
				} else if (callerror && t && t.hasOwnProperty('messages')) {
					callerror(new Error(t.messages.toString()));
				}
			} catch (error) {
				if (callerror) {
					const err =  { message: text, status: response.status, statusText: response.statusText }
					callerror(err);
				}
			}
		});
	}

	handleFailure(response, callerror) {
		if (response.status === 404 || response.status === 400 || response.status === 500) {
			this.buildResponseError(response, callerror);
		}
		if (response.status === 401) {
			this.redirectLogin();						
			return null;
		}
		if (response.status === 504) {
			callerror(new Error('TIME_OUT_EXCEPTION'));
		}
	}

}
