export class AppointmentItem {
  companyId: number;
  name: string;
  practiceTypeId?: number;
  packageId?: number;
  employeeTypeId?: number;
  estimatedDuration?: number;
  every?: number;
  recurrenceType?: RecurrenceType;
  weekDays?: string;
  monthDay?: string;
  processingScheduleType?: ProcessingScheduleType;
  quantityIntraday?: number;
  practiceStatus?: string;
  partnershipName?: string;
  partnership?: number;
  observations?: string;

  constructor({
    companyId, name, observations, practiceTypeId, packageId, employeeTypeId, estimatedDuration, every, recurrenceType, weekDays, monthDay, processingScheduleType, quantityIntraday, practiceStatus, partnershipName, partnership,
  }: AppointmentItemDto) {
    this.companyId = companyId;
    this.name = name;
    this.practiceTypeId = practiceTypeId;
    this.packageId = packageId;
    this.employeeTypeId = employeeTypeId;
    this.estimatedDuration = estimatedDuration;
    this.every = every;
    this.recurrenceType = recurrenceType;
    this.weekDays = weekDays;
    this.monthDay = monthDay;
    this.processingScheduleType = processingScheduleType;
    this.quantityIntraday = quantityIntraday;
    this.practiceStatus = practiceStatus;
    this.partnershipName = partnershipName;
    this.partnership = partnership;
    this.observations = observations;
  }
}

export interface AppointmentItemDto {
  companyId: number;
  name: string;
  practiceTypeId?: number;
  packageId?: number;
  employeeTypeId?: number;
  estimatedDuration?: number;
  every?: number;
  recurrenceType?: RecurrenceType;
  weekDays?: string;
  monthDay?: string;
  processingScheduleType?: ProcessingScheduleType;
  quantityIntraday?: number;
  practiceStatus?: string;
  partnershipName?: string;
  partnership?: number;
  observations?: string;
}

export enum RecurrenceType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  NULL = 'null',
}

export enum ProcessingScheduleType {
  INTRADAY = 'INTRADAY',
  SCHEDULE = 'SCHEDULE',
}
