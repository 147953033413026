import '../../assets/plugins/react-charts.css';
import './DashboardPB.css';

import React, { Component } from 'react';

import ApiPowerBI from '../../api/ApiPowerBI';
import Cookies from 'universal-cookie';
import PowerBiComponent from './PowerBiComponent';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class DashboardManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: 0,
      isNewVersion: false,
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({
      companyId: localStorage.getItem('itlg_default_company_id'),
    });
    ApiPowerBI.callReport("GERENCIAL", this.onSuccess, this.onError);
  }
  onSuccess = (data) => {
    this.setState({ type: "GERENCIAL", reportToken: data.reportToken, reportId: data.reportId, isNewVersion: true, error: null, loading: false });
  }

  onError = () => {
    this.setState({ isNewVersion: false, loading: false });
  }

  getLinkCompany11() {
    const companyPartnerShipLinks = {
      1: 'https://app.powerbi.com/reportEmbed?reportId=68f0a9cb-7d07-4836-bcf0-eaacc79fc8c5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      8: 'https://app.powerbi.com/reportEmbed?reportId=68f0a9cb-7d07-4836-bcf0-eaacc79fc8c5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      13: 'https://app.powerbi.com/view?r=eyJrIjoiMzJjMTFlYTQtMjEwZS00MWVmLWEwZjctMjEwZDRmYzhhMWUwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      14: 'https://app.powerbi.com/reportEmbed?reportId=68f0a9cb-7d07-4836-bcf0-eaacc79fc8c5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      15: 'https://app.powerbi.com/reportEmbed?reportId=68f0a9cb-7d07-4836-bcf0-eaacc79fc8c5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      16: 'https://app.powerbi.com/view?r=eyJrIjoiMDllNTUzYWUtZmRiMy00MjJhLWFkNDQtZTUyZjUwZmJiY2VkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      17: 'https://app.powerbi.com/view?r=eyJrIjoiNDc0MDNlOGUtYjczNC00YWE1LTk0ZjUtNDE0NTlmZmMyMzlkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      18: 'https://app.powerbi.com/reportEmbed?reportId=68f0a9cb-7d07-4836-bcf0-eaacc79fc8c5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      19: 'https://app.powerbi.com/view?r=eyJrIjoiOTczZWQ5ZTctNTAzYS00ODdmLThjMGItODQ5MWZiNzQ4MWY2IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      20: 'https://app.powerbi.com/view?r=eyJrIjoiNjFkMWU3ZWItYTcyMS00MDhiLWE4ZDItNDE1NjU4OTE5OGJkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      23: 'https://app.powerbi.com/view?r=eyJrIjoiYmE2NjVkYjctZjI4MC00ZmY5LWE1MTYtYzMwNWM0ODJmMTYyIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      24: 'https://app.powerbi.com/view?r=eyJrIjoiZjdlNmUxYzktMzkwMy00NDc3LWE4MzQtZTYxNGM4ZjIwNmUyIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      25: 'https://app.powerbi.com/view?r=eyJrIjoiNWYzY2JhZmMtOGM0Ni00NTczLWIyNTItZmY3Mzc3NjdkNjQwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
    };

    const companyPartnerShip = parseInt(localStorage.getItem('company_partnership_id'));
    return companyPartnerShipLinks[companyPartnerShip] || '';
  }
  
  getDashboardLink() {
    const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    const companyDashboardLinks = {
      1: 'https://app.powerbi.com/reportEmbed?reportId=a0cab4f7-9028-4793-85c3-d5b728fe1eec&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      4: 'https://app.powerbi.com/view?r=eyJrIjoiOTFjMjBjMjktZmI5My00MDlkLTgwMmUtYmZiOWQ2N2ZkNmIwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      5: 'https://app.powerbi.com/reportEmbed?reportId=3598dfd7-bda9-44c1-a13e-ef1270463544&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
      7: 'https://app.powerbi.com/reportEmbed?reportId=1d8c9b8d-0a13-4e4e-aa0c-e0598ebc702e&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22',
      8: 'https://app.powerbi.com/reportEmbed?reportId=a0cab4f7-9028-4793-85c3-d5b728fe1eec&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      10: 'https://app.powerbi.com/view?r=eyJrIjoiN2EzMGFmY2EtNGUzMS00ZjFlLThkYzEtNmFhMWY0NWM4MzMzIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      11: this.getLinkCompany11(),
      12: 'https://app.powerbi.com/reportEmbed?reportId=c979532c-9102-48c9-bc0d-a7cc53ee69c3&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22',
      13: 'https://app.powerbi.com/view?r=eyJrIjoiMDU0YjZiODMtNTI5ZS00ZDBiLTk5YmMtNjJlZjAyNTg1ZDEwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      14: 'https://app.powerbi.com/reportEmbed?reportId=a0cab4f7-9028-4793-85c3-d5b728fe1eec&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      15: 'https://app.powerbi.com/reportEmbed?reportId=a0cab4f7-9028-4793-85c3-d5b728fe1eec&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      16: 'https://app.powerbi.com/view?r=eyJrIjoiMDllNTUzYWUtZmRiMy00MjJhLWFkNDQtZTUyZjUwZmJiY2VkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      17: 'https://app.powerbi.com/view?r=eyJrIjoiNzc4NjFkMWUtNzIwYy00NmZjLThjNzQtYTA5OTgwZDM2Mzk2IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      19: 'https://app.powerbi.com/view?r=eyJrIjoiMWM3NjQ3YTItNmJmNy00NDA0LThlMzEtN2Y3ZTZkMTQ3ODY5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      20: 'https://app.powerbi.com/view?r=eyJrIjoiNjFkMWU3ZWItYTcyMS00MDhiLWE4ZDItNDE1NjU4OTE5OGJkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      21: 'https://app.powerbi.com/view?r=eyJrIjoiZmU0ZDcxMWUtOWFhMi00NWJkLWE0ODItZWQ1NzAzM2Y4NTEwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      22: 'https://app.powerbi.com/view?r=eyJrIjoiYWNhYmNjNjYtY2ZhZS00YTcyLThiYmMtZTVhNjFmNGI0MDMyIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      27: 'https://app.powerbi.com/reportEmbed?reportId=3c5753ea-af48-4258-9736-8ba607dabec1&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      28: 'https://app.powerbi.com/view?r=eyJrIjoiM2E2N2RhY2ItYTIyOS00Zjc2LWFiYmQtN2Q0ZmY4NWJmNGYxIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      29: 'https://app.powerbi.com/view?r=eyJrIjoiMjYyNjQ3ZmYtZjljOC00MjkwLWIyNWMtNWZmZWYwZjIwZWJmIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
    };
    
    return companyDashboardLinks[companyId] ||  '';
  }

  render() {
    const { t } = this.props;
    const { isNewVersion, loading, reportId, reportToken } = this.state;
    const cookies = new Cookies();
    const lang = cookies.get("lang") || 'es';

    if (loading) {
      return <div />
    }

    if (!isNewVersion) {
      const DASHBOARD_LINK = this.getDashboardLink();
      return (
        <div className="div">
          <iframe
            width="100%"
            height={window.innerHeight}
            src={DASHBOARD_LINK}
            allowFullScreen={true}
            title="DASHBOARD"
            style={{ border: '0' }}
          ></iframe>{' '}
        </div>
      )
    }
    if (reportToken != null) {
      return (
        <PowerBiComponent
          reportId={reportId}
          reportToken={reportToken}
          lang={lang}
        />
      );
    }
    return (<div>{t('table.loading')}</div>)
  }
}

DashboardManagement.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(DashboardManagement);
