import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException, VoidSuccess } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { UsersRepository } from "../repositories";

@injectable()
export class UpdateUserTermsUsecase {

  @inject(UsersRepository) protected repository!: UsersRepository;

  async execute(employeeId: number, version: string, isAccept: boolean): Promise<Either<UpdateUserTermsError, VoidSuccess>> {
    if (!employeeId || !version) {
      return Either.left(new UpdateUserTermsInvalidDataError());
    }
    const result = await this.repository.updateUserTerms(employeeId, version, isAccept);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): UpdateUserTermsError {
    if (exception instanceof NotFoundException) {
      return new UpdateUserTermsNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new UpdateUserTermsConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new UpdateUserTermsOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new UpdateUserTermsAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new UpdateUserTermsBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in UpdateUserTermsUsecase");
  }
}

export abstract class UpdateUserTermsError {
  error?: string;
}

export class UpdateUserTermsInvalidDataError extends UpdateUserTermsError { }
export class UpdateUserTermsNotFoundError extends UpdateUserTermsError { }
export class UpdateUserTermsConnectionError extends UpdateUserTermsError { }
export class UpdateUserTermsOperationalError extends UpdateUserTermsError { }
export class UpdateUserTermsAuthorizationError extends UpdateUserTermsError { }
export class UpdateUserTermsBadRequestError extends UpdateUserTermsError {
  constructor(public readonly error: string) {
    super();
  }
}
