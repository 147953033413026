import '@material/react-chips/dist/chips.css';

import { Chip, ChipSet } from '@material/react-chips';
import React, { Component } from 'react';

import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ChipFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            professionals: [],
            employees: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            elements: [],
        }
    }

    addFilter = (s) => {
        const c = this.props.chips;
        c.push(s);
        this.props.onChangeChips(c, this.props.chips);
    }

    removeChip = (keyremove) => {
        this.props.chips.map((item, key) => {
            if (item.code === keyremove) {
                this.props.chips.splice(key,1);
            }
        });
    }

    onChipRemove = (s) => {
        this.props.chips.map((item, key) => {
            const label = `${item.title} '${item.name}'`;
            if (label === s) {
                if (this.props.chips[key].code === 'city') {
                    this.removeChip('geographicZone');
                }
                if (this.props.chips[key].code === 'province') {
                    this.removeChip('city');
                    this.removeChip('geographicZone');
                }
                if (this.props.chips[key].code === 'disabledPatient') {
                    this.removeChip('activeDisabilityCertificate');
                }
                this.props.chips.splice(key, 1);
                this.props.onChangeChips(this.props);
            }
        });

        this.setState({refresh: true})
    }

    renderChipsLabel = (chips) => chips.map((item) => {
        const label = `${item.title} '${item.name}'`;
        return (
            <Chip
                key={item.code}
                className="chipsInFilter"
                filter={true}
                selecteditems={chips}
                trailingIcon={<Cancel />}
                shouldRemoveOnTrailingIconClick={false}
                handleTrailingIconInteraction={this.onChipRemove}
                id={label}
                label={label}
            />
        );
    })

    render() {
        const { chips, employees, employeesType, financiers, patologies, provincies, t } = this.props;
        return (
            <ChipSet>
                <CustomInputChip
                    selecteditems={chips} 
                    id="add-filter"
                    label={t('common.addFilter')}
                    employees={employees}
                    employeesType={employeesType}
                    financiers={financiers} 
                    patologies={patologies} 
                    onApply={this.addFilter} 
                    provincies={provincies} 
                />
                {this.renderChipsLabel(chips)}
            </ChipSet>
        )
    }
}

ChipFilters.propTypes = {
    t: PropTypes.func,
    employees: PropTypes.array,
    employeesType: PropTypes.array,
    chips: PropTypes.array,
    onChangeChips: PropTypes.func,
    financiers: PropTypes.array,
    patologies: PropTypes.array,
    provincies: PropTypes.array,
}

export default withTranslation()(ChipFilters);
