import React, { Component } from 'react';

import Button from '../../CustomButtons/Button';
import ConfirmAlert from '../../ConfirmAlert';
import { Delete } from '@material-ui/icons';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import { withTranslation } from 'react-i18next';

class EmployeeDriverGeographic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employeeGeographic: [],
            personId: '',
            success: false,
            error: false,
            loading: false,
            deleteAlert: false,
            selectedGeographic: '',
        }
    }

    componentWillReceiveProps(next) {
    if(next.personId !== this.props.personId) {
        PersonApiInvoker.getEmployeeGeographics(next.personId, (data) => {
            this.props.onGetEmployeeGeographics(data);
        });
    } 
}

    deleteEmployeeGeographic(geographicId) {
        this.setState({ deleteAlert: true, selectedGeographic: geographicId });
    }

    deletedItemGeographic() {
        const { employeeGeographic, selectedGeographic } = this.state;
        PersonApiInvoker.deleteEmployeeGeographic(this.props.personId, selectedGeographic, () => {
            const employee = employeeGeographic.filter(e => e.geographicZoneId !== selectedGeographic);
            this.props.onDeletedItemGeographic(selectedGeographic);
            this.setState({ success: true, employeeGeographic: employee });
        }, () => {
            this.setState({ error: true });
        });
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            error: false,
            success: false,
        });
    }

    onSuccess = () => {
        this.setState({ success: false, deleteAlert: false });
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props
        const tableHead =  [
            { Header: t('employee.new.province'), accessor: 'provinceName' },
            { Header: t('employee.new.location'), accessor: 'locationName' },
            { Header: t('employee.new.GeographicZone'), accessor: 'name' }
        ];
        if (componenMode !== 'view') {
            tableHead.push({ 
                Header: t('employee.new.actions'), 
                accessor: 'actions', 
                sortable: false, 
                width: 150 });
        }
        return tableHead
    }

    formatGeographic(a) {
        return {
            companyId: a.companyId,
            provinceName: a.provinceName,
            location: a.location?.name,
            name: a.detail,
            locationName: a.locationName,
            actions: (this.props.componenMode === 'view') ? '' : <div>
                <Button 
                    id={`button-delete-${a.geographicZoneId}`} 
                    simple justIcon color="danger" 
                    onClick={() => this.deleteEmployeeGeographic(a.geographicZoneId)}
                ><Delete />
                </Button>
            </div>,
        }            
    }

    getEmployeesGeographic() {
        const { currentEmployeeGeographics } = this.props;
        const employeesGeographic = currentEmployeeGeographics.length > 0 ? currentEmployeeGeographics.map(this.formatGeographic.bind(this)) : [];
        return employeesGeographic
    }

    render() {
        const { t } = this.props;
        const { loading, deleteAlert, error, success } = this.state;
        const tableHead = this.renderTableHead()
        const employeesGeographic = this.getEmployeesGeographic()
        return (
            <>
                <Table
                    loading={loading}
                    defaultPageSize={5}
                    sortable={false}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    tableHead={tableHead}
                    tableData={employeesGeographic}
                    colorsColls={['primary']}
                />

                <ConfirmAlert
                    text={t('employee.new.modal.geographic')}
                    contextText={'female'}
                    onClose={() => this.closeAlert()}
                    show={deleteAlert}
                    success={success}
                    onConfirm={() => this.deletedItemGeographic()}
                    onSuccess={this.onSuccess}
                    error={error}
                    showCancelSuccess={false}
                />
            </>
        )
    }
}

EmployeeDriverGeographic.defaultProps = {
    currentEmployeeGeographics: [],
}

EmployeeDriverGeographic.propTypes = {
    t: PropTypes.func,
    currentEmployeeGeographics: PropTypes.array,
    personId: PropTypes.string,
    onGetEmployeeGeographics: PropTypes.func,
    componenMode: PropTypes.string,
}

export default withTranslation()(EmployeeDriverGeographic);
