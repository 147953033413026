export enum TermsPolicyType { TERMS = 'TERMS', POLICY = 'POLICY', TERMS_POLICY = 'TERMS_POLICY' };

export enum Color {
  primary = "primary",
  secondary = "secondary",
  violet = "violet",
  blue = "blue",
  info = "info",
  success = "success",
  warning = "warning",
  danger = "danger",
  rose = "rose",
  white = "white",
  twitter = "twitter",
  facebook = "facebook",
  google = "google",
  linkedin = "linkedin",
  pinterest = "pinterest",
  youtube = "youtube",
  tumblr = "tumblr",
  github = "github",
  behance = "behance",
  dribbble = "dribbble",
  reddit = "reddit",
  transparent = "transparent",
};

export enum Size {
  sm = "sm", lg = "lg",
};
