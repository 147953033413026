import { connect } from 'react-redux';
import { getEmployeeTypes, getFinanciers, getProvincies, getEmployees, getPracticeTypes } from '../actions/dashboardprofesional';
import DashboardProfesional from '../components/DashboardProfesional';
import Filters from '../components/DashboardProfesional/Filters';

const mapStateToProps = (state) => {
  return {
    financiers: state.dashboardprofesional.financiers,
    provincies: state.dashboardprofesional.provincies,
    employeeTypes: state.dashboardprofesional.employeeTypes,
    employees: state.dashboardprofesional.employees,
    practiceTypes: state.dashboardprofesional.practiceTypes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFinanciers: (financiers) => {
      dispatch(getFinanciers(financiers));
    },
    onGetEmployeeTypes: (employeeTypes) => {
      dispatch(getEmployeeTypes(employeeTypes));
    },
    onGetProvincies: (provincies) => {
      dispatch(getProvincies(provincies));
    },
    onGetEmployees: (employees) => {
      dispatch(getEmployees(employees));
    },
    onGetPracticeTypes: (practices) => {
      dispatch(getPracticeTypes(practices));
    }
  }
}

const DashboardProfesionalService = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardProfesional);

const FiltersService = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);

export { DashboardProfesionalService, FiltersService };
