import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import TermsAndConditionsForm, { DataCloseTerms } from './TermsAndConditionsForm';
import { TermsPolicyProfile, useTermsPolicyProfile } from '../../hooks';

import CustomDialog from '../CustomDialog';
import PropTypes from 'prop-types';
import { initialValueTermsPolicyProfile } from '../../util';
import { useTranslation } from 'react-i18next';

type Props = {
  message: string;
  open: boolean;
  onCloseTerms: (data: DataCloseTerms) => void;
}

const useStyles = makeStyles({
  updatedTermsPolicy: {
    '& .MuiDialogTitle-root .MuiButtonBase-root': {
      display: 'none',
    },
  },
  loading: {
    textAlign: 'center',
    padding: 100,
  },
  content: {
    padding: '20px 40px',
    '& p': {
      textAlign: 'center',
      marginBottom: 15,
    },
  },
  profileName: {
    fontSize: '1.3rem !important',
    color: '#022E5E !important',
    marginBottom: '40px !important',
  },
});

const TermsAndPolicyOnUpdated: FC<Props> = ({ message, open, onCloseTerms }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { getTermsAndPolicyFromProfile } = useTermsPolicyProfile();

  const [loading, setLoading] = useState<boolean>(false);
  const [dataTermsPolicy, setDataTermsPolicy] = useState<TermsPolicyProfile>(initialValueTermsPolicyProfile);

  useEffect(() => {
    const data = getTermsAndPolicyFromProfile();
    setDataTermsPolicy(data);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const onClose = (data: DataCloseTerms) => {
    onCloseTerms(data);
  }

  return (
    <CustomDialog
      className={classes.updatedTermsPolicy}
      title={t('termsPolicy.titleUpdated', { number: dataTermsPolicy.requestTermsVersion })}
      maxWidth="md"
      open={open}
    >
      <>
        {loading ?
          <div className={classes.loading}>
            <CircularProgress className="circular-progress" />
          </div> :
          dataTermsPolicy && <div className={classes.content}>
            <p className={classes.profileName}>
              {`${dataTermsPolicy?.firstName} ${dataTermsPolicy?.lastName}`}
            </p>
            <p>{message}</p>
            <TermsAndConditionsForm
              data={dataTermsPolicy}
              onCloseTermsPolicy={(data) => onClose(data)}
              isLoggedUser={true}
            />
          </div>
        }
      </>
    </CustomDialog>
  )
}

TermsAndPolicyOnUpdated.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
}

export default TermsAndPolicyOnUpdated;
