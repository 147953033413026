import "./DriversStyles.css";

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ApiInvoker from "../../api/ApiInvoker";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Drivers as DriversIcon } from "../../icons";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Snackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableRowActionsButtons from "../TableRowActionsButtons";
import { withTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";

const showSnackbarInitialValue = {
  open: false,
  color: "danger",
  message: "",
};

const Drivers = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(showSnackbarInitialValue);

  const cleanFilters = () => {
    setId("");
    setEmail("")
    setBrand("")
    setFirstName("");
    setLastName("");
    setModel("")
  };

  const getAllDrivers = () => {
    getDrivers("", "", "", "", "", "")
  };

  const openSnackbar = (message, color = "danger") => {
    setShowSnackbar({
      message,
      open: true,
      color,
    });

    setTimeout(() => {
      setShowSnackbar(showSnackbarInitialValue);
    }, 2000);
  };

  const getDrivers = (id, firstName, lastName, email, brand, model) => {
    let params = {}
    if (id !== "") params.id = id;
    if (firstName !== "") params.firstName = firstName;
    if (lastName !== "") params.lastName = lastName;
    if (email !== "") params.email = email;
    if (brand !== "") params.brand = brand;
    if (model !== "") params.model = model
    
    params.page = 0;
    params.pageSize = 20

    let queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&");

    setLoading(true);
    ApiInvoker.getDrivers(
        queryString,
        (data) => {
            formatDrivers(data);
            setLoading(false);
        },
        (error) => {
            setLoading(false);
            openSnackbar(t("common.errorMessage"));
            console.log("** error", error);
        }
    );
};

  const formatDrivers = (data) => {
    const dataDrivers = data.map((d) => {
      return {
        lastName: d.lastName,
        firstName: d.firstName,
        id: d.personId,
        email: d.email,
        zoneGeographics: d.geographicZoneString ? d.geographicZoneString : "",
        identification: d.idNumber,
        vehicle: d?.vehicle?.brand + " " + d?.vehicle?.model,
        actions: (
          <div className="drivers-actions">
            <TableRowActionsButtons
              buttons={[
                {
                  icon: AssignmentIcon,
                  state: { mode: "view", driverVehicleId: d.personId },
                  pathname: `/driver/${d.personId}`,
                  messageTooltip: "common.view",
                },
                {
                  icon: Edit,
                  state: { mode: "edit-chofer", driverVehicleId: d.personId },
                  pathname: `/driver/${d.personId}`,
                  messageTooltip: "Editar",
                },
              ]}
            />
          </div>
        ),
      };
    });
    setDrivers(dataDrivers);
  };

  const find = () => {
    getDrivers(id, firstName, lastName, email, brand, model);
  };

  useEffect(() => {
    getDrivers("", "", "", "", "", "");
    return () => {
      setDrivers([]);
      setLoading(false);
    };
  }, []);

  


  return (
    <>
      <Snackbar
        open={showSnackbar.open}
        color={showSnackbar.color}
        message={t(showSnackbar.message)}
        place="tr"
      ></Snackbar>
      <GridContainer className="drivers-list">
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="primary">
                <DriversIcon />
              </CardIcon>
              <GridContainer className="appointments-filters">
                <GridItem xs={10} sm={9} md={10} className="no-padding">
                  <GridContainer>
                    <GridItem xs={6}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("driver.identification")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={id}
                        onChange={(value) => setId(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    
                    <GridItem xs={6}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("driver.lastName")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={lastName}
                        onChange={(value) => setLastName(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("driver.firstName")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={firstName}
                        onChange={(value) => setFirstName(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={t("driver.email")}
                        className="filter-full no-padding"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(value) => setEmail(value.target.value)}
                        value={email}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    
                  </GridContainer>
                </GridItem>

                <GridItem xs={2}>
                  <GridContainer className="appointments-filters" style={{}}>
                    <GridItem className="filters-actions" xs={12}>
                      <Button 
                        block 
                        onClick={() => find()} color="primary"
                        >
                        {t("driver.common.find")}
                      </Button>
                    </GridItem>
                    <GridItem 
                      className="filters-actions" 
                      xs={12}
                    >
                      <Button
                        color="danger"
                        block
                        onClick={() => {
                          cleanFilters();
                          getAllDrivers();
                        }}
                      >
                        {t("driver.common.clean")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className="filtrable-table">
              <Table
                tableHeaderColor="primary"
                striped
                loading={loading}
                filterable
                tableHead={[
                  { Header: t("driver.lastName"), accessor: "lastName" },
                  { Header: t("driver.firstName"), accessor: "firstName" },
                  {
                    Header: t("driver.identification"),
                    accessor: "identification",
                  },
                  { Header: t("driver.email"), accessor: "email" },
                  {
                    Header: t("driver.geographicZone"),
                    accessor: "zoneGeographics",
                  },
                  {
                    Header: t("driver.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={20}
                tableData={drivers}
                colorsColls={["primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

Drivers.propTypes = {
  t: PropTypes.func, 
}

export default withTranslation()(Drivers);
