import '../../assets/plugins/react-charts.css';
import './DashboardPB.css';

import React, { Component } from 'react';

import ApiPowerBI from '../../api/ApiPowerBI';
import Cookies from 'universal-cookie';
import PowerBiComponent from './PowerBiComponent';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class DashboardOperative extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNewVersion: false,
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({ companyId: localStorage.getItem('itlg_default_company_id') });
    ApiPowerBI.callReport("OPERATIVO", this.onSuccess, this.onError);
  }

  onSuccess = (data) => {
    this.setState({ type: "OPERATIVO", reportToken: data.reportToken, reportId: data.reportId, isNewVersion: true, error: null, loading: false });
  }

  onError = () => {
    this.setState({ isNewVersion: false, loading: false });
  }

  getLinkCompany11() {
    const companyPartnerShipLinks = {
      1: 'https://app.powerbi.com/view?r=eyJrIjoiOGVlY2Y4Y2UtODMyZi00ZDhhLTk1ZmYtM2RiY2U4NmUwZGM5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      8: 'https://app.powerbi.com/view?r=eyJrIjoiOGVlY2Y4Y2UtODMyZi00ZDhhLTk1ZmYtM2RiY2U4NmUwZGM5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      13: 'https://app.powerbi.com/view?r=eyJrIjoiMmFlZTczZjQtYjE2Yi00NzdkLTg5M2UtYjkyOWU1N2I2OTZkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      14: 'https://app.powerbi.com/view?r=eyJrIjoiOGVlY2Y4Y2UtODMyZi00ZDhhLTk1ZmYtM2RiY2U4NmUwZGM5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      15: 'https://app.powerbi.com/view?r=eyJrIjoiOGVlY2Y4Y2UtODMyZi00ZDhhLTk1ZmYtM2RiY2U4NmUwZGM5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      16: 'https://app.powerbi.com/view?r=eyJrIjoiMzllZTRhYmYtNWRmOC00NjdjLWI0Y2ItZDlmNTdiMzM0ZmI4IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      17: 'https://app.powerbi.com/view?r=eyJrIjoiZTAwNWExMGMtOWRmMS00Mzc4LWFhMTQtYzZlNTQ4YmUwMDVmIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      18: 'https://app.powerbi.com/view?r=eyJrIjoiOGVlY2Y4Y2UtODMyZi00ZDhhLTk1ZmYtM2RiY2U4NmUwZGM5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      19: 'https://app.powerbi.com/view?r=eyJrIjoiM2VhMWEyMjItMGQ1Ni00MWUzLTk4ZDMtOGViNDY2NGU4YzdjIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      20: 'https://app.powerbi.com/view?r=eyJrIjoiMmE0ZmY2OTYtZTk0Mi00OWYyLTk5MzktNDQ5NWQzNzU2ZGJkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      23: 'https://app.powerbi.com/view?r=eyJrIjoiMzA4YzdkMDEtOTZiMC00NDgyLWEwZjQtNjZhNWZmOTMzZDRkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      24: 'https://app.powerbi.com/view?r=eyJrIjoiNmJjOTczNWUtNzE2ZC00YWZlLTg2YjAtNzI3ODIxNGMzYjI2IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      25: 'https://app.powerbi.com/view?r=eyJrIjoiNTI1MzliMzctYzEwNi00YTdiLWIxOTQtMDQyNDNhMDUyNTBmIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
    };

    const companyPartnerShip = parseInt(localStorage.getItem('company_partnership_id'));
    return companyPartnerShipLinks[companyPartnerShip] || '';
  }

  getDashboardLink() {
    const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    const companyDashboardLinks = {
      1: 'https://app.powerbi.com/reportEmbed?reportId=65801247-2fad-4605-b137-95f4bf1019e8&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      4: 'https://app.powerbi.com/view?r=eyJrIjoiYWQzNjZiZWMtMTgwNS00NWEwLTk3MzMtN2JiOWZkNzBkOGM5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      5: 'https://app.powerbi.com/view?r=eyJrIjoiZmIxZTFjMzEtMGZhMC00Y2ZlLTkzNTgtOTk4Y2IwNmQ0ZjE5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      7: 'https://app.powerbi.com/reportEmbed?reportId=5e41bce1-3f36-4d1e-b977-a481f10fd112&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22',
      8: 'https://app.powerbi.com/reportEmbed?reportId=65801247-2fad-4605-b137-95f4bf1019e8&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      10: 'https://app.powerbi.com/view?r=eyJrIjoiMDMxOGMyNWYtNGFmZi00NDU1LTk5M2ItZjI3Mjg4YjIxOWQ5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      11: this.getLinkCompany11(),
      12: 'https://app.powerbi.com/reportEmbed?reportId=9ef13f7e-bf0e-4825-8a59-dacd7d18d378&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22',
      13: 'https://app.powerbi.com/view?r=eyJrIjoiZGQ4Y2MyNTYtZWY1YS00N2JlLWE4ZTYtNzI1Y2VjN2JjYjYwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      14: 'https://app.powerbi.com/reportEmbed?reportId=65801247-2fad-4605-b137-95f4bf1019e8&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      15: 'https://app.powerbi.com/reportEmbed?reportId=65801247-2fad-4605-b137-95f4bf1019e8&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      16: 'https://app.powerbi.com/view?r=eyJrIjoiMzllZTRhYmYtNWRmOC00NjdjLWI0Y2ItZDlmNTdiMzM0ZmI4IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      17: 'https://app.powerbi.com/view?r=eyJrIjoiOTM5NWFmYmEtMDk4YS00YTI3LTg3ZTgtZDZkMGY0Y2VkN2FkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      19: 'https://app.powerbi.com/view?r=eyJrIjoiMWQ4OTUxZTMtYjFmMS00ZTAwLWEzNDUtMTRjZTg0MzQ3YWIzIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      20: 'https://app.powerbi.com/view?r=eyJrIjoiMmE0ZmY2OTYtZTk0Mi00OWYyLTk5MzktNDQ5NWQzNzU2ZGJkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      21: 'https://app.powerbi.com/view?r=eyJrIjoiNWQ2MGQwNGYtMGQ2YS00Y2RjLWI0MzItYmE4YzExNDczMjYxIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      22: 'https://app.powerbi.com/view?r=eyJrIjoiYmYyZGU3NTQtYWNiYi00YjQ4LWEzOTgtNjAwMDRmMzkwMzk4IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      27: 'https://app.powerbi.com/reportEmbed?reportId=35fb59c4-f8a1-431e-8bec-f387e7b73dd6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      28: 'https://app.powerbi.com/view?r=eyJrIjoiMDA0ZGZlMjAtYjIxNy00MmNlLTgwNWItNGJiZTZkMTNkZDZlIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      29: 'https://app.powerbi.com/view?r=eyJrIjoiNTAzZDIzYTYtY2UzZi00ODdmLTkzOGItMGI2NWM1OTU1NGRkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
    }

    return companyDashboardLinks[companyId] ||  '';
  }

  render() {
    const { t } = this.props;
    const { isNewVersion, loading, reportId, reportToken } = this.state;
    if (loading) {
      return <div />
    }
    
    if (!isNewVersion) {
      const DASHBOARD_LINK = this.getDashboardLink();
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            title="Tablero Operativo - Solicitudes"
            width="100%"
            height={window.innerHeight}
            src={DASHBOARD_LINK}
            allowFullScreen={true}
            style={{ border: '0' }}
          ></iframe>
        </div>
      )
    }
    if (reportToken != null) {
      const cookies = new Cookies();
      let lang = cookies.get("lang");
      if (lang == null) {
        lang = "es";
      }
      return (
        <PowerBiComponent 
          reportId={reportId}
          reportToken={reportToken}
          lang={lang}
        />
      );
    }
    return (<div>{t('table.loading')}</div>)
  }
}

DashboardOperative.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(DashboardOperative);
