import React from 'react';
import { makeStyles } from '@material-ui/core';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import waves from '../../assets/img/waves.svg';
import {
    whiteColor,
    primaryColor,
  } from '../../assets/components/material-dashboard-pro-react';
import './FullLoading.css';

const useStyles = makeStyles({
    fullLoading: {
        background: whiteColor,
        top: 0,
        left: 0,
        width: '100vw !important',
        height: '100vh !important',
        position: 'fixed',
        zIndex: 9999,
        '& .full-loading-container': {
            '&.primary': {
                background: primaryColor[0],
            },
        },
        '& .full-loading-animation': {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 2,
            '& .heart': {
                width: '20%',
            },
        },
    },
})

const FullLoading = () => {
    const classes = useStyles();

    return (
        <GridContainer className={classes.fullLoading}>
            <GridItem xs={6} className="full-loading-container"></GridItem>
            <GridItem xs={6} className="full-loading-container primary"></GridItem>
            <div className="full-loading-animation">
                <img src={waves} alt="loading" className="heart" />
            </div>
        </GridContainer>
    )
}

export default FullLoading;
