import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import LogoGlin from '../../../src/assets/img/glin-full-grey.png';
import LogoHMM from '../../../src/assets/img/login/logo-hmm.png';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    about: {
        textAlign: 'center',
        '& .about-logo': {
            marginBottom: 30,
            marginTop: 20,
            maxWidth: '50%',
        },
        '& .about-information': {
            marginBottom: 15,
            '& .about-information-label': {
                color: 'grey !important',
            },
        },
        '& .about-company-info': {
            marginBottom: '1.5rem 0 0.7em !important',
            '& p': {
                fontSize: '0.875rem',
                '&.powered-by': {
                    opacity: 0.5,
                },
            },
            '& img': {
                width: 95,
            },
        }
    },
}))

const About = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const aboutVersionText = t('about.version');
    const dateText = t('common.date');

    return (
        <GridContainer className={classes.about}>
            <GridItem xs={12}>
                <img className="about-logo" alt="HMM" src={LogoHMM} />
            </GridItem>
            <GridItem xs={12} className="about-information">
                <p>
                    <span className="about-information-label">{aboutVersionText}: </span>
                    <span className="about-information-value">
                        {localStorage.getItem('APP_VERSION')}
                    </span>
                </p>
                <p>
                    <span className="about-information-label" >{dateText}: </span> 
                    <span className="about-information-value">
                        {localStorage.getItem('RELEASE_DATE')}
                    </span>
                </p>
            </GridItem>
            <GridItem className="about-company-info" xs={12}>
                <p className="powered-by"><small>Powered by</small></p>
                <p>
                    <a href="https://glin-technologies.com/" rel="noopener noreferrer" target="_blank">
                        <img className="about-company" alt="Glin" src={LogoGlin} />
                    </a>
                </p>
            </GridItem>
        </GridContainer>
    )
}

export default About;
