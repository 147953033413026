import { connect } from 'react-redux';
import DashboardManagement from '../components/DashboardsPB/DashboardManagementindex';

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const DashboardPBManagementService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardManagement);

export default DashboardPBManagementService;
