export class PracticeFee {
  id: number;
  companyId: number;
  fromDate: string;
  toDate?: string;
  amount: number;
  companyCurrencyId?: number;
  practiceTypeId?: number;
  employeeId?: number;
  scheduleAmountsChanged: boolean;
  isSchedulesFeeUpdated?: boolean;

  constructor({ id, companyId, practiceTypeId, employeeId, companyCurrencyId, amount, fromDate, toDate, scheduleAmountsChanged, isSchedulesFeeUpdated }: PracticeFeeDto) {
    this.id = id;
    this.companyId = companyId;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.amount = amount;
    this.companyCurrencyId = companyCurrencyId;
    this.practiceTypeId = practiceTypeId;
    this.employeeId = employeeId;
    this.scheduleAmountsChanged = scheduleAmountsChanged;
    this.isSchedulesFeeUpdated = isSchedulesFeeUpdated;
  }
}

export interface PracticeFeeDto {
  id: number;
  companyId: number;
  fromDate: string;
  toDate: string;
  amount: number;
  companyCurrencyId: number;
  practiceTypeId: number;
  employeeId: number;
  scheduleAmountsChanged: boolean;
  isSchedulesFeeUpdated?: boolean;
}

export interface PracticeFeeRequest {
  employeeTypeId: number;
  companyId: number;
  employeeId: number;
  fromDate: string;
  toDate?: string;
  companyCurrencyId: number;
  totalAmount: number;
}
