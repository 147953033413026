import '../../assets/plugins/react-datetime.css';
import './CustomInput.css';

import React, { useEffect, useState } from "react";

import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import config from '../../config/config';
import customInputStyle from "../../assets/components/customInputStyle";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const dateFormat = config.getDateFormat();
const locale = config.getLang();

const getFormControlClasses = (formControlProps, classes) => {
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return formControlClasses;
}

const CustomInput = ({ ...props }) => {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    placeholder,
    time,
    error,
    white,
    inputRootCustomClasses,
    multiline,
    success,
    helpText,
    onChange,
    onBlur,
    type,
    value,
    dateValue,
    defaultDateValue,
    closeOnSelect,
    dateShrink,
    min,
    max,
    pattern,
    step,
    minDate,
    maxDate,
  } = props;

  const [shrink, setShrink] = useState(false);
  
  useEffect(() => {
    setShrink(dateShrink);
  }, [dateShrink])

  const labelClasses = classNames({ [" " + classes.labelRootError]: error, [" " + classes.labelRootSuccess]: success && !error });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({ [inputRootCustomClasses]: inputRootCustomClasses !== undefined });
  const inputClasses = classNames({ [classes.input]: true, [classes.whiteInput]: white });
  
  const formControlClasses = getFormControlClasses(formControlProps, classes);
  
  const helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  const valid = ( current ) => {
    const yesterday = Datetime.moment().subtract(1, 'day');
    if (!min) {
      return current.isAfter( yesterday );
    } else {
      if (max) {
        return current.isSameOrAfter( min ) && current.isSameOrBefore( max )
      }
      return current.isSameOrAfter( min );
    }
  }

  const onChangeInternal = (e) => {
    let regex = config.regex_normal;
    if (type == 'email') {
      regex = config.regex_email
    }

    if (!regex.test(e.target.value) && onChange != undefined) {
      onChange(e);

    }
  }

  const renderDateInput = () => {
    const commonProps = {
      dateFormat,
      timeFormat: time ? "hh:mm a" : false,
      onChange,
      onBlur,
      value: dateValue,
      locale,
      defaultValue: defaultDateValue,
      closeOnSelect,
      className: props.className,
      inputProps: {
        placeholder,
        id,
        ...inputProps
      },
    };
  
    if (minDate || maxDate) {
      return <Datetime isValidDate={valid} {...commonProps} />;
    }
  
    return <Datetime {...commonProps} />;
  };

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses + " " + (shrink ? "MuiInputLabel-shrink" : "")}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      { type === 'date' ?
        renderDateInput() 
      :
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          underline: underlineClasses
        }}
        id={id}
        onChange={onChangeInternal}
        value={type === 'number' ? value || undefined : value}
        multiline={multiline}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        pattern={pattern}
        type={type || 'text'}
        inputProps= {{
          ...inputProps
        }}
      />
      }
      {helpText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  time: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  dateValue: PropTypes.any,
  defaultDateValue: PropTypes.any,
  value: PropTypes.any,
  closeOnSelect: PropTypes.bool,
  dateShrink: PropTypes.bool,
  minDate: PropTypes.bool,
  maxDate: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  className: PropTypes.any,
  multiline: PropTypes.bool,
  pattern: PropTypes.string,
  step: PropTypes.string,
};

export default withStyles(customInputStyle)(CustomInput);
