import '../../assets/plugins/react-charts.css';
import './Dashboard.css';

import { Element, Events } from 'react-scroll';
import React, { Component } from 'react';

import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import DashboardApiInvoker from '../../api/DashboardApiInvoker';
import FileSaver from 'file-saver';
import { FiltersService } from '../../containers/DashboardProfesionalService';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { MedicalDoctor } from '../../icons';
import PropTypes from 'prop-types';
import QuantityChart from './QuantityChart/index.js';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import XLSX from 'xlsx';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateToServer = config.getDateToServer();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalquery: [],
      schedules: [],
      dateFrom: '',
      dateTo: '',
      employeeId: '',
      graphics: false,
      clear: false,
      financiers: [],
      chips: [],
      alertColor: 'info',
      alertMessage: '',
      alertOpen: false,
    }
  }

  componentWillMount() {
    if (this.props.employees?.length > 0) {
      this.setState({ professionals: this.props.employees });
    }
  }

  componentWillReceiveProps(next) {
    if (next.employees) {
      this.setState({ employees: next.employees, professionals: next.employees });
    }
    if (next.financiers) {
      this.setState({ financiers: next.financiers, ofinanciers: next.financiers });
    }
    if (next.practiceTypes) {
      this.setState({ practiceTypes: next.practiceTypes, opracticeTypes: next.practiceTypes });
    }
  }

  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    });

    setTimeout(() => {
      this.setState({ alertOpen: false });
      if (color === 'success') {
          this.cleanFields();
      }
    }, 5000);
  }

  setFilters(filters) {
    if (filters.start_date?.length > 0 && filters.end_date?.length > 0) {
      this.setState({
        start_date: moment(filters.start_date).format(dateToServer),
        end_date: moment(filters.end_date).format(dateToServer),
        loadingEmployeesTable: true,
      });

      DashboardApiInvoker.getEmployeesDashboard({
        start_date: moment(filters.start_date).format(dateToServer),
        end_date: moment(filters.end_date).format(dateToServer),
      }, (data) => {
        this.formatTableData(data);
      }, (error) => {
        this.openAlert('danger', error.message + '');
        this.setState({ loadingEmployeesTable: false });
        console.error('** getEmployeesDashboard', error);
      });
    }
  }

  exportToExcel = () => {
    const { t } = this.props;
    let r = [];
    const fields = {
      'scheduleId': t('dashboardprofesional.table.scheduleId'),
      'startDateTime': t('dashboardprofesional.table.startDate_endDate'),
      'appointmentId': t('dashboardprofesional.table.appointmentId'),
      'employee': t('dashboardprofesional.table.employee'),
      'practiceTypes': t('dashboardprofesional.table.practice'),
      'scheduleStatusName': t('dashboardprofesional.table.scheduleStatusName'),
      'financier': t('dashboardprofesional.table.financier'),
      'customer': t('dashboardprofesional.table.customer'),
      'province': t('dashboardprofesional.table.province'),
      'location': t('dashboardprofesional.table.location'),
      'geographicZone': t('dashboardprofesional.table.geographicZone'),
    };

    r.push(fields);
    this.state.schedules.forEach(e => {
      delete e['timeAcept'];
      delete e['employeeId']
      delete e['scheduleStatus']
      delete e['speciality']
      return r.push(e);
    });

    const fileName = `dashboard-professionals-${moment().format('YYYYMMDD')}`;
    const fileType = 'xlsx';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(r, { skipHeader: 1 });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data2 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data2, fileName + fileExtension);
  }

  getArrayOf = (filters, type, fieldName) => {
    const ret = this.state.chips.filter(c => c.code === type).map(e => { 
      return e[fieldName];
    });
    return ret;
  }

  containsAny = (source, target) => {
    const result = source.filter(function (item) {
      return target.indexOf(item) > -1 
    });
    return (result.length > 0);
  }

  softFilter = () => {
    const { originalquery } = this.state;
    const filters = this.state.chips;
    let result = originalquery;
    const arra = this.getArrayOf(filters, 'practiceTypes', 'name');
    const employeeArray = this.getArrayOf(filters, 'employee', 'value');
    this.state.chips.forEach(e => {
      result = result.filter(function (item) {
        if (e.code === 'practiceTypes') {
          return this.containsAny(item[e.code].split(','), arra);
        }
        if (e.code === 'employee') {                    
          return employeeArray.indexOf(item.employeeId) > -1;
        }
        return item[e.code] === e.name;
      });
    });
    this.setState({ schedules: result });
  }

  formatTableData(data) {
    const { t } = this.props;
    if (!data.length) {
      this.setState({ schedules: [], originalquery: [], loadingEmployeesTable: false });
      return;
    }
    const result = data.map(d => ({
      scheduleId: d.scheduleId,
      appointmentId: d.appointmentId,
      employeeId: d.employeeId,
      speciality: d.practiceTypeGroupName ? d.practiceTypeGroupName : '',
      practiceTypes: d.practiceTypeName ? d.practiceTypeName : '',
      customer: `${d.customerLastName} ${d.customerFirstName}`,
      financier: d.financierBusinessName,
      employee: `${d.employeeLastName} ${d.employeeFirstName}`,
      scheduleStatus: d.scheduleStatus,
      scheduleStatusName: t(`status.${d.scheduleStatus}`),
      startDateTime: moment(d.scheduleDateTime).format(dateFormat),
      province: d.province,
      location: d.location,
      geographicZone: d.geographicZone,
      timeAcept: d.timeAcept,
    }));

    this.setState({
      schedules: result,
      originalquery: result,
      loadingEmployeesTable: false,
    });
    this.softFilter();
  }

  clearInformation() {
    this.setState({
      employees: [],
      employeeId: '',
      dateFrom: '',
      dateTo: '',
      clear: true,
      chips: [],
      schedules: [],
      originalquery: [],
    });
  }

  showGraphicsInfo(rowInfo) {
    if (rowInfo?.original?.employeeId) {
      this.setState({ graphics: true, employeeId: rowInfo.original.employeeId }, () => {
        this.setState({ graphics: false });
      });
    }
  }

  sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if(a1 < b1) {
      return -1;
    }
    if(a1 > b1) {
      return 1;
    }
    return 0;                  
  }

  render() {
    const { t } = this.props;
    const {
      chips, loadingEmployeesTable, schedules, alertColor, alertMessage, alertOpen,
    } = this.state;
    
    return (
      <div className="dashboard dashboard-professionals">
        <GridContainer>
          <GridItem className="table-container" xs={12}>
            <Card className="dashboard-table">
              <CardHeader icon>
                <CardIcon color="secondary">
                  <MedicalDoctor />
                </CardIcon>
                <h3 className="card-icon-title">{t('dashboardprofesional.table.title')}</h3>
                <FiltersService
                  loading={loadingEmployeesTable}
                  chips={chips}
                  onChangeChips={(filters) => this.softFilter(filters)}
                  onChange={(filters) => this.setFilters(filters)}
                  onClearFilters={() => this.clearInformation()}
                />
              </CardHeader>
              <CardBody className="dashboard-content-body">
                <Table
                  loading={loadingEmployeesTable}
                  tableHeaderColor="primary"
                  sortable
                  tableHead={[
                    { Header: t('dashboardprofesional.table.scheduleId'), accessor: 'scheduleId' },
                    { Header: t('dashboardprofesional.table.startDate_endDate'), accessor: 'startDateTime', sortMethod: (a, b) => this.sortDate(a, b)},
                    { Header: t('dashboardprofesional.table.appointmentId'), accessor: 'appointmentId' },
                    { Header: t('dashboardprofesional.table.employee'), accessor: 'employee' },
                    { Header: t('dashboardprofesional.table.speciality'), accessor: 'speciality' },
                    { Header: t('dashboardprofesional.table.practice'), accessor: 'practiceTypes' },
                    { Header: t('dashboardprofesional.table.scheduleStatusName'), accessor: 'scheduleStatusName' },
                    { Header: t('dashboardprofesional.table.financier'), accessor: 'financier' },
                    { Header: t('dashboardprofesional.table.customer'), accessor: 'customer' },
                    { Header: t('dashboardprofesional.table.province'), accessor: 'province' },
                    { Header: t('dashboardprofesional.table.location'), accessor: 'location' },
                  ]}
                  tableData={schedules}
                  defaultPageSize={schedules?.length > 5 ? 20 : 5}
                  colorsColls={['primary']}
                  onRowClick={(e, t, rowInfo) => this.showGraphicsInfo(rowInfo)}
                />
              </CardBody>
              <CardFooter className="footer-buttons">
                <p className="legend-footer">{t('dashboardprofesional.table.total')}: {schedules?.length}</p>
                <Button id="button-export" className="button-export" onClick={() => this.exportToExcel()} color="primary" disabled={schedules?.length < 1}>
                  {t('common.exportExcel')}
                </Button>
              </CardFooter>
            </Card>

            <Snackbar
              place="tr"
              color={alertColor}
              message={alertMessage}
              open={alertOpen}
            />

            <Element name="scroll-to-charts">
              <GridContainer className="dashboard-charts">
                <GridItem xs={6}>
                  <QuantityChart icon={true} title={t('dashboardprofesional.chart.financier')} data={schedules} column="financier" />
                </GridItem>
                <GridItem xs={6}>
                  <QuantityChart icon={true} title={t('dashboardprofesional.chart.times')} data={schedules} column="timeAcept" />
                </GridItem>
                <GridItem xs={6}>
                  <QuantityChart icon={true} title={t('dashboardprofesional.chart.location')} data={schedules} column="location" />
                </GridItem>
                <GridItem xs={6}>
                  <QuantityChart icon={true} title={t('dashboardcustomer.chart.geographicZone')} data={schedules} column="geographicZone" />
                </GridItem>
              </GridContainer>
            </Element>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.func,
  employees: PropTypes.array,
}

export default withTranslation()(Dashboard);
