import React from 'react';
import PropTypes from 'prop-types';
import './StatusIcon.css';


const StatusIcon = ({ value, quote }) => {
  const setColor = (value) => {
    switch (value) {
      case 'OK':
        return 'green';
      case 'WARNING':
        return 'orange';
      case 'ERROR':
        return 'red';
      default:
        return 'green';
    }
  }

  return (
    <div className="status-container">
      <div className={`status-icon ${setColor(value)}`}>
        <span className="child-1"></span>
        <span className="child-2"></span>
      </div>
      <p className="status-text">{quote || ''}</p>
    </div>
  )
}

StatusIcon.propTypes = {
  value: PropTypes.string,
  quote: PropTypes.string,
}

export default StatusIcon;
