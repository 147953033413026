import { Delete, Edit } from '@material-ui/icons';
import React, { Component } from 'react';

import Button from '../CustomButtons/Button';
import ConfirmAlert from '../ConfirmAlert';
import CustomCheckbox from '../CustomCheckbox';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

class Address extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personAddress: [],
            isAddress: false,
            personId: '',
            loading: true,
            deleteAlert: false,
            success: false,
            error: false,
            selectedAddres: '',
        }
    }

    componentWillReceiveProps(next) {      
        if (next.personId && next.refresh) {
            PersonApiInvoker.getPersonsAddress(next.personId, false, (data) => {
                const personAddress = this.formatAddress(data);
                const isAddress = personAddress.length > 0;
                this.setState({
                    personAddress,
                    isAddress,
                    loading: false,
                    personId: next.personId,
                    refresh: next.refresh,
                }, () => {
                    if (this.props.onFilledAddress) {
                        this.props.onFilledAddress({
                            isAddress: isAddress,
                            isLoading: this.state.loading,
                            refresh: false,
                        });
                    }
                });
            });
        }
    }

    formatAddress(address) {
        return address.map(a => {
            const actions = <>
                <Button id={`button-edit-${a.addressId}`} simple justIcon color="success" onClick={() => this.editAddress(a)}><Edit /></Button>
                <Button id={`button-delete-${a.addressId}`} simple justIcon color="danger" onClick={() => this.deleteAddress(a.addressId)}><Delete /></Button>
            </>;

            const floorAndDepto = a.floor || a.department ? `${a.floor} ${a.department}` : '-';

            return {
                addressId: a.addressId,
                addressType: a.addressType.name,
                street: a.street,
                number: a.number,
                floorAndDepto,
                location: a.location?.name || '',
                province: a.provinceName,
                geographicZone: a.geographicZone?.detail ?? '',
                default: <CustomCheckbox checked={a.addressDefault} />,
                actions: this.props.componenMode !== 'view' && actions, 
            }
        });
    }

    editAddress(address) {
        this.props.onEditAddress(address);
    }

    deleteAddress(addressId) {
        this.setState({ deleteAlert: true, selectedAddres: addressId });
    }

    deleteItem() {
        const { onFilledAddress } = this.props;
        const { loading, personAddress, selectedAddres } = this.state;
        PersonApiInvoker.deletePersonsAddressCustomer(selectedAddres, null, () => {
            const addresses = personAddress;
            const pAddress = addresses.filter(addr => addr.addressId !== selectedAddres);
            const isAddress = pAddress.length > 0;
            this.setState({
                success: true,
                personAddress: pAddress,
                isAddress,
            }, () => {
                if (onFilledAddress) {
                    onFilledAddress({
                        isAddress,
                        isLoading: loading,
                        refresh: false,
                    });
                }
            });
        }, err => {
            console.error('** error deletePersonsAddress', err);
            if (err.message){
                this.openAlert('danger', err.message);
                this.closeAlert();
            } else {
                this.setState({ error: true, success: false });
            }
        });
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
        });
    }
    
    openAlert = (color, message) => {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: t('address.type'), accessor: 'addressType' },
            { Header: t('address.street'), accessor: 'street' },
            { Header: t('address.number'), accessor: 'number' },
            { Header: t('address.floorAndDepto'), accessor: 'floorAndDepto' },
            { Header: t('address.location'), accessor: 'location' },
            { Header: t('address.province'), accessor: 'province' },
            { Header: t('common.geographicZone'), accessor: 'geographicZone' },
            { Header: t('address.mainAddress'), accessor: 'default' },
        ];
        if (componenMode !== 'view') {
            tableHead.push({ Header: t('common.actions'), accessor: 'actions', sortable: false, width: 150 });
        }
        return tableHead;
    }

    render() {
        const { alertColor, alertMessage, alertOpen, deleteAlert, error, loading, personAddress, success } = this.state;
        const { t } = this.props;

        return (
            <div className="address-list">
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={true}
                    tableHead={this.renderTableHead()}
                    tableData={personAddress}
                    colorsColls={["primary"]}
                />
                <Snackbar
                    place="tr"
                    color={alertColor}
                    message={alertMessage}
                    open={alertOpen}
                />
                <ConfirmAlert
                    text={t('address.title')}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    show={deleteAlert}
                    success={success}
                    onSuccess={() => this.closeAlert()}
                    error={error}
                    showCancelSuccess={false}
                    contextText="female"
                />
            </div>
        )
    }
}

Address.defaultProps = {
    onFilledAddress: () => {},
}

Address.propTypes = {
    t: PropTypes.func,
    onEditAddress: PropTypes.func,
    componenMode: PropTypes.string.isRequired,
    onFilledAddress: PropTypes.func,
}

export default withStyles(styles)(withTranslation()(Address));
