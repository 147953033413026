export enum SnackbarColor {
  danger = 'danger',
  info = 'info',
  inherit = '',
  success = 'success',
  warning = 'warning',
}

export interface SnackbarProps {
  color: SnackbarColor;
  message: string;
  open?: boolean;
}
