import '@material/react-chips/dist/chips.css';

import { Button, Card, CardActions, CardContent, MenuItem, MenuList, Paper } from '@material-ui/core';
import React, { Component } from 'react';

import ApiInvoker from '../../../../../api/ApiInvoker';
import { Chip } from '@material/react-chips';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import PropTypes from 'prop-types';
import SelectInput from '../../../../SelectInput';
import { withTranslation } from 'react-i18next';

class CustomInputChip extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            show_items: false,
            items: [],
            locations: [],
            filters: [
                { id: 'financier', title: t('dashboardprofesional.filter.financier'), code: 'financier', type: 'select', multiple: false },
                { id: 'practiceTypes', title: t('dashboardprofesional.filter.practice'), code: 'practiceTypes', type: 'select', multiple: true },
                { id: 'province', title: t('dashboardprofesional.filter.province'), code: 'province', type: 'select', multiple: false },
                { id: 'location', title: t('dashboardprofesional.filter.location'), code: 'location', type: 'select', multiple: false },
                { id: 'geographicZone', title: t('dashboardprofesional.filter.zoneGeografic'), code: 'geographicZone', type: 'select', multiple: false },
                { id: 'employee', title: t('dashboardprofesional.filter.employee'), code: 'employee', type: 'select', multiple: true },
            ],
        }
    }

    onChipClick = () => {
       this.setState((prev) => ({ show_items: !prev.show_items }))
    }

    handleEvent = (e) => {
        const { filters } = this.state;
        const selected = filters.find(f => f.id === e.target.id);
        let source = null;
        if (selected.code === 'financier') {
            source = this.props.financiers;
        } else if (selected.code === 'practiceTypes') {
            source = this.props.practiceTypes;
        } else if (selected.code === 'province') {
            source = this.props.provincies;
        } else if (selected.code === 'location') {
            source = this.state.locations;
        } else if (selected.code === 'geographicZone') {
            source = this.state.zones;
        } else if (selected.code === 'employee') {
            source = this.props.employees;
        }
        this.setState((prevState) => ({ items: source, show_items: !prevState.show_items, selected_type: selected }));
    }

    findValue = (value) => {
        const selectedItem = this.state.items.find(d => d.id === value);
        return selectedItem ? selectedItem.value : null;
    }

    getLocations(value) {
        ApiInvoker.getLocations(value, loc => {
            if (loc?.length) {
                this.setState({ locations: this.formatLocations(loc) })
            }
        }, e => {
            console.error(e);
        });
    }

    formatLocations(loc) {
        let result = loc.map(e => ({
            id: e.locationId,
            value: `${e.name}`
        }))
        return result;
    }

    getZones(value) {
        ApiInvoker.getGeographicZoneFromLocation(value, zones => {
            this.setState({ zones: this.formatZones(zones) })
        }, (error) => {
            console.error('** error getGeographicZoneFromLocation', error);
        });
    }

    formatZones(zones) {
        if (!zones.length) {
            return [];
        }
        return zones.map(e => ({
            id: e.geographicZoneId,
            value: `${e.detail}`,
        }));
    }

    handleValue(value, state) {
        const { selected_type } = this.state;
        if (selected_type.code === 'province') {
            this.getLocations(value);
        }
        if (selected_type.code === 'location') {
            this.getZones(value);
        }
        const newType =  { ...selected_type, value, name: this.findValue(value) };
        this.setState({ selected_type: newType });
    }

    handleCloseDialog = () => {
        this.setState({ selected_type: null });
    }

    handleApply = () => {
        if (!this.state.selected_type.value) {
            return;
        }
        const s = this.state.selected_type;
        this.props.onApply(s);
        this.setState({ selected_type: null });
    }

    has = (keyvalue) => {
        return this.state.filters.some(item => item.code === keyvalue && item.value);
    };

    findItemValue = (code) => {
        let ret = false;
        this.props.selecteditems.map((item, key) => {
            if (item.code === code) {
                ret = true;
            }
        });
        return ret;
    }

    renderDialogSelect = () => {
        const { t } = this.props;
        const { items, selected_type } = this.state;
        
        return (
            <Card
                open={true}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CardContent>
                    <GridContainer className="filters" justify="center">
                        <GridItem xs={12}  className="no-padding">
                            <SelectInput
                            className="customInputChipSelect" 
                            elements={items} 
                            onSelectedValue={(value) => this.handleValue(value, 'item')}
                            label={selected_type.title}
                            />
                        </GridItem>
                    </GridContainer>
                </CardContent>
                <CardActions>
                    <Button id="button-filters-close" onClick={this.handleCloseDialog} color="primary">
                        {t('dashboardprofesional.filters.close')}
                    </Button>
                    <Button id="button-filters-apply" onClick={this.handleApply} color="primary">
                        {t('dashboardprofesional.filters.apply')}
                    </Button>
                </CardActions>
            </Card>
        );
    }

    render() {
        const { id, label } = this.props;
        const { filters, selected_type, show_items } = this.state;

        if (selected_type && selected_type.type === 'select') return this.renderDialogSelect();

        return (
            <div>
                <Chip id={id} label={label} handleInteraction={this.onChipClick} />
                {show_items ? 
                    <div style={{position: 'absolute'}}>
                        <Paper>
                            <MenuList>
                                {filters.map((item) => {
                                    let findc = this.findItemValue(item.code);
                                    if (item.multiple) {
                                        findc = false;
                                    }
                                    if (findc || (item.code === 'location' && !this.has('province')) || (item.code === 'geographicZone' && !this.has('location'))) {
                                        return <div key="1"></div>;
                                    }
                                    return (
                                        <MenuItem id={item.id} key={item.id} onClick={this.handleEvent}>{item.title}</MenuItem>
                                    )}
                                )}
                            </MenuList>
                        </Paper>
                    </div>
                : <div/>
                }
            </div>
        )
    }
}

CustomInputChip.propTypes = {
    t: PropTypes.func,
    id: PropTypes.number,
    label: PropTypes.string,
    financiers: PropTypes.array,
    practiceTypes: PropTypes.array,
    provincies: PropTypes.array,
    onApply: PropTypes.func,
    selecteditems: PropTypes.array,
    employees: PropTypes.array,
}

export default withTranslation()(CustomInputChip);
