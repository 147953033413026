import { SnackbarColor } from "../interfaces";

export const initialValueAlert = {
  message: '',
  show: false,
}

export const initialValueSnackbar = {
  color: SnackbarColor.inherit,
  message: '',
  open: false,
}

export const initialValueTermsPolicyProfile = {
  userId: 0,
  firstName: '',
  lastName: '',
  requiereTermsAccept: true,
  requestTermsVersion: '0',
}

export const initialTerms = {
  id: 0,
  companyId: 0,
  contentTerms: '',
  contentPolicy: '',
  version: '0',
}
