import { FETCH_COMPANIES, SELECT_COMPANY, USER_LOGIN, USER_LOGOUT } from "../actions/user";

const initialState = {};

const user = (state = initialState, action) => {
    switch(action.type) {
        case USER_LOGIN:
            return { ...state, ...action.user };
        case USER_LOGOUT:
            return initialState;
        case FETCH_COMPANIES:
            return {...state, companies: action.companies}
        case SELECT_COMPANY: {
            localStorage.setItem('itlg_default_company_id', action.selectedCompany);
            return { ...state, selectedCompany: action.selectedCompany }
        }
        default: 
            return state;
    }
}

export default user;
