import {
    CHECK_ALL_TYPE,
    CHECK_GEOGRAPHIC,
    CHECK_TYPE,
    CLEAN_EMPLOYEE,
    DELETE_ITEM,
    DELETE_ITEMGEOGRAPHIC,
    EMPLOYEE_GEOGRAPHICS,
    EMPLOYEE_TYPES,
    GEOGRAPHICS,
    PUT_EMPLOYEEGEOGRAPHIC,
    PUT_EMPLOYEETYPE,
    UNCHECK_ALL,
    UNCHECK_ALLGEOGRAPHIC
} from '../actions/newEmployee';
import {
    EMPLOYEES_DETAIL,
    EMPLOYEE_DETAIL,
    EMPLOYEE_GEOGRAPHICDETAIL,
    UNMOUNT_EMPLOYEE,
} from "../actions/employees";

const employees = (state = {}, action, root) => {
    switch(action.type) {
        case CLEAN_EMPLOYEE:
            return { ...state, employees: [] };
        case EMPLOYEES_DETAIL:
            return { ...state, employees: action.employees };
        case EMPLOYEE_DETAIL: {
            return { ...state, employee: action.employee };
        }
        case EMPLOYEE_GEOGRAPHICDETAIL: {
            const geographicOfEmployee = action.geographicOfEmployee
            return { ...state, employee: { ...state.employee, employeeGeographics: geographicOfEmployee }} ;
        }
        case EMPLOYEE_TYPES: 
            return { ...state, employeesType: action.Types };
        case EMPLOYEE_GEOGRAPHICS: 
            return { ...state, employee: { ...state.employee, employeesGeographics: action.geographics } };
        case GEOGRAPHICS: 
            return { ...state, geographics: action.geographics };
        case DELETE_ITEM: {
            const item = action.itemId;
            const listEmployeeTypes = (state.employee?.employeeTypes) || [];
            return {
                ...state,
                employee: {
                    ...state.employee,
                    employeeTypes: listEmployeeTypes.filter(a => a.employeeTypeId !== item),
                }
            }
        }
        case DELETE_ITEMGEOGRAPHIC: {
            const itemg = action.geographicId;
            const listEmployeeGeographic = (state.employee?.employeesGeographics) || [];
            return {
                ...state,
                employee: {
                    ...state.employee,
                    employeesGeographics: listEmployeeGeographic.filter(a => {
                    return a.geographicZoneId !== itemg}),
                }
            }
        }
        case PUT_EMPLOYEETYPE: {
            const employeeToPut = action.employeType;
            const listToPut = (state.employee?.employeeTypes) || [];
            return  {
                ...state,
                employee: {
                    ...state.employee,
                    employeeTypes: employeeToPut.concat(listToPut),
                }
            }
        }
        case PUT_EMPLOYEEGEOGRAPHIC: {
            const geographicIds = action.geographic;
            const newGeographicsList = state.geographics.filter(g =>  geographicIds.indexOf(g.geographicZoneId) > -1);
            const listToConcat = (state.employee?.employeesGeographics) || [];
            return  {
                ...state,
                employee: {
                    ...state.employee,
                    employeesGeographics: listToConcat.concat(newGeographicsList),
                }
            }
        }
        case UNCHECK_ALL:
            return { ...state, checkType: [] };
        case UNCHECK_ALLGEOGRAPHIC:
            return { ...state, checkGeographic: [] };
        case CHECK_TYPE: {
            const checkType = action.Type;
            const currArray = state.checkType || [];
            let nextArray = []
            if(currArray.indexOf(checkType.employeeTypeId) < 0) {
                  nextArray = [ ...currArray, checkType.employeeTypeId ];
            } else {
                  nextArray = [ ...currArray ];
                  nextArray = nextArray.filter(id => id !== checkType.employeeTypeId);
                }
            return { ...state, checkType: nextArray };
        }
        case CHECK_ALL_TYPE: {
            const checkAllTypes = action.types;
            return { ...state, checkType: checkAllTypes };
        }
        case CHECK_GEOGRAPHIC: {
            const checkGeographic = action.geographic;
            const currArrayg = state.checkGeographic || [];
            let nextArrayg = [];
            if (currArrayg.indexOf(checkGeographic.geographicZoneId) < 0) {
                    nextArrayg = [ ...currArrayg, checkGeographic.geographicZoneId ];
            } else {
                nextArrayg = [ ...currArrayg ];
                nextArrayg = nextArrayg.filter(id => id !== checkGeographic.geographicZoneId);
            }
            return { ...state, checkGeographic: nextArrayg };
        }
        case UNMOUNT_EMPLOYEE:
            return { ...state, employee: [] };
        default:
            return state;
    }
}

export default employees;
