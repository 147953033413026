import "./VehiclesStyles.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import ApiInvoker from "../../api/ApiInvoker";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CustomInput from "../CustomInput/CustomInput";
import { Drivers as DriversIcon } from "../../icons";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Snackbar from "../Snackbar/Snackbar";
import Table from "../Table/Table";
import TableRowActionsButtons from "../TableRowActionsButtons";
import { withTranslation } from "react-i18next";
import { Add, Edit } from "@material-ui/icons";
import {Tooltip } from "@material-ui/core";
import { browserHistory } from "react-router";
import DriverApiInvoker from "../../api/DriverApiInvoker";
import { debounce } from "lodash";

const showSnackbarInitialValue = {
  open: false,
  color: "danger",
  message: "",
};

const Vehicles = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
 
  const [vehiclesDrivers, setVehiclesDrivers] = useState([]);
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(showSnackbarInitialValue);

  const [customersOptions, setCustomersOptions] = useState([]);


  const cleanFilters = () => {
    setId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setBrand("");
    setModel("");
  };

  const getAllDrivers = () => {
    getDrivers("", "", "", "", "", "");
  };

  const openSnackbar = (message, color = "danger") => {
    setShowSnackbar({
      color,
      message,
      open: true,
    });

    setTimeout(() => {
      setShowSnackbar(showSnackbarInitialValue);
    }, 2000);
  };


  const addVehicle = ()=> {
    
    browserHistory.push({
      state: { mode: 'create', authority: 'appointment_create_vehicle' },
      pathname: `/vehicle/new`
    });
  }

  const getDriverVehicle = ()=>{
    DriverApiInvoker.getDriverVehicle(
      (data) => {
          setVehiclesDrivers(data)
      },
      (error) => {
          setLoading(false);
          openSnackbar(t("common.errorMessage"));
          console.log("** error", error);
      }
  );
  }

  const formatCustomers = (customers) => {
    let result = customers.map((c) => {
      return {
        id: c.personId,
        value: `${c.lastName} ${c.firstName}`,
        customerId: c.id,
      };
    });
    return result;
  };

  const getCustomersOptions = () => {
    ApiInvoker.getDrivers("?page=0&pageSize=20",(response) => {
      setCustomersOptions(formatCustomers(response));
    });
  };

  const getDrivers = (id, firstName, lastName, email, brand, model) => {
    let params = {};
    
    if (id !== "") params.id = id;
    if (firstName !== "") params.firstName = firstName;
    if (lastName !== "") params.lastName = lastName;
    if (email !== "") params.email = email;
    if (brand !== "") params.brand = brand;
    if (model !== "") params.model = model;
    
    params.page = 0;
    params.pageSize = 20;


    setLoading(true);
    DriverApiInvoker.getDriverVehicle(
        (data) => {
            formatVehicles(data);

            setLoading(false);
        },
        (error) => {
            setLoading(false);
            openSnackbar(t("common.errorMessage"));
            console.log("** error", error);
        }
    );
};

  const formatVehicles = (data) => {
    
    DriverApiInvoker.getDriverVehicle(
      (v) => {
      
        let dataDrivers = data.map((d) => {
          const matchedDrivers = v.filter(driverVehicle => driverVehicle.vehicle.vehicleId === d.vehicle.vehicleId);
          return {
            brand: d.vehicle.vehicleBrand.name,
            model: d.vehicle.vehicleModel.name,
            id: d.companyVehicleId,
            type: d.vehicle.carType,
            suitableForChairs: d.vehicle.wheelChairSuitable === true ? 'SI' : 'NO',
            vehicleIdentification: d.vehicle.patent,
            status:d.vehicle.active ? t("vehicle.status.active") : t("vehicle.status.inactive"),
            year: d.vehicle.year,
            drivers:matchedDrivers,
            actions: (
              <div className="drivers-actions">
                <TableRowActionsButtons
                  buttons={[
                    {
                      icon: AssignmentIcon,
                      state: { mode: "view", vehicleId: d.vehicle.vehicleId },
                      pathname: `/vehicle/${d.vehicle.vehicleId}`,
                      messageTooltip: "common.view",
                    },
                    {
                      icon: Edit,
                      state: { mode: "edit-vehicle", vehicleId: d.vehicle.vehicleId },
                      pathname: `/vehicle/${d.vehicle.vehicleId}/edit`,
                      messageTooltip: "Editar",
                    },
                  ]}
                />
              </div>
            ),
          };
        });

        if(lastName !== ""){
          
          dataDrivers = dataDrivers.filter(vehicle => 
            vehicle.drivers.some(driverVehicle => 
              driverVehicle.driver.firstName.toLowerCase().includes(lastName.toLowerCase()) || driverVehicle.driver.lastName.toLowerCase().includes(lastName.toLowerCase())
            )
          );
 
        }
        setDrivers(dataDrivers);
      },
      (error) => {
          setLoading(false);
          openSnackbar(t("common.errorMessage"));
          console.log("** error", error);
      }
  );

    
    
  };

  const find = () => {
    getDrivers(id, firstName, lastName, email, brand, model);
  };

  useEffect(() => {
    getDrivers("", "", "", "", "", "");
    getDriverVehicle();
    getCustomersOptions()
    return () => {
      setDrivers([]);
      setLoading(false);
    };
  }, []);

  


  return (
    <>
      <Snackbar
        open={showSnackbar.open}
        color={showSnackbar.color}
        message={t(showSnackbar.message)}
        place="tr"
      ></Snackbar>
      <GridContainer className="drivers-list">
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              
              <div className="header-internment-table">
                <div className="header-icon vehicle-title">
                  <CardIcon color="primary">
                    <DriversIcon />
                  </CardIcon>
                </div>
                <div className="header-buttons">
                  <Tooltip title="Nuevo vehículo">
                    <span>
                      <Button
                        className="add-content-button appointments-button"
                        round
                        color="primary"
                        authority="appointment_new"
                        onClick={() => addVehicle()}
                      >
                        <Add className="appointments-button-icon" />{" "}
                        {t("vehicle.create")}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
              <GridContainer className="appointments-filters">
                <GridItem xs={10} sm={9} md={10} className="no-padding">
                  <GridContainer>
                    
                    
                    <GridItem xs={6}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("vehicle.driver")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={lastName}
                        onChange={(value) => setLastName(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    
                  </GridContainer>
                </GridItem>

                <GridItem xs={2}>
                  <GridContainer className="appointments-filters" style={{}}>
                    <GridItem className="filters-actions" xs={12}>
                      <Button block onClick={() => find()} color="primary">
                        {t("driver.common.find")}
                      </Button>
                    </GridItem>
                    <GridItem className="filters-actions" xs={12}>
                      <Button
                        block
                        onClick={() => {
                          cleanFilters();
                          getAllDrivers();
                        }}
                        color="danger"
                      >
                        {t("driver.common.clean")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className="filtrable-table">
              <Table
                loading={loading}
                tableHeaderColor="primary"
                striped
                filterable
                tableHead={[
                  { Header: t("vehicle.brand"), accessor: "brand" },
                  { Header: t("vehicle.model"), accessor: "model" },
                  { Header: t("vehicle.type"), accessor: "type" },
                  { Header: t("vehicle.suitableForChairs"), accessor: "suitableForChairs" },
                  { Header: t("vehicle.year"), accessor: "year" },
                  { Header: t("vehicle.vehicleIdentification"), accessor: "vehicleIdentification" },
                  { Header: t("vehicle.status"), accessor: "status" },
                  {
                    Header: t("driver.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={20}
                tableData={drivers}
                colorsColls={["primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

Vehicles.propTypes = {
  t: PropTypes.func, 
}

export default withTranslation()(Vehicles);
