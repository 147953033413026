import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@material-ui/core';

const ComponentDisabledWithTooltip = ({ component, disabled, loading, tooltipText }) => {
  const title = disabled && !loading ? tooltipText : ''
  return (
    <Tooltip title={title} placement="top" arrow>
      <span>{component}</span>
    </Tooltip>
  );
};

ComponentDisabledWithTooltip.defaultProps = {
  disabled: false,
  loading: false,
};

ComponentDisabledWithTooltip.propTypes = {
  component: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  tooltipText: PropTypes.string.isRequired,
};

export default ComponentDisabledWithTooltip;
