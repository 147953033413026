import { DOCUMENT_TYPES } from '../actions/newCustomer';

const newCustomer = (state = {}, action, root) => {
    if (action.type === DOCUMENT_TYPES) {
        return { ...state, documentTypes: action.documentTypes };
    }
    return state;
}

export default newCustomer;
