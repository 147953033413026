import { connect } from 'react-redux';
import CustomerForm from '../components/NewSchedule/CustomerForm';
import DiagnosticForm from '../components/NewSchedule/DiagnosticForm';
import DiagnosticFormReadOnly from '../components/NewSchedule/DiagnosticFormReadOnly';
import ProviderForm from '../components/NewSchedule/ProviderForm';
import ScheduleDetail from '../components/NewSchedule/ScheduleDetail';
import ScheduleDetailTransfer from '../components/NewSchedule/ScheduleDetail/ScheduleDetailTransfer.js';
import { getCustomers } from '../actions/customers';
import { fetchModules, fetchPracticeTypes } from '../actions/newSchedule';
import Modules from '../components/NewSchedule/ScheduleDetail/ScheduleDetailModal/Modules';
import Plan from '../components/NewSchedule/ScheduleDetail/ScheduleDetailModal/Plan';
import AuthorizerForm from '../components/NewSchedule/AuthorizerForm';
import ResponsableForm from '../components/NewSchedule/ResponsableForm/ResponsableForm';
import { fetchPatologies, fetchComplexities, fetchBarthels } from '../actions/records'
import DisabilityForm from '../components/NewSchedule/DisabilityForm';
import CustomerFormServicesType from '../components/NewSchedule/CustomerFormServiceType';
import CustomerFormServicesDetail from '../components/NewSchedule/CustomerFormServiceDetail';
import CustomerFormServicesSummary from '../components/NewSchedule/CustomerFormServiceSummary';
import FinancialForm from '../components/NewSchedule/FinancialForm';

const mapStateToProps = (state) => {
    return {
        customers: state.customers.customers,        
        modules: state.newSchedule.modules,
        practiceTypes: state.newSchedule.practiceTypes,
        complexities: state.records.complexities,
        barthels: state.records.barthels,
        patologies: state.records.patologies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetComplexities: (complexities) => {
            dispatch(fetchComplexities(complexities));
        },

        onGetPatologies: (patologies) => {
            dispatch(fetchPatologies(patologies));
        },

        onGetBarthels: (barthels) => {
            dispatch(fetchBarthels(barthels));
        },
        onGetCustomers: (customers) => {
            dispatch(getCustomers(customers));
        },
        onFetchModules: (modules) => {
            dispatch(fetchModules(modules));
        },
        onFetchPracticeTypes: (practiceTypes) => {
            dispatch(fetchPracticeTypes(practiceTypes));
        }
    }
}

const CustomerFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerForm);

const DisabilityFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DisabilityForm);

const DiagnosticFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiagnosticForm);

const DiagnosticFormReadOnlyService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiagnosticFormReadOnly);

const ProviderFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProviderForm);

const FinancialFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(FinancialForm);

const ScheduleDetailService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleDetail);

const ScheduleDetailTransferService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleDetailTransfer);

const ModulesService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Modules);

const PlanService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Plan);

const AuthorizerFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthorizerForm);

const ResponsableFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsableForm);

const CustomerFormServiceType = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerFormServicesType);

const CustomerFormServiceDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerFormServicesDetail);

const CustomerFormServiceSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerFormServicesSummary);


export default {
    CustomerFormService,
    DisabilityFormService,
    DiagnosticFormService,
    DiagnosticFormReadOnlyService,
    ProviderFormService,
    FinancialFormService,
    ScheduleDetailService,
    ScheduleDetailTransferService,
    ModulesService,
    PlanService,
    ResponsableFormService,
    AuthorizerFormService,
    CustomerFormServiceType,
    CustomerFormServicesDetail,
    CustomerFormServiceSummary
};
