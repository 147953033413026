import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { AppointmentsRepository } from "../repositories/appointments.repository";
import { ScheduleRequest } from "../../domain";

@injectable()
export class GetSchedulesRequestUsecase {
  @inject(AppointmentsRepository) protected repository!: AppointmentsRepository;

  async execute(appointmentId: number): Promise<Either<GetSchedulesRequestError, ScheduleRequest[]>> {
    if (!appointmentId) {
      return Either.left(new GetSchedulesRequestInvalidDataError());
    }
    const result = await this.repository.getSchedulesRequest(appointmentId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetSchedulesRequestError {
    if (exception instanceof NotFoundException) {
      return new GetSchedulesRequestNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetSchedulesRequestConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetSchedulesRequestOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetSchedulesRequestAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetSchedulesRequestBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetSchedulesRequestUsecase");
  }
}

export abstract class GetSchedulesRequestError {
  error?: string;
}

export class GetSchedulesRequestInvalidDataError extends GetSchedulesRequestError { }
export class GetSchedulesRequestNotFoundError extends GetSchedulesRequestError { }
export class GetSchedulesRequestConnectionError extends GetSchedulesRequestError { }
export class GetSchedulesRequestOperationalError extends GetSchedulesRequestError { }
export class GetSchedulesRequestAuthorizationError extends GetSchedulesRequestError { }
export class GetSchedulesRequestBadRequestError extends GetSchedulesRequestError {
  constructor(public readonly error: string) {
    super();
  }
}
