import '@material/react-chips/dist/chips.css';

import { Button, Card, CardActions, CardContent, MenuItem, MenuList, Paper } from '@material-ui/core';
import React, { Component } from 'react';

import ApiInvoker from '../../../api/ApiInvoker';
import { Chip } from '@material/react-chips';
import PropTypes from 'prop-types';
import RadioInput from '../../RadioInput';
import SelectInput from '../../SelectInput';
import { withTranslation } from 'react-i18next';

class CustomInputChip extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        const filters = [
            { id: 'financier', title: t('dashboardcustomer.filter.financier'), code: 'financier', type: 'select' },
            { id: 'mainDiagnostic', title: t('dashboardcustomer.filter.mainDiagnostic'), code: 'mainDiagnostic', type: 'select' },
            { id: 'pathologyId', title: t('dashboardcustomer.filter.patologicid'), code: 'pathologyId', type: 'select' },
            { id: 'employeesInvolved', title: t('dashboardcustomer.filter.employee'), code: 'employeesInvolved', isArray: true, subproperty: 'personId', type: 'select' },
            { id: 'province', title: t('dashboardcustomer.filter.province'), code: 'province', type: 'select' },
            { id: 'city', title: t('dashboardcustomer.filter.city'), code: 'city', type: 'select' },
            { id: 'geographicZone', title: t('dashboardcustomer.filter.geographiczone'), code: 'geographicZone', type: 'select' },
            { id: 'disabledPatient', title: t('appointment.disabledPatient.title'), code: 'disabledPatient', type: 'radio' },
            { id: 'activeDisabilityCertificate', title: t('appointment.activeDisabilityCertificate'), code: 'activeDisabilityCertificate', type: 'radio' },
        ];

        this.state = {
            show_items: false,
            items: [],
            locations: [],
            filters,
        }
    }

    onChipClick = () => {
       this.setState((prev) => ({ show_items: !prev.show_items }));
    }

    handleEvent = (e) => {
        const { filters } = this.state;
        const v = filters[e.target.id];
        let source = null;
        const optionsRadio = [
            { id: '1', value: this.props.t('form.option.yes') },
            { id: '2', value: this.props.t('form.option.no') },
        ];
        if (v.code === 'employeesInvolved') {
            source = this.props.employees;
        } else if (v.code === 'employeesType') {
            source = this.props.employeesType;
        } else if (v.code === 'financier') {
            source = this.props.financiers;
        } else if (v.code === 'mainDiagnostic' || v.code === 'pathologyId') {
            source = this.props.patologies;
        } else if (v.code === 'province') {
            source = this.props.provincies;
        } else if (v.code === 'city') {
            source = this.state.locations;
        } else if (v.code === 'geographicZone') {
            source = this.state.zones;
        } else if (v.code === 'disabledPatient' || v.code === 'activeDisabilityCertificate') {
            source = optionsRadio;
            v.value = '2';
            v.name = ['NO'];
        } else {
            console.error('WARNING PASSED A FILTER THAT IS NOT ON THE LIST OF ACCEPTED PROPS!!')
        }
        this.setState((prevState) => ({ items: source, show_items: !prevState.show_items, selected_type: v }));

    }

    findValue = (value) => {
        let r = null;
        const selectedItem = this.state.items.find(item => item.id === value);

        if (selectedItem) {
            r = selectedItem.value;
        }
        return r;
    }

    getLocations(value) {
        ApiInvoker.getLocations(value, loc => {
            if (loc?.length) {
                this.setState({ locations: this.formatLocations(loc) });
            }
        }, (error) => console.error('** error getLocations', error));
    }

    formatLocations = (locations) => locations.map(loc => ({
        id: loc.locationId,
        value: loc.name,
    }));

    getZones(value) {
        ApiInvoker.getGeographicZoneFromLocation(value, zones => {
            const geoZones = zones?.length ? this.formatZones(zones) : [];
            this.setState({ zones: geoZones });
        }, (error) => console.error('** error getGeographicZoneFromLocation', error));
    }

    formatZones = (zones) => zones.map(e => ({
        id: e.geographicZoneId,
        value: e.detail,
    }));

    handleValue(value) {
        const { selected_type } = this.state;
        const item = selected_type;
        item.value = value;
        if (item.code === 'province') {
            this.getLocations(value);
        } else if (item.code === 'city') {
            this.getZones(value);
        }
        item.name = this.findValue(value);
        this.setState({ selected_type: item });
    }

    handleCloseDialog = () => this.setState({ selected_type: null });

    handleApply = () => {
        if (!this.state.selected_type.value) {
            return;
        }
        const s = this.state.selected_type;
        this.props.onApply(s);
        this.setState({ selected_type: null });
    }

    has = (keyvalue) => {
        let p = false;
        this.state.filters.forEach((item) => {
            if (item.code === keyvalue && item.value) {
                p = true;
            }
        });
        return p;
    }

    hasSelectedRadio = (keyvalue) => {
        let p = false;
        this.state.filters.forEach((item) => {
            if (item.code === keyvalue && item.value === '1') {
                p = true;
            }
        });
        return p;
    }

    findItemValue = (code) => {
        let ret = false;
        this.props.selecteditems.map((item, key) => {
            if (item.code === code) {
                ret = true;
            }
        });
        return ret;
    }

    renderCardDialogItem = (selected_type) => {
        const { items } = this.state;
        switch (true) {
            case selected_type.type === 'select':
                return  (
                    <SelectInput 
                        className="customInputChipSelect"
                        elements={items}
                        onSelectedValue={(value) => this.handleValue(value)}
                        label={selected_type.title}
                    />
                );
            case selected_type.type === 'radio':
                return (
                    <>
                        <p className="radio-legend">
                            {selected_type.title}
                        </p>
                        <RadioInput
                            elements={items}
                            value={selected_type.value}
                            onChangeValue={value => this.handleValue(value)}
                            inputProps={{
                                disabled: false,
                            }}
                        />
                    </>
                )
            default:
                break;
        }
    }

    renderCardDialog = () => {
        const { t } = this.props;
        return (
            <Card
                open={true}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title" 
                aria-describedby="alert-dialog-description"
            >
                <CardContent>
                    {this.renderCardDialogItem(this.state.selected_type)}
                </CardContent>
                <CardActions>
                    <Button id="button-close" onClick={this.handleCloseDialog} color="primary">
                        {t('dashboardcustomer.filter.close')}
                    </Button>
                    <Button id="button-apply" onClick={this.handleApply} color="primary">
                        {t('dashboardcustomer.filter.apply')}
                    </Button>
                </CardActions>
            </Card>
        );
    }

    render() {
        const { id, label } = this.props;
        const { filters, selected_type, show_items } = this.state;

        if (selected_type) return this.renderCardDialog();

        return (
            <div>
                <Chip id={id} label={label} handleInteraction={this.onChipClick} />
                {show_items ? 
                    <div style={{position: 'absolute'}}>
                        <Paper>
                            <MenuList>
                                {filters.map((item) => {
                                    const findc = this.findItemValue(item.code)
                                    if (findc || (item.code === 'city' && !this.has('province')) || (item.code === 'geographicZone' && !this.has('city')) || (item.code === 'activeDisabilityCertificate' && !this.hasSelectedRadio('disabledPatient'))) {
                                        return <div key={item.code} />;
                                    }
                                    return (
                                        <MenuItem id={item.id} key={item.code} onClick={this.handleEvent}>{item.title}</MenuItem>
                                    )}
                                )}
                            </MenuList>
                        </Paper>
                    </div>
                    : <div/>
                }
            </div>
        )
    }
}

CustomInputChip.propTypes = {
    t: PropTypes.func,
    employees: PropTypes.array,
    employeesType: PropTypes.array,
    financiers: PropTypes.array,
    patologies: PropTypes.array,
    provincies: PropTypes.array,
    selecteditems: PropTypes.array,
    onApply: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
}

export default withTranslation()(CustomInputChip);
