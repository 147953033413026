import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { useStylesGralSteps } from '../../useStylesReassignAppStepper';


import SchedulerService from '../../../../containers/SchedulerService.js';


const ServicesSummary = ({
  clearInformation,
  handleIsValidStep,
  isConfirmStep,
  onSelectAllSchedules,
  showGraphicsInfo,
  customerDetail,
  transfer,
  transferWay,
  values: { chips, loadingSchedule, schedules, schedulesSelected, checkAllSchedules, startDate, endDate, practicesType },
  t,
}) => {
  const classes = useStylesGralSteps();

  const [customerId, setCustomerId] = useState('');
  const [addressId, setAddressId] = useState('');
  const [zoneId, setZoneId] = useState('');
  const isValid = schedulesSelected.length;

  useEffect(() => {
    handleIsValidStep(isValid);
  }, [handleIsValidStep, isValid])

  const handleFilledCustomer = (value) => {
    setCustomerId(value);
    setAddressId(value.address);
    setZoneId(value.zone);
  };
  return (
    <div className={clsx(classes.formStepper, {[classes.readOnlyForm]: isConfirmStep})}>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={12} md={12} >
        
          <SchedulerService.CustomerFormServiceSummary
            
            transferWay={transferWay}
            onFilledCustomer={handleFilledCustomer}
            customerDetail={customerDetail}
            onUpdateCustomers=""
            
          />
        </Grid>

        
        
      </Grid>
    </div>
  )
}

ServicesSummary.propTypes = {
  t: PropTypes.func,
  clearInformation: PropTypes.func,
  handleIsValidStep: PropTypes.func,
  isConfirmStep: PropTypes.bool,
  customerDetail: PropTypes.object,
  onSelectAllSchedules: PropTypes.func,
  showGraphicsInfo: PropTypes.func,
  values: PropTypes.object,
}

export default withTranslation()(ServicesSummary);
