import '@material/react-chips/dist/chips.css';

import { Chip, ChipSet } from '@material/react-chips';
import React, { Component } from 'react';

import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ChipFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      professionals: [],
      employees: [],
      dateFrom: '',
      dateTo: '',
      professional: [],
      elements: [],
    }
  }

  addFilter = (s) => {
    const c = this.props.chips;
    c.push(JSON.parse(JSON.stringify(s)));
    this.props.onChangeChips(c, this.props.chips);
  }

  onChipRemove = (s) => {
    const { chips, onChangeChips } = this.props;
    chips.map((item, key) => {
      const label = `${item.title} '${item.name}' `;
      if (label === s) {
        chips.splice(key, 1);
        onChangeChips(item, this.props);
      }
    });

    this.setState({ refresh: true });
  }

  renderChipsLabel = (chips) => chips.map((item) => {
    const label = `${item.title} '${item.name}'`;
    return (
      <Chip
          key={item.code}
          className="chipsInFilter"
          filter={true}
          selecteditems={chips}
          trailingIcon={<Cancel />}
          shouldRemoveOnTrailingIconClick={false}
          handleTrailingIconInteraction={this.onChipRemove}
          id={label}
          label={label}
      />
    );
  })

  render() {     
    const {
      chips, employees, financiers, practiceTypes, provincies, t,
    } = this.props;
    return (
      <ChipSet>
        <CustomInputChip
          selecteditems={chips}
          id="add-filter"
          label={t('common.addFilter')}
          financiers={financiers}
          practiceTypes={practiceTypes}
          provincies={provincies}
          employees={employees}
          onApply={this.addFilter}
        />
        {this.renderChipsLabel(chips)}
      </ChipSet>
    )
  }
}

ChipFilters.propTypes = {
  t: PropTypes.func,
  chips: PropTypes.array,
  employees: PropTypes.array,
  onChangeChips: PropTypes.func,
  financiers: PropTypes.array,
  practiceTypes: PropTypes.array,
  provincies: PropTypes.array,
}

export default withTranslation()(ChipFilters);