import React from 'react';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Button from '../CustomButtons/Button';

const TableRowActionsButtons = ({ t, buttons }) => {
  
  const renderData = () => buttons.map((b, i) => (
    <Tooltip title={t(b.messageTooltip)} key={`button${i}`}>
      <span>
        <Button simple justIcon color="info" onClick={
          () => browserHistory.push({
            state: b.state,
            pathname: b.pathname,
          })}
        ><b.icon /></Button>
      </span>
    </Tooltip>
  ))

  return renderData();
}

TableRowActionsButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any.isRequired,
      state: PropTypes.object,
      pathname: PropTypes.string.isRequired,
      messageTooltip: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default withTranslation()(TableRowActionsButtons)
