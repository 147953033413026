import { BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { UsersRepository } from "../repositories";

@injectable()
export class ConfirmUserUsecase {

  @inject(UsersRepository) protected repository!: UsersRepository;

  async execute(hash: string, companyId: string): Promise<Either<ConfirmUserError, string>> {
    if (!hash || !companyId) {
      return Either.left(new ConfirmUserInvalidDataError());
    }
    const result = await this.repository.confirmUser(hash, companyId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): ConfirmUserError {
    if (exception instanceof NotFoundException) {
      return new ConfirmUserNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new ConfirmUserConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new ConfirmUserOperationalError();
    }
    if (exception instanceof BadRequestException) {
      return new ConfirmUserBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in ConfirmUserUsecase");
  }
}

export abstract class ConfirmUserError {
  error?: string;
}

export class ConfirmUserInvalidDataError extends ConfirmUserError { }
export class ConfirmUserNotFoundError extends ConfirmUserError { }
export class ConfirmUserConnectionError extends ConfirmUserError { }
export class ConfirmUserOperationalError extends ConfirmUserError { }
export class ConfirmUserBadRequestError extends ConfirmUserError {
  constructor(public readonly error: string) {
    super();
  }
}
