import { AuthorizationException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { ReasonRejected } from "../../domain/rejected-reason";
import { ReasonsRejectedRepository } from "../repositiories/reasons-rejected-repository";

@injectable()
export class GetReasonsRejectedUsecase {

  @inject(ReasonsRejectedRepository) protected repository!: ReasonsRejectedRepository;

  async execute(): Promise<Either<GetReasonRejectedError, ReasonRejected[]>> {
    const result = await this.repository.getReasons();
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetReasonRejectedError {
    if (exception instanceof NotFoundException) {
      return new GetReasonsRejectedNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetReasonsRejectedConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetReasonsRejectedOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetReasonsRejectedAuthorizationError();
    }

    throw new Error("Unexpected error in GetReasonsRejectedUsecase");
  }
}

export abstract class GetReasonRejectedError {
  error?: string;
}

export class GetReasonsRejectedNotFoundError extends GetReasonRejectedError { }
export class GetReasonsRejectedConnectionError extends GetReasonRejectedError { }
export class GetReasonsRejectedOperationalError extends GetReasonRejectedError { }
export class GetReasonsRejectedAuthorizationError extends GetReasonRejectedError { }
