import { FETCH_CUSTOMERS, FETCH_EMPLOYEES } from '../actions/routes'

const routes = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS: {
      const customers = action.customers
      return { ...state, customers }
    }
    case FETCH_EMPLOYEES: {
      const employees = action.employees
      return { ...state, employees }
    }
    default:
      return state;
  }
}

export default routes;
