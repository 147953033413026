import { FETCH_MODULES, FETCH_PRACTICE_TYPES, SAVE_SCHEDULE, RESET_SCHEDULE, SAVE_DATES, APPOINTMENT } from "../actions/newSchedule";

const newSchedule = (state = {}, action) => {
    switch(action.type) {
        case FETCH_MODULES: {
            const modules = action.modules
            return {...state, modules}
        }
        case FETCH_PRACTICE_TYPES: {
            const practiceTypes = action.practiceTypes
            return {...state, practiceTypes}
        }
        case SAVE_SCHEDULE: {
            const schedule = action.schedule
            return {...state, schedule}
        }
        case RESET_SCHEDULE:
            return { ...state, schedule: undefined, dates: undefined}
        case APPOINTMENT:
            return { ...state, schedule: action.appointment}
        case SAVE_DATES: {
            const dates = action.dates
            return {...state, dates}
        }
        default:
            return state;
    }
}

export default newSchedule;
