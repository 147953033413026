import { ApiGetProfileFromMap } from "./api-get-profile.from-map";
import { Profile } from "../domain/profile";

export class ApiGetProfileFromMapper {
  fromMap(map: ApiGetProfileFromMap): Profile {
    const companies = map.companies.map((company) => ({
      companyId: company.company_id,
      name: company.name,
      authorities: company.authorities,
      companyPartnership_id: company.companyPartnership_id,
      companyPartnership_name: company.companyPartnership_name

    }));
    const profile = new Profile({
      userId: map.userId,
      firstName: map.firstName,
      lastName: map.lastName,
      email: map.email,
      companies,
      companyPartnershipId: map.company_partnership_id ?? null,
      companyCurrencyId: map.companyCurrencyId,
      isPatient: map.is_patient,
      isProfessional: map.profesional,
      requiereTermsAccept: map.requiereTermsAccept,
      requestTermsVersion: map.requestTermsVersion,
      isActive: map.active,
      appRelaseVersion: map.APP_VERSION,
      appRelaseDate: map.RELEASE_DATE,
    });
    return profile;
  }
}
