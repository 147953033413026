import BaseInvoker from "./BaseInvoker";

const baseInvokerInstance = new BaseInvoker();

export default class ThirdPartiesInvoker {
    static getGoogleGeolocation(completeAddress, callback, callerror) {
		baseInvokerInstance.getWithoutSecurityHeaders(`/geocode/json?address=${completeAddress}`, callback, callerror);
	}

    static getGoogleLocationBasedOnLatLong(lat, long, callback, callerror) {
		baseInvokerInstance.getWithoutSecurityHeaders(`/geocode/json?latlng=${lat},${long}&sensor=false`, callback, callerror);
	}
}
