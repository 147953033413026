import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import ChartistGraph from 'react-chartist';
import CardBody from '../../Card/CardBody';
import CardIcon from '../../Card/CardIcon';
import BarChart from '@material-ui/icons/BarChart';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import DashboardApiInvoker from "../../../api/DashboardApiInvoker";

class DesviationChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            series: {},
        }
    }

    componentWillReceiveProps(next) {
        if (next.employeeId) {
            this.getSeriesData(next.employeeId)
        }

        if (next.clear) {
            this.setState({ series: [] });
        }
    }

    getSeriesData(employeeId) {
        if (employeeId
            && this.props.find) {
            DashboardApiInvoker.postEmployeeDashboardPuntuality({
                dateFrom: this.props.dateFrom,
                dateTo: this.props.dateTo,
                employeeId: employeeId
            }, data => {
                this.formatSeries(data)
            }, null)
        }
    }

    updateSerieArray(series, i, j, schedulePuntuality) {
        if (i === 0) {
            series.push([schedulePuntuality])
        } else if (series[j]) {
            series[j].push(schedulePuntuality)
        } else {
            series[j] = [];
            for (let k = 0; k < i; k++) {
                series[j].push(null)
            }
            series[j].push(schedulePuntuality)
        }
        return series;
    }

    extractLabelsAndSeries(data) {
        const labels = [];
        let series = [];
        if (data?.length) {
            data.forEach((serie, i) => {
                if (serie?.length) {
                    labels.push(moment(serie[0].scheduleDateTime).format('DD-MM'))
                    serie.forEach((s, j) => {
                        series = this.updateSerieArray(series, i, j, s.schedulePuntuality)
                    });
                }
            });
        }
        return { labels, series };
    }
    

    formatSeries(data) {
        const { labels, series } = this.extractLabelsAndSeries(data);
        this.setState({ series: { labels, series } });
    }

    render() {
        const { t } = this.props
        return (
            <Card>
                <CardHeader icon>
                    <CardIcon color="primary">
                        <BarChart />
                    </CardIcon>
                    <h3 className="card-icon-title">
                        {t("dashboard.chart.deviation")}
                    </h3>
                </CardHeader>
                <CardBody>
                    <ChartistGraph
                        className="ct-chart-white-colors"
                        data={this.state.series}
                        type="Bar"
                        options={this.props.options}
                    />
                </CardBody>
            </Card>
        )
    }
}

DesviationChart.propTypes = {
    t: PropTypes.func,
    options: PropTypes.object,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    find: PropTypes.bool,
}

export default withTranslation()(DesviationChart);