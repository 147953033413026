import { Check, Delete, Edit } from '@material-ui/icons';
import React, { Component } from 'react';

import Button from '../CustomButtons/Button';
import { Checkbox } from '@material-ui/core';
import ConfirmAlert from '../ConfirmAlert';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import Table from '../Table/Table';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

class Phone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phones: [],
            loading: true,
            deleteAlert: false,
            selectedPhone: '',
            success: false,
            error: false,
        }
    }

    componentWillReceiveProps(next) {
        if (next.personId && next.refresh) {
            this.findPhones(next.personId);
        }
    }

    findPhones(personId) {
        PersonApiInvoker.getPersonPhone(personId, data => {
            const phones = this.formatPhone(data);
            this.setState({ phones, loading: false });
        }, null);
    }

    formatPhone(phones) {
        const { t, classes, personType } = this.props;
        if (phones?.lenght < 1) {
            return [];
        }
        const result = phones.map(p => {
            let customerData = {};
            if (personType == 'customer') {
                customerData = {
                    isFamily: p.isFamiliar ? t('form.option.yes') : t('form.option.no'),
                    familyName: p.fullName ?? '',
                }
            }
            return {
                ...customerData,
                phoneId: p.phoneId,
                detail: p.detail,
                type: p.phoneType.name,
                number: p.number,
                default: <Checkbox
                    tabIndex={-1}
                    checked={p.phoneDefault}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                />,
                actions: (this.props.componenMode !== 'view') && <div><Button id={`button-edit-${p.phoneId}`} simple justIcon color="success" onClick={() => this.editPhone(p)}><Edit /></Button>
                    <Button id={`button-delete-${p.phoneId}`} simple justIcon color="danger" onClick={() => this.deletePhone(p.phoneId)}><Delete /></Button></div>
            }
        });
        return result;
    }

    editPhone(phone) {
        this.props.onEditPhone(phone)
    }

    deletePhone(phoneId) {
        this.setState({
            deleteAlert: true,
            selectedPhone: phoneId,
        });
    }

    deleteItem() {
        PersonApiInvoker.deletePersonsPhone(this.props.personId, this.state.selectedPhone, (data) => {
            const { phones } = this.state;
            const phonesData = phones.filter(phone => phone.phoneId !== this.state.selectedPhone);
            this.setState({
                success: true,
                phones: phonesData,
            });
            this.props.onDeletePhone(phonesData);
        }, () => {
            this.setState({ error: true });
        });
    }

    closeAlert() {
        this.setState({ deleteAlert: false, success: false, error: false });
    }

    renderTableHead = () => {
        const { t, componenMode, personType } = this.props;
        const tableHead =  [
            { Header: t('common.description'), accessor: 'detail' },
            { Header: t('tooltypes.table.toolType'), accessor: 'type' },
            { Header: t('employee.new.modal.phone'), accessor: 'number' },
            { Header: t('employee.new.modal.mainPhone'), accessor: 'default' },
        ];
        if (personType == 'customer') {
            tableHead.push(
                { Header: t('phoneForm.isFamily'), accessor: 'isFamily' },
                { Header: t('phoneForm.isFamily.name'), accessor: 'familyName' }
            );
        }
        if (componenMode !== 'view') {
            tableHead.push({ Header: t('employee.new.actions'), accessor: 'actions', sortable: false, width: 150 });
        }
        return tableHead;
    }

    render() {
        const { t } = this.props;
        const { loading, deleteAlert, error, phones, success } = this.state;
        return (
            <>
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={true}
                    tableHead={this.renderTableHead()}
                    tableData={phones}
                    colorsColls={["primary"]}>
                </Table>
                
                <ConfirmAlert
                    text={t('employee.new.modal.phone')}
                    onClose={() => this.closeAlert()}
                    onSuccess={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    show={deleteAlert}
                    success={success}
                    error={error}
                />
            </>
        )
    }
}

Phone.propTypes = {
    personId: PropTypes.any.isRequired,
    personType: PropTypes.oneOf(['customer', 'employee']).isRequired,
    t: PropTypes.func,
    classes: PropTypes.object,
    onEditPhone: PropTypes.func,
    onDeletePhone: PropTypes.func,
    componenMode: PropTypes.string,
}

export default withStyles(styles)(withTranslation()(Phone));
