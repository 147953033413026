import { initialValueTermsPolicyProfile } from "../util";

export interface TermsPolicyProfile {
  userId: number;
  firstName: string;
  lastName: string;
  requiereTermsAccept: boolean;
  requestTermsVersion: string;
}

export const useTermsPolicyProfile = () => {
  const saveTermsPolicyOnProfile = (termsPolicy: TermsPolicyProfile) => {
    localStorage.setItem('terms_policy', JSON.stringify(termsPolicy));
  }

  const getTermsAndPolicyFromProfile = (): TermsPolicyProfile => {
    const termsPolicy = localStorage.getItem('terms_policy');
    return termsPolicy ? JSON.parse(termsPolicy) : initialValueTermsPolicyProfile;
  }

  return { saveTermsPolicyOnProfile, getTermsAndPolicyFromProfile };
}
