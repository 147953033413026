import { ApiErrorParser, CoreException, Either } from "../../../sdk";
import { AppointmentItem, ScheduleRequest } from "../domain";

import { ApiAppointmentsFromMapper } from "./api-appointments.from-mapper";
import { ApiAppointmentsItemsFromMap } from "./api-appointments-items.from-map";
import { ApiScheduleRequestFromMap } from "./api-schedule-request.from-map";
import { ApiScheduleRequestFromMapper } from "./api-schedule-request.from-mapper";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import { AppointmentsRepository } from "../application";
import { injectable } from "inversify";

const mapper = new ApiAppointmentsFromMapper();
const mapperRequest = new ApiScheduleRequestFromMapper();
const errorParser = new ApiErrorParser();

@injectable()
export class ApiAppointmentsRepository implements AppointmentsRepository {

  async acceptScheduleRequest(scheduleId: number): Promise<Either<CoreException, ScheduleRequest[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, ScheduleRequest[]>;
      AppointmentApiInvoker.postAcceptItemRequest(scheduleId, (res: any) => {
        const data = res.map((schedule: ApiScheduleRequestFromMap) => mapperRequest.fromMap(schedule));
        result = Either.right(data);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async getSchedulesRequest(appointmentId: number): Promise<Either<CoreException, ScheduleRequest[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, ScheduleRequest[]>;
      AppointmentApiInvoker.getAppointmentItems(appointmentId, (res: ApiScheduleRequestFromMap[]) => {
        const data = res.map((schedule: ApiScheduleRequestFromMap) => mapperRequest.fromMap(schedule));
        result = Either.right(data);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async getAppointmentItems(id: number): Promise<Either<CoreException, AppointmentItem[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, AppointmentItem[]>;
      AppointmentApiInvoker.getAppointmentItemsPracticeModule(id, (res: ApiAppointmentsItemsFromMap[]) => {
        const data = res.map((appointment: ApiAppointmentsItemsFromMap) => mapper.fromMap(appointment));
        result = Either.right(data);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    })
  }

}
