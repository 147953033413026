import { CompanyProfile, Profile } from "../core";

import { useTermsPolicyProfile } from "./useTermsPolicyProfile";

export const useProfile = () => {
  const { saveTermsPolicyOnProfile } = useTermsPolicyProfile();

  const saveCompanyOnLocalStorage = (companies: CompanyProfile[]) => {
    localStorage.setItem('companies', JSON.stringify(companies));
    if (companies?.length === 1) {
      localStorage.setItem('company', JSON.stringify(companies[0]));
      localStorage.setItem('itlg_default_company_id', companies[0].companyId.toString());
      localStorage.setItem('itlg_default_company_name', companies[0].name);
    } else {
      localStorage.setItem('itlg_default_company_id', '-1');
      localStorage.setItem('itlg_default_company_name', '');
    }
  }

  const saveProfileStorage = (profile: Profile) => {
    if (!profile) return;
    const { appRelaseVersion, appRelaseDate, companyPartnershipId, isProfessional, userId, firstName, lastName, email, companyCurrencyId, companies } = profile;
    localStorage.setItem('user_id', userId.toString());
    localStorage.setItem('username', `${firstName} ${lastName}`);
    localStorage.setItem('email', email);
    localStorage.setItem('company_currency_id', companyCurrencyId.toString());
    localStorage.setItem('profesional', isProfessional.toString());
    localStorage.setItem('APP_VERSION', appRelaseVersion);
    localStorage.setItem('RELEASE_DATE', appRelaseDate);
    if (companyPartnershipId != null) {
      localStorage.setItem('company_partnership_id', companyPartnershipId.toString());
    } else {
      localStorage.removeItem('company_partnership_id');
    }
    saveCompanyOnLocalStorage(companies);
    const { requiereTermsAccept, requestTermsVersion } = profile
    saveTermsPolicyOnProfile({ userId, firstName, lastName, requiereTermsAccept, requestTermsVersion });
  }

  return { saveProfileStorage };
}
