import './ToolTypes.css';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import React, { Component } from 'react';

import { Accessible } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import ApiInvoker from '../../api/ApiInvoker.js';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Edit from '@material-ui/icons/Edit';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import Table from '../Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class ToolTypes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltypes: [],
            loading: false,
        }
    }

    componentWillMount() {
        this.getToolTypes();
    }

    componentWillReceiveProps(next) {
        if (next.tooltypes) {
            this.formatToolTypes(next.tooltypes);
        }
    }

    getToolTypes() {
        this.setState({ loading: true });
        ApiInvoker.getToolTypes(data => {
            this.setState({ loading: false });
            if (data?.length) {
                this.props.onGetToolTypes(data);
            }
        }, (error) => {
            this.setState({ loading: false });
            console.error('** error getToolTypes', error);
        });
    }

    addToolType = () => browserHistory.push('nuevo-tooltype');
    
    editToolType = (id) => browserHistory.push(`/editar-tooltype/${id}`);

    formatToolTypes(tooltypes) {
        const data = tooltypes.map(c => {
            return {
                name: c.name,
                toolTypeCode: c.toolTypeCode,
                id: c.toolTypeId,
                detail: c.detail,
                actions: <div className="tooltypes-actions">
                    <Tooltip title={this.props.t('common.edit')}>
                        <span>
                            <Button
                                id={`button-edit-${c.toolTypeId}`}
                                simple
                                justIcon
                                color="success"
                                authority="tool_type_edit"
                                onClick={() => this.editToolType(c.toolTypeId)}
                            ><Edit /></Button>
                        </span>
                    </Tooltip>
                </div>
            }
        });
        this.setState({ tooltypes: data });
    }
    
    exportToExcel = (toolTypes) => {
        const { t } = this.props;
        let r = [];
        const fields = {
            'toolTypeCode': t('tooltypes.table.toolTypeCode'),
            'name': t('tooltypes.table.name'),
            'detail': t('tooltypes.table.detail'),
        };
        r.push(fields);
        
        toolTypes.forEach(s => {
            const toolType = {
                toolTypeCode: s.toolTypeCode,
                name: s.name,
                detail: s.detail,
            }
            r = r.concat(toolType);
        })

        const fileName = `toolTypes-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(r, {skipHeader: 1});
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data2, fileName + fileExtension);
    }

    render() {
        const { t } = this.props;
        const { loading, tooltypes } = this.state;
        return (
            <GridContainer className="tooltypes-container">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <Accessible />
                            </CardIcon>
                            <Button
                                id="button-create"
                                className="add-content-button tooltypes-button"
                                round
                                color="primary"
                                authority="tool_type_new"
                                onClick={() => this.addToolType()}
                            >
                                <Add className="tooltypes-button-icon" /> {t('tooltypes.create')}
                            </Button>
                            
                        </CardHeader>
                        <CardBody className="filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                className="filtrable sticky"
                                tableHead={[
                                    { Header: t('tooltypes.table.toolTypeCode'), accessor: 'toolTypeCode' },
                                    { Header: t('tooltypes.table.name'), accessor: 'name' },
                                    { Header: t('tooltypes.table.detail'), accessor: 'detail' },
                                    { Header: t('tooltypes.table.actions'), accessor: 'actions', sortable: false, filterable: false }
                                ]}
                                tableData={tooltypes}
                                colorsColls={['primary']}
                            />
                        </CardBody>
                        <CardFooter className="tooltypes-submit">
                            <Button
                                id="button-export"
                                className="button-export"
                                onClick={() => this.exportToExcel(tooltypes)}
                                color="primary"
                                disabled={tooltypes?.length < 1}
                            >
                                {t('common.exportExcel')}
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

ToolTypes.propTypes = {
    t: PropTypes.func.isRequired,
    onGetToolTypes: PropTypes.func.isRequired,
}

export default withTranslation()(ToolTypes);
