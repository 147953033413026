import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { Profile } from "../../domain";
import { UsersRepository } from "../repositories";

@injectable()
export class GetProfileUsecase {

  @inject(UsersRepository) protected repository!: UsersRepository;

  async execute(): Promise<Either<GetProfileError, Profile>> {
    const result = await this.repository.getProfile();
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetProfileError {
    if (exception instanceof NotFoundException) {
      return new GetProfileNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetProfileConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetProfileOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetProfileAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetProfileBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetProfileUsecase");
  }
}

export abstract class GetProfileError {
  error?: string;
}

export class GetProfileInvalidDataError extends GetProfileError { }
export class GetProfileNotFoundError extends GetProfileError { }
export class GetProfileConnectionError extends GetProfileError { }
export class GetProfileOperationalError extends GetProfileError { }
export class GetProfileAuthorizationError extends GetProfileError { }
export class GetProfileBadRequestError extends GetProfileError {
  constructor(public readonly error: string) {
    super();
  }
}
