import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import RadioInput from '../../RadioInput';

const disabilityInitialState = {
  disabledPatient: false,
  activeDisabilityCertificate: false,
  disabilityCertificateCode: null,
}
const DisabilityForm = ({ disabled, onFilledDisability, t, value }) => {
  const [disability, setDisability] = useState(disabilityInitialState);

  useEffect(() => {
    if (value?.metadata) {
      const { disabledPatient,  activeDisabilityCertificate } = value.metadata || {};
      setDisability({ disabledPatient, activeDisabilityCertificate });
    }
  }, [value]);

  const handleChange = (field, v) => {
    const valueRadio = v == '1';
    if (field === 'disabledPatient' && v === '2') {
      const disabilityValue = {
        [field]: valueRadio,
        activeDisabilityCertificate: false,
        disabilityCertificateCode: null,
      }
      setDisability(disabilityValue);
      onFilledDisability(disabilityValue);
    } else {
      const disabilityValue2 = {
        ...disability,
        [field]: valueRadio,
      }
      setDisability(disabilityValue2);
      onFilledDisability(disabilityValue2);
    }
  };

  return (
    <div className="disability-form">
      <GridContainer className="margin-top disabled-patient">
        <GridItem xs={12}>
          <h4 className="margin-top">
            {t('appointment.disabledPatient.title')}
          </h4>
        </GridItem>
        <GridItem xs={12} sm={6} md={5} className="radio-input-inline">
          <p className="radio-legend">
            {t('appointment.disabledPatient')}
          </p>
          <RadioInput
            id="disabled-patient"
            name="disabledPatient"
            value={disability.disabledPatient ? '1' : '2'}
            elements={[
              { id: '1', value: t('form.option.yes') },
              { id: '2', value: t('form.option.no') }
            ]}
            onChangeValue={(v) => handleChange('disabledPatient', v)}
            inputProps={{
              disabled,
            }}
          />
        </GridItem>
        {disability.disabledPatient && (
          <GridItem xs={12} sm={6} md={5} className="radio-technical-file radio-input-inline">
            <p className="radio-legend">
              {t('appointment.activeDisabilityCertificate')}
            </p>
            <RadioInput
              id="active-disability-certificate"
              name="activeDisabilityCertificate"
              value={disability.activeDisabilityCertificate ? '1' : '2'}
              elements={[
                { id: '1', value: t('form.option.yes') },
                { id: '2', value: t('form.option.no') }
              ]}
              onChangeValue={(v) => handleChange('activeDisabilityCertificate', v)}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>)
        }
    </GridContainer>
  </div>
  )
}

DisabilityForm.propTypes = {
  t: PropTypes.func.isRequired,
  onFilledDisability: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    metadata: PropTypes.object,
  }),
}

export default withTranslation()(DisabilityForm);
