import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException, VoidSuccess } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { PracticeFeesRepository } from "../repositories/practice-fees.repository";

@injectable()
export class DeleteFeeUsecase {

  @inject(PracticeFeesRepository) protected repository!: PracticeFeesRepository;

  async execute(id: number): Promise<Either<DeleteFeeError, VoidSuccess>> {
    if (!id) {
      return Either.left(new DeleteFeeInvalidDataError());
    }
    const result = await this.repository.deleteFee(id);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): DeleteFeeError {
    if (exception instanceof NotFoundException) {
      return new DeleteFeeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new DeleteFeeConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new DeleteFeeOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new DeleteFeeAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new DeleteFeeBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in DeleteFeeUsecase");
  }
}

export abstract class DeleteFeeError {
  error?: string;
}

export class DeleteFeeInvalidDataError extends DeleteFeeError { }
export class DeleteFeeNotFoundError extends DeleteFeeError { }
export class DeleteFeeConnectionError extends DeleteFeeError { }
export class DeleteFeeOperationalError extends DeleteFeeError { }
export class DeleteFeeAuthorizationError extends DeleteFeeError { }
export class DeleteFeeBadRequestError extends DeleteFeeError {
  constructor(public readonly error: string) {
    super();
  }
}
