import { connect } from 'react-redux';
import Modules from '../components/Modules';
import { getModules , unmountModule} from '../actions/modules';

const mapStateToProps = state => {
    return {
        modules: state.modules.modules,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetModules: (modules) => {
            dispatch(getModules(modules));
        },
        onUnmountModule: () => {
            dispatch(unmountModule());
        },
    }
}

const ModulesService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Modules);

export default ModulesService;
