import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import store from '../../../store';
import '../NewSchedule.css';
import Car from "../../../icons/Drivers";
import AccountBox from '../../../icons/AccountBox';
import Gps from '../../../icons/Gps';
import memory from '../../../config/memory';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';

class CustomerFormServicesSummary extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            customer: '',
            customerId: '',
            addresses: [],
            address: '',
            zone: '',
            location: '',
            province: '',
            traslados: memory.traslados,
            hospitals: [],
            prestadores : [],
            objectTransfer : {}
        }
    }

    componentDidMount() {
        this.getHospital()
        this.getPartner()
        const {customerDetail, transfer}=this.props
        
    }


    componentWillReceiveProps(next) {
        const { value,transferWay } = this.props;
        const { customers } = this.state;
    }

    convertTo24HourFormat(time) {
        const [timePart, period] = time.split(' ');
        let [hours, minutes] = timePart.split(':');
      
        hours = parseInt(hours, 10);
      
        if (period.toLowerCase() === 'am') {
          if (hours === 12) {
            hours = 0; 
          }
        } else if (period.toLowerCase() === 'pm') {
          if (hours !== 12) {
            hours += 12; 
          }
        }
      
        hours = hours.toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      }

    formatObjectTransfer = (customerDetail,transfer)=>{
        const newItem = {
            order: 1,
            recurrenceType: customerDetail.recurrenceType,
            companyIdPartnership: {
                companyId: customerDetail.companyId
            },
            endtype: "DATE",
            driver: null,
            processingScheduleType: "SCHEDULE",
            transferItemRequestStatus: "CREATED",
            startTime: this.convertTo24HourFormat(customerDetail.startTimeDeparture),
            endTime: this.convertTo24HourFormat(customerDetail.requestArrivalTimeDeparture),
            every: Number(customerDetail.quantityIntraday),
            startDate: customerDetail.startDateDeparture.split(' ')[0],
            endDate: customerDetail.shiftDateDeparture.split(' ')[0],
            transferType: "STANDAR",
            hospital: {
                hospitalId: customerDetail.nosocomio
            },
            transferWay: "ONEWAY",
            durationRoundTrip: "",
            distanceRoundTrip: 10
          };
        
          
          const updatedItems = transfer.items ? [...transfer.items, newItem] : [newItem];
        
          
          return {
            ...transfer,
            items: updatedItems,
          };
    }

    formatCustomers = (customers) => customers.map(c => ({
        id: c.personId,
        customerId: c.id,
        value: `${c.lastName} ${c.firstName}`,
    }));

    formatAddresses = (addresses) => addresses.map(a => ({
        id: a.addressId,
        zone: a.geographicZone ? a.geographicZone.detail : '',
        geographicZoneId: a.geographicZone  ? a.geographicZone.geographicZoneId : 0,
        value: `${a.street} ${a.number}`,
        location: a.location.name,
        province: a.provinceName,
    }));
    getHospital = () => {
        AppointmentApiInvoker.getHospital((response) => {
          this.setState({ hospitals: this.formatHospital(response) });
        });
      };
    
      formatHospital = (data) => {
        let result = data.map((c) => {
          return {
            id: c.hospitalId || '',
            latitude: c.latitude || 0,
            longitude: c.longitude || 0,
            value: c.businessName || '',
            address: c.address || '',
          };
        });
        return result;
      };

      formatPartner = (data) => {
        let result = data.map((c) => {
          return {
            id: c.companyIdPartnership.companyId,
            value: c.companyIdPartnership.name,
          };
        });
        return result;
      };
    
      getPartner = () => {
        AppointmentApiInvoker.getCompanyPartnershipMobility((response) => {
          this.setState({prestadores:this.formatPartner(response)});
        });
      };

    getCustomers() {
        if (store.getState().customers.customers &&
            store.getState().customers.customers.length > 0) {
            const customers = this.formatCustomers(store.getState().customers.customers);
            this.setState({ customers })
            return
        }
        PersonApiInvoker.getCustomers(true, data => {
            const customers = this.formatCustomers(data);
            this.setState({ customers })
            this.props.onGetCustomers(data)
        }, _ => {
            this.setState({ loading: false })
        })
    }

    formatDate=(date)=> {
        const [año, mes, día] = date.split("-");
        return `${día}/${mes}/${año}`;
    }

    propsOnFilledCustomer() {
        const { address, customer, customerId, geographicZoneId, location, province, zone } = this.state;
        this.props.onFilledCustomer({
            customer,
            customerId,
            address,
            zone,
            geographicZoneId,
            location,
            province,
        });
    }

    setAddress() {
        const { addresses } = this.state;
        let address = {
            address: '',
            zone: '',
            location: '',
            province: '',
            geographicZoneId: '',
        };
        
        if (addresses.length === 1) {
            const selectedAddress = addresses[0];
            address = {
                address: selectedAddress.id,
                zone: selectedAddress.zone,
                geographicZoneId: selectedAddress.geographicZoneId,
                location: selectedAddress.location,
                province: selectedAddress.province,
            };
        }
        if (addresses.length > 1) {
            const addressIndex = addresses.findIndex(item => item.id === this.state.address)
            if (addressIndex !== -1) {
                const selectedAddress = addresses[addressIndex];
                address = {
                    address: selectedAddress.id,
                    province: selectedAddress.province,
                    zone: selectedAddress.zone,
                    location: selectedAddress.location,
                    geographicZoneId: selectedAddress.geographicZoneId,
                };
            }
        }
        this.setState({
            address: address.address,
            geographicZoneId: address.geographicZoneId,
            zone: address.zone,
            location: address.location,
            province: address.province,
        }, () => { this.propsOnFilledCustomer() });
    }

    getAddresses(personId) {
        PersonApiInvoker.getPersonsAddress(personId,false, data => {
            if (data?.length) {
                this.setState({ addresses: this.formatAddresses(data)}, () => {
                    this.setAddress();
                });
            } else {
                this.setState({
                    addresses: [],
                    address: '',
                    location: '',
                    province: '',
                    zone: '',
                    geographicZoneId: '',
                }, () => {   
                    this.propsOnFilledCustomer();
                });
            }
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
        const { addresses, customer, customerId, customers } = this.state;

        if (state === 'customer') {
            this.getAddresses(value);
            const customerIndex = customers.findIndex(item => item.id === value);
            if (customerIndex !== -1) {
                this.setState({ customerId: customers[customerIndex].customerId }, () => {
                        this.props.onFilledCustomer({ customer, customerId });
                    }
                )
            }
        }
        if (state === 'address') {
            const addressIndex = addresses.findIndex(item => item.id === value)
            if (addressIndex !== -1) {
                this.setState({
                    address: addresses[addressIndex].id,
                    geographicZoneId: addresses[addressIndex].geographicZoneId,
                    zone: addresses[addressIndex].zone || '',
                    location: addresses[addressIndex].location || '',
                    province: addresses[addressIndex].province || '',
                }, () => {
                    this.propsOnFilledCustomer()
                });
            }
        }
    }

    render() {
        const { customers, customer, customerId, addresses, address, zone, location, province } = this.state;
        const { disabled, checkInvalid, t } = this.props;
        let hora_real_fin = "-";
        return (
            <Card id="card-customer" className="card-customer-form">
                <CardHeader icon>
                    <CardIcon color="primary">
                        <Car />
                    </CardIcon>
                    <h3 className="card-icon-title">Configuración de Servicio</h3>
                </CardHeader>
                <CardBody>
                    
                    <GridContainer className="card-customer-container">
                        {this.props.transferWay !== 2 &&
                        <GridContainer  xs={12} sm={this.props.transferWay ===3 ? 6 : 12}>
                            <h2>Viaje Ida</h2>
                            <GridContainer>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        id="select-customer"
                                        fullWidth
                                        disabled={disabled}
                                        label="Tipo de traslado"
                                        elements={this.state.traslados}
                                        value={this.state.traslados.find(t => t.id === this.props.customerDetail?.trasladoIda)?.value || ''}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className="fullWidth-input" xs={12} sm={12}>
                                <div style={{'display':'flex','alignItems': 'center' }}>
                                    <AccountBox/> 
                                    Origen
                                </div>

                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        id="select-customer"
                                        fullWidth
                                        disabled={disabled}
                                        label="Dirección"
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={this.props.customerDetail?.address?.[0].street + ' '+this.props.customerDetail?.address?.[0].number}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={12} sm={6}>
                                    <TextField
                                        id="input-zone"
                                        
                                        fullWidth
                                        label="Piso"
                                        value={this.props.customerDetail?.address?.[0].floor || '-'}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={12} sm={6}>
                                    <TextField
                                        id="input-zone"
                                        
                                        fullWidth
                                        label="Departamento"
                                        value={this.props.customerDetail?.address?.[0].department || '-'}
                                    />
                                </GridItem>
                                
                                <GridItem className="fullWidth-input timeInput" xs={6} sm={6} >
                                    <TextField
                                    fullWidth
                                    label="Fecha de inicio de retiro"
                                    value={this.formatDate(this.props.customerDetail?.startDateDeparture?.split(' ')[0])}
                                    labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input " xs={6} sm={6} md={6}>
                                    <TextField
                                    fullWidth
                                    label="Hora estimada de retiro"
                                    type="text"
                                    value={this.props.customerDetail?.startTimeDeparture  ?? '10:00 am'}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <div style={{'display':'flex','alignItems': 'center' }}>
                                    <Gps/> 
                                    Destino
                                </div>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="select-customer"
                                        label="Prestador médico"
                                        elements={customers}
                                        value={this.state.prestadores?.find(t => t.id === this.props.customerDetail?.prestador)?.value || ''}
                                        disabled={disabled}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                            
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="select-customer"
                                        label="Dirección de destino"
                                        elements={customers}
                                        disabled={disabled}
                                        value={this.state.hospitals?.find(t => t.id === this.props.customerDetail?.nosocomio)?.address || ''}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                
                            
                                <GridItem xs={6} sm={6} md={6} className="timeInput">
                                    <TextField
                                    label="Fecha de turno"
                                    fullWidth
                                    value={this.formatDate(this.props.customerDetail?.shiftDateDeparture?.split(' ')[0])}
                                    
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                    type="text"
                                    fullWidth
                                    label="Hora de llegada solicitada"
                                    value={this.props.customerDetail?.requestArrivalTimeDeparture ?? '10:00 pm'}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                        }
                        {this.props.transferWay !== 1 &&
                        <GridContainer xs={12} sm={this.props.transferWay ===3 ? 6 : 12}>
                            <h2>Viaje Vuelta</h2>
                            <GridContainer>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="select-customer"
                                        disabled={disabled}
                                        label="Tipo de traslado"
                                        
                                        value={this.state.traslados.find(t => t.id === this.props.customerDetail?.trasladoVuelta)?.value || ''}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className="fullWidth-input" xs={12} sm={12}>
                                <div style={{'display':'flex','alignItems': 'center' }}>
                                    <AccountBox/> 
                                    Origen
                                </div>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="select-customer"
                                        disabled={disabled}
                                        label="Prestador médico"
                                        elements={customers}
                                        value={this.state.prestadores?.find(t => t.id === this.props.customerDetail?.prestadorVuelta)?.value || ''}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="select-customer"
                                        disabled={disabled}
                                        label="Nosocomio"
                                        elements={customers}
                                        value={this.state.hospitals?.find(t => t.id === this.props.customerDetail?.nosocomioVuelta)?.value || ''}
                                        
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                               
                                <GridItem xs={6} sm={6} md={6} className="timeInput">
                                    <TextField
                                    label="Fecha de turno"
                                    fullWidth
                                    value={this.formatDate(this.props.customerDetail?.shiftDateReturn?.split(' ')[0])}
                                    
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                    type="text"
                                    fullWidth
                                    label="Hora de llegada solicitada"
                                    value={this.props.customerDetail?.requestArrivalTimeReturn ?? '10:00 pm'}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <div style={{'display':'flex','alignItems': 'center' }}>
                                    <Gps/> 
                                    Destino
                                </div>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        id="select-customer"
                                        disabled={disabled}
                                        label="Dirección"
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={this.props.customerDetail?.address?.[0].street + ' '+this.props.customerDetail?.address?.[0].number}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={6} sm={6}>
                                    <TextField
                                        id="input-zone"
                                        label="Piso"
                                        value={this.props.customerDetail?.address?.[0].floor || '-'}
                                        fullWidth
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={6} sm={6}>
                                    <TextField
                                        id="input-zone"
                                        label="Departamento"
                                        value={this.props.customerDetail?.address?.[0].department || '-'}
                                        fullWidth
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6} className="timeInput">
                                    <TextField
                                    label="Fecha estimada de llegada"
                                   fullWidth
                                   value={this.formatDate(this.props.customerDetail?.arrivalDateReturn?.split(' ')[0])}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                    type="text"
                                    label="Hora estimada de llegada"
                                    fullWidth
                                    value={this.props.customerDetail?.withdrawalTimeReturn ?? '10:00 am'}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                        }
                    
                    </GridContainer>
                </CardBody>
            </Card>
        )
    }
}

CustomerFormServicesSummary.propTypes = {
    t: PropTypes.func,
    onGetCustomers: PropTypes.func,
    onFilledCustomer: PropTypes.func,
    value: PropTypes.object,
    customerDetail: PropTypes.object,
    disabled: PropTypes.bool,
    checkInvalid: PropTypes.bool,
}

export default withTranslation()(CustomerFormServicesSummary);
