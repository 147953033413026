import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class RecordsApiInvoker extends Component {

	static getPatologyDetails(patId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/records/${patId}`, callback, callerror);
	}
	static getAllPatologies(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/records?recordCode=PAT`, callback, callerror);
	}
	static getAllComplexities(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/records?recordCode=COMPL`, callback, callerror);
	}
	static getAllBarthel(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/records?recordCode=BARTH`, callback, callerror);
	}
	static getToolsCondition(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/records?recordCode=PROD_COND`, callback, callerror);
	}

}