import { connect } from 'react-redux';
import NewSupplyCatalog from '../components/NewSupplyCatalog';
import { addSupplyCatalog, editSupplyCatalog } from '../actions/supplycatalog';
import EditSupplyCatalog from '../components/EditSupplyCatalog';

const mapStateToProps = (state) => {
    return {
        documentTypes: state.newCustomer.documentTypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAddSupplyCatalog: (supplycatalog) => {
            dispatch(addSupplyCatalog(supplycatalog));
        },
        onEditSupplyCatalog: (supplycatalog) => {
            dispatch(editSupplyCatalog(supplycatalog));
        }
    }
}

const NewSupplyCatalogService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewSupplyCatalog);

const EditSupplyCatalogService = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSupplyCatalog);

export default { NewSupplyCatalogService, EditSupplyCatalogService };
