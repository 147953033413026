import './i18n';

import { AcceptScheduleRequestUsecase, ApiAppointmentsRepository, ApiFinanciersRepository, ApiPracticeFeesRepository, ApiReasonsRejectedRepository, ApiUsersRepository, AppointmentsRepository, ConfirmUserUsecase, CreateCustomerFinancierUsecase, CreateFeeUsecase, DeleteCustomerFinancierUsecase, DeleteFeeUsecase, FinanciersRepository, GetAppointmentItemsUsecase, GetByEmployeeAndPracticeTypeUsecase, GetCustomerFinanciersUsecase, GetFinanciersUsecase, GetReasonsRejectedUsecase, GetSchedulesFeesByPracticeTypeUsecase, GetSchedulesRequestUsecase, GetTermsAndPolicyUsecase, PracticeFeesRepository, ReasonsRejectedRepository, SendReasonsRejectedUsecase, UpdateFeeUsecase, UpdateScheduleFeeUsecase, UpdateUserTermsUsecase, UsersRepository } from './core';
import React, { Suspense } from 'react';
import { Redirect, Route, Router, browserHistory } from 'react-router';

import AlertsService from './containers/AlertsService';
import AppointmentsComponents from './containers/AppointmentsService';
import AppointmentsToolsService from './containers/AppointmentsToolsService';
import BookingsService from './containers/BookingsService';
import ConfirmUser from './components/ConfirmUser';
import { Container } from 'inversify';
import CustomerComponents from './containers/NewCustomerService';
import CustomersService from './containers/CustomersService';
import { DashboardCustomerService } from './containers/DashboardCustomerService';
import DashboardManagementControlService from './containers/DashboardPBManagementControlService';
import DashboardManagementService from './containers/DashboardPBManagementService';
import DashboardOperativeService from './containers/DashboardPBOperativeService';
import DashboardPBFinancierService from './containers/DashboardPBFinancierService';
import { DashboardProfesionalService } from './containers/DashboardProfesionalService';
import { DashboardService } from './containers/DashboardService';
import DoneAppointmentsReport from './components/Statistics/DoneAppointmentsReport';
import EditPracticeTypes from './components/EditPracticeTypes';
import EmployeeComponents from './containers/NewEmployeeService';
import EmployeesService from './containers/EmployeesService';
import ForgotPassword from './components/ForgotPassword';
import { Provider as InjectProvider } from 'inversify-react';
import IntegralTechnicalFileService from './containers/IntegralTechnicalFileService';
import MedicalRecordService from './containers/MedicalRecordService';
import MedicalRecordSignVitalsService from './containers/MedicalRecordSignVitalsService';
import ModulesService from './containers/ModulesService';
import Navigator from './components/Navigator';
import NewScheduleService from './containers/NewScheduleService';
import PracticeTypes from './components/PracticeTypes';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ReassignAppointmentsStepperService } from './containers/ReassignAppointmentsStepperService';
import RoutesService from './containers/RoutesService';
import ScheduleDetail from './components/MedicalRecord/ScheduleDetail';
import SchedulesService from './containers/SchedulesService';
import SupplyCatalogComponent from './containers/NewSupplyCatalogService';
import SupplyCatalogService from './containers/SupplyCatalogService';
import TechnicalFileService from './containers/TechnicalFileService';
import ToolComponent from './containers/NewToolService';
import ToolTypesComponent from './containers/NewToolTypeService';
import ToolTypesService from './containers/ToolTypesService';
import ToolsService from './containers/ToolsService';
import TransferAppointmentsComponents from './containers/TransferAppointmentsService';
import UserComponent from './containers/NewUserService';
import UserService from './containers/UserService';
import UsersService from './containers/UsersService';
import { hot } from 'react-hot-loader';
import requireAuth from './handlers/require-auth';
import Drivers from './components/Drivers';
import Driver from './components/Driver';
import NewTransferService from './containers/NewTransferService';
import ServiceDetail from './containers/ServiceDetail';
import { ReassignTransferAppointmentsStepperService } from './containers/ReassignTransferAppointmentsStepperService';
import Vehicles from './components/Vehicles';
import Vehicle from './components/Vehicle';

const DIContainer = new Container();
DIContainer.bind(AppointmentsRepository).to(ApiAppointmentsRepository);
DIContainer.bind(GetAppointmentItemsUsecase).toSelf();
DIContainer.bind(GetSchedulesRequestUsecase).toSelf();
DIContainer.bind(AcceptScheduleRequestUsecase).toSelf();
DIContainer.bind(ReasonsRejectedRepository).to(ApiReasonsRejectedRepository);
DIContainer.bind(GetReasonsRejectedUsecase).toSelf();
DIContainer.bind(SendReasonsRejectedUsecase).toSelf();
DIContainer.bind(PracticeFeesRepository).to(ApiPracticeFeesRepository);
DIContainer.bind(GetByEmployeeAndPracticeTypeUsecase).toSelf();
DIContainer.bind(GetSchedulesFeesByPracticeTypeUsecase).toSelf();
DIContainer.bind(CreateFeeUsecase).toSelf();
DIContainer.bind(DeleteFeeUsecase).toSelf();
DIContainer.bind(UpdateFeeUsecase).toSelf();
DIContainer.bind(UpdateScheduleFeeUsecase).toSelf();
DIContainer.bind(UsersRepository).to(ApiUsersRepository);
DIContainer.bind(GetTermsAndPolicyUsecase).toSelf();
DIContainer.bind(UpdateUserTermsUsecase).toSelf();
DIContainer.bind(ConfirmUserUsecase).toSelf();
DIContainer.bind(FinanciersRepository).to(ApiFinanciersRepository);
DIContainer.bind(GetFinanciersUsecase).toSelf();
DIContainer.bind(GetCustomerFinanciersUsecase).toSelf();
DIContainer.bind(CreateCustomerFinancierUsecase).toSelf();
DIContainer.bind(DeleteCustomerFinancierUsecase).toSelf();

export { DIContainer };

const Root = ({ store }) => (
  <Suspense fallback={null}>
    <Provider store={store}>
      <InjectProvider container={() => DIContainer}>
        <Router history={browserHistory}>
          <Route path="/login" component={UserService.UserLogged} />
          <Route path="/forgot-password" exact component={ForgotPassword} onEnter={false} />
          <Route path="/confirm/:hash" component={ConfirmUser} onEnter={false}/>
          <Route path="/confirm/patient/:hash" component={ConfirmUser} onEnter={false}/>
          <Route path="/confirm/home-care/:hash" component={ConfirmUser} onEnter={false}/>
          <Route path="/confirm/driver/:hash" component={ConfirmUser} onEnter={false}/>

          <Route component={Navigator}>
            <Redirect
              exact
              from="/"
              to="/solicitudes"
              onEnter={requireAuth}
            />
            <Route path="/" component={UserService.UserLogged} />
            <Route path="/dashboard" component={DashboardService} onEnter={requireAuth} />
            <Route path="/dashboard-professionals" component={DashboardProfesionalService} onEnter={requireAuth} />
            <Route path="/done-appointments-report" component={DoneAppointmentsReport} onEnter={requireAuth} />

            <Route path="/dashboard-paciente" component={DashboardCustomerService} onEnter={requireAuth} />
            <Route path="/solicitudes" exact component={AppointmentsComponents.AppointmentsService} onEnter={requireAuth} />

            <Route path="/solicitudes/:id" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />
            <Route path="/appointments/:id/edit/agenda" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />

            <Route path="/appointments/:id/renew/agenda" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />

            <Route path="/appointments/:id/cancel" exact component={AppointmentsComponents.AppointmentCancelService} onEnter={requireAuth} />
            <Route path="/reasign-appointments" exact component={ReassignAppointmentsStepperService} onEnter={requireAuth} />

            <Route path="/nueva-solicitud" component={NewScheduleService} onEnter={requireAuth} />
            <Route path="/herramientas-turno" component={AppointmentsToolsService.Form} onEnter={requireAuth} />
            <Route path="/hojas-ruta" component={RoutesService} onEnter={requireAuth} />
            <Route path="/alertas" component={AlertsService} onEnter={requireAuth} />
            <Route path="/modulos" component={ModulesService} onEnter={requireAuth} />
            <Route path="/patient" component={CustomersService} onEnter={requireAuth} />
            <Route path="/ver-profesional/:id" component={EmployeeComponents.EditEmployeeService} onEnter={requireAuth} />
            <Route path="/catalogo-insumos" component={SupplyCatalogService} onEnter={requireAuth} />
            <Route path="/profesionales" component={EmployeesService.EmployeesService} onEnter={requireAuth} />
            <Route path="/tools" component={ToolsService} onEnter={requireAuth} />
            <Route path="/users" component={UsersService} onEnter={requireAuth} />
            <Route path="/new-user" component={UserComponent.NewUser} onEnter={requireAuth}/>

            <Route path="/nuevo-profesional" component={EmployeeComponents.NewEmployeeService} onEnter={requireAuth} />
            <Route path="/editar-profesional/:id" component={EmployeeComponents.EditEmployeeService} onEnter={requireAuth} />
            <Route path="/nuevo-paciente" component={CustomerComponents.NewCustomerService} onEnter={requireAuth}/>
            <Route path="/nuevo-insumo" component={SupplyCatalogComponent.NewSupplyCatalogService} onEnter={requireAuth}/>
            <Route path="/nuevo-producto" component={ToolComponent.NewToolService} onEnter={requireAuth}/>
            <Route path="/editar-paciente/:id" component={CustomerComponents.EditCustomerService} onEnter={requireAuth} />
            <Route path="/ver-paciente/:id" component={CustomerComponents.EditCustomerService} onEnter={requireAuth} />
            <Route path="/tool-types" component={ToolTypesService} onEnter={requireAuth}/>
            <Route path="/nuevo-tooltype" component={ToolTypesComponent.NewToolTypeService} onEnter={requireAuth} />
            <Route path="/editar-tooltype/:id" component={ToolTypesComponent.EditToolTypeService} onEnter={requireAuth} />
            <Route path="/editar-insumo/:id" component={SupplyCatalogComponent.EditSupplyCatalogService} onEnter={requireAuth}/>
            <Route path="/editar-producto/:id" component={ToolComponent.EditToolService} onEnter={requireAuth} />
            <Route path="/turnos" component={BookingsService.BookingsService} onEnter={requireAuth} />
            <Route path="/historial/:id" component={SchedulesService} onEnter={requireAuth} />
            <Route path="/patient/:patientId/medical-record" component={MedicalRecordService} onEnter={requireAuth} />
            <Route path="/patient/:patientId/medical-record/:appointmentId/view" component={TechnicalFileService} onEnter={requireAuth} />
            <Route path="/patient/:patientId/medical-record/:appointmentId/edit" component={TechnicalFileService} onEnter={requireAuth} />
            <Route path="/patient/:patientId/medical-record/full" component={IntegralTechnicalFileService} onEnter={requireAuth} />
            <Route path="/patient/:patientId/medical-record/:appointmentId/detail/schedule/:scheduleId" component={ScheduleDetail} onEnter={requireAuth} />
            <Route path="/practice-types" component={PracticeTypes} onEnter={requireAuth} />
            <Route path="/practice-types/:id/edit" component={EditPracticeTypes} onEnter={requireAuth} />
            <Route path="/patient/:patientId/medical-record/sign-vitals/:scheduleId" component={MedicalRecordSignVitalsService} onEnter={requireAuth} />

            <Route path="/dashboard-operatives-pb" component={DashboardOperativeService} onEnter={requireAuth} />
            <Route path="/dashboard-management-pb" component={DashboardManagementService} onEnter={requireAuth} />
            <Route path="/dashboard-management-control-pb" component={DashboardManagementControlService} onEnter={requireAuth} />
            <Route path="/dashboard-financier-pb" component={DashboardPBFinancierService} onEnter={requireAuth} />

            {/* mobility */}
            <Route path="/drivers" component={Drivers} onEnter={requireAuth} />
            <Route path="/driver/:id" component={Driver} onEnter={requireAuth} />

            <Route path="/solicitudes-traslado"  component={TransferAppointmentsComponents.TransferAppointmentsService} onEnter={requireAuth} />
            <Route path="/transferappointments/:id/edit/agenda" exact component={TransferAppointmentsComponents.TransferAppointmentDetailService} onEnter={requireAuth} />
            <Route path="/nueva-solicitud-traslado" component={NewTransferService} onEnter={requireAuth} />
            <Route path="/detalle-servicio" component={ServiceDetail} onEnter={requireAuth} />
            <Route path="/reasign-transferAppointments" exact component={ReassignTransferAppointmentsStepperService} onEnter={requireAuth} />
            
            <Route path="/solicitudes-traslado/:id" component={TransferAppointmentsComponents.TransferAppointmentDetailService} onEnter={requireAuth} />
            <Route path="/transferappointments/:id/edit/agenda" component={TransferAppointmentsComponents.TransferAppointmentDetailService} onEnter={requireAuth} />
            <Route path="/transferappointments/:id/cancel" exact component={TransferAppointmentsComponents.TransferAppointmentCancelService} onEnter={requireAuth} />
            <Route path="/turnos-traslado/:id" component={BookingsService.BookingsTransferService} onEnter={requireAuth} />
            {/*<Route path="/statistics" component={DataReport} onEnter={requireAuth} />*/}

            {/*Gestión de vehículos */}
            <Route path="/vehicles" component={Vehicles} onEnter={requireAuth} />
            <Route path="/vehicle/new" component={Vehicle} onEnter={requireAuth} />
            <Route path="/vehicle/:id" component={Vehicle} onEnter={requireAuth} />
            <Route path="/vehicle/:id/edit" component={Vehicle} onEnter={requireAuth} />
          </Route>
        </Router>
      </InjectProvider>
    </Provider>
  </Suspense>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default hot(module)(Root);
