import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { PracticeFee } from "../../domain";
import { PracticeFeesRepository } from "../repositories/practice-fees.repository";

@injectable()
export class GetByEmployeeAndPracticeTypeUsecase {

  @inject(PracticeFeesRepository) protected repository!: PracticeFeesRepository;

  async execute(employeeId: number, practiceTypeId: number): Promise<Either<GetByEmployeeAndPracticeTypeError, PracticeFee[]>> {
    if (!employeeId || !practiceTypeId) {
      return Either.left(new GetByEmployeeAndPracticeTypeInvalidDataError());
    }
    const result = await this.repository.getByEmployeeAndPracticeType(employeeId, practiceTypeId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetByEmployeeAndPracticeTypeError {
    if (exception instanceof NotFoundException) {
      return new GetByEmployeeAndPracticeTypeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetByEmployeeAndPracticeTypeConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetByEmployeeAndPracticeTypeOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetByEmployeeAndPracticeTypeAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetByEmployeeAndPracticeTypeBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetByEmployeeAndPracticeTypeUsecase");
  }
}

export abstract class GetByEmployeeAndPracticeTypeError {
  error?: string;
}
export class GetByEmployeeAndPracticeTypeInvalidDataError extends GetByEmployeeAndPracticeTypeError { }
export class GetByEmployeeAndPracticeTypeNotFoundError extends GetByEmployeeAndPracticeTypeError { }
export class GetByEmployeeAndPracticeTypeConnectionError extends GetByEmployeeAndPracticeTypeError { }
export class GetByEmployeeAndPracticeTypeOperationalError extends GetByEmployeeAndPracticeTypeError { }
export class GetByEmployeeAndPracticeTypeAuthorizationError extends GetByEmployeeAndPracticeTypeError { }
export class GetByEmployeeAndPracticeTypeBadRequestError extends GetByEmployeeAndPracticeTypeError {
  constructor(public readonly error: string) {
    super();
  }
}
