import { HOSPITAL_PATOLOGY_DETAILS, PATOLOGY_DETAILS, PATOLOGIES, BARTHEL, COMPLEXITIES, COMPLEXITY, BARTHELS,CLEAN_BARTHEL,CLEAN_COMPLEXITY } from "../actions/records";

const records = (state = {}, action, root) => {
    switch(action.type) {
        case PATOLOGIES: {
            let patologies = action.patologies
            return {...state, patologies}
        }
        case PATOLOGY_DETAILS: {
            let patology = action.patology
            return {...state, patology}
        }
        case HOSPITAL_PATOLOGY_DETAILS: {
            let hospitalPatology = action.patology
            return {...state, hospitalPatology}
        }
        case COMPLEXITIES: {
            let complexities = action.complexities
            return {...state, complexities }
        }
        case CLEAN_COMPLEXITY:
            return {}
        case BARTHELS: {
            let barthels = action.barthels
            return {...state, barthels }
        }
        case CLEAN_BARTHEL:
            return {}
        case BARTHEL: {
            let barthel = action.barthel
            return {...state, barthel }
        }
        case COMPLEXITY: {
            let complexity = action.complexity
            return {...state, complexity }
        }
        default:
            return state;
    }
}

export default records;
