import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function MedicalDoctorOutlined(props) {
  return (
    <SvgIcon width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M334 873 c-4 -27 -8 -94 -8 -150 -1 -110 7 -132 68 -177 39 -29 133 -29 172 0 61 45 69 67 68 177 0 56 -4 123 -8 150 l-7 47 -139 0 -139 0 -7 -47z m218 -73 l4 -40 -76 0 -76 0 4 40 4 40 68 0 68 0 4 -40z m8 -135 c0 -27 -47 -65 -80 -65 -33 0 -80 38 -80 65 0 12 15 15 80 15 65 0 80 -3 80 -15z" />
        <path d="M353 384 c-93 -20 -159 -52 -198 -95 -32 -36 -35 -44 -35 -104 l0 -65 360 0 360 0 0 65 c0 60 -3 68 -35 104 -80 89 -285 132 -452 95z m90 -124 c16 -28 32 -50 37 -50 5 0 21 22 37 50 33 59 43 61 138 26 69 -25 105 -50 105 -73 0 -10 -52 -13 -280 -13 -228 0 -280 3 -280 13 0 23 35 48 102 73 96 35 108 33 141 -26z" />
      </g>
    </SvgIcon>
  );
}
