import { AuthorizationException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { ReasonsRejectedRepository } from "../repositiories/reasons-rejected-repository";

@injectable()
export class SendReasonsRejectedUsecase {

  @inject(ReasonsRejectedRepository) protected repository!: ReasonsRejectedRepository;

  async execute(itemRequestId: number, reasonRejectedId: number): Promise<Either<SendReasonRejectedError, boolean>> {
    if (!itemRequestId || !reasonRejectedId) {
      return Either.left(new SendReasonsRejectedInvalidDataError());
    }
    const result = await this.repository.sendReason(itemRequestId, reasonRejectedId);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): SendReasonRejectedError {
    if (exception instanceof NotFoundException) {
      return new SendReasonsRejectedNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new SendReasonsRejectedConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new SendReasonsRejectedOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new SendReasonsRejectedAuthorizationError();
    }

    throw new Error("Unexpected error in SendReasonsRejectedUsecase");
  }
}

export abstract class SendReasonRejectedError {
  error?: string;
}

export class SendReasonsRejectedInvalidDataError extends SendReasonRejectedError { }
export class SendReasonsRejectedNotFoundError extends SendReasonRejectedError { }
export class SendReasonsRejectedConnectionError extends SendReasonRejectedError { }
export class SendReasonsRejectedOperationalError extends SendReasonRejectedError { }
export class SendReasonsRejectedAuthorizationError extends SendReasonRejectedError { }
