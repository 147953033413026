import { connect } from 'react-redux';
import Routes from '../components/Routes';
import {
  fetchCustomers,
  fetchEmployees,
} from '../actions/routes';

const mapStateToProps = state => {
  return {
    customers: state.routes.customers,
    employees: state.routes.employees,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchCustomers: customers => {
      dispatch(fetchCustomers(customers));
    },
    onFetchEmployees: employees => {
      dispatch(fetchEmployees(employees));
    }
  }
}

const RoutesService = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);

export default RoutesService;
