import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function SyringeOutlined(props) {
  return (
    <SvgIcon width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M627 853 l-27 -27 42 -43 42 -43 -42 -42 -42 -43 -60 60 -60 60 -160 -160 -160 -160 0 -120 0 -121 -40 -39 -40 -39 27 -28 27 -28 41 40 41 40 119 0 120 0 160 160 160 160 -60 60 -60 60 43 42 42 42 43 -42 43 -42 27 28 27 28 -113 112 -112 112 -28 -27z m3 -407 l-30 -30 -45 44 -45 44 -27 -27 -27 -27 44 -45 44 -45 -32 -32 -32 -32 -45 44 c-47 47 -63 48 -89 7 -9 -13 -2 -25 34 -62 l44 -45 -92 0 -92 0 0 92 0 93 117 117 118 118 92 -92 93 -93 -30 -29z"/>
      </g>
    </SvgIcon>
  );
}
