import BaseInvoker from './BaseInvoker';
import { Component } from 'react';

const urlInvoke = '/api';
const baseInvoker = new BaseInvoker();

export default class ScheduleApi extends Component {

	static getScheduleModel(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule-model/${scheduleId}`, callback, callerror);
	}

	static getSchedulesReasign(params, callback, callerror) {
		const {
			startDate, endDate, employeeId, appointmentId, practiceTypeId, customerId, startTime, evenDay, dayOfWeek
		} = params;
		const url = `${urlInvoke}/schedules-reasign/?date-from=${startDate}&date-to=${endDate}${employeeId}${appointmentId}${practiceTypeId}${customerId}${startTime}${evenDay}${dayOfWeek}`;
		baseInvoker.get(
			url,
			callback,
			callerror,
		);
	}

	static finishBySystem(scheduleId, body, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/schedule/${scheduleId}/finish-by-system`, body, callback, callerror);
	}

	static getDoneSchedulesReport(params, callback, callerror) {
		baseInvoker.getBlob(`${urlInvoke}/schedule/done/filter?${params}`, callback, callerror);
	}

}