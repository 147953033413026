import { Assignment, Check } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Button from '../../CustomButtons/Button';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import { Checkbox } from '@material-ui/core';
import ConfirmAlert from '../../ConfirmAlert';
import CustomInput from '../../CustomInput/CustomInput';
import { DeleteDocument } from '../../../icons';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import RadioInput from '../../RadioInput';
import Table from '../../Table/Table';
import { browserHistory } from 'react-router';
import config from '../../../config/config';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import stylesCheckbox from '../../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateTimeSmallFormat = config.getDateTimeSmallFormat();

const useStyles = makeStyles(stylesCheckbox);

const styles = makeStyles({
  radioInputContainer: {
    display: "flex",
    flexDirection: "column !important",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
})

const AppointmentCancel = ({ t, params }) => {
  const classes = styles();
  const classesCheck = useStyles();

  const [appointment, setAppointment] = useState({ createdDateTime: new Date() });
  const [ detailText, setDetailText ] = useState('');
  const [ appointmentCancellableDetail, setAppointmentCancellableDetail ] = useState([]);
  const [ alreadyCancelled, setAlreadyCancelled ] = useState([])
  const [ confirmationModal, setConfirmationModal ] = useState(false);
  const [ successModal, setSuccessModal ] = useState(false);
  const [ errorModal, setErrorModal ] = useState(false);
  const [ cancellationReason, setCancellationReason ] = useState('');
  const [ cancellationType, setCancellationType ] = useState('1');
  const [ markedToBeCancelled, setMarkedToBeCancelled ] = useState([]);

  const changeCancelationType = (type) => {
    if (type === '1') {
      setMarkedToBeCancelled([]);
    }
    setCancellationType(type);
  }

  const markToBeCanceled = (checked, newOne) => {
    if (checked) {
      setMarkedToBeCancelled(markedToBeCancelled.concat([newOne]));
      return;
    }
    setMarkedToBeCancelled(markedToBeCancelled.filter(id => id !== newOne));
  }

  const performCancelation = () => {
    const body = {
      reason: cancellationReason,
      appointmentItemRequestsIds: markedToBeCancelled,
    }
    AppointmentApiInvoker.cancelAppointment(params.id, body, () => {
      setConfirmationModal(false);
      setErrorModal(false);
      setSuccessModal(true);
    }, () => {
      setConfirmationModal(false);
      setSuccessModal(false);
      setErrorModal(true);
    });
  }

  const cancel = () => {
    browserHistory.push('/solicitudes');
  }

  useEffect(() => {
    AppointmentApiInvoker.getAppointmentCancellations(params.id, 
      (res) => {
        const canceled = res.filter(p => p.canceled)
        .map(p => p.appointmentDetailId);
        setAlreadyCancelled(canceled);
        setAppointmentCancellableDetail(res);
      }, (error) => {
        console.error("Error getting cancelable practices: ", error);
      });
      setAppointment((prev) => ({
        ...prev,
        id: params.id,
      }));
  }, [params.id])

  useEffect(() => {
    const text = cancellationType === '2' ? markedToBeCancelled.map((detailId) => {
        const detail = appointmentCancellableDetail.find(app => app.appointmentItemRequestId === detailId) || {};
        return `- ${t(detail.type)} ${detail.order}: ${detail.speciality}`;
      })
      : ''
    setDetailText(text);
  }, [cancellationType, markedToBeCancelled, appointmentCancellableDetail])

  const radioElements = [
    { id: '1', value: t('appointment.cancel.total_cancellation'), component: true },
    { id: '2', value: t('appointment.cancel.partial_cancellation'), component: false },
  ];

  const isChecked = (appointmentItemRequestId) => {
    const markedCancelled = markedToBeCancelled.indexOf(appointmentItemRequestId) > -1;
    const canceled = alreadyCancelled.indexOf(appointmentItemRequestId) > -1;
    return markedCancelled || canceled;
  }

  const formatAppointmentCancellableDetail = (data) => {
    return data.map((d) => {
      const appointmentDetailId = d.appointmentItemRequestId;
      const select = <Checkbox
        tabIndex={-1}
        onChange={(e) => markToBeCanceled(e.target.checked, d.appointmentItemRequestId)}
        disabled={alreadyCancelled.indexOf(d.appointmentItemRequestId) > -1}
        checked={isChecked(d.appointmentItemRequestId)}
        checkedIcon={<Check className={classesCheck.checkedIcon} />}
        icon={<Check className={classesCheck.uncheckedIcon} />}
        classes={{
            checked: classesCheck.checked,
            root: classesCheck.checkRoot,
        }}
      />;
      return {
        select,
        id: d.order,
        companyPartnershipName: d.companyPartnershipName,
        appointmentDetailId,
        type: t(d.type),
        speciality: d.speciality,
        startDate: moment(d.startDate).format(dateFormat),
        quantity: d.count,
      }
    });
  }

  return (
    <GridContainer className="cancellation-form">
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer className={classes.spaceBetween}>
              <GridItem xs={6} sm={4}>
                <CustomInput
                  id="appointment-id"
                  labelText={t('appointment.input.appointment_id')}
                  value={params.id}
                  formControlProps={{ disabled: true }}
                />
              </GridItem>
              <GridItem xs={6} sm={8}>
                <CustomInput
                  id="init_date"
                  labelText={t('appointment.input.init_date')}
                  value={ appointment ? moment(appointment.createdDateTime).format(dateTimeSmallFormat) : null }
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <DeleteDocument />
            </CardIcon>
            <h3 className="card-icon-title">{t('appointment.cancel.title')}</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem className="cancellation-content" xs={12}>
                <RadioInput
                  className={classes.radioInputContainer}
                  title={''}
                  value={cancellationType}
                  onChangeValue={(value) => { changeCancelationType(value) }}
                  elements={radioElements}
                />
                {cancellationType === '2' && (
                  <Table 
                    loading={false}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={false}
                    tableHead={[
                      { Header: '', accessor: 'select' },
                      { Header: t('cancellations.order'), accessor: 'id' },
                      { Header: t('label.provider'), accessor: 'companyPartnershipName' },
                      { Header: t('cancellations.type'), accessor: 'type' },
                      { Header: t('cancellations.name'), accessor: 'speciality' },
                      { Header: t('cancellations.date_init'), accessor: 'startDate' },
                      { Header: t('cancellations.quantity'), accessor: 'quantity' },
                    ]}
                    tableData={formatAppointmentCancellableDetail(appointmentCancellableDetail)}
                    colorsColls={["primary"]}
                  />
                  )
                }
              </GridItem>
              <GridItem xs={6} sm={6}>
                <h4>{t('appointment.cancel.cancellation_reason')}</h4>
                <CustomInput
                  id="cancellation_reason"
                  labelText={t('appointment.cancel.cancellation_reason')}
                  value={cancellationReason}
                  onChange={(e) => setCancellationReason(e.target.value)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} className={classes.spaceBetween}>
        <Button id="button-cancel" onClick={cancel}>{t('appointment.cancel.cancel')}</Button>
        <Button
          disabled={cancellationType === '2' && !markedToBeCancelled.length} 
          id="button-cancel-save"
          onClick={()=> setConfirmationModal(true)}
          color="primary"
        >
          {t('appointment.cancel.save')}
        </Button>
      </GridItem>
      <ConfirmAlert
        closeOnClickOutside={false}
        confirmBtnText={'appointment.cancel.modal.confirmBtnText'}
        cancelBtnText={'appointment.cancel.modal.cancelBtnText'}
        confirmTitle={'appointment.cancel.modal.confirmTitle'}
        confirmBodyText={(cancellationType === '2' ? t('appointment.cancel.modal.confirmBodyTextPartial') : t('appointment.cancel.modal.confirmBodyText', { appointmentId: params.id }))}
        confirmBodyTextSecondLine={detailText}
        successTitle={t('appointment.cancel.modal.successTitle')}
        successBodyText={cancellationType === '2' ? t('appointment.cancel.modal.successBodyTextPartial') : t('appointment.cancel.modal.successBodyText', { appointmentId: params.id })}
        successBodyTextSecondLine={detailText}
        successBodyTextThirdLine={cancellationType === '2' && t('appointment.cancel.modal.successBodyTextPartialSecondLine') ? t('appointment.cancel.modal.successBodyTextPartialSecondLine') : '' }
        errorBodyText={t('appointment.cancel.modal.errorBodyText', { appointmentId: params.id })}
        onConfirm={()=> {
          performCancelation();
        }}
        onSuccess={() => browserHistory.push('/solicitudes')}
        onClose={()=>{
          setConfirmationModal(false);
          setSuccessModal(false);
          setErrorModal(false);
        }}
        showConfirmWithText={confirmationModal}
        success={successModal}
        error={errorModal}
        useCustomStyles={cancellationType === '2'}
      />
    </GridContainer>
  )
}

AppointmentCancel.propTypes = {
  t: PropTypes.func,
  params: PropTypes.object,
}

export default withTranslation()(AppointmentCancel);
