import { DOCUMENT_TYPESP } from '../actions/newEmployee';

const newEmployee = (state = {}, action, root) => {
    if (action.type === DOCUMENT_TYPESP) {
        return { ...state, documentTypes: action.documentTypes };
    }
    return state;
}

export default newEmployee;
