import {  FINANCIERS, PROVINCIES, EMPLOYEETYPES, EMPLOYEES } from "../actions/dashboardprofesional";

const dashboardprofesional = (state = {}, action) => {
    switch(action.type) {
        case FINANCIERS: {
            const financiers = action.financiers
            return {...state, financiers};
        }
        case EMPLOYEETYPES: {
            const employeeTypes = action.employeeTypes
            return {...state, employeeTypes};
        }
        case PROVINCIES: {
            const provincies = action.provincies
            return {...state, provincies};
        }
        case EMPLOYEES: {
            const employees = action.employees
            return {...state, employees};
        }
        default:
            return state;
    }
}

export default dashboardprofesional;
