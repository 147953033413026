import classnames from 'classnames';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { TextField } from '@material-ui/core'

class TextareaInput extends Component {
  handleChange(e) {
    this.props.onChangeValue(e.target.value);
  }

  render() {
    const { className, disabled, errorText, id, invalid, text, value } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={7}>
          <TextField
            id={id}
            multiline
            className={classnames('', className)}
            fullWidth
            disabled={disabled}
            error={invalid}
            label={text}
            rowsMax="10"
            helperText={errorText}
            value={value}
            onChange={e => this.handleChange(e)}
            margin="normal"
            variant="outlined"
          />
        </GridItem>
      </GridContainer>
    )
  }
}

TextareaInput.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChangeValue: PropTypes.func,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}

export default TextareaInput
