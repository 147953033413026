import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Chip } from '@material/react-chips';
import GridContainer from '../../Grid/GridContainer';
import './Filters.css';

class Filters extends Component {
  
  onClickChip = () => {
    this.props.onClickChip();
  }
  render() {
    const { t } = this.props;

    return (
      <GridContainer className="filters" justify="flex-start">
        <Chip
          key="filter"
          className="chipsInFilter"
          filter={true}
          id="filter"
          onClick={this.onClickChip}
          label={t('appointment.avanced.find')}
        />
      </GridContainer>
    )
  }
}

Filters.propTypes = {
  t: PropTypes.func,
  onClickChip: PropTypes.func,
}

export default withTranslation()(Filters);
